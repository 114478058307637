import {
  IonContent,
  IonPage,
  IonRow,
  IonButton,
  IonText,
  useIonToast,
  IonCol,
  IonFooter,
  IonToolbar,
  IonModal,
  IonLoading,
  useIonAlert,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import instance from "../../utils/api";
import moment from "moment";
import { Star } from "@styled-icons/bootstrap/Star";
import { StarFill } from "@styled-icons/bootstrap/StarFill";
import "./index.css";
import { useHistory } from "react-router";
import CreateDischarge from "./createDischarge";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";
import Ratings from "./Ratings";
import ReactTooltip from "react-tooltip";
import ProgressBar from "./ProgressBar";
import RecentDischarges from "./RecentDischarges";

const DisChargeDocuments: React.FC = () => {
  const [openModal, setOpenModal] = useState<any>(false);
  const [dischargeList, setDischargeList] = useState<any>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, dismiss] = useIonToast();
  const [feedBackData, setFeedBackData] = useState<any>();
  const [modalValue, setModalValue] = useState<any>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [dischargeData, setDischargeData] = useState<any>([]);
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [postCareModal, setPostCareModal] = useState<any>(false);
  const [allRatingModal, setAllRatingModal] = useState<any>(false);
  const [searchData, setSearchData] = useState<any>([])
  const getDischarges = async () => {
    setShowLoading(true);
    await instance({
      method: "GET",
      url: "discharge/getDischarge",
      validateStatus: () => true,
    })
      .then((resp) => {
        let obj: any = {};
        obj = resp;
        setShowLoading(false);
        if (obj.status === "SUCCESS!") {
          let data = obj.result;
          setDischargeList(data);
          setShowLoading(false);
        } else {
          setShowLoading(false);
        }
      })
      .catch(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    getDischarges();
  }, []);

  
  const dismissCreateDischarge = () => {
    setOpenModal(false);
  };

  const createPatientModal = (value: any, item: any) => {
    setOpenModal(true);
    setModalValue(value);
    if (value === "patientProfile") {
      setFeedBackData(item);
    } else {
      setFeedBackData("");
    }
  };

  const [nameSortConfig, setNameSortConfig] = useState<any>({
    key: "name",
    direction: "ascending",
  });
  const [dateSortConfig, setdateSortConfig] = useState<any>({
    key: "timeStamp",
    direction: "ascending",
  });

  const sorting = () => {
    let sortableItems = [...dischargeData];
    if (nameSortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (nameSortConfig.key === "name") {
          if (
            a[nameSortConfig.key].toLowerCase() <
            b[nameSortConfig.key].toLowerCase()
          ) {
            return nameSortConfig.direction === "ascending" ? -1 : 1;
          }
          if (
            a[nameSortConfig.key].toLowerCase() >
            b[nameSortConfig.key].toLowerCase()
          ) {
            return nameSortConfig.direction === "ascending" ? 1 : -1;
          }
        }
        return 0;
      });
    }
    return setDischargeData(sortableItems);
  };

  const datesorting = () => {
    let sortableItems = [...dischargeData];
    if (nameSortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (dateSortConfig.key === "timestamp") {
          let date: any = "";
          date = moment(a[dateSortConfig.key]).format("YYYYMMDD");
          let dates: any = "";
          dates = moment(b[dateSortConfig.key]).format("YYYYMMDD");
          if (date < dates) {
            return dateSortConfig.direction === "ascending" ? -1 : 1;
          }
          if (date > dates) {
            return dateSortConfig.direction === "ascending" ? 1 : -1;
          }
        }
        return 0;
      });
    }
    return setDischargeData(sortableItems);
  };
  useEffect(() => {
    sorting();
  }, [nameSortConfig]);

  useEffect(() => {
    datesorting();
  }, [dateSortConfig]);

  const onChangeSearch = (query: any) => {
    const orginal = dischargeData
    if (query.detail.value) {
      const filteredData = orginal.filter(
        item => {
          return (
            item.name.toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
            new Date(item.timestamp).toLocaleDateString(
              "en-US",
              { timeZone: "America/Los_Angeles" }
            ).toLowerCase().includes(query.detail.value.toLowerCase().trim())
          );
        }
      );
      setSearchData(filteredData)
    }
    else {
      setSearchData(orginal)
    }
  }
  const requestSort = (key: any, value: any) => {
    let direction = "ascending";
    if (value === "date") {
      if (
        dateSortConfig &&
        dateSortConfig.key === key &&
        dateSortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setdateSortConfig({ key, direction });
    } else if (value === "name") {
      if (
        nameSortConfig &&
        nameSortConfig.key === key &&
        nameSortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setNameSortConfig({ key, direction });
    }
  };
 
  const hideratingModal = () => {
    setAllRatingModal(false)
  }
 
  return (
    <IonPage className="fnt-fm-rbt">
      <Header popover={true} unreadCount={
        dischargeList && dischargeList.qnas && dischargeList.qnas.filter((item: any) => item.isAnswerFound === false).length
      }></Header>
      <IonContent style={{ height: "100%" }} className="discharge-content">
        <IonRow className="cards-head-title-bg ht-100 ion-justify-content-center ion-align-items-center">
          <div style={{ width: "85%" }}>
            <IonRow className="ion-justify-content-between ion-align-items-center">
              <div className="ion-padding-vertical cards-head-title-txt">
                <IonText className="trt-hd">Discharge Insights</IonText>
              </div>
              <div>
                <IonRow className="ion-align-items-center">
                  <div style={{ marginRight: "20px" }}>
                    <ProgressBar />
                  </div>
                  <IonButton
                    className="button button-txt mg-0"
                    onClick={() => createPatientModal("createDischarge", "")}
                  >
                    Create Discharge
                  </IonButton>
                </IonRow>
              </div>
            </IonRow>
          </div>
          <div style={{ width: "85%" }}>
            <IonRow className="ion-justify-content-between ion-wrap">
              <div className="card-box">
                <div className="flex-col">
                  <div
                    className="flex-row ion-justify-content-center"
                    style={{ flexDirection: "column" }}
                  >
                    <IonText className="content-title-txt">
                      Patient Engagement %
                    </IonText>
                    <div style={{ paddingTop: "8px" }}>
                      <IonText className="card-count-txt">
                        {dischargeList &&
                          dischargeList.patientEngagement &&
                          dischargeList.patientEngagement.avarage
                          ? dischargeList.patientEngagement.avarage + "%"
                          : "0%"}
                      </IonText>
                    </div>
                  </div>
                  <div></div>
                  <IonRow className="ion-padding-top ion-justify-content-between ion-align-items-center">
                    <div style={{ width: "9rem" }}>
                      <div
                        className="card-status-txt">
                        <IonText>Total Discharges</IonText>
                      </div>
                      <div>
                        <div
                          style={{ paddingTop: "8px" }}
                          className="card-count-txt"
                        >
                          {dischargeList &&
                            dischargeList.patientEngagement &&
                            dischargeList.patientEngagement.total
                            ? dischargeList.patientEngagement.total
                            : "0"}
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "9rem" }}>
                      <div>
                        <IonText
                          className="card-status-txt">
                          Discharges Viewed
                        </IonText>
                      </div>
                      <div>
                        <div
                          style={{ paddingTop: "8px" }}
                          className="card-count-txt"
                        >
                          {dischargeList &&
                            dischargeList.patientEngagement &&
                            dischargeList.patientEngagement.viewd
                            ? dischargeList.patientEngagement.viewd
                            : 0}
                        </div>
                      </div>
                    </div>
                  </IonRow>
                </div>
              </div>
              <div className="card-box">
                <div className="flex-col">
                  <div
                    className="flex-row ion-justify-content-center"
                    style={{ flexDirection: "column" }}
                  >
                    <IonText className="content-title-txt ">
                      Post Care Provided %
                    </IonText>
                    <div style={{ paddingTop: "8px" }}>
                      <IonText className="card-count-txt">
                        {dischargeList &&
                          dischargeList.postCare &&
                          dischargeList.postCare.avarage
                          ? dischargeList.postCare.avarage + "%"
                          : "0 %"}
                      </IonText>
                    </div>
                  </div>
                  <div></div>
                  <IonRow className="ion-padding-top ion-justify-content-between ion-align-items-center">
                    <div style={{ width: "9rem" }}>
                      <div
                        className="card-status-txt">
                        <IonText>Question Asked</IonText>
                      </div>
                      <div>
                        <div
                          style={{ paddingTop: "8px" }}
                          className="card-count-txt"
                        >
                          {dischargeList &&
                            dischargeList.postCare &&
                            dischargeList.postCare.questionAsked
                            ? dischargeList.postCare.questionAsked
                            : 0}
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "9rem" }}>
                      <div>
                        <IonText
                          className="card-status-txt">
                          Ai Answered
                        </IonText>
                      </div>
                      <div>
                        <div
                          style={{ paddingTop: "8px" }}
                          className="card-count-txt">
                          {dischargeList &&
                            dischargeList.postCare &&
                            dischargeList.postCare.aiAnswered
                            ? dischargeList.postCare.aiAnswered
                            : 0}
                        </div>
                      </div>
                    </div>
                  </IonRow>
                </div>
              </div>
              <div className="card-box">
                <div className="flex-col">
                  <div
                    className="flex-row ion-justify-content-center"
                    style={{ flexDirection: "column" }}
                  >
                    <IonText className="content-title-txt  ion-padding-bottom">
                      Impact on Hospital
                    </IonText>
                  </div>
                  <div></div>
                  <IonRow className="ion-padding-top ion-justify-content-between ion-align-items-center">
                    <div className="pointer"
                      style={{ width: "9rem" }}
                      onClick={() => setAllRatingModal(true)}
                    >
                      <div
                        className="card-status-txt">
                        <IonText>Rating <InfoCircle color="#00B9CF" size={15} data-tip="Click here to see reviews" data-for="review" />
                        </IonText>
                        <ReactTooltip backgroundColor="white" className="tooltip" place="bottom" textColor="black" id='review' />
                      </div>
                      <div>
                        <div style={{ paddingTop: "8px" }}>
                          {" "}
                          {5 > 0
                            ? [...Array(5)].map((x, index) => (
                              <span key={index}>
                                {dischargeList &&
                                  dischargeList.impact &&
                                  dischargeList.impact.rating &&
                                  dischargeList.impact.rating > index ? (
                                  <span key={x} style={{ padding: "2px" }}>
                                    <StarFill size="12" color="#00B9CF" />
                                  </span>
                                ) : (
                                  <span key={x} style={{ padding: "2px" }}>
                                    <Star size="12" color="#00B9CF" />
                                  </span>
                                )}
                              </span>
                            ))
                            : null}
                        </div>
                        <div className="card-count-txt">
                          <IonText>
                            {dischargeList &&
                              dischargeList.impact &&
                              dischargeList.impact.count
                              ? dischargeList.impact.count
                              : 0}
                          </IonText>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "9rem" }}>
                      <div>
                        <IonText
                          className="card-status-txt">
                          Time Savings
                        </IonText>
                      </div>
                      <div>
                        <div
                          style={{ paddingTop: "8px" }}
                          className="card-count-txt"
                        >
                          {" "}
                          <IonText>
                            {dischargeList &&
                              dischargeList.impact &&
                              dischargeList.impact.timeSaving
                              ? Math.floor(
                                dischargeList.impact.timeSaving / 60
                              ) +
                              "hr" +
                              " " +
                              (dischargeList.impact.timeSaving % 60) +
                              "min"
                              : "0hr"}
                          </IonText>
                        </div>
                      </div>
                    </div>
                  </IonRow>
                </div>
              </div>
            </IonRow>
          </div>
        </IonRow>
        <RecentDischarges/>
        <IonFooter>
          <IonToolbar style={{ padding: "20px 0px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src={require(`../../images/final_logo.png`).default}
                  style={{ maxWidth: "fit-content" }}
                  height="38px"
                  width="37px"
                  alt=""
                />
              </div>
              <IonRow>
                <IonCol className="pad-0">
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonText className="footer-logo-txt">myBenefits.ai</IonText>
                  </IonRow>
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonText className="footer-subtitle">Treat Now, Pay Later</IonText>
                  </IonRow>
                </IonCol>
              </IonRow>
            </div>
            <div>
              <IonRow
                className="ion-align-items-center ion-justify-content-center"
                style={{ paddingTop: "10px" }}
              >
                <div
                  style={{ paddingRight: "12px" }}
                  className="footer-terms-hover pointer"
                >
                  Privacy Policy | Terms of Use
                </div>
                <div>© 2023 mybenefits.ai Inc | All rights reserved</div>
              </IonRow>
            </div>
          </IonToolbar>
        </IonFooter>
      </IonContent>
     
      {/* {modalValue !== "editCategory" ? (
        <IonModal isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)}>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className="popup-header"
          >
            <IonRow className="close pointer" onClick={() => hideModal()}>
              <CloseOutline
                title="Close"
                size={30}
                color="#FFFFFF"
              ></CloseOutline>
            </IonRow>
            <IonRow
              className="ion-justify-content-center popup-title ion-padding"
              style={{ fontSize: "30px" }}
            >
              <IonText>
                {modalValue === "message"
                  ? " Send Message to"
                  : modalValue === "pain"
                    ? "  Pain Feedback from"
                    : modalValue === "ratings"
                      ? "Ratings"
                      : "Feedback from"}
                {"  "} {feedBackData && feedBackData.name}
              </IonText>
            </IonRow>
          </div>
          {modalValue === "message" ? (
            <>
              <IonContent>
                <Formik
                  initialValues={{ message: "" }}
                  onSubmit={messageApiCall}
                  validationSchema={yup.object({
                    message: yup.string().required("This field is required"),
                  })}
                >
                  {(formikProps) => (
                    <form
                      onSubmit={formikProps.handleSubmit}
                      id="form"
                      style={{ height: "100%" }}
                    >
                      <IonRow
                        style={{
                          maxWidth: "430px",
                          margin: "0 auto",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <IonCol className="fields-row-pd">
                          <IonRow>
                            <IonCol className="pad-0">
                             
                              <IonTextarea
                                name="message"
                                placeholder="Type Your Message"
                                className="msg-txt-area"
                                onBlur={formikProps.handleBlur}
                                onIonChange={formikProps.handleChange}
                                value={formikProps.values.message}
                                maxlength={250}
                              ></IonTextarea>
                              <p
                                className="fielderrormsg"
                                style={{ marginBottom: "0px" }}
                              >
                                {formikProps.touched.message &&
                                  formikProps.errors.message}
                              </p>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </form>
                  )}
                </Formik>
              </IonContent>
              <IonFooter>
                <IonRow className="ion-padding-vertical ion-justify-content-center">
                  <IonButton form="form" className="nextbtn" type="submit">
                    Send Message
                  </IonButton>
                </IonRow>
              </IonFooter>
            </>
          ) : modalValue === "pain" ? (
            <>
              <IonContent>
                <IonRow className="ion-padding-bottom ion-justify-content-center">
                  <IonCol style={{ padding: "0px 5rem" }}>
                    {feedBackData &&
                      feedBackData.pain &&
                      feedBackData.pain.length > 0 ? (
                      feedBackData.pain.sort((a: any, b: any) => moment(a.timestamp).format("YYYYMMDD") > moment(b.timestamp).format("YYYYMMDD") ? 1 : -1).map((item: any, index: any) => (
                        <div className="pain-box" key={index}>
                          <IonRow>
                            <div className="painfeed-list-title patient-th">
                              Date
                            </div>
                            <div className="td-txt item-pd-5">
                              {" "}
                              {item.timestamp
                                ? new Date(item.timestamp).toLocaleDateString(
                                  "en-US",
                                  { timeZone: "America/Los_Angeles" }
                                )
                                : "NA"}
                            </div>
                          </IonRow>
                          <IonRow>
                            <div className="painfeed-list-title patient-th">
                              Time
                            </div>
                            <div className="td-txt item-pd-5">
                              {" "}
                              {item.timestamp
                                ? new Date(item.timestamp).toLocaleTimeString(
                                  "en-US",
                                  { timeZone: "America/Los_Angeles" }
                                )
                                : "NA"}
                            </div>
                          </IonRow>
                          <IonRow>
                            <div className="painfeed-list-title patient-th">
                              Pain Level
                            </div>
                            <div className="td-txt item-pd-5">
                              {item.rating ? item.rating + "/10" : "NA"}
                            </div>
                          </IonRow>
                          <IonRow>
                            <div className="painfeed-list-title patient-th">
                              Comment by Patient
                            </div>
                            <div className="td-txt item-pd-5">
                              {item.message ? item.message : "NA"}
                            </div>
                          </IonRow>
                        </div>
                      ))
                    ) : (
                      <IonRow className="ion-padding-vertical ion-justify-content-center">
                        <div className="no-data-msg">No Data Found</div>
                      </IonRow>
                    )}
                  </IonCol>
                </IonRow>
              </IonContent>
              <IonFooter>
                <IonRow className="ion-padding-vertical ion-justify-content-center">
                  <IonButton
                    className="btn-wd-100 nextbtn"
                    onClick={() => setModalOpen(false)}
                  >
                    Close
                  </IonButton>
                </IonRow>
              </IonFooter>
            </>
          ) : modalValue === "feedback" ? (
            <>
              <IonContent>
                <IonRow className="ion-margin-vertical ion-padding-vertical ion-justify-content-center">
                  {feedBackData &&
                    feedBackData.rating &&
                    feedBackData.comment ? (
                    <table className="tnplDetails-tb">
                      <thead>
                        <tr className="tr-ht-30">
                          <td className="patient-th">Rating</td>
                          <td className="td-txt tb-td-txt">
                            <span>
                              {feedBackData && feedBackData.rating
                                ? [...Array(5)].map((x, index) => (
                                  <span key={index}>
                                    {feedBackData &&
                                      feedBackData.rating &&
                                      feedBackData.rating &&
                                      feedBackData.rating > index ? (
                                      <span key={x}>
                                        <StarFill color="#00B9CF" size="20" />
                                      </span>
                                    ) : (
                                      <span key={x}>
                                        <Star size="20" color="#7C7878" />
                                      </span>
                                    )}
                                  </span>
                                ))
                                : "NA"}
                            </span>
                          </td>
                        </tr>
                        <tr className="tr-ht-30">
                          <td className="patient-th ion-padding-top">Review</td>
                          <td className="td-txt tb-td-txt ion-padding-top">
                            {feedBackData && feedBackData.comment
                              ? feedBackData.comment
                              : "NA"}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  ) : (
                    <div className="no-data-msg ion-justify-content-center">
                      Feedback not Provided
                    </div>
                  )}
                </IonRow>
              </IonContent>
              <IonFooter>
                <IonRow className="ion-padding-vertical ion-justify-content-center">
                  <IonButton
                    className="btn-wd-100 nextbtn"
                    onClick={() => setModalOpen(false)}
                  >
                    Close
                  </IonButton>
                </IonRow>
              </IonFooter>
            </>
          ) : null}
        </IonModal>
      ) : null}
      <IonModal
        isOpen={postCareModal}
        onDidDismiss={() => hideModal()}
        id="postcare-modal"
      >
        <PostCareUpload
          userId={feedBackData && feedBackData.user}
          hideModal={hideModal} providerId={feedBackData && feedBackData._id}
          name={feedBackData && feedBackData.name}
        />
      </IonModal>
      <IonModal
        isOpen={openModal}
        id={modalValue === "createDischarge" ? "createModal" : "profileModal"}
        onDidDismiss={() => setOpenModal(false)}
      >
        <CreateDischarge
          dismissModal={dismissCreateDischarge}
          getDischarge={getDischarges}
          modalValue={modalValue}
          PatientProfile={feedBackData}
          userId={feedBackData && feedBackData._id}
        />
      </IonModal>
      {modalValue === "editCategory" && (
        <IonModal
          isOpen={modalOpen}
          id="edit-modal"
          onDidDismiss={() => setModalOpen(false)}
        >
          <EditCategory
            userId={feedBackData && feedBackData._id}
            name={feedBackData && feedBackData.name}
            hideModal={hideModal}
          />
        </IonModal>
      )}*/}
       <IonModal
        isOpen={openModal}
        id={modalValue === "createDischarge" ? "createModal" : "profileModal"}
        onDidDismiss={() => setOpenModal(false)}
      >
        <CreateDischarge
          dismissModal={dismissCreateDischarge}
          getDischarge={getDischarges}
          modalValue={modalValue}
          PatientProfile={feedBackData}
          userId={feedBackData && feedBackData._id}
        />
      </IonModal>
      <IonModal isOpen={allRatingModal} onDidDismiss={() => hideratingModal()} id="rating-modal">
        <Ratings dissmissModal={hideratingModal} ratingsData={dischargeData}></Ratings>
      </IonModal>
      <IonLoading isOpen={showLoading} message="Please wait..." />
    </IonPage>
  );
};

export default DisChargeDocuments;
