import { IonCol, useIonToast, 
    IonLoading,IonButton, IonContent, IonToast, IonInput, IonLabel, IonPage, IonRow, IonText } from '@ionic/react';
import { useState} from 'react';
import { useHistory } from "react-router-dom";
import instance from '../../../utils/api';
import '../AdminDashboard/index.css'
const AdminLogin:React.FC=()=>{
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [showToast, dismiss] = useIonToast();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState();
    const [showToast1, setShowToast1] = useState(false);
    const history = useHistory();
    const next = async () => {
        // window.location.href="/admin_dashboard";
        let data = {
            email,
            password,
        }
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z]{3,20}[\.][a-zA-Z]{2,5}/g;
        const result = pattern.test(email);
        if (!email) {
            showToast('Please Enter Email Id', 3000)
        }
        // else if (result === false) {
        //     showToast('Enter valid Email Id', 3000)
        // }
        else if (!password) {
            showToast('Please Enter Password', 3000)
        }
        else {
            setShowLoading(true)
            await instance({
                method: 'POST',
                url: `user/adminLogin`,
                data,
                validateStatus: () => true
            }).then(res => {
                let obj: any = {};
                obj = res
                if (obj.status === "SUCCESS!") {
                    instance.defaults.headers.common['Authorization'] = obj.result.token;
                    localStorage.setItem("auth_token", obj.result.token);
                    localStorage.setItem("admin_name",obj.result.firstName);
                    history.push("admin_dashboard");
                    setShowLoading(false)
                }
                else {
                    setShowLoading(false);
                    setErrorMessage(obj.error.message);
                    setShowToast1(true);
                }
            })
        }
    }
return(
    <IonPage>
    <IonContent fullscreen>
        <IonRow className="ion-justify-content-center display-flex">
            <IonCol className='pad-0'>
                <IonRow className="ion-justify-content-center myben-title-pd-tp">
                    <div style={{ display: 'flex' }}>
                        <img src={require(`../../../images/final_logo.png`).default} height="38px" width="37px" alt="" />
                    </div>
                    <IonRow className='ion-padding-bottom'>
                        <IonCol>
                            <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                            {/* <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='subtitle'>Treat Now, Pay Later</IonText></IonRow> */}
                        </IonCol>
                    </IonRow>
                </IonRow>
                <IonRow className='ion-justify-content-center ion-padding-bottom ion-padding-top'>
                            <IonText className='pass-title-fnt'>Admin</IonText>
                        </IonRow>
                <IonRow style={{ maxWidth: '430px', margin: '0 auto' }}>
                    <IonCol className="fields-row-pd">
                        <IonRow className='ion-padding-top'>
                            <IonCol className="pad-0">
                                <IonLabel className='label'>Enter Email</IonLabel>
                                <IonInput type="text" autocomplete='on' className="input-field mg-top placeholder-txt" placeholder="Email" value={email}
                                   onIonInput={(e:any)=>{setEmail(e.target.value.trim())}}
                                > </IonInput>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-top'>
                            <IonCol className="pad-0">
                                <IonLabel className='label'>Enter Password</IonLabel>
                                <IonInput type="password" className="ion-padding input-field mg-top placeholder-txt" placeholder="****" value={password}
                                    onIonChange={e => setPassword(e.detail.value!)}
                                > </IonInput>
                            </IonCol>
                        </IonRow>
                        <IonButton expand="block" className='searchbtn searchbtn-txt' onClick={() => next()}>Login</IonButton>
                        {/* <IonRow className='signuprow ion-justify-content-center'>
                            <span>Don't have an account</span>?<span className='signup' onClick={() => { window.location.href = '/login' }}>Sign up</span>
                        </IonRow> */}
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
        <IonLoading isOpen={showLoading} message={'Please wait...'} />
        <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={errorMessage}
        duration={3000}
    />
    </IonContent>
</IonPage>
)
}
export default AdminLogin;