import { IonContent, IonGrid, IonPage, IonButton, IonRow, IonText } from "@ionic/react"
import { useState } from "react";
import './index.css';
const AmountApproved: React.FC = () => {
    const [approved, setApproved] = useState<any>(true);
    const patientName = localStorage.getItem("patientName")
    return (
        <IonPage className="bg-cnt">
            <IonContent fullscreen>
                {approved === true ?
                    <div className="ht-100 flex-col-amnt pad-0">
                        <div className="flex-d-col ht-80 bg-clr scroll-y-auto">
                            <IonRow className="ion-justify-content-center cong-txt"><IonText>Congratulations {patientName}!
                            </IonText></IonRow>
                            <IonRow className="ion-justify-content-center cong-sub-txt ion-padding-top"><IonText>You have
                            </IonText></IonRow>
                            <IonRow className="ion-justify-content-center cong-sub-txt amt-txt"><IonText> $5,000
                            </IonText></IonRow>
                            <IonRow className="ion-justify-content-center cong-sub-txt ion-padding-vertical"><IonText> pre-approved in your myBenefits Plan.
                            </IonText></IonRow>
                            <IonRow className="ion-justify-content-center">
                                <img src={require(`../../../images/dog.png`).default} alt="" />
                            </IonRow>
                        </div>
                        <div className="ion-padding btn-bg">
                            <IonButton expand="block" onClick={()=>window.location.href="/request_amount"} className='provoiders-btn provoiders-btn-txt pro-btn-wd'>Next</IonButton>
                        </div>
                    </div>
                    :
                    <div className="ht-100 flex-col-amnt pad-0">
                        <div className="flex-d-col ht-80 bg-clr scroll-y-auto">
                            <IonRow className="ion-justify-content-center ion-padding-bottom">
                                <img src={require(`../../../images/close.png`).default} alt="" />
                            </IonRow>
                            <IonRow className="ion-justify-content-center ion-padding-vertical deneid-txt cong-txt">
                                <IonText>Sorry, myBenefits is not available for you</IonText>
                            </IonRow>
                            <IonRow className="ion-justify-content-center deneid-sub-txt cong-txt">
                                <IonText>Please work with your Provider to discuss other payment options.</IonText>
                            </IonRow>
                        </div>
                        <div className="ion-padding btn-bg">
                            <IonButton expand="block" type='submit' className='provoiders-btn provoiders-btn-txt'>Close</IonButton>
                        </div>
                    </div>
                }
            </IonContent>
        </IonPage>
    )
}
export default AmountApproved;