import { IonCol, IonGrid,IonRow } from "@ionic/react";

interface Props {
    postCareFollowUp: [],
    endSurvey:[]
}
const PostCareFollowUp: React.FC<Props> = (props) => {
    const postCareSurvey = props && props.postCareFollowUp;
    const endSurvey = props && props.endSurvey;
    return (
        <>
        <IonGrid style={{padding:"10px"}}>
        <IonCol>
            {postCareSurvey.length > 0 ?
                postCareSurvey.map((value: any, index: any) => (
                    <div key={index} className="survey-box">
                        <div className="survey-qns">
                            {value.question}
                        </div>
                        <div className="survey-ans">{value.answer}</div>
                    </div>
                ))
                :null
            }
            </IonCol>
            <IonCol>
            {endSurvey.length > 0 ? 
               endSurvey.map((value: any, index: any) => (
                    <div key={index} className="survey-box">
                        <div className="survey-qns">
                            {value.question}
                        </div>
                        <div className="survey-ans">{value.answer}</div>
                    </div>
                ))
                : null
            }
            </IonCol>
            {(postCareSurvey.length === 0 && endSurvey.length === 0) && 
             <IonRow className="not-found-txt" style={{width:"100%"}}>
             <div>No Data Found</div>
           </IonRow>}
            </IonGrid>
        </>
    )
}
export default PostCareFollowUp