import {
    IonGrid, IonCol, IonRow, IonText,
} from '@ionic/react';
import './index.css';
const OuterHeader: React.FC = () => {
    return (
        <IonGrid className='pad-0'>
            <IonRow>
                <IonCol className='pad-0'>
                    <IonRow className='ion-align-items-center logo-pd-tp10-rt25'>
                        <div>
                            <img src={require(`../../images/mybenefitsDischarge.png`).default} className="" width="321px" height="61px" alt="" />
                        </div>
                        {/* <IonRow>
                            <IonCol className='pad-0'>
                                <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                                <IonRow className="ion-align-items-center ion-justify-content-flex-start">
                                    <IonText className="subtitle">Discharge Plus</IonText>
                                </IonRow>
                            </IonCol>
                        </IonRow> */}
                    </IonRow>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}
export default OuterHeader