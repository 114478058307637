import { Redirect, Route } from 'react-router-dom';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './App.css';
import './theme/variables.css';



import CreatePassword from './components/CreatePassword';
import EmailSentSuccess from './components/EmailSentSuccess';
import TermsAndConditions from './pages/TermsAndConditions/terms';
import VerificationLinkExpired from './components/VerificationLinkExpired';
import NotFoundPage from './components/NotFoundPage';
import PatientProcedureCodes from './components/addPatientProcedureCodes';
import Header from './components/Header';
import OuterHeader from './components/outerHeader';
import ProviderAddress from './components/ProviderAddress';
import ProviderBankDetails from './components/ProviderBankDetails';
import PatientPrivateRoute from './components/Routes/PatientPrivateRoute/PrivateRoute';
import PublicRoute from './components/Routes/PatientPrivateRoute/PublicRoute';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminLogin from './pages/Admin/AdminLogin';

import Signup from './pages/Signup';
import AddBank from './pages/AddBankAccount';
import ProviderLogin from './pages/ProviderLogin';
import TreatNowPayLater from './pages/TreatNowPayLater';
import ForgotPassword from './pages/ForgotPassword';
import Faq from './pages/FAQ';
import UnlockMap from './pages/UnlockMap';
import AcceptPayments from './pages/AcceptPayments';
import Help from './pages/Help';
import WelcomeToTNPL from './pages/providersInvite/WelcomeToTnpl';
import PatientInfo from './pages/providersInvite/PatientInfo';
import PatientVerification from './pages/providersInvite/PatientVerfication';
import AmountApproved from './pages/providersInvite/AmountApproved';
import RequestAmount from './pages/providersInvite/RequsetAmount';
import PaymentMethod from './pages/providersInvite/PaymentMethod';
import Agreement from './pages/providersInvite/AgreementAndSign';
import PaymentCollected from './pages/providersInvite/PaymentCollected';
import TreatCoins from './pages/TreatCoins';
import DisChargeDocuments from './pages/DischargeDocuments';
import QnAReports from './pages/PatientEngagement';

import LandingPage from './pages/PatientLogin/LandingPage';
import PatientLogin from './pages/PatientLogin/Login';
import PatientDashboard from './pages/PatientLogin/PatientDashboard';


import FloraMonitoring from './pages/PlusAdmin/plusDashboard';
import PlusAdminLogin from './pages/PlusAdmin/PlusLogin';
import AllDischarges from './pages/PlusAdmin/AllDischarges';
import PatientQuestions from './pages/PlusAdmin/PatientQuestions';
import PrivateRoute from './components/Routes/PatientPrivateRoute/PrivateRoute';
import SpecialInstructions from './pages/PatientLogin/PatientDashboard/SpecialInstructions';
const PlusDashboard = lazy(() => import('./pages/PlusAdmin/Dashboard'));

const Home = lazy(() => import('./pages/Home'))
setupIonicReact();
const App: React.FC = () => {
  useEffect(() => {
    let path = window.location.href;
    let urldischargeId = path.split("=");
    let dischargeId = urldischargeId[1] && urldischargeId[1].split("&");
    let logActivityEvent = urldischargeId[2]
    if (((logActivityEvent === "postcare_followup") || (logActivityEvent === "postcare_followup_2"))) {
      localStorage.clear()
      localStorage.setItem("dischargeId", dischargeId[0])
    }
    else 
      if (dischargeId && dischargeId[0]) {
        localStorage.setItem("dischargeId", dischargeId[0])
         }
  }, []);
  return (
    <IonApp>
      <Suspense fallback={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>Loading please wait...</div>}>
        <IonReactRouter forceRefresh>
          <IonRouterOutlet>
            <Route exact path="/">
              <Redirect to="/provider_login" />
            </Route>
            {/* {unlock_source &&
            <Route exact path ="/signup?source=smcds_email_campaign">
              <Signup />
            </Route>
          } */}
            <PublicRoute exact  path="/patient" component={LandingPage}></PublicRoute> 
            <PublicRoute  exact path="/patient_login" component={PatientLogin}></PublicRoute> 
            <PrivateRoute exact path="/patient_dashboard" component={PatientDashboard}></PrivateRoute>
            <Route exact path="/signup">
              <Signup />
            </Route>
            <Route exact path="/add_bank_account">
              <AddBank />
            </Route>
            <Route exact path="/provider-password">
              <CreatePassword />
            </Route>
            <Route exact path="/emailsentsuccess">
              <EmailSentSuccess />
            </Route>
            <Route exact path="/terms_and_conditions">
              <TermsAndConditions />
            </Route>
            <Route exact path="/linkExpired">
              <VerificationLinkExpired />
            </Route>
            <Route exact path="/provider_login">
              <ProviderLogin />
            </Route>
            <Route exact path="/dashboard">
              <TreatNowPayLater />
            </Route>
            <Route exact path="/patient_procedure_code/:id">
              <PatientProcedureCodes />
            </Route>
            <Route exact path="/headers">
              <Header popover={false} unreadCount={0} />
            </Route>
            <Route exact path="/provider_address">
              <ProviderAddress />
            </Route>
            <Route exact path="/provider_bank_details">
              <ProviderBankDetails />
            </Route>
            <Route exact path="/personal_details">
              <Home />
            </Route>
            <Route exact path="/edit_provider_bank">
              <Home />
            </Route>
            <Route exact path="/provider_details">
              <Home />
            </Route>
            <Route exact path="/practice_details">
              <Home />
            </Route>
            <Route exact path="/change_password">
              <Home />
            </Route>
            <Route exact path="/admin">
              <AdminLogin />
            </Route>
            <Route exact path="/admin_dashboard">
              <AdminDashboard />
            </Route>
            <Route exact path="/plus-dashboard">
              <PlusDashboard />
            </Route>
            <Route exact path="/forgot_password">
              <ForgotPassword></ForgotPassword>
            </Route>
            <Route exact path="/faq">
              <Faq></Faq>
            </Route>
            <Route exact path="/unlockmap">
              <UnlockMap></UnlockMap>
            </Route>
            <Route exact path="/outerHeader">
              <OuterHeader></OuterHeader>
            </Route>
            <Route exact path="/accept_payments">
              <AcceptPayments></AcceptPayments>
            </Route>
            <Route exact path="/help">
              <Help></Help>
            </Route>
            <Route exact path="/welcome_to_tnpl">
              <WelcomeToTNPL></WelcomeToTNPL>
            </Route>
            <Route exact path="/patient_info">
              <PatientInfo></PatientInfo>
            </Route>
            <Route exact path="/patient_verify">
              <PatientVerification />
            </Route>
            <Route exact path="/amount_approved">
              <AmountApproved />
            </Route>
            <Route exact path="/request_amount">
              <RequestAmount />
            </Route>
            <Route exact path="/payment_method">
              <PaymentMethod />
            </Route>
            <Route exact path="/plus-admin">
              <PlusAdminLogin />
            </Route>
            <Route exact path="/plus-admin-patient-question">
              <PatientQuestions />
            </Route>
            <Route exact path="/plus-admin-floraQnA">
              <FloraMonitoring />
            </Route>
            <Route exact path="/plus-all_discharges">
              <AllDischarges />
            </Route>
            <Route exact path="/agreement">
              <Agreement loanBalance={0} amount={0} range={0} showBlock={false} />
            </Route>
            <Route exact path="/payment_collected">
              <PaymentCollected />
            </Route>
            <Route exact path="/treat_coins">
              <TreatCoins />
            </Route>
            <Route exact path='/discharge'>
              <DisChargeDocuments />
            </Route>
            <Route exact path="/superviseLearning">
              <QnAReports />
            </Route>
            <Route exact path="/specialInstructions">
              <SpecialInstructions></SpecialInstructions>
            </Route>
             <Route>
              <NotFoundPage></NotFoundPage>
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </Suspense>
    </IonApp>
  )
}

export default App;