import { IonCol, IonGrid, IonItem, IonButton, IonLoading, IonContent, useIonToast, IonInput, IonLabel, IonPage, useIonAlert, useIonLoading, IonRow, IonText, } from '@ionic/react';
import { useState, useContext, useEffect } from 'react';
import './index.css';
import { useHistory } from "react-router-dom";
import instance from '../../utils/api';
import TreatNowDescription from '../TreatNowDescription';
import { Formik, } from 'formik';
import * as yup from 'yup';
import OuterHeader from '../outerHeader';
const CreatePassword: React.FC = (props) => {
    const history = useHistory();
    const [showToast, dismiss] = useIonToast();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const walkInProviderSignUp = localStorage.getItem('walkInProviderSignUp')

    useEffect(() => {
        let path = window.location.href;
        let urlToken = path.split("?");
        let finalUrlToken = urlToken[1]
        if (finalUrlToken) {
            instance.defaults.headers.common['Authorization'] = finalUrlToken;
            localStorage.setItem("auth_token", finalUrlToken);
            var token = localStorage.getItem('auth_token') || "";
        }
    }, [])
    const updateStatus = async () => {
        let data = {
            status: "dashboard"
        }
        await instance({
            method: 'PUT',
            url: 'mybenefitsprovider/updateProvider',
            data,
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {};
            obj = resp
            if (obj.status === "SUCCESS!") {
                localStorage.setItem("providerStatus", obj.result.list[0].status);
            }
        })
    }
    const validationSchema = yup.object({
        newPassword: yup
            .string()
            .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g, "Password Should be at least one uppercase letter, one lowercase letter, one number and one special character")
            .required("This field is required"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('newPassword'), null], 'Password & Confirm Password should be same')
            .required("This field is required")
    });
    const submit = async (values: any) => {
        console.log(values)
        let newPassword = values.newPassword
        let confirmPassword = values.confirmPassword
        let data = {
            newPassword,
            confirmPassword
        }
        setShowLoading(true);
        await instance({
            method: 'POST',
            url: `user/changePassword`,
            data,
            validateStatus: () => true
        }).then(res => {
            let obj: any = {};
            obj = res
            if (obj.status === "SUCCESS!") {
                localStorage.setItem("providerStatus", obj.result.status);
                history.push('/discharge');
                setShowLoading(false)
                // if (walkInProviderSignUp === "true") {
                //     history.push('/dashboard');
                //     updateStatus()
                // }
                // else {
                //     history.push('/dashboard');
                // }
            }
            else {
                setShowLoading(false);
                showToast(obj.error.message, 3000)
            }
        })
    }
    return (
        <IonPage>
            <IonContent>
                <IonGrid className="gridht">
                    <IonRow className='pad-0'>
                        <IonCol className='pad-0'>
                            <OuterHeader></OuterHeader>

                            <IonRow className="head-prov-title-bg ion-justify-content-center ion-align-items-center ion-margin-bottom">
                                <IonText>
                                    Welcome to your account
                                </IonText>
                            </IonRow>
                            <Formik
                                initialValues={{
                                    newPassword: "",
                                    confirmPassword: ""
                                }}
                                validationSchema={validationSchema}
                                onSubmit={submit}>

                                {formikProps => (
                                    <div className='fieldiv'>
                                        <form onSubmit={formikProps.handleSubmit}>
                                            <IonRow className='ion-padding-top'>
                                                <IonCol className='pad-lr-0'>
                                                    <IonLabel className='label'>Password</IonLabel>
                                                    <IonInput type="password" value={formikProps.values.newPassword}
                                                        className="ion-padding input-field mg-top placeholder-txt"
                                                        placeholder="Enter" name='newPassword'
                                                        onIonChange={formikProps.handleChange}
                                                        onFocus={formikProps.handleBlur}
                                                    > </IonInput>
                                                    <p className="fielderrormsg">
                                                        {formikProps.touched.newPassword && formikProps.errors.newPassword}
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol className='pad-lr-0'>
                                                    <IonLabel className='label'>Confirm Password</IonLabel>
                                                    <IonInput type="password" name='confirmPassword'
                                                        className="ion-padding input-field mg-top placeholder-txt" placeholder="Enter"
                                                        value={formikProps.values.confirmPassword}
                                                        onIonChange={formikProps.handleChange}
                                                        onFocus={formikProps.handleBlur}
                                                    > </IonInput>
                                                    <p className="fielderrormsg">
                                                        {formikProps.touched.confirmPassword && formikProps.errors.confirmPassword}
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                            <IonButton expand="block" type='submit' className='searchbtn mg-bt searchbtn-txt'>Next</IonButton>
                                        </form>
                                    </div>
                                )}
                            </Formik>
                            <IonRow className='signuprow-pass ion-justify-content-center'>
                                <span>Have an account</span>?<span className='signup-pass pointer' onClick={() => { window.location.href = '/provider_login' }}>Sign in now</span>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
        </IonPage>
    )
}
export default CreatePassword