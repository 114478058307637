import { IonHeader, IonPage, IonRow, IonText } from "@ionic/react";
import './index.css';
interface Props {
    title:String,
}
const ProvidersHeader:React.FC<Props> = (props) =>{
    return(
        <IonHeader className="headbg">
            <IonRow className="ion-justify-content-center ion-padding headertitle">
                <IonText>{props.title}</IonText>
            </IonRow>
        </IonHeader>
    )
}
export default ProvidersHeader