import { IonCol, IonRow, IonButton, IonLabel, useIonToast, IonTextarea, IonLoading } from "@ionic/react";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import instance from "../../../utils/api";
import { Star } from '@styled-icons/bootstrap/Star'
import { StarFill } from '@styled-icons/bootstrap/StarFill';
import './index.css';
interface Props {
    dischargeId: string,
    patientDetails: any
}
const FeedBack: React.FC<Props> = (props) => {
    const [reviews, setReviews] = useState<any>(props && props.patientDetails)
    const [errorMessage, setErrorMessage] = useState<any>("");
    const [showLoading, setShowLoading] = useState<boolean>(false);

    const [showToast, dismiss] = useIonToast();

    const validaterating = (obj: any) => {
        return obj.rating > 0;
    };
    console.log(props.patientDetails, reviews)
    const ratingApicall = async (values: any) => {
        const newArray = [...feebackData];
        if (feebackData.every((obj: any) => obj.rating > 0)) {
            let data: any = {}
            data.rating = feebackData[3].rating
            data.comment = values.comment
            data.review = feebackData
            setShowLoading(true)
            await instance({
                method: "PUT",
                url: `discharge/${props.dischargeId}`,
                data,
                validateStatus: () => true
            }).then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    showToast("Your Feedback is submitted", 3000);
                    //props.getpatientData(); 
                    //props.backToView();
                }
                else {
                    setShowLoading(false);
                    showToast(obj.error.message, 3000)
                }
            })
        }
        else {
            const errorMessages = newArray.map((obj, index) => ({
                [index]: !validaterating(obj)
            }));
            setErrorMessage(Object.assign({}, ...errorMessages));


        }
    };


    const feedBackQuestions = [
        {
            question: "How was your experience with the Doctor ",
            rating: 0
        },
        {
            question: "How well did the staff work to take care of you?",
            rating: 0
        },
        {
            question: "How would your rate the nurses on explaining things in a way you understood?",
            rating: 0
        },
        {
            question: "How would you rate your overall experience for the hospital?",
            rating: 0
        },
        {
            question: "For your post-care needs, how would you rate myBenefits Discharge Plus?",
            rating: 0
        },
    ]


    const [feebackData, setFeedBackData] = useState<any>();

    const feedBack = (value: any, key: any) => {
        const newArray = [...feebackData];
        const index = newArray.findIndex(item => item.question === value.question);
        const updatedObject = {
            ...newArray[index],
            rating: key
        };
        newArray[index] = updatedObject;
        setFeedBackData(newArray);
        setErrorMessage({
            ...errorMessage,
            [index]: false
        });
    }
    useEffect(() => {
        setReviews(props && props.patientDetails)
        if (reviews && reviews.review && reviews.review.length > 0) {
            setFeedBackData(reviews.review)
        }
        else {
            setFeedBackData(feedBackQuestions)
        }
    }, [])

    return (
        <div className="pain-card" style={{ padding: "15px 20px" }}>
            <div className="pain-card pad-0">

                <Formik enableReinitialize={true}
                    initialValues={{
                        comment: reviews && reviews.comment ? reviews.comment : ""
                    }}
                    onSubmit={ratingApicall}
                    validationSchema={yup.object({
                        comment: yup.string().required("This field is required")
                    })}
                >
                    {(formikProps) => (
                        <div className="fieldiv" style={{ padding: "0px" }}>
                            <form onSubmit={formikProps.handleSubmit}>
                                {feebackData && feebackData.map((value: any, index: any) => (
                                    <IonRow key={index}>
                                        <IonCol className="pad-lr-0">
                                            <IonLabel className="label">
                                                {value.question}
                                            </IonLabel>
                                            <IonRow className="disp-flex justify-content-flex-start" style={{ paddingTop: "0px" }}>
                                                {[...Array(5)].map((star, index) => {
                                                    index += 1;
                                                    return (<span key={index} className="star-pd-5 pointer"
                                                        onClick={() => feedBack(value, index)}>{index <= value.rating ? <StarFill size="30" color="#00B9CF" /> : <Star size="30" color="#7C7878" />}</span>);
                                                })}
                                            </IonRow>
                                            {errorMessage[index] && <div className="fielderrormsg err-ft-14" style={{ paddingTop: "5px" }}>Rating is required</div>}
                                        </IonCol>
                                    </IonRow>
                                ))}
                                <IonRow className="ion-padding-top">
                                    <IonCol className="pad-lr-0">
                                        <IonLabel className="label">
                                            Review
                                        </IonLabel>
                                        <IonTextarea
                                            name="comment"
                                            placeholder="Type your message here."
                                            className="pain-msg-txt-box msg-txt-area" style={{ height: "116px", marginTop: "10px" }}
                                            onBlur={formikProps.handleBlur}
                                            onIonChange={formikProps.handleChange}
                                            value={formikProps.values.comment}
                                        ></IonTextarea>
                                        <p className="fielderrormsg err-ft-14">
                                            {formikProps.touched.comment &&
                                                formikProps.errors.comment}
                                        </p>
                                    </IonCol>
                                </IonRow>

                                <IonButton
                                    expand="block"
                                    type="submit"
                                    className="searchbtn searchbtn-txt btn-mg-tp-10"
                                >
                                    Submit
                                </IonButton>
                            </form>
                        </div>
                    )}
                </Formik>
            </div>
            <IonLoading isOpen={showLoading} message="Please wait..." />
        </div>
    )
}
export default FeedBack