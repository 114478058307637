
import { DocumentOnePage } from "@styled-icons/fluentui-system-filled/DocumentOnePage";
import { IonRow, IonCol, IonLabel, IonLoading, IonButton, useIonToast, IonFooter, IonContent, IonModal, IonHeader } from "@ionic/react";
import { useState, } from "react";
import { ArrowBackOutline } from "@styled-icons/evaicons-outline/ArrowBackOutline";
import { FaceMeh } from "@styled-icons/fa-regular/FaceMeh";
import { FaceSadTear } from "@styled-icons/fa-regular/FaceSadTear";
import { FaceSmile } from "@styled-icons/fa-regular/FaceSmile";
import { FaceFrown } from "@styled-icons/fa-regular/FaceFrown";
import instance from "../../../../utils/api";
import moment from "moment";
interface Props {
    eventsData: any,
    dischargeId: string
}
const PatientFollowUp: React.FC<Props> = (props) => {
    const duration = moment(new Date(props.eventsData.timestamp)).fromNow();
    const [modal, setModal] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showToast, dismiss] = useIonToast();
    const [feebBack, setFeedBack] = useState<any>("");
    const [feebBackPain, setFeedBackPain] = useState<any>("")
    const [feebBackerrorMsg, setfeebBackerrorMsg] = useState<any>("");
    const [painerorMessage, setPainerorMessage] = useState<any>("");
    const [question1, setQuestion1] = useState<any>("");
    const [question2, setQuestion2] = useState<any>("");
    const [question3, setQuestion3] = useState<any>("");


    const postcareFollowup2 = async () => {
        if (!question1 || !question2 || !question3) {
            showToast("All fields are required", 3000);
        }
        else {
            setShowLoading(true);
            let data: any = {}
            data.endSurvey = [{
                "question": "How was your experience with the hospital staff??",
                "answer": question1
            },
            {
                "question": "How was your experience with your Provider?",
                "answer": question2
            },
            {
                "question": "How was your overall post-op Care?",
                "answer": question3
            },
            ]

            await instance({
                method: "PUT",
                url: `discharge/doccument/${props.dischargeId}`,
                validateStatus: () => true,
                data
            }).then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    showToast("Your Feedback is submitted", 3000);
                    setModal(false);
                }
                else {
                    showToast(obj.error, 3000);
                    setShowLoading(false);
                }
            })
        }
    }
    const submitForm = async (values: any) => {
        if (props && props.eventsData && props.eventsData.eventName === "patient_followup_1") {
            if (!feebBack) {
                setfeebBackerrorMsg("This field is required");
            }
            else if (((feebBack === "NotGood") || (feebBack === "Bad")) && !feebBackPain) {
                setPainerorMessage("This field is required");
            }
            else {
                setShowLoading(true)
                let data: any = {}
                data.survey = [{
                    "question": "How are you doing today?",
                    "answer": feebBack
                },
                {
                    "question": "Is there any pain?",
                    "answer": feebBackPain
                },
                ]
                if (feebBackPain === "") {
                    data.survey.splice(1, 1);
                }
                await instance({
                    method: "PUT",
                    url: `discharge/doccument/${props.dischargeId}`,
                    validateStatus: () => true,
                    data
                }).then((resp) => {
                    let obj: any = {}
                    obj = resp
                    if (obj.status === "SUCCESS!") {
                        showToast("Your Feedback is submitted", 3000);
                        setShowLoading(false);
                        setModal(false);
                        setFeedBack("");
                        setFeedBackPain("");
                    }
                    else {
                        showToast(obj.error, 3000);
                        setShowLoading(false);
                    }
                })
            }
        }
        else {
            postcareFollowup2();
        }
    }


    const chooseRating = (value: any) => {
        setFeedBack(value);
        setfeebBackerrorMsg("")
    }

    const choosePain = (value: any) => {
        setFeedBackPain(value);
        setPainerorMessage("")
    }
    return (
        <div>
            <div style={{ display: "flex", alignItems: "center" }} onClick={() => setModal(true)}>
                <div className="icon-bg" style={{ background: "#EDFDEA" }}><DocumentOnePage size={30} color="#00B9CF" /></div>
                <div>
                    <div> 
                        <div className="txt-robot" style={{ lineHeight: "20px",textAlign:"left",fontSize:"14px"  }}>
                            {`You have received a new message from ${props && props.eventsData.providerName}`}</div>
                            <div style={{ paddingTop: "8px", fontSize: "12px" }}>
                            {duration && duration === 'a few seconds ago' ? 'Just now' : duration}
                        </div>
                    </div>
                </div>
            </div>
            <IonModal isOpen={modal} onDidDismiss={() => setModal(false)}>
                <IonHeader>
                    <div className="bg-img">
                        <IonRow
                            className="ion-padding ion-justify-content-between ion-align-items-center">
                            <ArrowBackOutline onClick={() => {setModal(false);setFeedBack("");setFeedBackPain("")}} size={30} color='black' />
                            <div className="patient-header-txt" style={{ fontSize: "20px" }}>Post care follow up</div>
                            <div></div>
                        </IonRow>
                    </div>
                </IonHeader>

                <IonContent>
                    {props && props.eventsData && props.eventsData.eventName === "patient_followup_1" ?
                        <div className='fieldiv ion-padding wd-100' style={{ padding: "0px 12px" }}>
                            <IonCol className="fields-row-pd">
                                <div className="radiabtn" style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
                                    <IonLabel className='pro-label-txt'>How are you doing today?</IonLabel>
                                    <IonRow className="ion-padding-vertical">
                                        <IonCol className="feedback-col">
                                            <FaceSmile onClick={() => chooseRating("Good")} size={40} color={feebBack === "Good" ? "#00B42C" : "grey"} />
                                            <div className="txt-center">Good</div>
                                        </IonCol>
                                        <IonCol className="feedback-col">
                                            <FaceSmile onClick={() => chooseRating("Better")} size={40} color={feebBack === "Better" ? "#60C51F" : "grey"} />
                                            <div className="txt-center">Better</div>
                                        </IonCol>
                                        <IonCol className="feedback-col">
                                            <FaceMeh onClick={() => chooseRating("Ok")} size={40} color={feebBack === "Ok" ? "#F5D100" : "grey"} />
                                            <div className="txt-center">Ok</div>
                                        </IonCol>
                                        <IonCol className="feedback-col">
                                            <FaceFrown onClick={() => chooseRating("NotGood")} size={40} color={feebBack === "NotGood" ? "#F0671F" : "grey"} />
                                            <div className="txt-center">NotGood</div>
                                        </IonCol>
                                        <IonCol className="feedback-col">
                                            <FaceSadTear onClick={() => chooseRating("Bad")} size={40} color={feebBack === "Bad" ? "#EA3426" : "grey"} />
                                            <div className="txt-center">Bad</div>
                                        </IonCol>
                                    </IonRow>
                                    <p className="fielderrormsg">
                                        {feebBackerrorMsg}
                                    </p>
                                </div>
                                {((feebBack === "NotGood") || (feebBack === "Bad")) &&
                                    <div className="radiabtn" style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
                                        <IonLabel className='pro-label-txt'>Is there any pain?</IonLabel>
                                        <IonRow className="ion-padding-vertical">
                                            <IonCol className="feedback-col">
                                                <FaceSmile onClick={() => { choosePain("No Pain") }} size={40} color={feebBackPain === "No Pain" ? "#00B42C" : "grey"} />
                                                <div className="txt-center">No Pain</div>
                                            </IonCol>
                                            <IonCol className="feedback-col">
                                                <FaceSmile onClick={() => choosePain("Mid Pain")} size={40} color={feebBackPain === "Mid Pain" ? "#60C51F" : "grey"} />
                                                <div className="txt-center">Mid Pain</div>
                                            </IonCol>
                                            <IonCol className="feedback-col">
                                                <FaceMeh onClick={() => choosePain("Moderate Pain")} size={40} color={feebBackPain === "Moderate Pain" ? "#F5D100" : "grey"} />
                                                <div className="txt-center">Moderate Pain</div>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="feedback-col">
                                                <FaceMeh onClick={() => choosePain("Severe Pain")} size={40} color={feebBackPain === "Severe Pain" ? "#F29900" : "grey"} />
                                                <div className="txt-center">Severe Pain</div>
                                            </IonCol>
                                            <IonCol className="feedback-col">
                                                <FaceFrown onClick={() => choosePain("Very Severe Pain")} size={40} color={feebBackPain === "Very Severe Pain" ? "#F0671F" : "grey"} />
                                                <div className="txt-center">Very Severe Pain</div>
                                            </IonCol>
                                            <IonCol className="feedback-col">
                                                <FaceSadTear onClick={() => choosePain("Worst Pain")} size={40} color={feebBackPain === "Worst Pain" ? "#EA3426" : "grey"} />
                                                <div className="txt-center">Worst Pain</div>
                                            </IonCol>
                                        </IonRow>
                                        <p className="fielderrormsg">
                                            {painerorMessage}
                                        </p>
                                    </div>}
                            </IonCol>
                        </div> :
                        <div>
                            <div className="radiabtn" style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
                                <IonLabel className='pro-label-txt'>How was your experience with the hospital staff?</IonLabel>
                                <IonRow className="ion-padding-vertical">
                                    <IonCol className="feedback-col">
                                        <FaceSmile onClick={() => { setQuestion1("happy") }} size={40} color={question1 === "happy" ? "#00B42C" : "grey"} />
                                        <div className="txt-center">Happy</div>
                                    </IonCol>
                                    <IonCol className="feedback-col">
                                        <FaceFrown onClick={() => setQuestion1("Unhappy")} size={40} color={question1 === "Unhappy" ? "#F5D100" : "grey"} />
                                        <div className="txt-center">Unhappy</div>
                                    </IonCol>
                                </IonRow>
                                <p className="fielderrormsg">
                                    {painerorMessage}
                                </p>
                            </div>
                            <div className="radiabtn" style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
                                <IonLabel className='pro-label-txt'>How was your experience with your Provider?</IonLabel>
                                <IonRow className="ion-padding-vertical">
                                    <IonCol className="feedback-col">
                                        <FaceSmile onClick={() => { setQuestion2("happy") }} size={40} color={question2 === "happy" ? "#00B42C" : "grey"} />
                                        <div className="txt-center">Happy</div>
                                    </IonCol>
                                    <IonCol className="feedback-col">
                                        <FaceFrown onClick={() => setQuestion2("Unhappy")} size={40} color={question2 === "Unhappy" ? "#F5D100" : "grey"} />
                                        <div className="txt-center">Unhappy</div>
                                    </IonCol>
                                </IonRow>
                                <p className="fielderrormsg">
                                    {painerorMessage}
                                </p>
                            </div>
                            <div className="radiabtn" style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
                                <IonLabel className='pro-label-txt'>How was your overall post-op Care?</IonLabel>
                                <IonRow className="ion-padding-vertical">
                                    <IonCol className="feedback-col">
                                        <FaceSmile onClick={() => { setQuestion3("happy") }} size={40} color={question3 === "happy" ? "#00B42C" : "grey"} />
                                        <div className="txt-center">Happy</div>
                                    </IonCol>
                                    <IonCol className="feedback-col">
                                        <FaceFrown onClick={() => setQuestion3("Unhappy")} size={40} color={question3 === "Unhappy" ? "#F5D100" : "grey"} />
                                        <div className="txt-center">Unhappy</div>
                                    </IonCol>
                                </IonRow>
                                <p className="fielderrormsg">
                                    {painerorMessage}
                                </p>
                            </div>
                        </div>}
                </IonContent>
                <IonFooter style={{ background: "white" }}>
                    <div className="ion-justify-content-center" style={{ margin: "12px" }}>
                        <IonButton expand="block" onClick={submitForm} className='provoiders-btn provoiders-btn-txt ion-no-margin patient-btn'>Submit</IonButton>
                    </div>
                </IonFooter>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonModal>
        </div>
    )
}
export default PatientFollowUp;
