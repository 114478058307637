import { IonPage, IonRow, IonSearchbar, IonContent, } from "@ionic/react"
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import instance from "../../../utils/api";
import PlusHeader from "../Header/inex";
const FloraMonitoring: React.FC = () => {
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [QnAMonitorlist, setQnAMonitorlist] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>([]);

    const getQuestionAnswer = async () => {
        setShowLoading(true)
        await instance({
            method: "GET",
            url: "discharge/getFlora",
            validateStatus: () => true,
        }).then((resp) => {
            let obj: any = {};
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setQnAMonitorlist(obj.result);
                setShowLoading(false);
                setSearchData(obj.result)

            } else {
                setShowLoading(false);
            }
        });
    };
    useEffect(() => {
        getQuestionAnswer();
    }, [QnAMonitorlist.length]);


    const onChangeSearch = (query: any) => {
        const orginal = QnAMonitorlist
        if (query.detail.value) {
            const filteredData = orginal.filter(
                item => {
                    return (
                        (item && item.provider[0] && item.provider[0].displayName && item.provider[0].displayName)?.toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
                        item.question.toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
                        item.answer.toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
                        (item.providerResponse && item.providerResponse)?.toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
                        new Date(item.timestamp).toLocaleDateString(
                            "en-US",
                            { timeZone: "America/Los_Angeles" }
                        ).toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
                        item.mybenefitsprovider.toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
                        item.user.toLowerCase().includes(query.detail.value.toLowerCase().trim())
                    );
                }
            );
            setSearchData(filteredData)
        }
        else {
            setSearchData(orginal)
        }
    }

    return (
        <IonPage>
            <PlusHeader />
            <IonContent>
                <IonSearchbar onIonChange={(ev) => onChangeSearch(ev)} className="pad-0" mode="ios"></IonSearchbar>
                <div
                    className="ovrflow"
                    style={{ width: "100%", overflow: "auto" }}
                >
                    <table className="table-container">
                        <thead className="patient-thread">
                            <tr>
                                <th className="patient-th pd-lr-24 whitespace">
                                    Date & Time
                                </th>
                                <th className="patient-th pd-lr-24 whitespace">
                                    Provider Id
                                </th>
                                <th className="patient-th pd-lr-24 whitespace td-txt-center">
                                    Patient Id
                                </th>
                                <th className="patient-th pd-lr-24 whitespace">
                                    Provider Name
                                </th>
                                <th className="patient-th pd-lr-24 whitespace">
                                    Patient Question
                                </th>
                                <th className="patient-th pd-lr-24 whitespace">
                                    Flora Answer
                                </th>
                                <th className="patient-th pd-lr-24 whitespace">
                                    Provider Answer
                                </th>
                            </tr>
                        </thead>
                        <tbody className="patient-tbody">
                            {searchData && searchData.length > 0 &&
                                searchData.map((item: any, key: any) => {
                                    return (
                                        <tr
                                            key={key}
                                            className="patient-tr"
                                        >
                                            <td className="td-txt pd-lr-24">
                                                {item.timestamp
                                                    ? new Date(item.timestamp).toLocaleDateString(
                                                        "en-US",
                                                        { timeZone: "America/Los_Angeles" }
                                                    )
                                                    : "NA"} {" "}
                                                {item.timestamp
                                                    ? new Date(item.timestamp).toLocaleTimeString(
                                                        "en-US",
                                                        { timeZone: "America/Los_Angeles" }
                                                    )
                                                    : "NA"}
                                            </td>
                                            <td className="td-txt pd-lr-24">
                                                {item && item.mybenefitsprovider}
                                            </td>
                                            <td className="td-txt pd-lr-24">
                                                {item && item.user}
                                            </td>
                                            <td className="td-txt pd-lr-24">
                                                {item && item.provider && item.provider[0].displayName}
                                            </td>
                                            <td className="td-txt pd-lr-24">
                                                {item && item.question}
                                            </td>
                                            <td className="td-txt pd-lr-24">
                                                {item && item.answer}
                                            </td>
                                            <td className="td-txt pd-lr-24" style={{ textAlign: "center" }}>
                                                {item && item.providerResponse ? item.providerResponse : "NA"}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                    {searchData.length === 0 &&
                        <IonRow className="ion-align-items-center ion-justify-content-center txt-robot">No Data Found</IonRow>}
                </div>
            </IonContent>

            {showLoading && <Loader />}
        </IonPage>
    )
}
export default FloraMonitoring