import {
  IonRow,
  IonCol,
  IonPopover,
  IonIcon,
  IonGrid,
  IonHeader,
  IonText,
  IonContent,
  IonItem,
  IonAvatar,
  IonModal,
} from "@ionic/react";
import "./index.css";
import { useState, useContext, useEffect } from "react";
import { person, power, helpCircleOutline, eye } from "ionicons/icons";
import { DotSingle } from "@styled-icons/entypo/DotSingle";
import instance from "../../utils/api";
import { Person } from "@styled-icons/evaicons-solid/Person";
import { Dashboard } from "@styled-icons/boxicons-solid/Dashboard";
import { Question } from "@styled-icons/evil/Question";
import { PersonCircle } from "@styled-icons/bootstrap/PersonCircle";
// import { Bell } from '@styled-icons/bootstrap/Bell';
import { ChatLeftDots } from "@styled-icons/bootstrap/ChatLeftDots";
import { CurrencyDollar } from "@styled-icons/bootstrap/CurrencyDollar";
import { QuestionMarkCircleOutline } from "@styled-icons/evaicons-outline/QuestionMarkCircleOutline";
import { Coin } from "@styled-icons/bootstrap/Coin";
import { CloseO } from "@styled-icons/evil/CloseO";
import { ArrowLongUp } from "@styled-icons/entypo/ArrowLongUp";
import { Report } from "@styled-icons/boxicons-solid/Report";
import { Schedule } from "@styled-icons/material/Schedule";
import { useHistory } from "react-router-dom";

interface Props {
  popover: Boolean;
  unreadCount:number;
}
const Header: React.FC<Props> = (props) => {
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const history = useHistory();

  const providerName = localStorage.getItem("providerName");
  const providerStatus = localStorage.getItem("providerStatus") || "";
  const [notifications, setNofications] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [providerDetails, setProviderDetails] = useState<any>([]);
  const [hostName, setHostName] = useState<any>([]);
  var coachMarker = localStorage.getItem("coachMarker");
  const auth_token =  localStorage.getItem('auth_token');
  const [navbarActive, setNavbarActive] = useState({
    active:
      window.location.hostname === "myoffice-dev.mybenefits.ai"
        ? "discharge"
        : "schedule",
    link:
      window.location.hostname === "myoffice-dev.mybenefits.ai"
        ? "/discharge"
        : "/schedule",
  });
  const [showNotifyPopState, setShowNotifyPopover] = useState({
    showNotifyPopover: false,
    event: undefined,
  });

  const signOut = () => {
    localStorage.clear();
    window.location.href = "/provider_login";
  };

  const showPopUp = (e) => {
    e.persist();
    if (providerStatus !== "invite") {
      setShowPopover({ showPopover: true, event: e });
    }
  };

  // api/notification/readNotifications
  const getProviderNotifications = async () => {
    await instance({
      method: "GET",
      url: "notification/ProviderNotifications",
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      setNofications(obj.result);
    });
  };

  useEffect(() => {
    if(auth_token){
    getProviderNotifications();
    getUserDetails();
    }
  }, []);

  const showNotifyPop = async (e: any) => {
    e.persist();
    setShowNotifyPopover({ showNotifyPopover: true, event: e });
    await instance({
      method: "GET",
      url: "notification/readNotifications",
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        getProviderNotifications();
      }
    });
  };
  useEffect(() => {
    let locationPath = window.location.pathname;
    let active = locationPath.substring(1, locationPath.length);
    setNavbarActive({ active: active, link: locationPath });
  }, []);

  const getUserDetails = async () => {
    await instance({
      method: "GET",
      url: "user/getUserDetails",
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        let data = obj.result[0].mybenefitsprovider;
        setProviderDetails(data);
        if (obj.result[0].mybenefitsprovider.displayName) {
          localStorage.setItem(
            "providerName",
            obj.result[0].mybenefitsprovider.displayName
          );
        } else if (obj.result[0].mybenefitsprovider.providerFirstName) {
          localStorage.setItem(
            "providerName",
            obj.result[0].mybenefitsprovider.providerFirstName
          );
        }
      }
    });
  };

  return (
    <IonHeader
      className={
        props.popover === false
          ? "header-box zindex-auto"
          : "header-box zindex-999"
      }
    >
      <IonGrid className="pad-0">
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="header"
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => (window.location.href = "/discharge")}
          >
            <div style={{ display: "flex" }}>
              <img
                src={require(`../../images/mybenefitsDischarge.png`).default}
                style={{ maxWidth: "fit-content" }}
                height="61px"
                width="321px"
                alt=""
              />
            </div>
            {/* <IonRow>
              <IonCol>
                <IonRow className="ion-align-items-center ion-justify-content-center">
                  <IonText className="logo-txts">myBenefits.ai</IonText>
                </IonRow>
                <IonRow className="ion-align-items-center ion-justify-content-flex-start">
                  <IonText className="subtitle">Discharge Plus</IonText>
                </IonRow>
              </IonCol>
            </IonRow> */}
          </div>
          <IonRow
            className="ion-align-items-center ion-justify-content-evenly headers-list-txt"
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            {/* <IonRow onClick={() => window.location.href = "/treat_now_pay_later"}>TNPL</IonRow> */}
            {/* <IonRow>Offers</IonRow> */}

            {/* <IonRow onClick={() => setModalOpen(true)} className={"ion-hide-sm-down " + (modalOpen === true ? "active" : "Inactive")}>Offers</IonRow> */}
            <IonRow
              onClick={() => (window.location.href = "/discharge")}
              className={
                "ion-hide-lg-down " +
                (navbarActive.active === "discharge" ? "active" : "Inactive")
              }
            >
              Discharges
            </IonRow>
            {/* <IonRow onClick={() => window.location.href = "/dashboard"} className={"ion-hide-lg-down " + (navbarActive.active === "dashboard" ? "active" : "Inactive")}>TNPL</IonRow> */}
            <IonRow
              onClick={() => (window.location.href = "/superviseLearning")}
              className={
                "ion-hide-lg-down " +
                (navbarActive.active === "superviseLearning"
                  ? "active"
                  : "Inactive")
              }
            >
              Patient Engagement
            </IonRow>

            {/* <div className={"ion-hide-lg-down " + (navbarActive.active === "faq" ? "active" : "Inactive")}>
                        <IonText><a href="/faq" target="_blank" className='help-link'>How it Works?</a></IonText>
                    </div> */}
            {/* <div className={"ion-hide-lg-down " + (navbarActive.active === "help" ? "active" : "Inactive")}>
                        <IonText><a href="/faq" target="_blank" className='help-link'>Help</a></IonText>
                    </div> */}
            {/* <div className='flex-col'>
                        <span onClick={() => window.location.href = "/treat_coins"} className={"ion-hide-lg-down " + (navbarActive.active === "treat_coins" ? "active" : "Inactive")}>Treat Coins</span>
                        <span>
                            {(props.popover === false) && (coachMarker && coachMarker === "true") &&
                                <div className="coach-marks">
                                    <div className="arrow">
                                        <div className="arrow-pt-right point"></div>
                                        <div className="curve icon-arrow"></div>
                                    </div>
                                    <div className="modal-content">
                                        <div className="container pad-0-mg-30">
                                            <p> View summary of treat coins collected and redeem treat coins for cash</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </span>
                    </div> */}

            <IonRow>
              {/* Notification bell code */}

              {/* <div className='align-center' onClick={(e: any) => showNotifyPop(e)} style={{ padding: "0px 10px" }}>
                                <Bell color='#00B9CF' title="bell" size={30}></Bell>
                                {notifications && notifications.length > 0 && notifications.map((value: any, key: any) => {
                                    return (
                                        <div key={key}>
                                            {value.isRead === 'true' ?
                                                <DotSingle title="DotSingle" className='dot' /> : <></>
                                            }
                                        </div>
                                    )
                                }
                                )}
                            </div> */}
              <div
                className="align-center"
                style={{ paddingTop: "10px" }}
                onClick={() => {
                  history.push("/superviseLearning", { props: "fromHeader" });
                }}
              >
                <ChatLeftDots color="#00B9CF" title="chat" size={28} />
                <span
                  style={{
                    position: "relative",
                    height: "20px",
                    width: "20px",
                    borderRadius: "10px",
                    backgroundColor: "red",
                    bottom: "14px",
                    right: "8px",
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    fontSize: "10px",
                  }}
                >
                  {props.unreadCount >= 100 ? "99+" : props.unreadCount}
                </span>
              </div>
              {/* <IonPopover mode='ios'
                                event={showNotifyPopState.event}
                                isOpen={showNotifyPopState.showNotifyPopover}
                                onDidDismiss={() => setShowNotifyPopover({ showNotifyPopover: false, event: undefined })}
                            >
                                <div style={{ height: '100%', background: "white", overflowY: "scroll" }}>
                                    <div className='notify-title' style={{ padding: '20px' }}>Notifications</div>
                                    {notifications && notifications.length > 0 && notifications.map((value: any, key: any) => {
                                        return (
                                            <div key={key} style={{ display: 'flex', padding: '0px 10px 20px 10px', flexDirection: "row", alignItems: 'center' }}>
                                                <div>
                                                    <Person color='#E0E0E0' title="person" size={55}></Person>
                                                </div>
                                                <div className={value.isRead === 'false' ? 'searchlabel' : "searchlabel msg-ft-txt"} style={{ paddingLeft: '10px' }}>
                                                    {value.message}
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            </IonPopover> */}
              <div
                className="align-center img-pd pointer flex-col"
                onClick={(e: any) => showPopUp(e)}
              >
                <span>
                  <PersonCircle
                    title="person"
                    size="35"
                    color="#00B9CF"
                    className="userimg"
                  />
                </span>
                <span className="username-pd header-txt ion-hide-lg-down">
                  {providerName === undefined ? "" : providerName}
                </span>
                {props.popover === false &&
                  coachMarker &&
                  coachMarker === "true" && (
                    <div className="coach-marks">
                      <div className="arrow">
                        <div className="point"></div>
                        <div className="curve"></div>
                      </div>
                      <div className="modal-content">
                        <div className="container">
                          <p>View your Profile and Settings</p>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </IonRow>
            <IonPopover
              className="popover-top"
              event={popoverState.event}
              isOpen={popoverState.showPopover}
              onDidDismiss={() =>
                setShowPopover({ showPopover: false, event: undefined })
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  padding: "5px",
                }}
              >
                <div className="align-center ion-hide-lg-up header-txt">
                  {providerName}
                </div>
                {/* <div
                  onClick={() => (window.location.href = "/dashboard")}
                  className={
                    "align-center ion-hide-lg-up pop-pd-8 " +
                    (navbarActive.active === "dashboard"
                      ? "pop-active"
                      : "pop-inactive")
                  }
                >
                  <Dashboard
                    title="Dashboard"
                    size={25}
                    className="icon-pdrt-10"
                  />
                  TNPL
                </div> */}
                <div
                  onClick={() => (window.location.href = "/discharge")}
                  className={
                    "align-center ion-hide-lg-up pop-pd-8 " +
                    (navbarActive.active === "discharge"
                      ? "pop-active"
                      : "pop-inactive")
                  }
                >
                  <Dashboard
                    title="Dashboard"
                    size={25}
                    className="icon-pdrt-10"
                  />
                  DisCharges
                </div>
                <div
                  className={
                    "align-center ion-hide-lg-up pop-pd-8 " +
                    (navbarActive.active === "superviseLearning"
                      ? "pop-active"
                      : "pop-inactive")
                  }
                  onClick={() => {
                    window.location.href = "/superviseLearning";
                  }}
                >
                  <Report className="icon-pdrt-10" size="25" />
                  Patient Engagement
                </div>
                <div
                  className={
                    "align-center pop-pd-8 " +
                    (navbarActive.active === "personal_details"
                      ? "pop-active"
                      : "pop-inactive")
                  }
                  onClick={() => {
                    window.location.href = "/personal_details";
                  }}
                >
                  <IonIcon className="icon-pdrt-10" size="20" icon={person} />
                  Profile
                </div>
                {/* <div onClick={() => window.location.href = "/treat_coins"} className={"align-center ion-hide-lg-up pop-pd-8 " + (navbarActive.active === "treat_coins" ? "pop-active" : "pop-inactive")}>
                                <Coin className="icon-pdrt-10" size="20" />Treat Coins</div> */}
                {/* <a href="/accept_payments" target="_blank" style={{ textDecoration: "none", color: "black" }}><div className={"align-center pop-pd-8 pdlt-5-acpt " + (navbarActive.active === "accept_payments" ? "pop-active" : "pop-inactive")}><div className="icon-pdrt-10">
                                    <CurrencyDollar title="currencydollar" size="20" /></div>Accept Payments</div></a> */}
                {/* <div className={"align-center ion-hide-lg-up pop-pd-8 " + (navbarActive.active === "help" ? "pop-active" : "pop-inactive")} onClick={() => window.location.href = "/help"}>
                                <div className="icon-pdrt-10"><QuestionMarkCircleOutline size="20" /></div>Help</div> */}
                <div
                  onClick={() => signOut()}
                  className="align-center pop-pd-8"
                  style={{ color: "#000000" }}
                >
                  <IonIcon
                    className="icon-pdrt-10"
                    size="20"
                    icon={power}
                  ></IonIcon>
                  Sign out
                </div>
              </div>
            </IonPopover>
          </IonRow>
        </div>
        <IonModal
          className="signup-modal"
          isOpen={modalOpen}
          onDidDismiss={() => setModalOpen(false)}
        >
          <IonContent>
            <IonGrid style={{ height: "100%" }} className="pad-0">
              <IonRow className="popup-header">
                <IonCol className="pad-0">
                  <IonRow
                    className="ion-justify-content-end pointer pad-0"
                    onClick={() => setModalOpen(false)}
                  >
                    <CloseO title="Close" size={30} color="#FFFFFF"></CloseO>
                  </IonRow>
                  <IonRow className="ion-justify-content-center popup-title ion-padding-bottom">
                    <IonText>Offers</IonText>
                  </IonRow>
                </IonCol>
              </IonRow>

              <IonRow
                className="ion-justify-content-center ion-align-items-center popup-title"
                style={{ height: "70%", color: "#095E68" }}
              >
                <IonText>Coming Soon</IonText>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonGrid>
    </IonHeader>
  );
};
export default Header;
