import { IonCol, IonContent, IonButton, IonPage, IonRow, IonText, } from '@ionic/react';
import './index.css';
const EmailSentSuccess: React.FC = () => {
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonRow className="ion-justify-content-center">
                    <IonCol>
                        <IonRow className="ion-justify-content-center myben-title-pd-tp">
                            <div style={{ display: 'flex' }}>
                                <img src={require(`../../images/final_logo.png`).default} className="" height="38px" width="37px" alt="" />
                            </div>
                            <IonRow className='ion-padding-bottom'>
                                <IonCol>
                                    <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                                    <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='subtitle'>Treat Now, Pay Later</IonText></IonRow>
                                </IonCol>
                            </IonRow>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center ion-padding">
                            <IonText className='emailscstxt'>Verify Your Account</IonText>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center pad-tp-37">
                            <IonCol>
                                <IonText className='emailsentdesctxt'>Welcome to myBenefits! Before we get started please confirm your email address. Click on the verify link in the email we just sent to you!</IonText>
                            </IonCol>
                        </IonRow>
                        {/* <IonButton expand="block" className='searchbtn paid-btn searchbtn-txt' onClick={() => next()}>Continue To Get Paid</IonButton> */}
                    </IonCol>
                </IonRow>
                <IonRow className='ion-align-items-center ion-justify-content-center ion-padding myben-position myben-ft'>@myBenefits 2021</IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EmailSentSuccess;
