import { IonCol, IonGrid, IonPage, useIonToast, IonRange, IonRow, IonLabel, IonInput, IonButton, IonContent, IonLoading } from "@ionic/react"
import { useState, useContext, useEffect } from "react";
import ProvidersHeader from "../../../components/providersInvite/providersHeader"
import { Formik, } from 'formik';
import * as yup from 'yup';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import NumberFormat from "react-number-format";
import './index.css';
import Agreement from "../AgreementAndSign";
import instance from "../../../utils/api";
import CommonContext from "../../../utils/contexts/commonProps";


const RequestAmount: React.FC = () => {
    const [headerTitle, setHeaderTitle] = useState<any>("Request Amount");
    const [downPaymentBlock, setDownPayemntBlock] = useState<any>(true);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [providerName, setProviderName] = useState<any>("")
    const [providerEmail, setProviderEmail] = useState<any>("")
    const [providerDetails, setProviderDetails] = useState<any>()
    const inviteProId = localStorage.getItem('inviteProId');
    const [showToast, dismiss] = useIonToast();
    const { common, setCommon } = useContext(CommonContext);
    const [loanBalance, setLoanBalance] = useState<any>()
    const isloanBankAdded = localStorage.getItem("isloanBankAdded") || ""
    useEffect(() => {
        if (inviteProId) {
            getUserDetails()
        }
    }, [inviteProId]);


    const getUserDetails = async () => {
        setShowLoading(true);
        await instance({
            method: 'GET',
            url: `mybenefitsprovider/provider/${inviteProId}`,
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp
            if (obj.status === "SUCCESS!") {
                setShowLoading(false);
                setProviderDetails(obj.result.list[0]);
                setProviderName(obj.result.list[0].providerFirstName)
                setProviderEmail(obj.result.list[0].email)
            }
            else {
                setShowLoading(false);
            }
        })
    }
    const validationSchema = yup.object({
        amount: yup.number()
            .required('This field is required')
            .min(1, "This field is required")
            .max(loanBalance, `Max loan allowed $${loanBalance}`),
        name: yup.string()
            .required("This field is required"),
        email: yup.string().email('Invalid email')
            .required("This field is required"),
        // range: yup.number()
        //     .min(3, "This field is required")
        //     .required("This field is requireds"),
    });
    const submit = (values: any) => {
        localStorage.setItem("type", providerDetails.type)
        if (isloanBankAdded === "true") {
            window.location.href = "/agreement";
        }
        else {
            window.location.href = "/payment_method";
        }
    }
    useEffect(() => {
     getLoanBalance()
    }, [])
    
    const getLoanBalance=async()=>{
        await instance({
            method: 'GET',
            url: 'paylater/getBalance',
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp
            if (obj.status === "SUCCESS!") {
                setLoanBalance(obj.result.loanAmount)
            }
            else {     
            }
        })
    }
    return (
        <IonPage className="bg-cnt">
            <ProvidersHeader title={headerTitle}></ProvidersHeader>
            <IonContent fullscreen>
                <IonGrid className="ht-100">
                    <div className="ht-100 pro-flex-col ion-align-items-start">
                        <Formik
                            initialValues={{
                                amount: 0,
                                range: 6,
                                name: "",
                                email: "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={submit}>
                            {formikProps => (
                                <div className='fieldiv ion-padding wd-100'>
                                    <IonCol className="fields-row-pd">
                                        <form onSubmit={formikProps.handleSubmit} id="form1">
                                            <IonRow className='ion-padding-top'>
                                                <IonCol className='pad-lr-0'>
                                                    <IonLabel className='pro-label-txt label-clr'>Request Amount</IonLabel>
                                                    <div className="pro-inpt-field mg-field-top num-format-wd-100  number-flex">
                                                        <NumberFormat
                                                            placeholder="Enter Number"
                                                            prefix="$"
                                                            name="amount"
                                                            className="num-for-br-no"
                                                            decimalScale={formikProps.values.amount > 0 ? 2 : 0}
                                                            value={formikProps.values.amount > 0 ? formikProps.values.amount : ""}
                                                            // onChange={formikProps.handleChange}
                                                            onValueChange={val => formikProps.setFieldValue("amount", val.value)}
                                                            onBlur={formikProps.handleBlur}
                                                        />
                                                        {formikProps.values.amount && formikProps.touched.amount && !formikProps.errors.amount ?
                                                            <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                    </div>
                                                    <p className="max-amt-txt ion-no-margin">Max loan allowed: ${loanBalance}</p>
                                                    <p className="max-amt-txt ion-no-margin">Existing loan balance: ${loanBalance === 5000  ?  5000 : 5000 - loanBalance}</p>
                                                    <p className="fielderrormsg">
                                                        {formikProps.touched.amount && formikProps.errors.amount}
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-padding-top'>
                                                <IonCol className='pad-lr-0'>
                                                    <IonLabel className='pro-label-txt label-clr'>Select Monthly Terms</IonLabel>
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className="pad-0">
                                                            <IonRange min={0} name="range" max={12} step={3} pin={true} snaps={true} ticks={true} value={formikProps.values.range}
                                                                onIonChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                className="custom-ion-range-slider ion-no-padding" mode="ios"
                                                            >
                                                                <IonLabel slot="start" className='fs-14'>1</IonLabel>
                                                                <IonLabel slot="end" className='fs-14 ion-no-margin'>12</IonLabel>
                                                            </IonRange>
                                                            <p className="fielderrormsg ion-padding-top">
                                                                {formikProps.touched.range && formikProps.errors.range}
                                                            </p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-padding-top'>
                                                <IonCol className='pad-lr-0'>
                                                    <IonLabel className='pro-label-txt label-clr'>Provider Name</IonLabel>
                                                    <IonInput type="text" name="name" className="pro-inpt-field mg-field-top"
                                                        placeholder="Enter Name" value={providerName}
                                                        onBlur={formikProps.handleBlur}
                                                        readonly
                                                        onIonChange={formikProps.handleChange}
                                                    > {formikProps.values.name && formikProps.touched.name && !formikProps.errors.name ?
                                                        <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}</IonInput>
                                                    <p className="fielderrormsg">
                                                        {formikProps.touched.name && formikProps.errors.name}
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-padding-top'>
                                                <IonCol className='pad-lr-0'>
                                                    <IonLabel className='pro-label-txt label-clr'>Provider Email</IonLabel>
                                                    <IonInput type="text" name="email" className="pro-inpt-field mg-field-top"
                                                        placeholder="Enter Email" value={providerEmail}
                                                        onBlur={formikProps.handleBlur}
                                                        readonly
                                                        onIonChange={formikProps.handleChange}
                                                    > {formikProps.values.email && formikProps.touched.email && !formikProps.errors.email ?
                                                        <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}</IonInput>
                                                    <p className="fielderrormsg">
                                                        {formikProps.touched.email && formikProps.errors.email}
                                                    </p>
                                                </IonCol>
                                            </IonRow>

                                            <div>
                                                <IonCol>
                                                    <Agreement showBlock={downPaymentBlock} loanBalance={loanBalance} amount={formikProps.values.amount} range={formikProps.values.range}></Agreement>
                                                </IonCol>
                                            </div>
                                            <IonCol>
                                                <IonRow className="ion-justify-content-center ion-padding-vertical">
                                                    <IonButton expand="block" type='submit' className='provoiders-btn provoiders-btn-txt ion-no-margin'>Confirm</IonButton>
                                                </IonRow>
                                            </IonCol>

                                        </form>
                                    </IonCol>
                                </div>
                            )}
                        </Formik>
                    </div>
                </IonGrid>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
        </IonPage>
    )
}
export default RequestAmount