import {
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonCardContent,
  IonButton,
  IonContent,
} from "@ionic/react";
import "./index.css";
import { eyeOutline, mailOutline, eye } from "ionicons/icons";
import { useState, useEffect } from "react";
import { Lock } from "@styled-icons/boxicons-solid/Lock";
import { LocationOn } from "@styled-icons/material/LocationOn";
const UnlockMap: React.FC = () => {
  var source: any;

  useEffect(() => {
    let path = window.location.href;
    let urlToken = path.split("?");
    let unlockmap = path.split("=");
    let unlocksource = unlockmap[1];
    if (unlocksource) {
      source = unlocksource;
      localStorage.setItem("unlock_source", unlocksource);
    }
  }, []);

  const navtosignup = () => {
    window.location.href = "/signup";
  };

  return (
    <IonPage>
      <IonHeader style={{ zIndex: "99999" }} className="header-box">
        <IonGrid className="pad-0">
          <IonRow className="header">
            <IonRow className="ion-align-items-center">
              <IonRow className="ion-justify-content-center ion-align-items-center">
                <div style={{ display: "flex" }}>
                  <img
                    src={require(`../../images/final_logo.png`).default}
                    className=""
                    height="38px"
                    width="37px"
                    alt=""
                  />
                </div>
                <IonRow>
                  <IonCol>
                    <IonRow className="ion-align-items-center ion-justify-content-center">
                      <IonText className="logo-txts">myBenefits.ai</IonText>
                    </IonRow>
                    <IonRow className="ion-align-items-center ion-justify-content-center">
                      <IonText className="subtitle">
                        Treat Now, Pay Later
                      </IonText>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonRow>
            </IonRow>
            <IonRow className="ion-align-items-center ion-justify-content-evenly headers-list-txt">
              <IonCol className="header-txt">
                <div style={{ paddingBottom: "10px" }}>
                  Total Providers Joined : 700+{" "}
                </div>
                <div>Total Employees Joined : 2000+</div>
              </IonCol>
            </IonRow>
            <IonRow></IonRow>
          </IonRow>
        </IonGrid>
      </IonHeader>

      <div className="pane">
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "422px", top: "101px" }}>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">Mike used TNPL 4 days ago...</div>
              <div className="locpinsubtxt">Sunset dentistry</div>
            </IonCol>
            <IonRow style={{ marginRight: "-13px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "67px", top: "193px" }}>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">Mike used TNPL 4 days ago...</div>
              <div className="locpinsubtxt">Sunset dentistry</div>
            </IonCol>
            <IonRow style={{ marginRight: "-13px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "290px", top: "304px" }}>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">John used TNPL 7 hours ago...</div>
              <div className="locpinsubtxt">Sunset dentistry</div>
            </IonCol>
            <IonRow style={{ marginRight: "-13px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "82px", top: "380px" }}>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">John used TNPL 7 hours ago...</div>
              <div className="locpinsubtxt">Union Square Dental </div>
            </IonCol>
            <IonRow style={{ marginRight: "-13px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "297px", top: "510px" }}>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">32 new customers joined</div>
              <div className="locpinsubtxt">Starbucks join network</div>
            </IonCol>
            <IonRow style={{ marginRight: "-13px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "49px", top: "553px" }}>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">Tony used TNPL 2 days ago...</div>
              <div className="locpinsubtxt">SF Family Dentistry</div>
            </IonCol>
            <IonRow style={{ marginRight: "-13px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "880px", top: "100px" }}>
            <IonRow style={{ marginLeft: "-27px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">John used TNPL 5 mins ago...</div>
              <div className="locpinsubtxt">Macus Dental</div>
            </IonCol>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "900px", top: "180px" }}>
            <IonRow style={{ marginLeft: "-27px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">32 new customers joined</div>
              <div className="locpinsubtxt">Hobby Lobby join network</div>
            </IonCol>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "930px", top: "300px" }}>
            <IonRow style={{ marginLeft: "-27px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">John used TNPL 5 mins ago...</div>
              <div className="locpinsubtxt">Beach Dental</div>
            </IonCol>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "900px", top: "400px" }}>
            <IonRow style={{ marginLeft: "-27px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">Sara used TNPL 8 mins ago...</div>
              <div className="locpinsubtxt">Salinas Dental</div>
            </IonCol>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "694px", top: "494px" }}>
            <IonRow style={{ marginLeft: "-27px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">88 new customers joined</div>
              <div className="locpinsubtxt">Uber join network</div>
            </IonCol>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "920px", top: "516px" }}>
            <IonRow style={{ marginLeft: "-27px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">Lydia used TNPL 45 mins ago...</div>
              <div className="locpinsubtxt">Child Dental</div>
            </IonCol>
          </IonRow>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          <IonRow className="ion-align-items-center loacbg" style={{ left: "554px", top: "550px" }}>
            <IonCol className="ion-no-padding">
              <div className="localpnttxt">79 new customers joined</div>
              <div className="locpinsubtxt">Homedepot join network</div>
            </IonCol>
            <IonRow style={{ marginRight: "-13px" }}>
              <LocationOn title="LocationOn" size="45" color="#00B9CF" className="locicon" />
            </IonRow>
          </IonRow>
        </IonRow>
        <div className="card-flex">
          <IonCard className="viewsmain-card">
            <IonCardContent>
              <IonRow className="ion-justify-content-center ion-text-center">
                <IonCol className="ion-justify-content-center ion-text-center">
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonText className="unlkinmbr">1&nbsp;</IonText>
                    <IonIcon className="unlkicon" icon={eye} />
                  </IonRow>
                  <IonRow className="ion-justify-content-center">
                    <IonText className="txt-card">Views</IonText>
                  </IonRow>
                </IonCol>
                <IonCol className="ion-justify-content-center ion-text-center">
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonText className="unlkinmbr" style={{ color: "#F55B5D" }}>
                      2&nbsp;
                    </IonText>
                    <IonIcon className="unlkicon" icon={mailOutline} />
                  </IonRow>
                  <IonRow className="ion-justify-content-center">
                    <IonText className="txt-card">Pending Invites</IonText>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center smcds ion-padding-top">
                <IonText>SMCDS</IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center ion-padding-bottom">
                <IonText>
                  San Mateo Dental Society invites you to mybenefits Treat Now
                  Pay Later
                </IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center">
                <IonButton
                  className="unlckbtn"
                  onClick={() => {
                    navtosignup();
                  }}
                >
                  Unlock Now
                </IonButton>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center lock">
                <img
                  src={require(`../../images/locationpng.png`).default}
                  height="58px"
                  width="38px"
                  alt=""
                />
              </IonRow>
            </IonCardContent>
          </IonCard>
        </div>
      </div>
    </IonPage>
    // <IonPage>
    //   <IonHeader style={{ zIndex: "99999" }} className="header-box">
    //     <IonGrid className="pad-0">
    //       <IonRow className="header">
    //         <IonRow className="ion-align-items-center">
    //           <IonRow className="ion-justify-content-center ion-align-items-center">
    //             <div style={{ display: "flex" }}>
    //               <img
    //                 src={require(`../../images/final_logo.png`).default}
    //                 className=""
    //                 height="38px"
    //                 width="37px"
    //                 alt=""
    //               />
    //             </div>
    //             <IonRow>
    //               <IonCol>
    //                 <IonRow className="ion-align-items-center ion-justify-content-center">
    //                   <IonText className="logo-txts">myBenefits.ai</IonText>
    //                 </IonRow>
    //                 <IonRow className="ion-align-items-center ion-justify-content-center">
    //                   <IonText className="subtitle">
    //                     Treat Now, Pay Later
    //                   </IonText>
    //                 </IonRow>
    //               </IonCol>
    //             </IonRow>
    //           </IonRow>
    //         </IonRow>
    //         <IonRow
    //           className="ion-align-items-center ion-justify-content-center"
    //           style={{ paddingLeft: -300 }}
    //         >
    //           <IonText style={{ fontSize: 20, fontWeight: "bold" }}>
    //             Total Providers Joined : 246 <br></br> Total Employees Joined :
    //             690
    //           </IonText>

    //           {/* <IonText>Total Employees Joined : 690</IonText> */}
    //         </IonRow>
    //         <IonRow className="ion-align-items-center ion-justify-content-center">
    //           <IonText></IonText>
    //         </IonRow>
    //         <IonRow className="ion-align-items-center ion-justify-content-center">
    //           <IonText></IonText>
    //         </IonRow>
    //       </IonRow>
    //     </IonGrid>
    //   </IonHeader>
    //   <IonRow className="pane">
    //     <IonCard className="viewsmain-card">
    //       <IonCardContent>
    //         <IonRow className="ion-justify-content-center ion-text-center">
    //           <IonCol className="ion-justify-content-center ion-text-center">
    //             <IonText className="unlkinmbr">
    //               1 &nbsp;
    //               <IonIcon className="unlkicon" icon={eyeOutline} />
    //             </IonText>
    //             <br></br>
    //             <IonText style={{ color: "pink" }}>Views</IonText>
    //           </IonCol>
    //           <IonCol>
    //             <IonText className="unlkinmbr">2 &nbsp;</IonText>
    //             <IonIcon className="unlkicon" icon={mailOutline} />
    //             <br></br>
    //             <IonText style={{ color: "pink" }}>Pending Invites</IonText>
    //           </IonCol>
    //         </IonRow>
    //         <IonRow className="ion-justify-content-center ion-text-center">
    //           <IonCol>
    //             <IonText
    //               style={{
    //                 color: "#095E68",
    //                 fontSize: "23px",
    //                 fontWeight: "bold",
    //               }}
    //             >
    //               SMCDS
    //             </IonText>
    //           </IonCol>
    //         </IonRow>
    //         <IonRow className="ion-justify-content-center ion-text-center">
    //           <IonCol>
    //             <IonText>
    //               San Mateo Dental Society invites you to mybenefits Treat Now
    //               Pay Later
    //             </IonText>
    //             <br></br>
    //             <div>
    //               <IonButton
    //                 style={{
    //                   width: "100%",
    //                 }}
    //                 className="unlckbtn"
    //                 onClick={() => { navtosignup() }}
    //               >
    //                 Unlock Now
    //               </IonButton>
    //             </div>
    //           </IonCol>
    //         </IonRow>
    //       </IonCardContent>
    //     </IonCard>
    //   </IonRow>
    // </IonPage>
  );
};
export default UnlockMap;
