import {
    IonCol, IonContent, IonGrid, IonChip, IonIcon, IonLoading, IonSelect, IonSelectOption,
    IonInput, IonLabel, IonModal, IonDatetime, IonButton, IonPopover, IonPage, IonRow, IonText, useIonAlert, useIonLoading, useIonToast, IonItem
} from '@ionic/react';
import './index.css';
import TreatNowDescription from "../TreatNowDescription";
import { useState, useContext, useEffect } from 'react';
import { useHistory, useParams, } from "react-router-dom";
import 'react-multiple-select-dropdown-lite/dist/index.css';
import CommonContext from "../../utils/contexts/commonProps";
import moment from "moment";
import Select from 'react-select';
import instance from '../../utils/api';
import { close } from 'ionicons/icons';
import NumberFormat from 'react-number-format';
import { PlusCircleFill } from '@styled-icons/bootstrap/PlusCircleFill';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OuterHeader from '../outerHeader';

const PatientProcedureCodes: React.FC = (props) => {
    const history = useHistory();
    const [alert] = useIonAlert();
    const [showToast, dismiss] = useIonToast();
    const { common, setCommon } = useContext(CommonContext);
    const patientLoanDetails = common.patientData.patientObj.users || [];
    const employerName = common.patientData.patientObj.employers || []
    const token = localStorage.getItem('auth_token');
    const [allCodes, setAllCodes] = useState<any>([]);
    const [searchDiv, setSearchDiv] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [procedureCost, setProcedureCost] = useState<any>()
    const params = useParams<any>();
    const [selectedDate, setSelectedDate] = useState<any>();
    const isBankAdded = localStorage.getItem('isBankAdded');
    const [tnplFees, setTnplFees] = useState<any>(0);
    const [payoutAmount, setPayoutAmount] = useState<any>();
    const walkInProviderSignUp = localStorage.getItem('walkInProviderSignUp');
    const [cost, setCost] = useState<any>();
    const [allCodesFilter, setallCodesFilter] = useState<any>(0)
    const [searchCodes, setSearchCodes] = useState<any>()
    const [loanAmount, setLoanAmount] = useState<any>("");
    const [serviceType, setServiceType] = useState('');
    // const search = async () => {
    //     setShowLoading(true)
    //     let queryUrl: any = ""
    //     if (walkInProviderSignUp === "true") {
    //         queryUrl = `PayLater/userloanDetails/${params.id}`
    //     }
    //     else {
    //         queryUrl = `PayLater/searchloanDetails/${params.id}`
    //     }
    //     await instance({
    //         method: 'GET',
    //         url: queryUrl,
    //         validateStatus: () => true
    //     }).then(res => {
    //         let obj: any = {};
    //         obj = res
    //         if (obj.status === "SUCCESS!") {
    //             let data = obj.result
    //             common.patientData.patientObj = data;
    //             setCommon(common)
    //             // setProcedureCost(data.totalAmount.toFixed(2));
    //             // setCost(data.totalAmount);
    //             // setLoanAmount(data.totalAmount.toFixed(2));
    //             // setShowLoading(false);
    //             // setServiceType(data && data.serviceType ? data.serviceType : '')
    //         }
    //         else {
    //             setShowLoading(false);
    //         }
    //     })
    // }
    useEffect(() => {
        //search();
        setProcedureCost(common.patientData.patientObj.totalAmount.toFixed(2));
        setCost(common.patientData.patientObj.totalAmount);
        setLoanAmount(common.patientData.patientObj.totalAmount.toFixed(2));
        setServiceType(common.patientData.patientObj && common.patientData.patientObj.serviceType ? common.patientData.patientObj.serviceType : '')
    }, [])

    const getProcedureCodes = async () => {
        await instance({
            method: 'GET',
            url: 'mybenefitsprovider/code',
            validateStatus: () => true,
        }).then(res => {
            if (res) {
                let obj: any = {};
                obj = res
                obj.result.sort((a: any, b: any) => a - b);
                setAllCodes(obj.result);
                setallCodesFilter(obj.result)
            }
        }).catch(error => {
            console.log(error);
        })
    }
    useEffect(() => {
        getProcedureCodes()
    }, [])

    const [selectedValues, setSelectedValues] = useState<any>([]);
    useEffect(() => {
        setAllCodes(allCodes);
    }, [selectedValues])

    const chooseItem = (v) => {
        setSearchDiv(false);
        setSearchCodes("");
        setSelectedValues([...selectedValues, v]);
    }
    const removeSelectedCode = (e: any, i: any) => {
        e.stopPropagation();
        setSearchDiv(false);
        setSelectedValues((selectedValues: any) => selectedValues.filter((el: any) => el != i));
    }
    const closeSearchDiv = () => {
        if (searchDiv === true) {
            setSearchDiv(false);
        }
    }
    const next = async (value: any) => {
        // if (selectedValues.length == 0) {
        //     showToast('Please Select Procedure codes', 3000)
        // }
        if (!procedureCost) {
            showToast('Please Enter Total Procedure Cost', 3000)
        }
        else if (!selectedDate) {
            showToast('Please Enter Date of Service', 3000)
        }
        else {
            setShowLoading(true);
            let selectedCodes = new Array()
            selectedValues.map((value: any) => {
                if (value) {
                    selectedCodes.push(value.code)
                }
            })
            let date = moment(selectedDate).format('x')
            let data = {
                code: selectedCodes,
                dateOfService: date,
                totalAmount: procedureCost,
                payoutAmount: payoutAmount
            }
            await instance({
                method: 'PUT',
                url: `PayLater/procedureCode/${common.patientData.patientObj._id}`,
                data,
                validateStatus: () => true
            }).then((response) => {
                let obj: any = {}
                obj = response;
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    if (value === "addedBank") {
                        window.location.href = "/dashboard"
                    }
                    else if (value === "addBank" && isBankAdded === "false") {
                        window.location.href = '/provider_bank_details';
                    }
                }
                else if (obj.status === "ERROR!") {
                    setShowLoading(false);
                    showToast(obj.error, 3000)
                }
            })
        }
    }
    const searCodes = (e: any) => {
        setSearchCodes(e.detail.value);
        const orginal = allCodesFilter;
        if (e.detail.value) {
            setSearchDiv(true);
            let filterdata = orginal.filter((item: any) => {
                let query = (e.detail.value).toLowerCase();
                return (
                    (item.description || '').toLowerCase().indexOf(query) >= 0 ||
                    String(item.code).toLowerCase().indexOf(query) >= 0
                    // item.description.toLowerCase().indexOf(query) >= 0
                )
            })
            setAllCodes(filterdata);
        }
        else {
            setAllCodes(orginal)
        }
    }
    useEffect(() => {
        let per: number = 10.00
        let fees: number = 0.00
        let Pcost: number = 0.00
        Pcost = procedureCost;
        fees = per * Pcost / 100.00;
        setTnplFees(fees);
        setPayoutAmount(Pcost - fees)
    }, [procedureCost, cost])

    const proccedureCostChange = (e: any) => {
        setProcedureCost(e.value)
    }
    const keypres = (e: any) => {
        if (procedureCost > cost) {
            e.preventDefault();
            e.stopPropagation();
            setProcedureCost(cost)
        }
    }
    return (
        <IonPage>
            <IonContent onClick={() => closeSearchDiv()}>
                <IonGrid className="gridht">
                    <IonRow style={{ height: '100%' }}>
                        <IonCol className='pad-0'>
                            {/* <OuterHeader></OuterHeader> */}
                            <IonRow className="head-prov-title-bg ion-justify-content-center ion-align-items-center ion-margin-bottom">
                                <IonText>
                                    Treatment Details
                                </IonText>
                            </IonRow>
                            <div className='detaildiv'>
                                <table className='table-pad'>
                                    <thead style={{ textAlign: 'left' }}>
                                        <tr>
                                            <th className='th-pad userdetails-header'>Patient Name</th>
                                            <th className='th-pad userdetails-header'>Date of Birth</th>
                                            <th className='th-pad userdetails-header'>Gender</th>
                                            <th className='th-pad userdetails-header'>Employer</th>
                                            <th className='th-pad userdetails-header'>Approved Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: 'left' }}>
                                        {patientLoanDetails.map((value: any, key: any) => {
                                            return (
                                                <tr key={key}>
                                                    <td className='td-pad userdetails-values'>{value.firstName} {value.lastName}</td>
                                                    <td className='td-pad userdetails-values'>{moment(parseInt(value.dob)).format("DD/MM/YYYY")}</td>
                                                    <td className='td-pad userdetails-values'>{value.gender}</td>
                                                    <td className='td-pad userdetails-values'>{employerName && employerName[0].name}</td>
                                                    <td className='td-pad userdetails-values'>${loanAmount}</td>
                                                </tr>
                                            )
                                        }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className='fieldiv'>
                                <IonCol className="fields-row-pd">
                                    {/* {
                                        serviceType == 'Dental' ?
                                            <IonRow style={{ display: 'flex', flexDirection: 'column', }} className={searchDiv === true ? 'pad-bt-0' : ''}>
                                                <div style={{ padding: '0px 0px 5px 0px' }}>
                                                    <IonLabel className='label'>Procedure Codes</IonLabel>
                                                </div>
                                                <div className={selectedValues.length > 3 ? 'search-ht search-box-flex' : 'input-field search-box-flex'}>
                                                    <IonRow>
                                                        {selectedValues.length > 0 ? (
                                                            selectedValues.map((item: any, key: any) => {
                                                                return (
                                                                    <IonRow key={key}><IonChip className='chip-color'><IonLabel>{item.code}</IonLabel><IonIcon icon={close} color="white" onClick={(e) => removeSelectedCode(e, item)} /></IonChip></IonRow>
                                                                )
                                                            })
                                                        ) : null}</IonRow>

                                                    <IonInput className='searchtxt placeholder-txt zindex1' placeholder='Enter' onClick={() => setSearchDiv(!searchDiv)} value={searchCodes} onIonChange={e => searCodes(e)}>
                                                    </IonInput>
                                                </div>
                                            </IonRow> : ''}
                                    {searchDiv === true &&
                                        <div className='dropdiv'>
                                            <IonRow className='dropdowns'>
                                                <table className='table-pad' style={{ height: "max-content" }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="procodes-header">Code</th>
                                                            <th className="procodes-header">Decription</th>
                                                        </tr>

                                                    </thead>
                                                    <tbody style={{ textAlign: 'left' }}>
                                                        {allCodes && allCodes.map((item: any, key: any) => {
                                                            return (
                                                                <tr key={key} onClick={() => chooseItem(item)} style={{ height: '10px' }}>
                                                                    <td className='td-pad codes-values-txt'>{item.code}</td>
                                                                    <td className='td-pad codes-values-txt'>{item.description}</td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                </table>
                                            </IonRow>
                                        </div>
                                    }
                                    {
                                        serviceType == 'Dental' ?

                                            <IonRow className='add-more-pd ion-padding-bottom' onClick={() => setSearchDiv(!searchDiv)}>
                                                <PlusCircleFill size="25" title="PlusCircleFill" color='#00B9CF' />
                                                <IonText className='add-more-txt'>Add more</IonText>
                                            </IonRow> : ''} */}
                                    <IonRow>
                                        <IonCol className="pad-0 mg-rt-10">
                                            <IonLabel className='label whitespace'>Treatment Amount</IonLabel>
                                            <NumberFormat
                                                displayType="input"
                                                type="text"
                                                mask=""
                                                allowNegative={true} prefix="$" className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="Enter" value={procedureCost} onKeyUp={(e) => keypres(e)} onValueChange={(e) => proccedureCostChange(e)} />
                                        </IonCol>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Convenience Fees</IonLabel>
                                            <IonInput readonly value={`${"$"}${tnplFees && tnplFees.toFixed(2)}`} className="zindex-1 ion-padding mg-top input-field placeholder-txt" ></IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Payout Amount to You</IonLabel>
                                            <IonInput readonly value={`${"$"}${payoutAmount && payoutAmount.toFixed(2)}`} className="zindex-1 ion-padding mg-top input-field placeholder-txt" ></IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Select Date of Service</IonLabel>
                                            <DatePicker minDate={moment().toDate()} placeholderText="MM/DD/YYYY" dateFormat="MM/dd/yyyy"
                                                className='date-pd-10 mg-top input-field mg-top placeholder-txt width-100' selected={selectedDate} onChange={e => setSelectedDate(e)} />
                                            {/* <IonDatetime datatype='date' placeholder='Select' className='date-pd-10 mg-top input-field mg-top placeholder-txt' value={selectedDate} onIonChange={e => setSelectedDate(e.detail.value!)}></IonDatetime> */}
                                        </IonCol>
                                    </IonRow>
                                    {isBankAdded === "true" ?
                                        <IonButton expand="block" className='searchbtn  btn-mg-tp-0 searchbtn-txt' onClick={() => next("addedBank")}>Continue</IonButton>
                                        :
                                        <IonButton expand="block" className='searchbtn  btn-mg-tp-0 searchbtn-txt' onClick={() => next("addBank")}>Continue</IonButton>}
                                    {/* <IonRow className='signuprow ion-justify-content-center ion-padding'>
                                        <span>Have an account</span>?<span className='signup' onClick={() => { window.location.href = '/provider_login' }}>Sign in now</span>
                                    </IonRow> */}
                                </IonCol>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
        </IonPage>
    )
}
export default PatientProcedureCodes;