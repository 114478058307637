
import { IonContent, IonItem, IonLabel, IonList, IonPage, IonRow, IonHeader, IonCol, IonText, IonGrid, } from '@ionic/react';
import { arrowDownCircle } from 'ionicons/icons';
import './index.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Dot } from '@styled-icons/bootstrap/Dot'
import OuterHeader from '../../components/outerHeader';
// Demo styles, see 'Styles' section below for some notes on use.
const Faq: React.FC = () => {

    return (
        <IonPage className="bg-cnt">
            {/* <IonHeader style={{ zIndex: '99999' }} className="header-box">
                <IonGrid className='pad-0'>
                    <IonRow className='header'>
                        <IonRow className="ion-justify-content-center ion-align-items-center">
                            <div style={{ display: 'flex' }}>
                                <img src={require(`../../images/final_logo.png`).default} className="" height="38px" width="37px" alt="" />
                            </div>
                            <IonRow>
                                <IonCol>
                                    <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                                    <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='subtitle'>Treat Now, Pay Later</IonText></IonRow>
                                </IonCol>
                            </IonRow>
                        </IonRow>
                        <IonRow onClick={() => window.location.href = "/signup"}>
                            <IonText className='subtitle pointer'>Create Account</IonText>
                        </IonRow>
                    </IonRow>
                </IonGrid>
            </IonHeader>
            <IonContent fullscreen> */}
            <IonContent fullscreen>
                <IonRow className="ion-justify-content-center display-flex">
                    <IonCol className='pad-0'>
                        <OuterHeader></OuterHeader>
                        <IonRow className="head-prov-title-bg ion-justify-content-center ion-align-items-center ion-margin-bottom">
                            <IonText>
                                Provider FAQs
                            </IonText>
                        </IonRow>
                        <div className="ion-justify-content-center display-flex-faq ion-padding">
                            {/* <IonRow className='ion-padding-top ion-padding-bottom faqtitle'><IonText>Provider FAQs</IonText></IonRow>
                            <IonRow className='subtxt ion-padding-top'><IonText>We are here to help!</IonText></IonRow>
                            <IonRow className='subtxt'><IonText>Email: support@mybenefits.ai </IonText></IonRow>
                            <IonRow className='subtxt'><IonText>Phone: (650)-777-7133</IonText></IonRow> */}
                            <IonRow className='cnthelp ion-padding-bottom' style={{ fontSize: "22px" }}>Contact for help</IonRow>
                            {/* <IonRow className='subtxt ion-padding-top'><IonText>We are here to help!</IonText></IonRow> */}
                            <IonRow><IonText className='cnthelpfield'>Email:&nbsp;<IonText style={{ color: "#00B9CF" }}>support@mybenefits.ai </IonText></IonText></IonRow>
                            <IonRow><IonText className='cnthelpfield'>Phone:&nbsp;<IonText style={{ color: "#00B9CF" }}>(650)-777-7133</IonText></IonText></IonRow>
                            {/* <IonRow className='ion-padding-top'>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <IonRow className='ion-padding-top'><IonText className='subtxt'> Get Started:</IonText></IonRow>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div></div><br></br>
                                    <IonRow></IonRow><br></br>
                                    <IonRow className='subtxt pad-2'><IonText>1. &nbsp;Easy to Start,</IonText></IonRow>
                                    <IonRow className='subtxt pad-2'><IonText>2. &nbsp;Simple to use,</IonText></IonRow>
                                    <IonRow className='subtxt pad-2'><IonText>3. &nbsp;Best Provider Experience &</IonText></IonRow>
                                    <IonRow className='subtxt pad-2'><IonText>4. &nbsp;Best Patient Experience </IonText></IonRow>
                                </div>
                            </IonRow> */}
                            <div style={{ maxWidth: "700px" }}>
                                <IonRow className='ion-padding-top fnt-subtxt'>
                                    <IonText>
                                        About myBenefits.ai
                                    </IonText>
                                </IonRow>
                                <IonRow className='ion-padding-top abt-txt'>
                                    <IonText>myBenefits.ai was founded by a few providers who understood the pain points of being unable to treat patients due to restricted insurance coverage and patient affordability to pay for any out of pocket expenses. myBenefits.ai helps employees to pay for copays and deductibles.</IonText>
                                </IonRow>
                                <IonRow className='ion-padding-top abt-txt'>
                                    <IonText>We are aware of the high cost of patient acquisition with marketing through Google, Yelp and Zocdoc.Our mission is to help providers with direct access to employers and employees and help employees to pay for the treatment with Treat Now, Pay Later. </IonText>
                                </IonRow>
                                <IonRow className='ion-padding-top abt-txt'>
                                    <IonText>It's a Win-Win for all!</IonText>
                                </IonRow>
                            </div>
                            <IonRow className='ion-padding mybenwel'>
                                <IonText>myBenefits Team Welcomes You! </IonText>
                            </IonRow>
                            <IonRow className='ion-padding fnt-subtxt'><IonText>We want to make it simple and easy for Providers, here are some Frequently Asked Questions:</IonText></IonRow>
                            <div style={{ maxWidth: "700px" }} className='ion-padding'>
                                <Accordion allowMultipleExpanded allowZeroExpanded>
                                    <AccordionItem className='acc-br-top'>
                                        <AccordionItemHeading role="end" >
                                            <AccordionItemButton>
                                                What is myBenefits.ai?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                myBenefits.ai is a platform that connects healthcare Providers with Patients (employees belonging to various Organisations) in need of healthcare through innovative services like Treat Now Pay Later ©
                                            </p>
                                            <p>Treat Now Pay Later © enables Employees to seek out any health or wellness services and turn out of pocket expense into an interest free payment plan.
                                            </p>
                                            <p>
                                                Providers enrolled on myBenefits.ai get listed for free as a participating provider for all the Employees of the local businesses signed up on myBenefits.ai.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Why should I enroll with myBenefits.ai, I already provide other financing options to my patients / clients?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Advantages for a Practice or Provider with mybenefits.ai:
                                            </p>
                                            <p>Patients enrolled for myBenefits.ai who come to your practice are pre-approved. Compared to other vendor or financing solutions, with myBenefits.ai Providers do not need to go through an approval process or worry about the patient getting declined.
                                            </p>
                                            <p>As compared to other vendors, our fees do not vary and on an average less than our competitors
                                            </p>
                                            <p>Providers get listed with the local businesses as a participating provider for free.</p>
                                            <p>Providers have the option to send Offers to all employees of the participating local businesses.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What type of practices and business are enrolled with myBenefits.ia?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                myBenefits.ai partners with all businesses that offer healthcare and wellness services. Below is a representative list of providers that are joining our network; dental, vision, chiropractor, mental health, acupuncture, hospitals, vet, and sports medicine
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                I am Skeptical about providing my data; is my data secure?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                myBenefits.ai is HIPAA compliant and prioritises security by encrypting your data using industry best practice such AES 256 Bit encryption and dual passwords.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                How do I get started?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                you can get started :<a href='/signup'> Click Here</a>
                                            </p>
                                            <p>Create your account with One click </p>
                                            <p>Add your Practice Profile and Bank Details</p>
                                            <p>Enter 6 digit Pin from Employees and Get Paid instantly</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is the cost to use myBenefits.ai?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Patient finances $1000 through the mybenefits TNPL platform. The provider will be charged a flat transaction fee of $100. Your practice will be listed at No Cost with local employers.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                            <div style={{ maxWidth: "700px" }} className='ion-padding'>
                                <IonRow className='ion-padding-vertical mybenwel' style={{ fontSize: "18px" }}><IonText>Patient and Client Experience</IonText></IonRow>
                                <Accordion allowMultipleExpanded allowZeroExpanded>
                                    <AccordionItem className='acc-br-top'>
                                        <AccordionItemHeading role="end" >
                                            <AccordionItemButton>
                                                What financing options do you offer?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Currently we are offering a 12 month interest free payment plan to all the employees of the businesses that are signed up with myBenefits.ai
                                            </p>

                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                How do my patients / clients get access to myBenefits.ai’s "Treat Now, Pay Later,” interest-free financing option?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Currently Treat Now, Pay Later is available only to employees of the businesses that are signed up with myBenefits.ai. We recommend that patients whose employers are not signed with myBenefits.ai reach out to their HR representative in their organisation and ask them to enroll with myBenefits.ai
                                            </p>

                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                How will my patients / clients pay?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Once your patient has received their services they can easily set up a repayment plan within the myBenefits.ai app.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Can I offer myBenefits.ai to all my patients / clients?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Currently Treat Now, Pay Later is available only to employees of the businesses that are signed up with myBenefits.ai. At this moment, we recommend that patients whose employers are not signed with myBenefits.ai reach out to their HR representative in their organisation and ask them to enroll with myBenefits.ai.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                            <div style={{ maxWidth: "700px" }} className='ion-padding'>
                                <IonRow className='ion-padding-vertical mybenwel' style={{ fontSize: "18px" }}><IonText>Provider and Business Experience</IonText></IonRow>
                                <Accordion allowMultipleExpanded allowZeroExpanded>
                                    <AccordionItem className='acc-br-top'>
                                        <AccordionItemHeading role="end" >
                                            <AccordionItemButton>
                                                How will I get paid?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                We pay you within two business days by making an electronic deposit into your bank account, minus our transaction fee.
                                            </p>

                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What if my patients or clients don’t pay their bills?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                myBenefits.ai will pay for your practice as soon as the service is provided to your patient. There is no risk to you if your patient or client does not pay their bills. It is our responsibility to work with your patients to collect payments.
                                            </p>

                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What if my patients or clients have a dispute about the service received?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                myBenefits.ai covers only healthcare and wellness services. Once the services are rendered myBenefits.ai will pay you within two business days. If your patient or client have a dispute about the services rendered we recommend that you amicably resolve that directly with your client. But we will issue NO chargebacks to you. We recommend that you keep all the documentation showing that the services were rendered.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                            <IonRow className='ion-padding'>
                                {/* <div className='faqimg'></div> */}
                                <img src={require(`../../images/faqs.jpeg`).default} className="faqimg" height="100%" alt="" />
                            </IonRow>
                        </div>

                        {/* <IonRow className='ion-justify-content-center ion-align-items-start'>
                        <IonRow className='ion-padding'>
                            <img src={require(`../../images/faq.png`).default} className="faqimg" height="100%" width="100%" alt="" />
                        </IonRow>
                        <IonCol className='accor-width ion-padding'>
                                <Accordion allowMultipleExpanded allowZeroExpanded>
                                    <AccordionItem>
                                        <AccordionItemHeading role="end" >
                                            <AccordionItemButton>
                                                What is myBenefits.ai?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                myBenefits.ai is a platform that connects healthcare Providers with Patients (employees belonging to various Organisations) in need of healthcare through innovative services like Treat Now Pay Later ©
                                            </p>
                                            <p>Treat Now Pay Later © enables Employees to seek out any health or wellness services and turn out of pocket expense into an interest free payment plan.
                                            </p>
                                            <p>
                                                Providers enrolled on myBenefits.ai get listed for free as a participating provider for all the Employees of the local businesses signed up on myBenefits.ai.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Why should I enrol with myBenefits.ai, I already provide other financing options to my patients / clients?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Advantages for a Practice or Provider with mybenefits.ai:
                                            </p>
                                            <p>Patients enrolled for myBenefits.ai who come to your practice are pre-approved. Compared to other vendor or financing solutions, with myBenefits.ai Providers do not need to go through an approval process or worry about the patient getting declined.
                                            </p>
                                            <p>As compared to other vendors, our fees do not vary and on an average less than our competitors
                                            </p>
                                            <p>Providers get listed with the local businesses as a participating provider for free.</p>
                                            <p>Providers have the option to send Offers to all employees of the participating local businesses.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What type of practices and business are enrolled with myBenefits.ia?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                myBenefits.ai partners with all businesses that offer healthcare and wellness services. Below is a representative list of providers that are joining our network; dental, vision, chiropractor, mental health, acupuncture, hospitals, vet, and sports medicine
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                I am sceptical about providing my data; is my data secure?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                myBenefits.ai is HIPAA compliant and prioritises security by encrypting your data using industry best practice such AES 256 Bit encryption and dual passwords.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                How do I get started?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                You can get started by going to our sign-up page, link here.
                                            </p>
                                            <p>Create your account with One click </p>
                                            <p>Add your Practice Profile and Bank Details</p>
                                            <p>Enter 6 digit Pin from Employees and Get Paid instantly</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                What is the cost to use myBenefits.ai?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                There is no cost to sign-up. There are no contracts or subscription fees. Providers are charged a 10% transaction fee for the out of the pocket expenses that are paid through Treat Now Pay Later.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                        </IonCol>

                    </IonRow> */}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage >
    );
};
export default Faq
