import { IonRow, IonText } from "@ionic/react"
import { Smartphone } from "@styled-icons/material-outlined/Smartphone";
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
interface Props {
    qrCode: any
    dissmissQrModal: () => void,
}
const Print: React.FC<Props> = (props) => {
    return (
        <div style={{ height: "100%", overflow: "auto", background: "#FFFFFF" }}>
            <div className="qrmdcnct" id="divToPrint">
                <div style={{ padding: "0px 0px 0px 16px", display: "flex", justifyContent: "space-around", flexDirection: "column" }}>
                    <div>
                        <div style={{ display: "flex", justifyContent: "flex-end",cursor:"pointer" }} onClick={() => props.dissmissQrModal()}>
                            <CloseOutline title="Close" size={30} color="#3DC2FF"></CloseOutline>
                        </div>
                    </div>
                    <div style={{ padding: "16px 32px 0px 16px" }}>
                        <div style={{
                            display: "flex", flexDirection: "column", fontFamily: 'Roboto',
                            fontStyle: "normal",
                        }}>
                            <IonRow><IonText style={{
                                fontWeight: "900",
                                fontSize: "60px",
                                lineHeight: "50px",
                                letterSpacing: "0.1px",
                                color: "#095E68"
                            }}>Treat Now,<br></br> Pay Later!</IonText></IonRow>
                            <IonRow style={{
                                fontWeight: "400",
                                fontSize: "17px",
                                lineHeight: "40px",
                                letterSpacing: "0.1px",
                                padding: "16px 0px",
                                color: "#333333"
                            }}>
                                <IonText>Do you need health care and wellness services but cannot afford the high costs. No problem! We are here to assist you with immediate funds available to you at no additional cost.</IonText>
                            </IonRow>
                           
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div>
                                    <div style={{margin:"16px 0px"}}> 
                                        <IonText style={{
                                        fontWeight: "700",
                                        fontSize: "30px",
                                        lineHeight: "35px",
                                        letterSpacing: "0.1px",
                                        color: "#095E68",
                                    }}>How it works?</IonText></div>
                                    <div style={{
                                        display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: "16px", fontWeight: "400",
                                        fontSize: "17px",
                                        letterSpacing: "0.1px",
                                        color: "#333333"
                                    }}>
                                        <div style={{
                                            width: "30px",
                                            height: "30px",
                                            background: "#00B9CF",
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#FFFFFF",
                                            margin: "0px 10px 0px 0px"
                                        }}><IonText>1</IonText></div>
                                        <IonText >Enroll in Treat Now, Pay Later</IonText>
                                    </div>
                                    <div style={{
                                        display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: "16px", fontWeight: "400",
                                        fontSize: "17px",
                                        letterSpacing: "0.1px",
                                        color: "#333333"
                                    }}>
                                        <div style={{
                                            width: "30px",
                                            height: "30px",
                                            background: "#00B9CF",
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#FFFFFF",
                                            margin: "0px 10px 0px 0px"
                                        }}><IonText>2</IonText></div>
                                        <IonText >Find or Invite your service provider</IonText>
                                    </div>
                                    <div style={{
                                        display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: "16px", fontWeight: "400",
                                        fontSize: "17px",
                                        letterSpacing: "0.1px",
                                        color: "#333333"
                                    }}>
                                        <div style={{
                                            width: "30px",
                                            height: "30px",
                                            background: "#00B9CF",
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#FFFFFF",
                                            margin: "0px 10px 0px 0px",

                                        }}><IonText>3</IonText></div>
                                        <IonText>Pay for service using Treat Now, Pay Later  </IonText>
                                    </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", alignSelf: "flex-end" }}>
                                    <div className="scanbgflex" style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        backgroundColor: "#000000",
                                        borderRadius: "99px",
                                        padding: "3px",
                                        margin:"16px 0px"
                                        
                                    }}>
                                        <div className="scanbg" style={{
                                            background: "white",
                                            borderRadius: "99px",
                                            color: "black",
                                            padding: "2px"
                                        }}>
                                            <Smartphone title="smartPhone" size="25" />
                                        </div>
                                        <IonText style={{
                                            padding: "0px 15px",
                                            fontFamily: 'Roboto',
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            lineHeight: "12px",
                                            /* text-align: center; */
                                            letterSpacing: "-0.4px",
                                            textTransform: "uppercase",
                                            color: "#FFFFFF"
                                        }} >SCAN ME</IonText>
                                    </div>
                                    <img
                                        src={
                                            props.qrCode && props.qrCode.qrCode
                                                ? props.qrCode.qrCode
                                                : null
                                        }
                                        style={{
                                            border: "5px solid black",
                                            borderRadius: "10px"
                                        }}
                                        height="auto"
                                        width="auto"
                                        alt=""
                                    />
                                    <IonRow style={{ margin: "10px 8px" }}>
                                        <IonText style={{
                                            width: "100px", background: "#00B9CF",
                                            borderRadius: "10px",
                                            color: "#FFFFFF", fontFamily: 'Roboto',
                                            fontStyle: "normal",
                                            fontWeight: "700",
                                            fontSize: "15px",
                                            lineHeight: "20px",
                                            textAlign: "center",
                                            letterSpacing: "0.3px",
                                            height: "44px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer"
                                        }}

                                            onClick={() => {
                                                var win = window.open(
                                                    "",
                                                    "",
                                                    "width=877,height=877"
                                                );
                                                var content = "<html>";
                                                content +=
                                                    '<body onload="window.print(); window.close();">';
                                                content += document.getElementById("divToPrint")?.innerHTML;
                                                content += "</body>";
                                                content += "</html>";
                                                win?.document.write(content);
                                                win?.document.close();
                                            }}


                                        >
                                            Print
                                        </IonText>
                                    </IonRow>
                                </div>
                            </div>

                            {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingBottom: "16px" }} >
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    backgroundColor: "#000000",
                                    borderRadius: "99px",
                                    padding: "3px"
                                }}>
                                    <div style={{
                                        background: "white",
                                        borderRadius: "99px",
                                        color: "black",
                                        padding: "2px"
                                    }}>
                                        <Smartphone title="smartPhone" size="25" />
                                    </div>
                                    <IonText style={{
                                        padding: "0px 15px",
                                        fontFamily: 'Roboto',
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                        lineHeight: "12px",
                                        text-align: center; 
                                        letterSpacing: "-0.4px",
                                        textTransform: "uppercase",
                                        color: "#FFFFFF"
                                    }} >Or Text at 714-242-534</IonText>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
                <div style={{ backgroundColor: "#00B9CF" }}>
                    <div className="qr-toolbar">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ display: 'flex' }}>
                                    <img src={require(`../../images/final_logo.png`).default} className="" height="38px" width="37px" alt="" />
                                </div>
                                <div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <IonRow style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><IonText style={{
                                            fontFamily: 'Avenir',
                                            fontStyle: "normal",
                                            fontWeight: "800",
                                            fontSize: "20px",
                                            lineHeight: "28px",
                                            color: "#FFFFFF",
                                        }}>myBenefits.ai</IonText></IonRow>
                                        <IonRow style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><IonText style={{
                                            fontFamily: 'Avenir',
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            lineHeight: "135.69%",
                                            color: "#FFFFFF",
                                            letterSpacing: "0.04px"
                                        }} className="qr-toolbar-title">Treat Now, Pay Later</IonText></IonRow>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <IonRow style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><IonText style={{
                                        fontFamily: 'Roboto',
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "20px",
                                        textAlign: "right",
                                        letterSpacing: "-0.4px",
                                        color: "#FFFFFF",
                                    }} className="qr-toolbar-email">Our website: www.mybenefits.ai</IonText></IonRow>
                                    <IonRow style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><IonText style={{
                                        fontFamily: 'Roboto',
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "20px",
                                        textAlign: "right",
                                        letterSpacing: "-0.4px",
                                        color: "#FFFFFF",
                                    }}>Contact us: support@mybenefits.ai</IonText></IonRow>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Print;