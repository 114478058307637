import { IonButton, IonCol, IonContent, IonFooter, useIonAlert, IonInput, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonText, useIonToast, IonSelect, IonSelectOption } from "@ionic/react"
import { useEffect, useState } from "react";
import instance from "../../../utils/api";
import PlusHeader from "../Header/inex";
import { ModeEdit } from "@styled-icons/material-outlined/ModeEdit";
import { Delete } from "@styled-icons/fluentui-system-regular/Delete";
import { Formik } from "formik";
import * as yup from 'yup';
const PatientQuestions: React.FC = () => {
    const [patientQuestions, setPatientQuestions] = useState<any>([]);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalValue, setModalValue] = useState<any>("");
    const [presentAlert] = useIonAlert();
    const [showToast, dismiss] = useIonToast();
    const [SurgeryDepartment, setSurgeryDepartment] = useState<any>("Urology")
    const [addQuestion, setAddQuestion] = useState<any>({
        question: "",
        category: "",
        id: "",
    })

    async function fetchData() {
        setShowLoading(true)
        await instance({
            method: "GET",
            url: "discharge/getQuestion",
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setShowLoading(false)
                setPatientQuestions(obj.result);
                // const parseData = JSON.parse(obj.result.value)
                // if (parseData) {
                //     setPatientQuestions(parseData);
                // }
            }
            else {
                setShowLoading(false)
            }
        })
    }
    useEffect(() => {
        fetchData()
    }, [])

    const deleteQuestion = (e: any, value: any, index: any) => {
        console.log(value)
        e.stopPropagation()
        presentAlert({
            header: 'Are you sure you want to Delete?',
            buttons: [
                {
                    text: 'NO',
                    role: 'cancel',
                },
                {
                    text: 'YES',
                    role: 'confirm',
                    handler: async () => {
                        const deleteArray = [...patientQuestions]
                        deleteArray.splice(index, 1)
                        let obj: any = {}
                        // obj.key = "flora_questions"
                        // obj.value = JSON.stringify(deleteArray)
                        //obj.active = false

                        await instance({
                            method: "DELETE",
                            url: `discharge/question/${value._id}`,
                            data: obj,
                            validateStatus: () => true
                        }).then((resp) => {
                            let obj: any = {}
                            obj = resp;
                            if (obj.status === "SUCCESS!") {
                                setModalOpen(false)
                                setShowLoading(false)
                                fetchData()
                                showToast("Deleted Successfully", 3000)
                            }
                            else {
                                setShowLoading(false)
                            }
                        })
                    },
                },
            ],
        })
    }
    const showQuestionModal = (item: any, index: any, value: any) => {
        setModalValue(value)
        if (value === "edit") {
            //setAddQuestionValue({ index: index, item: item })
            setAddQuestion({
                question: item.question,
                category: item.category,
                id: item._id
            })
        }
        else {
            setAddQuestion("")
        }
        setModalOpen(true)
    }
    const addQuestionsApiCall = async (values: any) => {
        let data: any = {}
        if (modalValue === "add") {
            data.category = values.category
        }
        data.question = values.question
        let headers: any = {}
        if (modalValue === "add") {
            headers.method = "POST"
            headers.url = "discharge/question"
        } else {
            headers.method = "PUT"
            headers.url = `discharge/question/${addQuestion.id}`
        }
        setShowLoading(true)
        // const addQuestionArray = [...patientQuestions, addQuestionValue]
        // const editQuestionArray = [...patientQuestions]
        // if (modalValue === "edit") {
        //     editQuestionArray[addQuestionValue.index] = addQuestionValue.item
        // }
        // let obj: any = {}
        // obj.key = "flora_questions"
        // obj.value = modalValue === "add" ? JSON.stringify(addQuestionArray) : JSON.stringify(editQuestionArray)

        await instance({
            method: headers.method,
            url: headers.url,
            data: data,
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setModalOpen(false)
                setShowLoading(false)
                fetchData()
                if (modalValue === "add") {
                    showToast("Added Successfully", 3000)
                }
                else {
                    showToast("Updated Successfully", 3000)
                }
            }
            else {
                setShowLoading(false)
            }
        })
    }

    // const handleChange = (e: any) => {
    //     if (modalValue === "add") {
    //         setAddQuestionValue(e.detail.value)
    //     }
    //     else {
    //         addQuestionValue.item = e.detail.value
    //     }
    // }
    const validationSchema = yup.object({
        question: yup.string()
            .required('This field is required'),
        category: yup.string().required("This field is required")
    });
    return (
        <IonPage>
            <PlusHeader />
            <IonRow className="ion-justify-content-between ion-align-items-center ion-padding-horizontal" style={{ background: "white" }}>
                <IonSelect placeholder="Select" value={SurgeryDepartment} name="type" className="placeholder-txt" onIonChange={(e) => { setSurgeryDepartment(e.detail.value); console.log(e) }}>
                    <IonSelectOption value="Urology">Urology</IonSelectOption>
                    <IonSelectOption value="Endodontics">Endodontics</IonSelectOption>
                    <IonSelectOption value="Periodontitis">Periodontitis</IonSelectOption>
                    <IonSelectOption value="ENT">ENT</IonSelectOption>
                </IonSelect>
                <IonRow>
                    <IonButton expand="block" className='searchbtn searchbtn-txt' onClick={() => showQuestionModal("", "", "add")} style={{ margin: "10px" }}>Add Question</IonButton>
                </IonRow>
            </IonRow>

            <IonContent>
                <div className="ovrflow"
                    style={{ width: "100%", overflow: "auto" }}>
                    <table className="table-container" style={{ tableLayout: "fixed" }}>
                        <thead className="patient-thread">
                            <tr>
                                <th className="patient-th pd-lr-24 whitespace td-txt-center">Questions</th>
                                <th className="patient-th pd-lr-24 whitespace td-txt-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="patient-tbody">
                            {patientQuestions && patientQuestions.length > 0 ?
                                patientQuestions.map((value: any, index: any) => (
                                    SurgeryDepartment === value.category &&
                                    (<tr className="patient-tr" key={index}>
                                        <td className="td-txt pd-lr-24 td-txt-center">{value.question}</td>
                                        <td className="td-txt pd-lr-24 td-txt-center">
                                            <ModeEdit color="#00B9CF" size={30} onClick={() => showQuestionModal(value, index, "edit")} />
                                            <Delete color="red" size={30} onClick={(e) => deleteQuestion(e, value, index)} />
                                        </td>
                                    </tr>)
                                )):<IonRow className="not-found-txt" style={{width:"100%"}}>
                                <div>No Data Found</div></IonRow>}
                        </tbody>
                    </table>
                </div>
            </IonContent>
            <IonModal isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)} id="patient-questions">
                <IonRow className="popup-header">
                    <IonCol className="pad-0">
                        <IonRow className="ion-justify-content-center popup-title ion-padding">
                            <IonText className="popup-title">
                                {modalValue === "add" ? "Add Question" : "Edit Question"}
                            </IonText>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonContent>
                    <div className="ion-margin">
                        <Formik enableReinitialize={true} initialValues={{ question: addQuestion.question, category: addQuestion.category }}
                            onSubmit={addQuestionsApiCall} validationSchema={validationSchema}>
                            {formikProps => (
                                <div>
                                    <form onSubmit={formikProps.handleSubmit} id="form">
                                        <IonRow>
                                            <IonLabel className='label'>Question</IonLabel>
                                        </IonRow>
                                        <IonInput className="ion-padding input-field mg-top placeholder-txt" name="question" placeholder="Enter" value={formikProps.values.question}
                                            onIonChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                        >

                                        </IonInput>

                                        {formikProps.touched.question &&
                                            formikProps.errors.question ?
                                            <p className="fielderrormsg err-ft-14">{formikProps.errors.question}</p> : null}


                                        {modalValue === "add" &&
                                            <>
                                                <IonRow className="ion-padding-top">
                                                    <IonLabel className='label'>Choose category</IonLabel>
                                                </IonRow>
                                                {/* <IonSelect  name="category"  value={formikProps.values.category} className="placeholder-txt"
                                                  onIonChange={(e: any) =>
                                                    formikProps.setFieldValue("category", e.detail.value)
                                                    }
                                                    onBlur={formikProps.handleBlur}>
                                                    <IonSelectOption value="Urology">Urology</IonSelectOption>
                                                    <IonSelectOption value="Endodontics">Endodontics</IonSelectOption>
                                                </IonSelect> */}
                                                <select
                                                    name="category"
                                                    value={formikProps.values.category}
                                                    onChange={formikProps.handleChange}
                                                    onBlur={formikProps.handleBlur}
                                                    className="input-field mg-top placeholder-txt pointer"
                                                    style={{
                                                        width: "100%",
                                                        padding: "9px",
                                                        outline: "none",
                                                        background: "white",
                                                        color: "#808080"
                                                    }}
                                                >
                                                    <option value="">Select Your option</option>
                                                    <option value="Urology">Urology</option>
                                                    <option value="Endodontics">Endodontics</option>
                                                    <option value="Periodontitis">Periodontitis</option>
                                                    <option value="ENT">ENT</option>
                                                </select> 
                                                {formikProps.touched.category &&
                                                    formikProps.errors.category ?
                                                    <p className="fielderrormsg err-ft-14">{formikProps.errors.category}</p> : null}
                                            </>
                                        }
                                    </form>
                                </div>
                            )}
                        </Formik>
                    </div>
                </IonContent>
                <IonFooter>
                    <IonRow className="ion-padding-horizontal ion-justify-content-between">
                        <IonButton expand="block" className='searchbtn searchbtn-txt' form="form"

                            type="submit" style={{ margin: "10px 0px" }}>
                            {modalValue === "add" ? "Add" : "Update"}
                        </IonButton>
                        <IonButton expand="block" style={{ margin: "10px 0px" }} className='searchbtn searchbtn-txt' onClick={() => { setModalOpen(false)}}>Cancel</IonButton>
                    </IonRow>
                </IonFooter>
            </IonModal>
            <IonLoading isOpen={showLoading} message="Please wait..." />
        </IonPage>
    )
}
export default PatientQuestions