import { IonAvatar, useIonAlert, IonButton, useIonToast, IonCol, IonContent, IonLoading, IonGrid, IonItem, IonLabel, IonModal, IonPage, IonRow, IonText, IonToggle, IonInput } from "@ionic/react"
import { useState, useEffect } from "react";
import Header from "../../components/Header";
import moment from "moment";
import instance from "../../utils/api";
import './index.css';
import NumberFormat from "react-number-format";
import { CloseOutline} from '@styled-icons/evaicons-outline/CloseOutline';
const TreatCoins: React.FC = () => {
    const [toggle, setToggle] = useState<any>(false);
    const [alert] = useIonAlert();
    const [showToast, dismiss] = useIonToast();
    const [toggleActive, setToggleActive] = useState<any>("All");
    const [openModal, setOpenModal] = useState<any>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [getAllTreatCoins, setGetAllTreatCoins] = useState<any>([]);
    const [totalTreatCoins, setTotalTreatCoins] = useState<any>()
    const [providerDetails, setProviderDetails] = useState<any>([]);
    const [getfilterTreatCoins, setGetfilterTreatCoins] = useState<any>([]);
    const [reedemCoins, setReedemCoins] = useState<any>(0)
    const toggleHandle = (e: any) => {
        setToggle(e.detail.checked);
        var isRedeemCoinsTotal: any = []
        if (e.detail.checked === true) {
            getAllTreatCoins.filter((item: any) => {
                if (item.type === "patient") {
                    isRedeemCoinsTotal.push(item)
                }
            })
            if (isRedeemCoinsTotal.length > 0) {
                setGetAllTreatCoins(isRedeemCoinsTotal)
            }
        }
        else if (e.detail.checked === false) {
            isRedeemCoinsTotal = []
            setGetAllTreatCoins(getfilterTreatCoins);
        }

    }
    useEffect(() => {
        if (toggle === false) {
            setToggleActive("All")
        }
        else if (toggle === true) {
            setToggleActive("patients")
        }
    }, [toggle])

    useEffect(() => {
        getTreatCoins();
    }, [])

    const redeemApicall = async () => {
        if(reedemCoins == 0){
            showToast("Please Enter min 1  Coin",3000)
        }
        else if (reedemCoins > totalTreatCoins) {
            showToast("Please Enter max " + `${totalTreatCoins}` + " " + "Coins", 3000)
            setShowLoading(false);
        }
        else {
            setShowLoading(true);
            instance({
                method: "GET",
                url: `treatcoins/redeemCoins?coins=${reedemCoins}`,
                validateStatus: () => true,
            }).then((res) => {
                let obj: any = {};
                obj = res;
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    getTreatCoins()
                    setOpenModal(false)
                    alert({
                        cssClass: 'my-css',
                        message: "Congratulations, You have Successfully Redeemed Treat Coins!",
                        buttons: [{
                            text: 'Ok',
                        }],
                    })
                } else {
                    setShowLoading(false);
                    setOpenModal(false);
                    showToast(obj.error, 3000)
                }
            })
        }
    }
    const getTreatCoins = async () => {
        setShowLoading(true);
        instance({
            method: "GET",
            url: "treatcoins/getCoins",
            validateStatus: () => true,
        }).then((res) => {
            let obj: any = {};
            obj = res;
            if (obj.status === "SUCCESS!") {
                setShowLoading(false);
                obj.result.sort((a: any, b: any) => b.date - a.date);
                setGetAllTreatCoins(obj.result)
                setGetfilterTreatCoins(obj.result)
                setTotalTreatCoins(obj.availableCoins)
                setReedemCoins(obj.availableCoins)
                // var isRedeemCoinsTotal: any = []
                // obj.result.map((item: any) => {
                //     if (item.isRedeem === false) {
                //         isRedeemCoinsTotal.push(item)
                //     }
                // })
                // if (isRedeemCoinsTotal.length > 0) {
                //     const totalCoins = isRedeemCoinsTotal.reduce((total: any, currentValue: any) => total = total + currentValue.coins, 0);
                //     setTotalTreatCoins(totalCoins)
                // }
                // else {
                //     setTotalTreatCoins(0)
                // }
            } else {
                setShowLoading(false);
            }
        })
    }
    const openRedemModal = async () => {
        if (!totalTreatCoins) {
            setShowLoading(false);
            alert({
                cssClass: 'my-css',
                message: "No Treat Coins to Redeem",
                buttons: [{
                    text: 'Ok',
                    handler(value) {
                        setOpenModal(false)
                    },
                }],
            })
        }
        else {
            setOpenModal(true)
            getTreatCoins()
            await instance({
                method: 'GET',
                url: 'user/getUserDetails',
                validateStatus: () => true
            }).then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.status === "SUCCESS!") {
                    let data = obj.result[0].mybenefitsprovider
                    if (data.bank) {
                        setProviderDetails(data.bank)
                    }
                }
                else {
                    setShowLoading(false);
                }
            })
        }
    }
    const dismissModal=()=>{
        setOpenModal(false); 
        setReedemCoins("");
    }
    return (
        <IonPage>
            <Header popover={true} unreadCount={0}></Header>
            <div>
                <IonRow className="head-prov-title-bg ht-100 ion-justify-content-around ion-align-items-center">
                    <div className="ion-padding-vertical">
                        <IonText className="trt-hd">
                            Treat Coins
                        </IonText>
                    </div>

                    <div className="coins-box">
                        <IonRow className="ion-align-items-center ion-justify-content-around">
                            <IonText><div className="coins-count">{totalTreatCoins ? totalTreatCoins : "0"}</div><div className="total">Total</div></IonText>
                            <IonButton className="searchbtn searchbtn-txt" onClick={() => openRedemModal()}>Redeem Treat Coins</IonButton>
                        </IonRow>
                    </div>
                </IonRow>

            </div>
            <IonContent style={{ height: "100%" }}>
                <IonGrid style={{ width: "80%" }}>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                        <div className={toggle === false && toggleActive === "All" ? "color-blue" : "color-black"}>All</div>
                        <div className="ion-padding-horizontal">
                            <IonToggle name="isChecked" className="treat-checkbox" checked={toggle}
                                onIonChange={e => toggleHandle(e)} value={toggle}></IonToggle></div><div className={toggle === true && toggleActive == "patients" ? "color-blue" : "color-black"}>Patients</div></IonRow>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                        <div
                            className="ovrflow"
                            style={{ width: "100%" }}
                        >
                            <table className="fixed">
                                <thead className="th-feed">
                                    <tr>
                                        <th className="th-ft  whitespace">
                                            Activity Feed
                                        </th>
                                        <th className="th-ft  whitespace th-txt-ctr">
                                            Dates
                                        </th>
                                        <th className="th-ft  whitespace th-txt-rt">
                                            Treat Coins
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="patient-tbody">
                                    {getAllTreatCoins.length > 0 ?
                                        getAllTreatCoins.map((item: any, key: any) => {
                                            return (
                                                <tr key={key}>
                                                    <td className="item-bg">
                                                        <IonItem lines="none" className="td-item ion-no-padding">
                                                            <img src={require('../../images/coin.png').default} className="coin-img" />
                                                            <IonLabel>
                                                                <p className="td-col1-txt td-name">
                                                                    {item.type === "provider" ? "PROVIDER INVITE" : item.type === "redeem" ? "REDEEMED" : item.type === "patient" ? "PATIENT INVITE" : item.type === "provider_loanaccept" ? "LOAN ACCEPTED" : ""}
                                                                </p>
                                                                <p className="td-col1-txt">{item.name}</p>
                                                            </IonLabel>
                                                        </IonItem>
                                                    </td>
                                                    <td className="th-txt-ctr td-date">
                                                        {moment((item.date)).format(
                                                            "MM/DD/YYYY"
                                                        )}
                                                    </td>
                                                    <td className="th-txt-rt td-coin">
                                                        {item.type === "redeem" ? "" : "+"}
                                                        {item.coins}
                                                    </td>
                                                </tr>
                                            )

                                        }) : <tr><td>No Transactions Found</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </IonRow>
                </IonGrid>
                <IonModal isOpen={openModal} onDidDismiss={() => dismissModal()} className="modal-ht">
                    <IonContent>
                        {/* <div className="title-bg  ion-justify-content-around ion-align-items-center">
                        <IonRow className="ion-justify-content-end">dd</IonRow>
                            <div className="ion-padding-vertical head-prov-title-bg">
                                <IonText className="popup-title">
                                    Redeem Treat Coins
                                </IonText>
                            </div>
                        </div> */}
                         <IonRow className='popup-header'>
                            <IonCol className='pad-0'>
                                    <IonRow className='ion-justify-content-end pointer pad-0' onClick={()=>dismissModal()}>
                                        <CloseOutline title="Close" size={30} color="#FFFFFF"></CloseOutline>
                                    </IonRow>
                                <IonRow className='ion-justify-content-center popup-title ion-padding-bottom'>
                                    <IonText className="popup-title">
                                    Redeem Treat Coins
                                    </IonText>
                                </IonRow>
                                </IonCol>
                                </IonRow>
                        <IonRow className="ion-justify-content-around ion-align-items-center ion-margin">
                            <div className="ion-padding-vertical box-coin">
                                {/* <IonText className="ft-20 coins-count">{totalTreatCoins ? totalTreatCoins : "0"}
                                </IonText> */}
                                    <NumberFormat format="####" mask="" className="br-none ft-20 coins-count" value={reedemCoins} onValueChange={(e) => setReedemCoins(e.value)}></NumberFormat>         
                            </div>
                        </IonRow>
                        <IonRow className="ion-justify-content-around ion-align-items-center">
                            <div className="ion-no-padding">
                                <IonText className="ft-16">Treat Coins Available
                                </IonText>
                            </div>
                        </IonRow>
                        <IonRow className="ion-justify-content-around ion-align-items-center ion-margin-top ion-text-center">
                            <div className="ion-padding-vertical">
                                <IonText className="ft-16">You will receive ${reedemCoins ? reedemCoins: 0} to your following connected bank account
                                </IonText>
                            </div>
                        </IonRow>
                        <IonRow className="ion-justify-content-around ion-align-items-center ion-margin-bottom">
                            <div>
                                <IonText className="ft-wt-600 ft-16">{providerDetails && providerDetails.number ? providerDetails.number : ""}
                                </IonText>
                            </div>
                        </IonRow>
                        <IonRow className="ion-justify-content-around ion-align-items-center ion-margin-vertical">
                            <IonButton onClick={() => redeemApicall()}>Redeem Treat Coins</IonButton>
                        </IonRow>
                    </IonContent>
                </IonModal>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
        </IonPage>
    )
}
export default TreatCoins