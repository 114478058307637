import axios from 'axios';

// const URL = 'http://localhost:4000/api/'; //Local
const URL = 'https://provider-api-dev.mybenefits.ai/api/'; //Dev
// const URL = 'https://provider-api.mybenefits.ai/api/'; //Prod

const instance = axios.create({
    baseURL: URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Authorization",
    },
});
instance.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token') || "";
instance.interceptors.response.use((response) => {
    if (response.status === 401) {
        window.location.href = '/provider_login';
    } 
    else 
    return response.data;
}, function (error:any) {
    return error;
});

export default instance;