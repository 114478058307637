import {
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonModal,
  IonCol,
  IonInput,
  IonHeader,
  IonFooter,
  IonLoading,
  IonIcon,
  IonButton,
} from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import { useHistory } from "react-router-dom";
import instance from "../../../utils/api";
import { Send } from "@styled-icons/feather/Send";
import { ArrowBackOutline } from "@styled-icons/evaicons-outline/ArrowBackOutline";
import MessagesFromProvider from "./DashboardEvents/Messages";
import InstructionsUpdate from "./DashboardEvents/InstructionsUpdate";
import PatientFollowUp from "./DashboardEvents/PatientFollowUp";
import { LogoutCircleR } from "@styled-icons/remix-line/LogoutCircleR";
import { DocumentReport } from "@styled-icons/heroicons-outline/DocumentReport";
import { FileEarmarkImage } from "@styled-icons/bootstrap/FileEarmarkImage";
import { ArrowRight } from "@styled-icons/bootstrap/ArrowRight";

const PatientDashboard: React.FC = () => {
  const [openModal, setOpenModal] = useState<any>(false);
  const history = useHistory<any>();
  const dischargeId = localStorage.getItem("dischargeId") || "";
  const props =
    history.location && history.location.state && history.location.state.props;
  const activityEvent =
    (history.location &&
      history.location.state &&
      history.location.state.activityEvent) ||
    "";
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [patientDetails, setPatientDetails] = useState<any>();
  const [getFloraAnswer, setGetFloraAnswer] = useState<any>([]);
  const [message, setMessage] = useState<any>("");
  const [bottomSheet, setBottomSheet] = useState<boolean>(false);
  const [showFloraLoader, setShowFloraLoader] = useState<boolean>(false);
  const [floraQuestions, SetFloraQuestions] = useState<any>([]);
  const [department, setDepartment] = useState<any>("");
  const [floraTypingLoader, setFloraTypingLoader] = useState<boolean>(false);
  const [dashboardEvents, setDashboardEvents] = useState<any>([])

  const getDashboardEvents = async () => {
    await instance({
      method: "GET",
      url: `discharge/getPatientEvents/${dischargeId}`,
      validateStatus: () => true
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        setDashboardEvents(obj.result)
      } else {

      }
    })
  }
  const getPatientData = async () => {
    if (dischargeId) {
      setShowLoading(true);
      instance({
        method: "GET",
        url: `discharge/${dischargeId}`,
        validateStatus: () => true,
      }).then((resp) => {
        let obj: any = {};
        obj = resp;
        if (obj.status === "SUCCESS!") {
          setPatientDetails(obj.result[0]);
          setDepartment(obj.result[0].department);
          setShowLoading(false);
        } else {
          setShowLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    getPatientData();
    if (dischargeId) {
      getDashboardEvents()
    }
  }, []);


  const navTolist = async (val: any) => {
    history.push('/specialInstructions', { props: val, dischargeId: dischargeId })
    if (val === "pain" || val === "feedback") {
      let data: any = {};
      data.eventName =
        val === "pain"
          ? "pain_section_click"
          : val === "feedback" && "feedback_section_click";
      data.user = patientDetails && patientDetails.user;
      data.discharge = dischargeId && dischargeId;
      await instance({
        method: "POST",
        url: "/discharge/patientActivity",
        data,
        validateStatus: () => true,
      }).then((resp) => {
        let obj: any = {};
        obj = resp;
        if (obj.status === "SUCCESS!") {
          setShowLoading(false);
        } else {
          setShowLoading(false);
        }
      });
    }
  };

  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom();
  }, [getFloraAnswer]);

  const floraAnswers = async () => {
    if (floraTypingLoader === false) {
      setShowFloraLoader(true);
    }
    await instance({
      method: "GET",
      url: `discharge/floraInteractions/${dischargeId}`,
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        obj.result.sort(
          (a: any, b: any) =>
            (new Date(a.timestamp) as any) - (new Date(b.timestamp) as any)
        );
        setGetFloraAnswer(obj.result);
        setFloraTypingLoader(false);
        setShowFloraLoader(false);
      } else {
        setShowFloraLoader(false);
      }
    });
  };
  const floraActivity = async () => {
    if (floraTypingLoader === false) {
      setShowFloraLoader(true);
    }
    await instance({
      method: "GET",
      url: `discharge/floraClick/${dischargeId}`,
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        obj.result.sort(
          (a: any, b: any) =>
            (new Date(a.timestamp) as any) - (new Date(b.timestamp) as any)
        );
        setGetFloraAnswer(obj.result);
        setFloraTypingLoader(false);
        setShowFloraLoader(false);
      } else {
        setShowFloraLoader(false);
      }
    });
  };



  const getAnswers = async () => {
    setFloraTypingLoader(true);
    const getMessage = localStorage.getItem("message");
    setMessage("");
    let floradata: any = {};
    floradata.question = getMessage;
    floradata.questionTimestamp = new Date().toLocaleString("en-US");
    if (floradata) {
      setGetFloraAnswer([...getFloraAnswer, floradata]);
    }
    let data: any = {};
    data.question = message;
    data.id = dischargeId;
    await instance({
      method: "PUT",
      url: "discharge/getAnswer",
      data,
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        floraAnswers();
        localStorage.removeItem("message");
      } else {
        setMessage("");
        setFloraTypingLoader(false);
      }
    });
  };


  const getFloraQuestions = async () => {
    await instance({
      method: "GET",
      url: "discharge/getQuestion",
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        SetFloraQuestions(obj.result);
      }
    });
  };
  const chatWithFlora = () => {
    setOpenModal(true);
    floraActivity();
    getFloraQuestions();
  };


  const askFlora = async (value: any) => {
    setFloraTypingLoader(true);
    let floradata: any = {};
    floradata.question = value;
    floradata.questionTimestamp = new Date().toLocaleString("en-US");
    if (floradata) {
      setGetFloraAnswer([...getFloraAnswer, floradata]);
    }
    let data: any = {};
    data.question = value;
    data.id = dischargeId;
    await instance({
      method: "PUT",
      url: "discharge/getAnswer",
      data,
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        floraAnswers();
      } else {
        setFloraTypingLoader(false);
      }
    });
  };

  const logout = () => {
    localStorage.clear();
    history.push("/patient_login")
  }
  return (
    <IonPage className="patient-bg">
      <IonHeader style={{ background: "white" }}>
        <div>
          <IonGrid className="pad-0" style={{ background: "white" }}>
            <IonRow
              className="ion-justify-content-between ion-align-items-center txt-robot bg-img"
              style={{ padding: "10px" }}
            >
              <IonRow className="ion-align-items-center ion-justify-content-between" style={{ width: "100%" }}>
                <IonRow className="ion-align-items-center">
                  <div className="letter-circle">
                    <span>
                      {(patientDetails &&
                        patientDetails.name &&
                        patientDetails.name.charAt(0).toUpperCase()) ||
                        ""}
                    </span>
                  </div>
                  <IonText
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                  >
                    Hi,{" "}
                    {patientDetails &&
                      patientDetails.name &&
                      patientDetails.name}{" "}
                    👋
                  </IonText>
                </IonRow>

                <div>
                  <IonRow className="ion-align-items-center">
                    <div style={{ paddingLeft: "10px" }}><LogoutCircleR onClick={() => logout()} size={20} color="#1a1a1a" /></div>
                  </IonRow>
                </div>
              </IonRow>
            </IonRow>
            <IonRow className="special-card">
              <IonText>
                <div className="descrit-txt txt-robot" style={{ padding: "0px" }}>
                  In case
                  of a life-threatening emergency,
                  <span style={{ borderBottom: "2px solid #275FFA",color:"black" }}>please call 911
                  </span>
                  {" "}or go to the nearest emergency hospital.
                </div>
              </IonText>
            </IonRow>
            <div style={{ margin: "0px 10px 10px 10px"}}>
              <IonRow className="ion-justify-content-between">
              {patientDetails && patientDetails.preopDocData &&
              <IonRow className="ion-justify-content-between special-bg-clr" style={{padding:"10px",width:"auto",marginRight:"5px"}} onClick={() => navTolist("preopInstructions")}>
                <div style={{fontSize:"15px",paddingRight:"5px"}}>PreOp Instructions</div>
                <ArrowRight size="20" color="white" />
              </IonRow>
              }
              {patientDetails && patientDetails.docData &&
              <IonRow className="ion-justify-content-between special-bg-clr" style={{padding:"10px",width:"auto"}} onClick={() => navTolist("postopInstructions")}>
                <div style={{fontSize:"15px",paddingRight:"5px"}}>PostOp Instructions</div>
                <ArrowRight size="20" color="white" />
              </IonRow>}
              </IonRow>
             
            </div>

          </IonGrid>
        </div>
      </IonHeader>
      <IonContent
        className="ion-padding-horizontal"
        style={{ textAlign: "justify", background: "white" }}
      >
        <div>
          {dashboardEvents && dashboardEvents.length > 0 ?
            dashboardEvents.map((value: any, index: any) => (
              <div key={index} className="ion-padding-vertical events-br">
                {((value.eventName === "preop_init_message") || (value.eventName === "postop_init_message") || (value.eventName === "patient_msg_from_provider")) &&
                  <MessagesFromProvider eventsData={value} dischargeId={dischargeId} />}
                {value.eventName === "patient_instruction_update" &&
                  <InstructionsUpdate eventsData={value} value="postopInstructions" dischargeId={dischargeId} />}
                {((value.eventName === "patient_followup_1") || (value.eventName === "patient_followup_2")) &&
                  <PatientFollowUp eventsData={value} dischargeId={dischargeId} />}
              </div>
            ))
            : <IonRow className="not-found-txt" style={{ width: "100%" }}>
              <div>No Data Found</div>
            </IonRow>}
        </div>
        <div className="askflora left" onClick={() => chatWithFlora()}>
          <img
            src={require(`../../../images/final_logo.png`).default}
            style={{ maxWidth: "fit-content" }}
            height="38px"
            width="37px"
            alt=""
          />
          <IonText style={{ paddingLeft: 10 }}>Need Care?</IonText>
        </div>

        {/* Bottom right icon */}
        <div
          className="bottom-icon right"
          onClick={() => {
            setBottomSheet(true);
          }}
          style={{
            border: "1px solid #00B9CF",
          }}
        >
          <IonIcon icon={ellipsisVertical} color="#275FFA" />
        </div>
        <IonModal
          isOpen={bottomSheet}
          initialBreakpoint={0.3}
          onDidDismiss={() => setBottomSheet(false)}
          id="bottom-sheet"
        >
          <div style={{ background: "white" }}>
          {patientDetails && patientDetails.preopDischageDocLink &&
            <IonRow onClick={() => navTolist("preop")} className="ion-align-items-center ion-padding">
              <DocumentReport size={30} color="black" />
              <div className="txt-robot" style={{ paddingLeft: "10px", fontSize: "18px" }}>PreOp Document</div>
            </IonRow>}
            {patientDetails && patientDetails.dischageDocLink &&
            <IonRow onClick={() => navTolist("postop")} className="ion-align-items-center ion-padding">
              <DocumentReport size={30} color="black" />
              <div className="txt-robot" style={{ paddingLeft: "10px", fontSize: "18px" }}>PostOp Document</div>
            </IonRow>}
            <IonRow onClick={() => navTolist("postCare")} className="ion-align-items-center ion-padding">
              <FileEarmarkImage size={30} color="black" />
              <div className="txt-robot" style={{ paddingLeft: "10px", fontSize: "18px" }}>Post Care</div>
            </IonRow>
          </div>
        </IonModal>
        {/* flora popup */}
        <IonModal isOpen={openModal} id="flora-modal">
          <IonPage>
            <IonHeader>
              <div className="bg-img">
                <IonRow
                  className="ion-padding ion-justify-content-between ion-align-items-center">
                  <ArrowBackOutline onClick={() => setOpenModal(false)} size={30} color='black' />
                  <div className="patient-header-txt" style={{ fontSize: "20px" }}> Ask Questions</div>
                  <div></div>
                </IonRow>
              </div>
            </IonHeader>
            <IonContent className="msg-bg-cnt"
              ref={messagesEndRef}
              scrollEvents={true}
            >
              <div style={{ height: "100%" }}>
                {getFloraAnswer && getFloraAnswer.length > 0 ? (
                  getFloraAnswer.map((value: any, index: any) => (
                    <div
                      key={index}
                      className="txt-robot"
                      style={{ padding: "0px 12px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          margin: "8px 0px",
                        }}
                      >
                        <div>
                          <div className="question-box">{value.question}</div>
                          <div
                            style={{
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {value.questionTimestamp ? (
                              <div>
                                {value.questionTimestamp &&
                                  value.questionTimestamp &&
                                  new Date(
                                    value.questionTimestamp
                                  ).toLocaleDateString("en-US", {
                                    timeZone: "America/Los_Angeles",
                                  })}{" "}
                                {value.questionTimestamp &&
                                  value.questionTimestamp &&
                                  new Date(
                                    value.questionTimestamp
                                  ).toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                    timeZone: "America/Los_Angeles",
                                  })}
                              </div>
                            ) : (
                              <div>
                                {value.timestamp
                                  ? new Date(
                                    value.timestamp
                                  ).toLocaleDateString("en-US", {
                                    timeZone: "America/Los_Angeles",
                                  })
                                  : "NA"}{" "}
                                {value.timestamp
                                  ? new Date(
                                    value.timestamp
                                  ).toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                    timeZone: "America/Los_Angeles",
                                  })
                                  : "NA"}
                              </div>
                            )}
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {value.answer ? (
                          <div>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <img
                                src={
                                  require(`../../../images/floralogo.png`)
                                    .default
                                }
                                className=""
                                height="32px"
                                width="32px"
                                alt=""
                              />
                              <div>
                                <div className="answer-bg-clr question-box">
                                  {value.answer}
                                </div>
                                <div
                                  style={{
                                    fontSize: "12px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    margin: "0px 5px",
                                  }}
                                >
                                  {value.answerTimestamp ? (
                                    <div>
                                      {value.answerTimestamp &&
                                        value.answerTimestamp &&
                                        new Date(
                                          value.answerTimestamp
                                        ).toLocaleDateString("en-US", {
                                          timeZone: "America/Los_Angeles",
                                        })}{" "}
                                      {value.answerTimestamp &&
                                        value.answerTimestamp &&
                                        new Date(
                                          value.answerTimestamp
                                        ).toLocaleTimeString("en-US", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          timeZone: "America/Los_Angeles",
                                        })}
                                    </div>
                                  ) : (
                                    <div>
                                      {value.timestamp
                                        ? new Date(
                                          value.timestamp
                                        ).toLocaleDateString("en-US", {
                                          timeZone: "America/Los_Angeles",
                                        })
                                        : "NA"}{" "}
                                      {value.timestamp
                                        ? new Date(
                                          value.timestamp
                                        ).toLocaleTimeString("en-US", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                          timeZone: "America/Los_Angeles",
                                        })
                                        : "NA"}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))
                ) : showFloraLoader === true && floraTypingLoader === false ? (
                  <IonRow
                    className="ion-justify-content-center ion-align-items-center ion-padding"
                    style={{ height: "100%" }}
                  >
                    <div className="spinloader" />
                  </IonRow>
                ) : null}

                {/* {(showFloraLoader === true && floraTypingLoader === false) &&
                <div style={{ height: "100%" }}>
                  <IonRow className="ion-justify-content-center ion-align-items-center ion-padding" style={{ height: "100%", }}>
                    <div className="spinloader" />
                  </IonRow>
                </div>} */}
                {getFloraAnswer.length === 0 && showFloraLoader === false && (
                  <div style={{ height: "100%", zIndex: "111111" }}>
                    <IonRow
                      className="ion-justify-content-center ion-align-items-center ion-padding"
                      style={{ height: "100%" }}
                    >
                      <IonCol
                        className="txt-robot"
                        style={{ letterSpacing: "1.1px" }}
                      >
                        <div
                          style={{
                            lineHeight: "35px",
                            fontWeight: "600",
                            textAlign: "center",
                            letterSpacing: "2.1px",
                          }}
                        >
                          How&nbsp;&nbsp;to&nbsp;&nbsp;Use?
                        </div>
                        <div>
                          Ask any discharge / medical related question here. Do
                          not use statements, ask questions.
                        </div>
                        <div style={{ paddingTop: "10px" }}>Some examples:</div>
                        <div>1. What can I eat?</div>
                        <div>2. When can I start exercising?</div>
                        <div>3. Is it ok to drink beer?</div>
                        <div>4. I cannot pee. What should I do?</div>
                      </IonCol>
                    </IonRow>
                  </div>
                )}
              </div>
            </IonContent>
            <IonFooter className="footer-bg">
              {floraTypingLoader && (
                <div
                  className="dot-container"
                  style={{
                    justifyContent: "flex-start",
                    height: "0px",
                    margin: "10px",
                  }}
                >
                  <div className="dot-typing"></div>
                  <div className="dot-typing"></div>
                  <div className="dot-typing"></div>
                </div>
              )}

              <div>
                <div
                  className="listrow"
                  style={{ padding: "0px", margin: "10px" }}
                >
                  {floraQuestions.length > 0 &&
                    floraQuestions.map(
                      (value: any, index: any) =>
                        department === value.category && (
                          <div key={index}>
                            <div
                              className="card-pd"
                              style={{ paddingLeft: "0px" }}
                            >
                              <IonRow
                                onClick={() =>
                                  floraTypingLoader
                                    ? ""
                                    : askFlora(value.question)
                                }
                                className={
                                  floraTypingLoader
                                    ? "flora-question disable"
                                    : "flora-question"
                                }
                              >
                                <div>{value.question}</div>
                              </IonRow>
                            </div>
                          </div>
                        )
                    )}
                </div>
              </div>

              <IonRow className="ion-align-items-center flora-input-box">
                <IonInput
                  placeholder="Send Message"
                  style={{ padding: "10px" }}
                  name="query"
                  disabled={floraTypingLoader}
                  className="msg-pad-0 placeholder-txt"
                  onIonChange={(e: any) => {
                    setMessage(e.detail.value);
                    localStorage.setItem("message", e.detail.value);
                  }}
                  value={message}
                ></IonInput>
                <button
                  onClick={() => getAnswers()}
                  disabled={!message || floraTypingLoader}
                  style={{ width: "55px", background: "white" }}
                >
                  <Send size={25} color="#546FF0" />
                </button>
              </IonRow>
            </IonFooter>
          </IonPage>
        </IonModal>
        <IonLoading isOpen={showLoading} message={"Please wait..."} />
      </IonContent>
    </IonPage>
  );
};

export default PatientDashboard;
