import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import instance from "../../../utils/api";

const LandingPage: React.FC = () => {
    const [dischargeUrlId, setDischargeUrlId] = useState<any>("")
    const history = useHistory();
    const [phoneNumber, setPhoneNumber] = useState<any>("");
    const [logActivityEvent,setLogActivityEvent] = useState<any>("")
    const logActivityApi=async(value:any,id:any)=>{
        let data:any={}
        data.discharge = id
        data.eventName = value
        console.log(data)
          await instance({
            method:"POST",
            url:"/discharge/patientActivity",
            validateStatus:()=>true,
            data
          })
        .then((resp) => {
            let obj: any = {}
            obj = resp
            if (obj.status === "SUCCESS!") {
                setPhoneNumber(obj.result.info.phoneNumber)
            }
            else {
            }
          })
    }
    useEffect(() => {
        let path = window.location.href;
        let urldischargeId = path.split("=");
        let dischargeId =urldischargeId[1] && urldischargeId[1].split("&");
        let logActivityEvent = urldischargeId[2]
        if (dischargeId && dischargeId[0]) {
            setDischargeUrlId(dischargeId[0])
            localStorage.clear();
        }
        if(logActivityEvent){
            setLogActivityEvent(logActivityEvent)
            logActivityApi(logActivityEvent,dischargeId[0])
        }
    }, []);

    const navToLogin=()=>{
        history.push("/patient_login",{props:phoneNumber,id:dischargeUrlId,eventType:logActivityEvent})
    }

    return (
        <IonPage className="bg-clr">
            <IonContent>
                <IonGrid style={{ height: "100%" }}>
                    <IonRow style={{ height: "100%" }}>
                        <IonCol className="display-flex ion-align-items-center ion-justify-content-between ion-padding" style={{ height: "100%" }}>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                <img src={require(`../../../images/mybenefitsDischarge.png`).default} className="" height="61px" width="321px" alt="" />
                                <div className="ion-padding-vertical landing-txt">Your Personalized Discharge Instructions</div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                <img src={require(`../../../images/landingPage.png`).default} className="" height="auto" width="auto" alt="" />
                                <div className="ion-padding-vertical landing-txt">
                                    We’d like to provide you an easy-to-understand and personalized post-care at your fingertips. Receive important updates from a healthcare provider and receive feedback on any concern related to your post-care in real-time.
                                </div>
                            </div>
                            <IonRow className="ion-justify-content-center ion-padding-vertical" style={{ width: "100%" }} >
                                <IonButton expand="block" style={{ width: "100%" }} type='submit' className='patient-btn provoiders-btn provoiders-btn-txt ion-no-margin' onClick={() => navToLogin()}>Next</IonButton>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}
export default LandingPage;