import { IonAvatar, useIonAlert, useIonToast, IonCard, IonButton, IonCardContent, IonLoading, IonCardHeader, IonToast, IonCheckbox, IonItemDivider, IonToggle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar } from '@ionic/react';
import "./index.css";
import { useState, useEffect, useContext } from 'react';
import NumberFormat from 'react-number-format';
import axios from "axios";
import { useHistory } from "react-router-dom";
import instance from '../../utils/api';
var CryptoJS = require("crypto-js");


const TreatNow: React.FC = () => {
  const [route, setRoute] = useState<string>();
  const [number, setNumber] = useState<any>("");
  const [billingAddress, setBillingAddress] = useState('')
  const [businessCity, setBusinessCity,] = useState('');
  const [zipCode, setZipCode] = useState('')
  const [bankName, setBankName] = useState('')
  const [checked, setChecked] = useState(false);
  const [addressRow, setAddressRow] = useState(true)
  const [wrAccRouteNo, setWrAccRouteNo] = useState(false)
  const [key, setKey] = useState<any>("AC1f7b6b8f1325f67ad887e297c69dd9de")
  const history = useHistory();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [alert] = useIonAlert();
  const [showToast, dismiss] = useIonToast();
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  useEffect(() => {
    axios
      .get(`https://www.routingnumbers.info/api/data.json?rn=${route}`)
      .then(data => {
        setBankName(data.data.customer_name)
      })
  }, [route])

  useEffect(() => {
    let finaltoken = localStorage.getItem('auth_token');
    let Token: any = ''
    Token = finaltoken
    instance.defaults.headers.common['Authorization'] = Token
  }, [])

  const addAddress = () => {
    let data = {
      billingAddress,
      businessCity,
      zipCode,
      phoneNumber
    }
    const pattern = /^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/;
    const result = pattern.test(businessCity)
    if (!phoneNumber) {
      showToast('Please Enter Phone Number.', 3000)
    }
    else if (phoneNumber.length < 10) {
      showToast('Phone Number must be 10 digits.', 3000)
    }
    else if (!billingAddress) {
      showToast('Please Enter Street Address.', 3000)
    }
    else if (!businessCity) {
      showToast('Please Enter Bussiness City.', 3000)
    }
    else if (!zipCode) {
      showToast('Please Enter ZipCode.', 3000)
    }
    else if (result == false) {
      showToast('city name should contain alphabets only', 3000)
    }
    else {
      let obj: any = {}
      obj = data
      localStorage.setItem("addAddressObj", JSON.stringify(obj));
      setAddressRow(false);
    }
  }
  const Next = () => {
    let bank = {
      number,
      route
    }
    if (!number) {
      showToast('Please Enter Account Number.', 3000)
    }
    else if (number.length < 8 || number.length > 17) {
      showToast('Account Number should contain min 8 digits or max 17 digits.', 3000)
    }
    else if (!route) {
      showToast('Please Enter Routing Number.', 3000)
    }
    else if (route.length < 9) {
      showToast('Routing Number should be Exact 9 Digits.', 3000)
    }
    // else if (!checked) {
    //   showToast('Please Accept Toggle.', 3000)
    // }
    else {
      let encrypt = CryptoJS.AES.encrypt(JSON.stringify(bank), key).toString();
      let res: any = ''
      res = encrypt
      localStorage.setItem("bankDetailes", res);
      alert({
        cssClass: 'my-css',
        message: "Are you sure you want to Add Bank Account.",
        buttons: ['Cancel', {
          text: 'Ok', handler: () => {
            history.push('/terms_and_conditions')
          }
        }],
      })
    }
  }
  const skipBank = () => {
    localStorage.removeItem("bankDetailes");
    history.push('/terms_and_conditions');
  }

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow className="ion-justify-content-center myben-title-pd-tp">
            <div style={{ display: 'flex' }}>
              <img src={require(`../../images/final_logo.png`).default} className="" height="38px" width="37px" alt="" />
            </div>
            <IonRow className='ion-padding-bottom'>
              <IonCol>
                <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='subtitle'>Treat Now, Pay Later</IonText></IonRow>
              </IonCol>
            </IonRow>
          </IonRow>
          <IonRow className="ion-align-items-center ion-justify-content-center  ion-padding-bottom"><IonText className='signup-title'>Treat Now, Treat More!</IonText></IonRow>
          <IonRow className='ion-justify-content-center ion-padding-bottom ion-padding-top'>
            {addressRow == true ?
              <IonText className='pass-title-fnt'>Provider Address</IonText>
              :
              <IonText className='pass-title-fnt'>Add Bank Account</IonText>}
          </IonRow>
          {addressRow == true ?
            <IonRow style={{ maxWidth: '430px', margin: '0 auto' }}>
              <IonCol className="fields-row-pd">
                <IonRow className='ion-padding-bottom'>
                  <IonCol className="pad-0">
                    <IonLabel className='label'>Enter Phone Number</IonLabel>
                    <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="(XXX) XXX-XXXX" format="(###) ###-####" value={phoneNumber} onValueChange={(e: any) => setPhoneNumber(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                  </IonCol>
                </IonRow>
                <IonRow className='ion-padding-bottom'>
                  <IonCol className="pad-0">
                    <IonLabel className='label'>Enter Street Address</IonLabel>
                    <IonInput type="text" autocomplete='on' placeholder="123 Street" className="input-field mg-top placeholder-txt" value={billingAddress}
                      onIonInput={(e: any) => setBillingAddress(e.target.value)}
                    > </IonInput>
                  </IonCol>
                </IonRow>
                <div className='city-flex'>
                  <IonRow className="pd-rt-20 ion-padding-bottom">
                    <IonCol className="pad-0">
                      <IonLabel className='label'>Enter City</IonLabel>
                      <IonInput type="text" autocomplete='on' className="input-field mg-top placeholder-txt" value={businessCity} onIonInput={(e: any) => setBusinessCity(e.target.value)}
                      > </IonInput>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="pad-0">
                      <IonLabel className='label'>Enter Zip Code</IonLabel>
                      <NumberFormat className="input-field mg-top placeholder-txt num-format-wd-100" value={zipCode} format="#####" onValueChange={(e: any) => setZipCode(e.value)}
                      />
                    </IonCol>
                  </IonRow>
                </div>
                <IonButton expand="block" className='nextbtn' onClick={() => addAddress()}>Next</IonButton>
              </IonCol>
            </IonRow> :
            <IonRow style={{ maxWidth: '430px', margin: '0 auto' }}>
              <IonCol className="fields-row-pd">
                <IonRow className='ion-padding-bottom'>
                  <IonCol className="pad-0">
                    <IonLabel className='label'>Enter Account Number</IonLabel>
                    <div>
                      <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="###-##-####" format="#################" value={number} onValueChange={(e: any) => setNumber(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="pad-0">
                    <IonLabel className='label'>Enter Routing Number</IonLabel>
                    <div>
                      <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="###-##-####" format="#########" value={route} onValueChange={(e: any) => setRoute(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                    </div>
                  </IonCol>
                </IonRow>
                {
                  bankName &&
                  <IonRow className="ion-padding-top ion-text-center">
                    <IonCol className="pad-0">
                      <IonText>{bankName}</IonText>
                    </IonCol>
                  </IonRow>
                }
                <IonRow className='input-field-blck ion-padding-top pointer ion-padding-bottom' onClick={() => { setWrAccRouteNo(!wrAccRouteNo) }}>
                  <IonText className='drctdbttxt1'>Where is my routing & account number?</IonText>
                </IonRow>
                {wrAccRouteNo ?
                  <IonGrid>
                    <IonRow className='ion-justify-content-center ion-text-center vh-15'>
                    </IonRow>
                    <IonCard style={{ height: "140px", width: "90%", backgroundColor: 'white' }}>
                      <IonCardContent style={{ padding: "5px 5px 5px 5px" }}>
                        <IonRow>
                          <IonCol size="2">
                            <div className='circleash'>
                            </div>
                          </IonCol>
                          <IonCol size="8" className='pt-14 pl-20' style={{ paddingLeft: '20px' }}>
                            <div className='ashlines'></div>
                            <div className='ashlines-2' style={{ marginTop: "10px" }}></div>
                          </IonCol>
                          <IonCol size="2" className='ion-text-end'>
                            <div className='color-ash'>001</div>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="2">
                            <div className='ashlines-3'></div>
                            <div className='ashlines-3 mt-5'></div>
                          </IonCol>
                          <IonCol size="6">
                            <div className='ashlines-4 mt-15'></div>
                            {/* <div className='ashlines-2' style={{marginTop:"10px"}}></div> */}
                          </IonCol>
                          <IonCol size="4" className='mrt-50'>
                            $<div className='ashlines-1'></div>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="12" className='color-ash'>
                            :| 123456789  :| 000987654321 || 001
                          </IonCol>
                        </IonRow>
                      </IonCardContent>
                    </IonCard>

                    <IonRow className='ion-justify-content-center'>
                      {/* <div>Routing Number | Account Number</div> */}
                      <IonCol size="1">

                      </IonCol>
                      <IonCol size="5">
                        <div className="vl"></div><br />
                        <IonText>Routing Number</IonText>
                      </IonCol>
                      <IonCol size="5">
                        <div className="vl"></div><br />
                        <IonText>Account Number</IonText>

                      </IonCol>

                      <IonCol size="1">

                      </IonCol>

                    </IonRow>

                  </IonGrid>
                  : null}
                <IonButton expand="block" className='nextbtn' onClick={() => Next()}>Add</IonButton>
                <IonRow onClick={() => skipBank()} className="ion-justify-content-center ion-padding">
                  <IonText className='label pointer later-bnk-txt'><a>Add Bank Later</a></IonText>
                </IonRow>
              </IonCol>
            </IonRow>}
          <IonRow className='ion-align-items-center ion-justify-content-center ion-padding myben-ft ht-100'>@myBenefits 2021</IonRow>
        </IonGrid>
        <IonLoading isOpen={showLoading} message={'Please wait...'} />
      </IonContent>
    </IonPage>
  );
};

export default TreatNow;
