import { IonPage, IonText, IonRow, IonCol, IonContent, IonButton, IonGrid, } from '@ionic/react';
const VerificationLinkExpired: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol>
              <IonRow className="ion-justify-content-center myben-title-pd-tp">
                <div>
                  <img src={require(`../../images/final_logo.png`).default} className="" height="38px" width="37px" alt="" />
                </div>
                <IonRow className='ion-padding-bottom'>
                  <IonCol>
                    <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                    <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='subtitle'>Treat Now, Pay Later</IonText></IonRow>
                  </IonCol>
                </IonRow>
              </IonRow>
              <div>
                <IonRow className="ion-justify-content-center ion-text-center ion-padding pad-tp-37">
                  <IonText className='emailscstxt'>Email verification link  expired</IonText>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center">
                  <IonCol>
                    <IonText className='emailsentdesctxt'>Verification link has expired. Please login to your account to resend the verification email!</IonText>
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonRow className='ion-align-items-center ion-justify-content-center ion-padding myben-position myben-ft'>@myBenefits 2021</IonRow>
      </IonContent>
    </IonPage>
  )
}
export default VerificationLinkExpired;