import { IonContent, IonPage, IonRow, IonHeader, IonCol, IonText, IonGrid, } from '@ionic/react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import OuterHeader from '../../components/outerHeader';
import './index.css'
const AcceptPayments: React.FC = () => {
    return (
        <IonPage className="bg-cnt">
            <IonContent fullscreen>
                <IonRow className="ion-justify-content-center display-flex">
                    <IonCol className='pad-0'>
                        <OuterHeader></OuterHeader>
                        <IonRow className="head-prov-title-bg ion-justify-content-center ion-align-items-center ion-margin-bottom">
                            <IonText>
                            Accepting Payments 
                            </IonText>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-justify-content-center display-flex-faq ion-padding">
                                {/* <IonRow className="ion-justify-content-center ion-align-items-center">
                            <div style={{ display: 'flex' }}>
                                <img src={require(`../../images/final_logo.png`).default} className="" height="38px" width="37px" alt="" />
                            </div>
                            <IonRow>
                                <IonCol>
                                    <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                                    <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='subtitle'>Treat Now, Pay Later</IonText></IonRow>
                                </IonCol>
                            </IonRow>
                        </IonRow> */}
                                {/* <IonRow className='ion-padding-bottom faqtitle' style={{ fontSize: "27px" }}><IonText>Accepting Payments </IonText></IonRow>
                    <IonRow className='faqtitle ion-padding-bottom' style={{ fontSize: "22px" }}><IonText>All in One Place </IonText></IonRow> */}
                                <IonRow className='cnthelp ion-padding-bottom' style={{ fontSize: "22px" }}>Contact for help</IonRow>
                                {/* <IonRow className='subtxt ion-padding-top'><IonText>We are here to help!</IonText></IonRow> */}
                                <IonRow><IonText className='cnthelpfield'>Email:&nbsp;<IonText style={{ color: "#00B9CF" }}>support@mybenefits.ai </IonText></IonText></IonRow>
                                <IonRow><IonText className='cnthelpfield'>Phone:&nbsp;<IonText style={{ color: "#00B9CF" }}>(650)-777-7133</IonText></IonText></IonRow>
                                {/* <IonRow className='ion-padding-top'>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <IonRow className='ion-padding-top'><IonText className='subtxt'> Get Started:</IonText></IonRow>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div></div><br></br>
                            <IonRow></IonRow><br></br>
                            <IonRow className='subtxt pad-2'><IonText>1. &nbsp;Sell more treatments to Patients and Employees,</IonText></IonRow>
                            <IonRow className='subtxt pad-2'><IonText>2. &nbsp;Earn Treat Coins,</IonText></IonRow>
                            <IonRow className='subtxt pad-2'><IonText>3. &nbsp;Best Provider Experience &</IonText></IonRow>
                            <IonRow className='subtxt pad-2'><IonText>4. &nbsp;Best Patient Experience </IonText></IonRow>
                        </div>
                    </IonRow> */}
                                {/* <div style={{ maxWidth: "700px" }} className="ion-padding-vertical">
                        <IonRow className='ion-padding mybenwel ion-text-center' style={{ fontWeight: "500" }}>
                            <IonText>
                                myBenefits.ai is a smarter way to invite and sell more treatments to patients and employees
                            </IonText>
                        </IonRow>
                        <IonRow className='ion-padding-top abt-txt'>
                            <IonText>Patients can invite providers and Providers can accept new employees from innumerable employers in your area.</IonText>
                        </IonRow>
                    </div> */}
                                <IonRow className='ion-padding-top hwitwrktxt'>
                                    <IonText className='hwitwrktxt'>How does it work?</IonText>
                                </IonRow>
                                <div style={{ maxWidth: "700px", width: "100%" }} className='ion-padding'>
                                    <Accordion allowMultipleExpanded allowZeroExpanded>
                                        <AccordionItem className='acc-br-top'>
                                            <AccordionItemHeading role="end" >
                                                <AccordionItemButton>
                                                    Invite from QR code
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    1.&nbsp;Patient Scan QR code and get instant approval for treatment
                                                </p>
                                                <p>2.&nbsp;Receive funds and start the treatment
                                                </p>
                                                <p>
                                                    3.&nbsp;Earn Treat Coins
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Invite from Dashboard
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    1.&nbsp;Click Invite Button
                                                </p>
                                                <p>2.&nbsp;Enter First Name, Last Name and Phone Number
                                                </p>
                                                <p>3.&nbsp;Patient is instantly approved
                                                </p>
                                                <p>4.&nbsp;Earn Treat Coins</p>

                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Employer Networks
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    1.&nbsp;Employees invites providers and Employees provides you 6 digit token for treatments
                                                </p>
                                                <p>2.&nbsp;Accept Payments by entering the 6 digit token</p>
                                                <p>3.&nbsp;Employee is Pre-Approved  </p>
                                                <p>4.&nbsp;Earn Treat Coins </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Treat Coins Redemption
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    1.&nbsp;Redeem Treat coins
                                                </p>
                                                <p>2.&nbsp;Direct Deposit to your bank account.  </p>
                                                <p>3.&nbsp;Enjoy Treats Coin for your office from us </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>


            </IonContent>
        </IonPage >
    )
}
export default AcceptPayments;