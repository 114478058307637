import {
  IonContent,
  IonPage,
  IonRow,
  IonButton,
  IonText,
  useIonToast,
  IonCol,
  IonLabel,
  IonInput,
  IonLoading,
  IonTextarea,
  IonFooter,
  useIonAlert,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import DatePicker from "react-datepicker";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import * as yup from "yup";
import { Formik } from "formik";
import NumberFormat from "react-number-format";
import { useEffect, useState } from "react";
import instance from "../../utils/api";
import TextField from '@mui/material/TextField';
import moment from "moment";
import { Calendar } from "@styled-icons/bootstrap/Calendar"
import { useHistory } from "react-router";
interface Props {
  dismissModal: () => void;
  getDischarge: () => void;
  modalValue: string;
  PatientProfile: {};
  userId: string;
}
const CreateDischarge: React.FC<Props> = (props: any) => {
  const [showLoading, setShowLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<any>();
  const [showToast, dismiss] = useIonToast();
  const [patientProfileData, setPatientProfileData] = useState<any>([]);
  const [currentSlide, setSlide] = useState<any>(0);
  const [startDate, setStartDate] = useState(new Date());
  const [surgeryDate, setSurgeryDate] = useState<any>("");
  const [suregryDateOpen, setSurgeryDateOpen] = useState<boolean>(false);
  const [dischargeType, setdischargeType] = useState("PreOp");
  const [postopData, setPostopData] = useState<any>([
  ]);
  const [PostOpPatientData, setPostOpPatientData] = useState<any>([]);
  const [selectedPostopIndex, setSelectedPostopIndex] = useState<any>("");
  const [showLoder2, setShowloader2] = useState<boolean>(false);
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const validationSchema = yup.object({
    surgeryDate: dischargeType === "PreOp" ? yup.string().required("This field is required") : yup.string(),
    patientName: yup.string().required("This field is required"),
    patientEmail: yup
      .string()
      .email("Invalid email"),
    patientMobile: yup
      .string()
      .required("This field is required")
      .min(10, "Phone Number must be 10 digits."),
    patientDOB: yup.date()
      .required('This field is required').typeError('Please enter valid date')
      .test('is-current-year', 'Please enter valid date', function (value: any) {
        const currentDate = new Date();
        return value <= currentDate;
      }),

    department: yup.string().required("This field is required"),
    surgeryType: yup
      .string()
      .required("This field is required"),
    bodyPart: yup.string().when('department', {
      is: (department) => department === 'ENT',
      then: yup.string().required('This field is required'),
      otherwise: yup.string(),
    }),
  });

  const send = async (values: any) => {
    let dateNum = new Date(values.patientDOB.slice(0, 2) + '/' + values.patientDOB.slice(2, 4) + '/' + values.patientDOB.slice(4)).getTime();
    if (dischargeType === "PostOp" && !uploadedFiles) {
      setErrorMessage("Please choose a file ");
    } else if ((dischargeType === "PostOp") && (uploadedFiles.type !== "application/pdf")
      && (uploadedFiles.type !== "application/msword") &&
      (uploadedFiles.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
      setErrorMessage("Please upload only pdf, doc, or docx files.");
    }
    else {

      var formData = new FormData();
      formData.append("dischargeType", dischargeType);
      formData.append("name", values.patientName);
      formData.append("phoneNumber", values.patientMobile);
      formData.append("department", values.department);
      formData.append("type", values.surgeryType);
      formData.append("systemType", "web");
      if (dischargeType === "PostOp") {
        formData.append("file", uploadedFiles);
      }
      if (values.message) {
        formData.append("message", values.message);
      }
      if (values.patientEmail) {
        formData.append("email", values.patientEmail);
      }
      if (values.department === "ENT") {
        formData.append("subType", values.bodyPart);
      }
      if (dischargeType === "PreOp") {
        const dateSurgery = values.surgeryDate ? new Date(values.surgeryDate.slice(0, 2) + '/' + values.surgeryDate.slice(2, 4) + '/' + values.surgeryDate.slice(4)).getTime() : new Date(surgeryDate).getTime();
        formData.append("surgeryDate", `${dateSurgery}`);
      }
      if (postopData.length > 0) {
        formData.append("id", PostOpPatientData._id);
      }
      if (values.patientDOB) {
        formData.append("dob", `${dateNum}`);
      }
      else if (selectedDate) {
        let dob = new Date(selectedDate).getTime()
        formData.append("dob", `${dob}`);
      }
      setShowLoading(true);
      await instance({
        method: postopData.length > 0 ? "PUT" : "POST",
        url: postopData.length > 0 ? "discharge/updateDischarge" : "discharge",
        validateStatus: () => true,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((resp) => {
          let obj: any = {};
          obj = resp;
          if (obj.status === "SUCCESS!") {
            setShowLoading(false);
            setErrorMessage("");
            props.dismissModal();
            props.getDischarge();
            history.push('/discharge')
            presentAlert({
              message: 'Discharge has been uploaded for processing. Patients will receive a text and email when they can login.',
              buttons: ['OK'],
            })
          } else {
            setShowLoading(false);
          }
        })
        .catch((err) => {
          setShowLoading(false);
          showToast(err, 3000);
        });
    }
  };
  const handleFileEvent = (e: any) => {
    if (e.target.files[0] || e.target.files[0].type === "application/pdf") {
      setErrorMessage("");
    }
    setUploadedFiles(e.target.files[0]);
  };

  const slides = [
    "We're gonna do 3 fundamental exercises.",
    "First, 10 push-ups",
    "Do 10 reps. Remember about full range of motion. Don't rush.",
    "Next, 20 squats",
    "Finally, 15 sit-ups",
    "Slightly bend your knees. Remember about full range of motion.",
    "Great job!",
    "You made it, have a nice day and see you next time!",
  ];
  const [slidesData, setSlidesData] = useState<any>(slides);

  const patientProfile = async () => {
    setShowLoading(true);
    await instance({
      method: "GET",
      url: `/discharge/${props.userId}`,
      validateStatus: () => true,
    })
      .then((resp) => {
        let obj: any = {};
        obj = resp;
        setShowLoading(false);
        if (obj.status === "SUCCESS!") {
          let data = obj.result;
          setShowLoading(false);
          setPatientProfileData(obj.result[0])
        } else {
          setShowLoading(false);
        }
      })
      .catch((err) => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    if (props && props.modalValue !== "createDischarge") {
      patientProfile();
    }
  }, []);
  const [selectedDate, setSelectedDate] = useState<any>('');
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const getPreops = async (number: any) => {
    setShowloader2(true);
    await instance({
      method: "GET",
      url: `discharge/getPreops/${number}`,
      validateStatus: () => true
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      setShowLoading(false);
      if (obj.status === "SUCCESS!") {
        setPostopData(obj.result);
        setShowloader2(false);
      }
      else {
        setShowloader2(false);
      }
    })
  }
  return (
    <IonPage style={{ width: "100%" }}>

      <IonRow className="popup-header">
        <IonCol className="pad-0">
          <IonRow
            className="ion-justify-content-end pointer pad-0"
            onClick={() => props.dismissModal()}
          >
            <CloseOutline
              title="Close"
              size={30}
              color="#FFFFFF"
            ></CloseOutline>
          </IonRow>
          <IonRow className="ion-justify-content-center popup-title ion-padding-bottom">
            <IonText className="popup-title">
              Create Discharge
            </IonText>
          </IonRow>
        </IonCol>
      </IonRow>

      <IonContent scrollEvents={true}>
        <Formik enableReinitialize={true}
          initialValues={{
            patientName: PostOpPatientData && PostOpPatientData.name ? PostOpPatientData.name : "",
            patientEmail: PostOpPatientData && PostOpPatientData.email ? PostOpPatientData.email : "",
            patientMobile: PostOpPatientData && PostOpPatientData.phoneNumber ? PostOpPatientData.phoneNumber : "",
            patientDOB: PostOpPatientData && PostOpPatientData.dob
              ? moment(PostOpPatientData.dob
              ).format("MM-DD-YYYY") : "",
            department: PostOpPatientData && PostOpPatientData.department ? PostOpPatientData.department
              : "",
            uploadDocuments: "",
            message: "",
            surgeryType: PostOpPatientData && PostOpPatientData.type ? PostOpPatientData.type :
              "",
            bodyPart: "",
            surgeryDate: "",
            dischargesNames: PostOpPatientData && PostOpPatientData[selectedPostopIndex],
          }}
          onSubmit={send}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit} id="my-form-data">
              <div
                className="fieldiv"
                style={{ maxWidth: "100%", padding: "20px 30px" }}
              >
                <IonSegment mode="ios" value={dischargeType}>
                  <IonSegmentButton
                    value="PreOp"
                    onClick={() => {
                      setdischargeType("PreOp");
                      setPostOpPatientData([]);
                      formikProps.resetForm();
                    }}

                  >
                    <IonLabel>
                      Preop
                    </IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="PostOp"
                    onClick={() => {
                      setdischargeType("PostOp");
                      setPostOpPatientData([]);
                      formikProps.resetForm();
                    }}

                  >
                    <IonLabel>
                      Postop
                    </IonLabel>
                  </IonSegmentButton>
                </IonSegment>

                <IonRow className="ion-justify-content-between ion-margin-top">
                  {dischargeType === "PreOp" &&
                    <IonCol sizeLg="6" sizeSm="12" sizeXs="12" onClick={() => { setIsOpen(false) }}>
                      <IonLabel className="label">
                        Surgery Date <span className="star-clr">*</span>
                      </IonLabel>
                      <IonRow className="mg-top input-field placeholder-txt num-format-wd-100 ion-justify-content-between" style={{ height: "auto", paddingRight: "12px" }}>

                        <NumberFormat placeholder="MM/DD/YYYY" format="##/##/####"
                          name="surgeryDate" className="number-format"
                          value={formikProps.values.surgeryDate ? formikProps.values.surgeryDate : moment(surgeryDate).format('MM/DD/YYYY')}
                          autoComplete="off"
                          style={{ padding: "12px", height: "40.16px", border: "none", background: "white", color: "black" }}
                          onValueChange={(val) => {
                            formikProps.setFieldValue("surgeryDate", val.value)
                            setSelectedDate("")
                            if (surgeryDate) {
                              setSurgeryDateOpen(false)
                            }
                          }
                          }
                          onBlur={formikProps.handleBlur}
                        /><span onClick={() => setSurgeryDateOpen(!suregryDateOpen)} className="pointer"><Calendar size={15} color="black" /></span>
                      </IonRow>
                      {suregryDateOpen &&
                        <DatePicker
                          placeholderText="MM/DD/YYYY"
                          dateFormat="MM/dd/yyyy"
                          name="patientDOB"
                          inline
                          className="ion-padding input-field mg-top placeholder-txt width-100  date-z-index"
                          selected={surgeryDate}
                          onChange={(e) => { setSurgeryDate(e); formikProps.values.surgeryDate = "" }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onBlur={formikProps.handleBlur}
                          minDate={new Date()}
                        ></DatePicker>
                      }
                      <p className="fielderrormsg">
                        {formikProps.touched.surgeryDate &&
                          formikProps.errors.surgeryDate}
                      </p>
                    </IonCol>}
                  <IonCol sizeLg="6" sizeSm="12" sizeXs="12" onClick={() => { setSurgeryDateOpen(false); setIsOpen(false) }}>
                    <IonLabel className="label" style={{ whiteSpace: "nowrap" }}>
                      Patient Phone number <span className="star-clr">*</span>
                    </IonLabel>
                    <NumberFormat
                      placeholder="xxx-xxx-xxxx"
                      format="(###) ###-####"
                      name="patientMobile"
                      className="mg-top input-field placeholder-txt num-format-wd-100"
                      value={formikProps.values.patientMobile}
                      style={{ width: "100%", padding: "12px" }}
                      onValueChange={(val) => {
                        formikProps.setFieldValue("patientMobile", val.value);
                        setPostOpPatientData([]);
                        setPostopData([]);
                        setSelectedPostopIndex("");
                        if (val.value.length === 10 && dischargeType === "PostOp") {
                          getPreops(val.value)
                        }
                      }}
                      onBlur={formikProps.handleBlur}
                    />
                    <p className="fielderrormsg err-ft-14">
                      {formikProps.touched.patientMobile &&
                        formikProps.errors.patientMobile}
                    </p>
                  </IonCol>
                  {dischargeType === "PostOp" && postopData.length > 0 &&
                    <IonCol sizeLg="6" sizeSm="12" sizeXs="12" onClick={() => { setSurgeryDateOpen(false); setIsOpen(false) }}>
                      <IonLabel className="label">
                        Select Discharge Name{" "}
                        <span className="star-clr">*</span>
                      </IonLabel>
                      <select
                        name="dischargesNames"
                        value={formikProps.values.dischargesNames}
                        onChange={(e: any) => { formikProps.handleChange(e); setPostOpPatientData(postopData[e.target.value]); setSelectedPostopIndex(e.target.value) }}
                        onBlur={formikProps.handleBlur}
                        className="input-field mg-top placeholder-txt pointer"
                        style={{
                          width: "100%",
                          padding: "9px",
                          outline: "none",
                        }}
                      >
                        <option value="">Select Your option</option>
                        {postopData && postopData.length > 0 &&
                          postopData.map((value: any, index: any) => (
                            <option key={index} value={index}>{value.name}{" "}-{" "}{value.surgeryDate
                              ? new Date(value.surgeryDate).toLocaleDateString(
                                "en-US",
                                { timeZone: "America/Los_Angeles" }
                              )
                              : "NA"}
                            </option>
                          ))}
                      </select>
                      <p className="fielderrormsg err-ft-14">
                        {formikProps.touched.dischargesNames &&
                          formikProps.errors.dischargesNames}
                      </p>
                    </IonCol>}
                  <IonCol sizeLg="6" sizeSm="12" sizeXs="12" onClick={(e) => { setIsOpen(false); setSurgeryDateOpen(false) }}>
                    <IonLabel className="label">
                      Patient Name <span className="star-clr">*</span>
                    </IonLabel>
                    <IonInput
                      type="text"
                      value={formikProps.values.patientName}
                      className="ion-padding input-field mg-top placeholder-txt"
                      placeholder="eg. Mary Jackson"
                      name="patientName"
                      onIonChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    >
                      {" "}
                    </IonInput>
                    <p className="fielderrormsg">
                      {formikProps.touched.patientName &&
                        formikProps.errors.patientName}
                    </p>
                  </IonCol>
                  <IonCol sizeLg="6" sizeSm="12" sizeXs="12" onClick={() => { setSurgeryDateOpen(false) }}>
                    <IonLabel className="label">
                      Patient DOB <span className="star-clr">*</span>
                    </IonLabel>
                    <IonRow className="mg-top input-field placeholder-txt num-format-wd-100 ion-justify-content-between" style={{ height: "auto", paddingRight: "12px" }}>

                      <NumberFormat placeholder="MM/DD/YYYY" format="##/##/####"
                        name="patientDOB" className="number-format"
                        value={formikProps.values.patientDOB ? formikProps.values.patientDOB : moment(selectedDate).format('MM/DD/YYYY')}
                        autoComplete="off"
                        style={{ padding: "12px", height: "40.16px", border: "none", background: "white", color: "black" }}
                        onValueChange={(val) => {
                          formikProps.setFieldValue("patientDOB", val.value)
                          setSelectedDate("")
                          if (selectedDate) {
                            setIsOpen(false)
                          }
                        }
                        }
                        onBlur={formikProps.handleBlur}
                      /><span onClick={() => setIsOpen(!isOpen)} className="pointer"><Calendar size={15} color="black" /></span>
                    </IonRow>
                    {isOpen &&
                      <DatePicker
                        placeholderText="MM/DD/YYYY"
                        dateFormat="MM/dd/yyyy"
                        name="patientDOB"
                        inline
                        className="ion-padding input-field mg-top placeholder-txt width-100  date-z-index"
                        selected={selectedDate}
                        onChange={(e) => { setSelectedDate(e); formikProps.values.patientDOB = "" }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onBlur={formikProps.handleBlur}
                        maxDate={startDate}
                      ></DatePicker>
                    }
                    <p className="fielderrormsg">
                      {formikProps.touched.patientDOB &&
                        formikProps.errors.patientDOB}
                    </p>
                  </IonCol>
                  <IonCol sizeLg="6" sizeSm="12" sizeXs="12" onClick={() => { setSurgeryDateOpen(false); setIsOpen(false) }}>
                    <IonLabel className="label">
                      Patient Email
                    </IonLabel>
                    <IonInput
                      clearInput={true}
                      type="text"
                      name="patientEmail"
                      onBlur={formikProps.handleBlur}
                      className="input-field mg-top placeholder-txt"
                      placeholder="eg. mary.jackson@gmail.com"
                      value={formikProps.values.patientEmail}
                      onIonChange={formikProps.handleChange}
                    >
                      {" "}
                    </IonInput>
                    <p className="fielderrormsg err-ft-14">
                      {formikProps.touched.patientEmail &&
                        formikProps.errors.patientEmail}
                    </p>
                  </IonCol>
                  <IonCol sizeLg="6" sizeSm="12" sizeXs="12" onClick={() => { setSurgeryDateOpen(false); setIsOpen(false) }}>
                    <IonLabel className="label">
                      Surgery Department <span className="star-clr">*</span>
                    </IonLabel>
                    <select
                      name="department"
                      value={formikProps.values.department}
                      onChange={(e) => {
                        formikProps.setFieldValue('department', e.target.value)
                        if ((e.target.value === "Periodontitis") || (e.target.value === "Endodontics")) {
                          formikProps.values.surgeryType = "Out Patient"
                        }
                        else {
                          formikProps.values.surgeryType = ""
                        }
                      }
                      }
                      onBlur={formikProps.handleBlur}
                      className="input-field mg-top placeholder-txt pointer"
                      style={{
                        width: "100%",
                        padding: "9px",
                        outline: "none",
                        background: "white",
                        color: "#808080"
                      }}
                    >
                      <option value="">Select Your option</option>
                      <option value="Urology">Urology</option>
                      <option value="Endodontics">Endodontics</option>
                      <option value="Periodontitis">Periodontitis</option>
                      <option value="ENT">
                        ENT
                      </option>
                      <option value="Cardiology" disabled>
                        Cardiology
                      </option>
                      <option value="Neurology" disabled>
                        Neurology
                      </option>
                      <option value="Orthopedics" disabled>
                        Orthopedics
                      </option>
                      <option value="Podiatry" disabled>
                        Podiatry
                      </option>
                      <option value="Spine Surgery" disabled>
                        Spine Surger
                      </option>
                    </select>
                    <p className="fielderrormsg err-ft-14">
                      {formikProps.touched.department &&
                        formikProps.errors.department}
                    </p>

                  </IonCol>
                  <IonCol sizeLg="6" sizeSm="12" sizeXs="12" onClick={() => { setSurgeryDateOpen(false); setIsOpen(false) }}>
                    <IonLabel className="label">
                      Select Surgery Type{" "}
                      <span className="star-clr">*</span>
                    </IonLabel>
                    <select
                      name="surgeryType"
                      value={formikProps.values.surgeryType}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      className="input-field mg-top placeholder-txt pointer"
                      style={{
                        width: "100%",
                        padding: "9px",
                        outline: "none",
                      }}
                    >
                      <option value="">Select Your option</option>
                      <option value="In Patient">In Patient</option>
                      <option value="Out Patient">Out Patient</option>
                    </select>

                    <p className="fielderrormsg err-ft-14">
                      {formikProps.touched.surgeryType &&
                        formikProps.errors.surgeryType}
                    </p>
                  </IonCol>

                  {formikProps.values.department === "ENT" &&
                    <IonCol sizeLg="6" sizeSm="12" sizeXs="12" onClick={() => { setSurgeryDateOpen(false); setIsOpen(false) }}>
                      <IonLabel className="label">
                        Select Body Part{" "}
                        <span className="star-clr">*</span>
                      </IonLabel>
                      <select
                        name="bodyPart"
                        value={formikProps.values.bodyPart}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        className="input-field mg-top placeholder-txt pointer"
                        style={{
                          width: "100%",
                          padding: "9px",
                          outline: "none",
                        }}
                      >
                        <option value="">Select Your option</option>
                        <option value="Ear">Ear</option>
                        <option value="Nose">Nose</option>
                        <option value="Throat">Throat</option>
                        <option value="Neck">Neck</option>
                      </select>
                      <p className="fielderrormsg err-ft-14">
                        {formikProps.touched.bodyPart &&
                          formikProps.errors.bodyPart}
                      </p>
                    </IonCol>}
                </IonRow>

                <div onClick={(e) => { setIsOpen(false); setSurgeryDateOpen(false) }}>
                  {dischargeType === "PostOp" &&
                    <IonRow style={{ paddingBottom: "10px" }}>
                      <IonCol className="ion-justify-content-center display-flex">
                        <IonLabel className="label">
                          Upload Document (pdf, doc, docx only){" "}
                          <span className="star-clr">*</span>
                        </IonLabel>
                        <input
                          id="fileUpload"
                          type="file"
                          multiple
                          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          onChange={(e) => handleFileEvent(e)}
                          style={{ color: "black" }}
                        />
                        {errorMessage ? (
                          <p className="fielderrormsg err-ft-14">
                            {errorMessage}
                          </p>
                        ) : null}
                      </IonCol>
                    </IonRow>}
                  <IonRow>
                    <IonCol>
                      <IonLabel className="label">
                        <div style={{ marginBottom: "5px" }}>Message to Patient</div>
                      </IonLabel>
                      <IonRow className="ion-align-items-center">
                        <IonTextarea
                          name="message"
                          style={{ height: "150px", width: "50%", margin: "0px" }}
                          placeholder="If you want to send a message to patient, type your message here."
                          className="dis-txt-area msg-txt-area"
                          onBlur={formikProps.handleBlur}
                          onIonChange={formikProps.handleChange}
                          value={formikProps.values.message}
                        ></IonTextarea>

                      </IonRow>
                    </IonCol>
                  </IonRow>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </IonContent>
      <IonFooter>
        <IonRow className="ion-justify-content-center">
          <IonButton
            form="my-form-data"
            expand="block"
            type="submit"
            className="searchbtn mg-bt searchbtn-txt"
          >
            Create Discharge
          </IonButton>
        </IonRow>
      </IonFooter>
      <IonLoading isOpen={showLoading} message={"Discharge creation in progress... "} />
      <IonLoading isOpen={showLoder2} message={"Please await... "} />
    </IonPage>
  );
};
export default CreateDischarge;
