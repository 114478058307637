
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { IonHeader, IonPage, IonGrid, IonRow, IonCol, IonText, IonContent, IonButton, IonModal, IonFooter, IonInput, IonLabel, IonTextarea, useIonToast, IonLoading } from "@ionic/react"
import { Formik } from "formik";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import * as yup from "yup";
import instance from "../../../utils/api";
const PlusHeader: React.FC = () => {
    const history = useHistory();
    const [showToast] = useIonToast();
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [dischargeModal, setDischargeModal] = useState<boolean>(false)
    const SUPPORTED_FORMATS = ["image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"];
    const validationSchema = yup.object({
        email: yup.string().email("Invalid email").required("This Field is required"),
        consentContent: yup.string().required("This Field is required"),
        file: yup.mixed().required("This Field is required")
        // .test('format',
        //     '', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
    })

    const uploadDischarge = async (values: any) => {
        setShowLoading(true)
        let data: any = {}
        data.email = values.email
        data.consentContent = values.consentContent
        data.logo = values.file
        console.log(data)
        await instance({
            method: "PUT",
            url: "mybenefitsprovider/updateProfile",
            data,
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp
            if (obj.status === "SUCCESS!") {
                setDischargeModal(false)
                showToast("Created Sucessfully")
                setShowLoading(false)
            }
            else {
                showToast(obj.error)
                setShowLoading(false)
            }
        })

    }
    const [navbarActiveValue, setNavBarActiveValue] = useState<any>("")

    useEffect(() => {
        const location = window.location.pathname;
        const active = location.substring(1)
        setNavBarActiveValue(active)
    }, [])

    const handleChange = (event: any, formikProps: any) => {
        var reader = new FileReader();
        reader.readAsDataURL(event.currentTarget.files[0]);

        reader.onload = () => {
            formikProps.setFieldValue("file", reader.result)
        };
        reader.onerror = error => {
            console.log("Error: ", error);
        };
        // formikProps.setFieldValue("file", event.currentTarget.files[0]) 
    }
    return (
        <IonHeader style={{ background: "white" }}>
            <IonGrid className='pad-0'>
                <div style={{ display: "flex", justifyContent: "space-between" }} className="header">
                    <div style={{ display: "flex", alignItems: "center" }} onClick={() => window.location.href = "/discharge"}>
                        <div style={{ display: 'flex' }}>
                            <img src={require(`../../../images/final_logo.png`).default} style={{ maxWidth: "fit-content" }} height="38px" width="37px" alt="" />
                        </div>
                        <IonRow>
                            <IonCol>
                                <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                                <IonRow className="ion-align-items-center ion-justify-content-flex-start">
                                    <IonText className="subtitle">Discharge Plus</IonText>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ padding: "0px 8px" }} className="pointer" onClick={() => history.push("/plus-dashboard")}>
                            <IonRow className={(navbarActiveValue === "plus-dashboard"
                                ? "active"
                                : "Inactive")}><IonText>Dashboard</IonText></IonRow>
                        </div>
                        <div style={{ padding: "0px 8px" }} className="pointer" onClick={() => history.push("/plus-all_discharges")}>
                            <IonRow className={(navbarActiveValue === "plus-all_discharges"
                                ? "active"
                                : "Inactive")}><IonText>Discharges</IonText></IonRow>
                        </div>
                        <div style={{ padding: "0px 8px" }} className="pointer" onClick={() => history.push("/plus-admin-floraQnA")}>
                            <IonRow className={(navbarActiveValue === "plus-admin-floraQnA"
                                ? "active"
                                : "Inactive")}><IonText>Flora QnA</IonText></IonRow>
                        </div>
                        <div style={{ padding: "0px 8px" }} className="pointer" onClick={() => history.push("/plus-admin-patient-question")}>
                            <IonRow className={(navbarActiveValue === "plus-admin-patient-question"
                                ? "active"
                                : "Inactive")}><IonText>Patient Questions</IonText></IonRow>
                        </div>
                        <div onClick={() => setDischargeModal(true)} style={{ padding: "0px 8px" }}>
                            <IonRow className="headers-list-txt header-txt"><IonText>Create Consent Content</IonText></IonRow>
                        </div>
                    </div>
                </div>
            </IonGrid>
            <IonModal isOpen={dischargeModal} onDidDismiss={() => setDischargeModal(false)}>
                <IonRow className="popup-header">
                    <IonCol className="pad-0">
                        <IonRow
                            className="ion-justify-content-end pointer pad-0"
                            onClick={() => setDischargeModal(false)}
                        >
                            <CloseOutline
                                title="Close"
                                size={30}
                                color="#FFFFFF"
                            ></CloseOutline>
                        </IonRow>

                        <IonRow className="ion-justify-content-center popup-title ion-padding-bottom">
                            <IonText className="popup-title">
                                Upload Discharge
                            </IonText>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonContent>
                    <Formik initialValues={{ email: "", consentContent: "", file: "" }} onSubmit={uploadDischarge} validationSchema={validationSchema}>
                        {(formikProps) => (
                            <form onSubmit={formikProps.handleSubmit} id="my-form">
                                <div
                                    className="fieldiv"
                                    style={{ padding: "20px 30px" }}
                                >
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel className="label">
                                                Email<span className="star-clr">*</span>
                                            </IonLabel>
                                            <IonInput
                                                type="text"
                                                value={formikProps.values.email}
                                                className="ion-padding input-field mg-top placeholder-txt"
                                                placeholder="Enter"
                                                name="email"
                                                onIonChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                            >
                                                {" "}
                                            </IonInput>
                                            <p className="fielderrormsg err-ft-14">
                                                {formikProps.touched.email &&
                                                    formikProps.errors.email}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="ion-justify-content-center display-flex">
                                            <IonLabel className="label">
                                                Logo
                                                <span className="star-clr">*</span>
                                            </IonLabel>
                                            <input
                                                id="fileUpload"
                                                type="file"
                                                name="file"
                                                multiple
                                                onChange={(event: any) => { handleChange(event, formikProps) }}
                                                style={{ color: "black" }}
                                            />
                                            <p className="fielderrormsg err-ft-14">
                                                {formikProps.touched.file &&
                                                    formikProps.errors.file}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel className="label">
                                                ConsentContent
                                            </IonLabel>
                                            <IonTextarea
                                                name="consentContent"
                                                placeholder="Type your message here."
                                                className="dis-txt-area review-txt-area"
                                                onBlur={formikProps.handleBlur}
                                                onIonChange={formikProps.handleChange}
                                                value={formikProps.values.consentContent}
                                            ></IonTextarea>
                                            <p className="fielderrormsg err-ft-14">
                                                {formikProps.touched.consentContent &&
                                                    formikProps.errors.consentContent}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </div>

                            </form>
                        )}
                    </Formik>
                </IonContent>
                <IonFooter style={{ background: "white" }}>
                    <IonRow className="ion-justify-content-center">
                        <IonButton
                            form="my-form"
                            expand="block"
                            type="submit"
                            className="searchbtn mg-bt searchbtn-txt"
                        >
                            Upload
                        </IonButton>
                    </IonRow>
                </IonFooter>
            </IonModal>
            <IonLoading isOpen={showLoading} />
        </IonHeader>
    )
}
export default PlusHeader