import {
    IonContent, IonModal, IonLoading, IonItem, IonList, IonRadio, IonRadioGroup, useIonToast, IonPage, IonGrid, IonRow, IonText, IonCol, IonInput,
    IonLabel, IonButton, IonFooter, IonHeader, IonToolbar,
} from "@ionic/react"
import { useState, useEffect, useRef } from "react";
import ProvidersHeader from "../../../components/providersInvite/providersHeader";
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import NumberFormat from "react-number-format";
import { Formik, } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Loader";
import './index.css'
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';
import { Circle } from '@styled-icons/bootstrap/Circle';
import { ArrowBackOutline } from '@styled-icons/evaicons-outline/ArrowBackOutline'
import SurveyQuestions from "./SurveyQuestions";
import instance from "../../../utils/api";
const PatientLogin: React.FC = () => {
    const history = useHistory<any>();
    const mobile_number =
        (history.location && history.location.state && history.location.state.props) || "";
    const discharge_Id =
        (history.location && history.location.state && history.location.state.id) || "";
    const eventType =
        (history.location && history.location.state && history.location.state.eventType) || "";
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [headerTitle, setHeaderTitle] = useState<any>("Patient Login");
    const [otpBlock, setOtpBlock] = useState<any>("login");
    const [showToast, dismiss] = useIonToast();
    const [phoneNumber, setPhoneNumber] = useState<any>(mobile_number)
    const [modalPopUp, setModalPopUp] = useState<boolean>(false);
    const [dischargeData, setDischargeData] = useState<any>([])
    const [dischargeUrlId, setDischargeUrlId] = useState<any>(discharge_Id);
    const [logActivityEvent, setLogActivityEvent] = useState<any>(eventType)
    const [otp, setOtp] = useState('');
    const logActivityApi = async (value: any, id: any) => {
        let data: any = {}
        data.discharge = id
        data.eventName = value
        await instance({
            method: "POST",
            url: "/discharge/patientActivity",
            validateStatus: () => true,
            data
        })
            .then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false)
                    setPhoneNumber(obj.result.info.phoneNumber)
                    console.log(obj.result.info.phoneNumber)
                }
                else {
                    setShowLoading(false)
                }
            })
    }
    useEffect(() => {
        let path = window.location.href;
        let urldischargeId = path.split("=");
        let dischargeId = urldischargeId[1] && urldischargeId[1].split("&");
        let logActivityEvent = urldischargeId[2]
        if (dischargeId && dischargeId[0]) {
            setDischargeUrlId(dischargeId[0])
        }
        if (logActivityEvent) {
            setLogActivityEvent(logActivityEvent)
            logActivityApi(logActivityEvent, dischargeId[0])
        }
    }, []);

    const validationSchema = yup.object({
        phoneNumber: yup.string()
            .required('This field is required')
            .min(10, 'Phone Number must be 10 digits.'),
    });

    useEffect(() => {
        if (otpBlock === "verifyOtp") {
            setHeaderTitle("Phone Verification")
        }
        else if (otpBlock === "login") {
            setHeaderTitle("Patient Login")
        }
        else if (otpBlock === "discharges") {
            setHeaderTitle("Recent Discharges")
        }
        else {
            setHeaderTitle("Survey")
        }
    }, [otpBlock])

    const [token, setToken] = useState("")

    const login = async (values) => {
        setShowLoading(true);
        let data: any = {}
        data.phoneNumber = values.phoneNumber
        if (values) {
            await axios({
                method: 'PUT',
                url: 'https://provider-api-dev.mybenefits.ai/api/user/employeeLoginDirect',
                data,
            }).then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.data.status === "SUCCESS!") {
                    setShowLoading(false);
                    setOtpBlock("verifyOtp")
                    if (obj.data.result.token) {
                        setToken(obj.data.result.token)
                        //handleGetOTP()
                    }
                    setPhoneNumber(values.phoneNumber)
                    showToast(`Confirmation code sent to ${values.phoneNumber}`, 3000)

                }
                else {
                    setShowLoading(false);
                }
            }).catch((error) => {
                setShowLoading(false);
                showToast(error.response.data.error.message, 3000)
            })
        }
    }
    const resendOtp = async () => {
        setShowLoading(true);
        let data: any = {}
        data.phoneNumber = phoneNumber
        await axios({
            method: 'PUT',
            url: 'https://provider-api-dev.mybenefits.ai/api/user/employeeLoginDirect',
            data,
        }).then((resp) => {
            let obj: any = {}
            obj = resp
            if (obj.data.status === "SUCCESS!") {
                setShowLoading(false);
                setOtpBlock("verifyOtp")
                if (obj.data.result.token) {
                    setToken(obj.data.result.token)
                    //handleGetOTP()
                }
                setPhoneNumber(phoneNumber)
                showToast(`Confirmation code sent to ${phoneNumber}`, 3000)

            }
            else {
                setShowLoading(false);
            }
        }).catch((error) => {
            setShowLoading(false);
            showToast('Incorrect confirmation code entered.', 3000)
        })
    }
    const handleGetOTP = async () => {
        //if("OTPCredential" in window){
        // await navigator.credentials.get({
        //     otp: {
        //       transport: ['sms']
        //     },
        //     signal: new AbortController().signal,
        //     data:console.log(navigator,"insoide")
        //   })
        //   .then((otp) => {
        //     setOtp(otp.code);
        //     console.log('OTP retrieved successfully: ' + otp.code ,otp);
        //     navigator.clipboard.writeText(otp.code);
        //     showToast("OTP retrieved successfully",otp.code , 3000)
        //   })
        //   .catch((error) => {
        //     console.error('Error retrieving OTP:', error);
        //     showToast(`${"Error retrieving OTP" + error}` , 10000)
        //   });
        // try {
        //     const otp = await navigator.credentials.get({
        //         otp: { transport: ['sms'] },
        //         signal: new AbortController().signal,
        //     });
        //     setOtp(otp.code);
        //     showToast("OTP retrieved successfully" + otp.code, 3000)
        // } catch (error) {
        //     showToast("Error retrieving OTP" + error, 3000)
        // }
        //}
        //}
    }
    const setDataInLocalStorage = (data) => {
        const now = new Date();
        const dataWithTimestamp = {
            data, timestamp: now.getTime(),
        };
        localStorage.setItem("patientLogin", JSON.stringify(dataWithTimestamp));
    };
    const verifyOtp = async (values: any) => {
        setShowLoading(true);
        let data: any = {}
        data.otp = values.otp
        if (data) {
            await axios({
                method: 'PUT',
                url: 'https://provider-api-dev.mybenefits.ai/api/token/verifyDischargeOTP',
                data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token || ""
                }
            }).then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.data.status === "SUCCESS!") {
                    setShowLoading(false);
                    if (dischargeUrlId) {
                        var data: any = []
                        setDataInLocalStorage("true")
                        // localStorage.setItem("patientLogin", "true")
                        obj.data.result && obj.data.result.length > 0 && obj.data.result[0].map((async (value) => {
                            if (value._id === dischargeUrlId) {
                                await data.push(value)
                            }
                        }))
                        if (data.length > 0) {
                            setDischargeData(data[0])
                            if ((logActivityEvent !== "postcare_followup") && (logActivityEvent !== "postcare_followup_2")) {
                                if (logActivityEvent === "provider_message_link") {
                                    localStorage.setItem("dischargeId", (data[0]._id));
                                    history.push("/patient_dashboard", { activityEvent: logActivityEvent })
                                }
                                else {
                                    localStorage.setItem("dischargeId", (data[0]._id));
                                    history.push("/patient_dashboard")
                                }
                            }
                            else if ((logActivityEvent === "postcare_followup") || (logActivityEvent === "postcare_followup_2")) {
                                setOtpBlock("surveryDischarges")
                            }
                        }
                        else {
                            setModalPopUp(true)
                        }
                    }
                    else {
                        if (obj.data.result.length === 0) {
                            setModalPopUp(true)
                        }
                        else {
                            // localStorage.setItem("patientLogin", "true")
                            setDataInLocalStorage("true")
                            setDischargeData(obj.data.result[0])
                            setOtpBlock("discharges")
                            // history.push("/patient_dashboard", { props: obj.data.result })
                        }
                    }
                }
                else {
                    setShowLoading(false);
                    showToast(obj.error.message, 3000)
                }

            }).catch((error) => {
                if (error) {
                    showToast('Incorrect confirmation code entered.', 3000)
                }
                setShowLoading(false);
            })
        }
    }

    const otpValidation = yup.object({
        otp: yup.string()
            .required('This field is required')
            .min(4, 'Please Enter 4 digits OTP.'),
    });

    const navToLogin = () => {
        window.location.href = "/patient"
    }
    const [selectDischargeDocument, setSelectDischargeDocument] = useState<any>("")
    const [arrayDischargeDocument, setDischargeDocument] = useState<any>([]);

    const chooseDischarge = (value: any) => {
        setSelectDischargeDocument(value)
        if (arrayDischargeDocument.includes(value)) {
            setDischargeDocument(arrayDischargeDocument.filter((a) => a !== value));
        } else {
            setDischargeDocument([value]);
        }
    };
    const navToDashboard = () => {
        if (arrayDischargeDocument.length === 0) {
            showToast("Choose atleast one discharge", 3000)
        }
        else {
            console.log(selectDischargeDocument)
            localStorage.setItem("dischargeId", (selectDischargeDocument._id));
            history.push("/patient_dashboard",)
        }
    }
    const goBack = () => {
        if ((otpBlock === "verifyOtp") || (otpBlock === "discharges")) {
            setOtpBlock("login")
        }
    }


    const showLoader = () => {
        setShowLoading(true)
    }
    const hideLoader = () => {
        setShowLoading(false)
    }
    const goBackToSurvey = () => {
        setOtpBlock("login")
    }
    return (
        <IonPage>
            <IonHeader>
                <div className="bg-img">
                    <IonRow className={(otpBlock === "verifyOtp" || otpBlock === "discharges") ? "ion-align-items-center ion-justify-content-between" : "ion-align-items-center ion-justify-content-center"} style={{ padding: "16px" }}>
                        {(otpBlock === "verifyOtp" || otpBlock === "discharges") ?
                            <div onClick={() => goBack()}><ArrowBackOutline size="30" color="black"></ArrowBackOutline></div>
                            : null}
                        <IonText className="patient-header-txt">{headerTitle}</IonText>
                        <div></div>
                    </IonRow>
                </div>
            </IonHeader>
            <IonContent fullscreen>
                <IonGrid className="ht-100" style={{ padding: "0px" }}>
                    {/* <SurveyQuestions></SurveyQuestions> */}
                    {otpBlock === "login" ?
                        <div className="ht-100 pro-flex-col ion-justify-content-start">
                            <Formik enableReinitialize={true}
                                initialValues={{
                                    phoneNumber: phoneNumber ? phoneNumber : ""
                                }}
                                validationSchema={validationSchema}
                                onSubmit={login}>
                                {formikProps => (
                                    <div className='fieldiv ion-padding wd-100' style={{ padding: "0px 12px" }}>
                                        <IonCol className="fields-row-pd">
                                            <form onSubmit={formikProps.handleSubmit}>
                                                <IonRow className='ion-padding-top'>
                                                    <IonCol className='pad-lr-0'>
                                                        <IonLabel className='pro-label-txt'>Patient Phone Number</IonLabel>
                                                        <div className="pro-inpt-field mg-field-top num-format-wd-100  number-flex">
                                                            <NumberFormat
                                                                placeholder="Use your hospital registered phone number"
                                                                format="(###) ###-####"
                                                                name="phoneNumber"
                                                                className="num-for-br-no"
                                                                style={{ fontSize: "16px" }}
                                                                value={formikProps.values.phoneNumber}
                                                                // onChange={formikProps.handleChange}
                                                                onValueChange={val => formikProps.setFieldValue("phoneNumber", val.floatValue)}
                                                                onBlur={formikProps.handleBlur}
                                                            />
                                                            {formikProps.values.phoneNumber && formikProps.touched.phoneNumber && !formikProps.errors.phoneNumber ?
                                                                <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                        </div>

                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.phoneNumber && formikProps.errors.phoneNumber}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-justify-content-center ion-padding-vertical">
                                                    <IonButton expand="block" type='submit' className='patient-btn provoiders-btn provoiders-btn-txt ion-no-margin'>Next</IonButton>
                                                </IonRow>
                                            </form>
                                        </IonCol>
                                    </div>
                                )}
                            </Formik>
                        </div> : otpBlock === "verifyOtp" ?
                            <div className="ht-100 pro-flex-col ion-justify-content-start ion-padding-top">
                                <IonRow><IonText></IonText></IonRow>
                                <Formik enableReinitialize={true}
                                    initialValues={{
                                        otp: otp,
                                    }}
                                    validationSchema={otpValidation}
                                    onSubmit={verifyOtp}>
                                    {formikProps => (
                                        <div className='fieldiv ion-padding wd-100' style={{ padding: "0px 12px" }}>
                                            <IonCol className="fields-row-pd">
                                                <form onSubmit={formikProps.handleSubmit}>
                                                    <IonRow className='ion-padding-top'>
                                                        <IonCol className='pad-lr-0'>
                                                            <IonLabel className='pro-label-txt'>Confirmation Code</IonLabel>

                                                            <div className="pro-inpt-field mg-field-top num-format-wd-100  number-flex">
                                                                <NumberFormat
                                                                    placeholder="Enter 4 digit confirmation code"
                                                                    format="####"
                                                                    name="otp"

                                                                    className="num-for-br-no"
                                                                    style={{ fontSize: "16px" }}
                                                                    value={formikProps.values.otp}
                                                                    // onChange={formikProps.handleChange}
                                                                    onValueChange={val => formikProps.setFieldValue("otp", val.floatValue)}
                                                                    onBlur={formikProps.handleBlur}
                                                                />
                                                                {formikProps.values.otp && formikProps.touched.otp && !formikProps.errors.otp ?
                                                                    <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                            </div>

                                                            <p className="fielderrormsg">
                                                                {formikProps.touched.otp && formikProps.errors.otp}
                                                            </p>
                                                            <div style={{ color: "#00B9CF" }} onClick={() => { resendOtp() }}>Resend code</div>

                                                            <IonRow className="ion-justify-content-center ion-padding-vertical">
                                                                <IonButton expand="block" type='submit' className='patient-btn provoiders-btn provoiders-btn-txt ion-no-margin'>Verify</IonButton>
                                                            </IonRow>
                                                        </IonCol>
                                                    </IonRow>
                                                </form>
                                            </IonCol>
                                        </div>
                                    )}
                                </Formik>
                            </div> : otpBlock === "discharges" ? (
                                <div style={{ margin: "15px" }}>

                                    {dischargeData && dischargeData.length > 0 ?
                                        dischargeData.map((item, index) => (

                                            <div className="radiabtn txt-robot" key={index}>
                                                <IonRow style={{ width: "100%" }} className="ion-align-items-center ion-justify-content-between" onClick={() => chooseDischarge(item)}>
                                                    <div>
                                                        <IonLabel>{item.timestamp ? new Date(item.timestamp).toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' }) : "NA"}</IonLabel>
                                                        <div>{item && item.provider && item.provider[0].displayName ? item.provider[0].displayName : "NA"}</div>
                                                    </div>
                                                    {arrayDischargeDocument.includes(item) ?
                                                        <CheckCircle size={30} color="green" /> : <Circle size={30} />}
                                                </IonRow>
                                            </div>
                                        )) : null}

                                </div>

                            ) : otpBlock === "surveryDischarges" && (
                                <SurveyQuestions id={dischargeUrlId} dischargeData={dischargeData} showLoader={showLoader} hideLoader={hideLoader} goBackToSurvey={goBackToSurvey} logactivityType={logActivityEvent} />
                            )
                    }
                </IonGrid>
                <IonModal isOpen={modalPopUp} onDidDismiss={() => setModalPopUp(false)}>
                    <IonContent fullscreen>
                        <div className='fieldiv ion-padding wd-100' style={{ height: "100%", display: "flex" }}>
                            <IonCol>
                                <IonRow className="tnpltxt ion-justify-content-center ion-margin-bottom ion-padding-bottom">
                                    <IonText>No Discharge Doccument found</IonText>
                                </IonRow>
                                <div className="ion-margin-vertical ion-justify-content-center">
                                    <IonButton expand="block" onClick={() => navToLogin()} className='patient-btn provoiders-btn provoiders-btn-txt ion-no-margin'>Ok</IonButton>
                                </div>
                            </IonCol>
                        </div>
                    </IonContent>
                </IonModal>
            </IonContent>
            {otpBlock === "discharges" && (
                <IonFooter style={{ background: "white" }}>
                    <div className="ion-justify-content-center" style={{ margin: "12px" }}>
                        <IonButton expand="block" onClick={() => navToDashboard()} className='provoiders-btn provoiders-btn-txt ion-no-margin patient-btn'>Next</IonButton>
                    </div>
                </IonFooter>)}
            {showLoading && <Loader />}
        </IonPage >
    )
}
export default PatientLogin;