import { IonCol, IonRow } from '@ionic/react';
import React from 'react'


export default function SearchList({ filteredData }) {
    const filterData = filteredData;
    return (
        <div style={{ width: "100%" }}>
            {filterData && filterData.length > 0 ? filterData.map((value, index) => (
                <div key={index}><IonRow className="dataCard">
                    <IonCol>
                        <div className="cat-txt">{value.categoryName}</div>
                        <div style={{ color: "#4C4F78" }} className="txt-robot">{value.categoryInfo}</div>
                    </IonCol>
                </IonRow></div>
            )) : <IonRow className="not-found-txt" style={{width:"100%"}}>
            <div>No Data Found</div>
          </IonRow>}
        </div>
    )
}
