import {
    IonCol, useIonToast,
     IonButton, IonContent, IonInput, IonLabel, IonPage, IonRow, IonText
} from '@ionic/react';
import { useState, useContext,} from 'react';
import './index.css';
import CommonContext from "../../utils/contexts/commonProps";
import { useHistory } from "react-router-dom";
import instance from '../../utils/api';
import OuterHeader from '../../components/outerHeader';
import { Formik } from 'formik';
import * as yup from 'yup';
import Loader from '../../components/Loader';


const ProviderLogin: React.FC = () => {
    const [showToast, dismiss] = useIonToast();
    const { common, setCommon } = useContext(CommonContext);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const history = useHistory();

    const next = async (values: any) => {
        let data: any = {}
        data.email = values.email
        data.password = values.password
        data.loginType = "web"
        setShowLoading(true)
        await instance({
            method: 'POST',
            url: `user/login`,
            data,
            validateStatus: () => true
        }).then(res => {
            let obj: any = {};
            obj = res
            if (obj.status === "SUCCESS!") {
                common.auth.authObj = obj.result;
                setCommon(common);
                instance.defaults.headers.common['Authorization'] = obj.result.token;
                localStorage.setItem("auth_token", obj.result.token);
                localStorage.setItem("providerName", obj.result.mybenefitsprovider.displayName);
                localStorage.setItem("isBankAdded", obj.result.mybenefitsprovider.isBankAdded);
                localStorage.setItem("providerStatus", obj.result.mybenefitsprovider.status);
                history.push('/discharge');
                setShowLoading(false)
            }
            else {
                setShowLoading(false);
               showToast(obj.error.message,3000)
            }
        })
    }
    const validationSchema = yup.object({
        email: yup.string().email("Invalid email")
            .required("This field is required"),
        password: yup.string().required("This field is required")
    })

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonRow className="ion-justify-content-center display-flex">
                    <IonCol className='pad-0'>
                        <OuterHeader></OuterHeader>
                        <IonRow className="head-prov-title-bg ion-justify-content-center ion-align-items-center ion-margin-bottom">
                            <IonText>
                                Welcome
                            </IonText>
                        </IonRow>
                        <Formik initialValues={{
                            email: "",
                            password: ""
                        }} onSubmit={next} validationSchema={validationSchema}>
                            {formikProps => (
                                <IonRow style={{ maxWidth: '430px', margin: '0 auto',padding:"16px 18px" }}>
                                    <form onSubmit={formikProps.handleSubmit} style={{ width: "100%" }}>
                                        <IonCol className="fields-row-pd">
                                            <IonRow className='ion-padding-top'>
                                                <IonCol className="pad-0">
                                                    <IonLabel className='label'>Enter Email</IonLabel>
                                                    <IonInput autocomplete='on' type="text" name="email" className="input-field mg-top placeholder-txt" placeholder="Email" value={formikProps.values.email}
                                                        onIonChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                    > </IonInput>
                                                    {formikProps.touched.email && formikProps.errors.email &&
                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.email && formikProps.errors.email} </p>}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-padding-top'>
                                                <IonCol className="pad-0">
                                                    <IonLabel className='label'>Enter Password</IonLabel>
                                                    <IonInput type="password" name="password" className="ion-padding input-field mg-top placeholder-txt" placeholder="****" value={formikProps.values.password}
                                                        onIonChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                    > </IonInput>
                                                    {formikProps.touched.password && formikProps.errors.password &&
                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.password && formikProps.errors.password}
                                                        </p>
                                                    }
                                                </IonCol>
                                            </IonRow>
                                            <IonButton expand="block" className='searchbtn searchbtn-txt' type="submit">Login</IonButton>
                                            <IonRow className='signuprow ion-justify-content-center'>
                                                <span>Don't have an account</span>?<span className='signup' onClick={() => { window.location.href = '/signup' }}>Sign up</span>
                                            </IonRow>
                                            <IonRow className='signuprow ion-justify-content-center ion-padding signup'>
                                                <IonText onClick={() => { window.location.href = "/forgot_password" }}>Forgot Password?</IonText>
                                            </IonRow>
                                        </IonCol>
                                    </form>
                                </IonRow>
                            )}
                        </Formik>
                    </IonCol>
                </IonRow>
                {showLoading && <Loader />}
            </IonContent>
        </IonPage>
    )
}
export default ProviderLogin;