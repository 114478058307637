import { IonContent, IonGrid, IonPage, IonButton,IonCol,IonRow,IonText } from "@ionic/react";
import '../../pages/TermsAndConditions/terms.css';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import CommonContext from "../../utils/contexts/commonProps";
const TermsAndConditions: React.FC = () => {
  const history = useHistory();
  const { common, setCommon } = useContext(CommonContext);
  const walkInProviderSignUp = localStorage.getItem('walkInProviderSignUp');
  const agree = () => {
    if (walkInProviderSignUp === "true") {
      window.location.href = '/signup';
    }
    else
      history.push('/dashboard');
  }
  return (
    <IonPage style={{ background: '#EEF8FE' }}>
      <IonRow className="ion-justify-content-center myben-title-pd-tp">
        <div style={{ display: 'flex' }}>
          <img src={require(`../../images/final_logo.png`).default} className="" height="38px" width="37px" alt="" />
        </div>
        <IonRow className='ion-padding-bottom'>
          <IonCol>
            <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
            <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='subtitle'>Treat Now, Pay Later</IonText></IonRow>
          </IonCol>
        </IonRow>
      </IonRow>
      <IonRow className="ion-align-items-center ion-justify-content-center  ion-padding-bottom"><IonText className='signup-title'>Treat Now, Treat More!</IonText></IonRow>
      <IonRow className='ion-justify-content-center'>
        <IonText className='welcome-txt'>Terms & Conditions</IonText>
      </IonRow>
      <IonContent className="ion-contents scroller">
        <IonGrid>
          <IonRow className='ion-justify-content-center'>
            <IonText className="terms-txt"> 
            1. We ("myBenefits.ai") have no liability to you for any damages you may suffer as a result of any such third party's (other service providers engaged by myBenefits.ai) actions or inactions.
            <br></br><br></br>
            2. Indemnification. You agree to indemnify, defend and hold MyBenfits.ai harmless from any liability, including reasonable attorneys' fees, related to your use of the Services or any violation of these Terms of Service.
            <br></br><br></br>
            3. Limitation of Liability. myBenefits.ai HAVE NO LIABILITY WHATSOEVER FOR YOUR USE OF ANY PRODUCT AVAILABLE FROM OR THOUGH THE WEBSITE OR THE USE OF THE SERVICES. IN PARTICULAR, BUT NOT AS A LIMITATION THEREOF, myBenefits.ai ARE NOT LIABLE FOR ANY COMPENSATORY, DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF HEALTH, WELLNESS, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, PRODUCT OR SERVICE LIABILITY OR OTHERWISE
            <br></br><br></br>
            4. Accuracy, Reliability and Timeliness of Information: YOUR USE OF THE TOOLS AND SERVICES PROVIDED BY myBenefits.ai IS AT YOUR SOLE RISK. INFORMATION PROVIDED THROUGH THE TOOLS IS ON AN "AS IS" AND "AS AVAILABLE" BASIS FOR INFORMATIONAL PURPOSES ONLY. WE MAKE NO WARRANTY THAT THE TOOLS WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR BE UNINTERRUPTED, TIMELY, SECURE, ACCURATE OR RELIABLE.
            <br></br><br></br>
            5. Bank Transfers: When we initiate a bank transfer (ACH) to pay you (“Provider”), it takes 1-3 business days provided we have all the necessary information from you is accurate and compliant
            <br></br><br></br>
            Further, you acknowledge and agree that you will not hold myBenefits.ai responsible for, any liability arising from the actions or inactions of this third party in connection with the permissions you grant.
            </IonText>
          </IonRow>
        </IonGrid>
      </IonContent>
      <div className="bottom-div">
        <IonRow className='ion-justify-content-center pd-bt-4'>
          <IonButton expand="block" className='agreebtn agreebtn-txt' onClick={() => agree()}> I Agree</IonButton></IonRow>
        <IonRow className='signuprow-terms ion-justify-content-center ion-padding-bottom'>
          <span>Have an account</span>?<span className='signup-txt' onClick={() => { window.location.href = '/createproviderlogin' }}>Sign in now</span>
        </IonRow>
      </div>
    </IonPage>
  )
}
export default TermsAndConditions;