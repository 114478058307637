
import { IonContent, IonHeader, IonModal, IonRow } from "@ionic/react";
import { Message } from "@styled-icons/boxicons-solid/Message"
import moment from "moment";
import { useState } from "react";
import { ArrowBackOutline } from "@styled-icons/evaicons-outline/ArrowBackOutline";
import instance from "../../../../utils/api";
interface Props {
    eventsData: any,
    dischargeId: string
}
const MessagesFromProvider: React.FC<Props> = (props) => {
    const duration = moment(new Date(props.eventsData.timestamp)).fromNow();

    const [modal, setModal] = useState<boolean>(false);
    const readMessages = async () => {
        setModal(true)
        await instance({
            method: "PUT",
            url: `discharge/readProviderMessages/${props.dischargeId}`,
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp
            if (obj.status === "SUCCESS!") {
            }
            else {
            }
        })
    }
    return (
        <div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className="icon-bg"><Message size={30} color="#275FFA" /></div>
                <div>
                    <div onClick={() => readMessages()}>
                        <div className="txt-robot" style={{ lineHeight: "20px", textAlign: "left", fontSize: "14px" }}>
                            {`You have received a new message from ${props && props.eventsData.providerName}`}
                        </div>
                        <div style={{ paddingTop: "8px", fontSize: "12px" }}>
                            {duration && duration === 'a few seconds ago' ? 'Just now' : duration}
                        </div>
                    </div>
                </div>
            </div>
            <IonModal isOpen={modal} onDidDismiss={() => setModal(false)}>
                <IonHeader>
                    <div className="bg-img">
                        <IonRow
                            className="ion-padding ion-justify-content-between ion-align-items-center">
                            <ArrowBackOutline onClick={() => setModal(false)} size={30} color='black' />
                            <div className="patient-header-txt" style={{ fontSize: "20px" }}>Message from Provider</div>
                            <div></div>
                        </IonRow>
                    </div>
                </IonHeader>
                <IonContent>
                    <IonRow className="ion-padding">
                        <div className="txt-robot">{props && props.eventsData && props.eventsData.info && props.eventsData.info.comment}</div>
                    </IonRow>
                </IonContent>
            </IonModal>
        </div>
    )
}
export default MessagesFromProvider;
