import { IonCol, IonContent, IonGrid, IonSearchbar, IonRow, IonLabel, IonInput, IonText, IonLoading, IonTextarea, useIonToast, IonFooter, IonButton, IonSpinner } from "@ionic/react"
import ReactTooltip from "react-tooltip";
import instance from "../../utils/api";
import { useState, useEffect } from "react";
import { RefreshOutline } from "@styled-icons/evaicons-outline/RefreshOutline";
import { ChevronRight } from "@styled-icons/evil/ChevronRight";
import { FilePdf } from "@styled-icons/icomoon/FilePdf";
import * as yup from "yup";
import { Formik } from "formik";
import PainManagement from "./PainManagement";
import FeedBack from "./FeedBack";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import SpecialInstructions from "./SpecialInstructions";
import PostCareFollowUp from "./PostCareFollowUp";
import PostCareUpload from "./postCareUpload";
import { CheckAll } from "@styled-icons/bootstrap/CheckAll";
import { DotSingle } from "@styled-icons/entypo/DotSingle";
import { MessageDetail } from "@styled-icons/boxicons-regular/MessageDetail";
import { AlertCircleOutline } from "@styled-icons/evaicons-outline/AlertCircleOutline";
import { Wpforms } from "@styled-icons/fa-brands/Wpforms";
import { CardChecklist } from "@styled-icons/bootstrap/CardChecklist";
import { FileEarmarkImage } from "@styled-icons/bootstrap/FileEarmarkImage";
import { Star } from "@styled-icons/bootstrap/Star";
import { User } from "@styled-icons/boxicons-regular/User";
import moment from "moment";
import { Calendar } from "@styled-icons/bootstrap/Calendar"
const RecentDischarges: React.FC = () => {
    const [showLoading, setShowLoading] = useState(false);
    const [dischargeData, setDischargeData] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>([]);
    const [selectedDischargeData, setSelectedDischargeData] = useState<any>([]);
    const [getDischareData, setGetDischargeData] = useState<any>([]);
    const [selectedAction, setSelectedAction] = useState<any>("");
    const [showCol2, setShowCol2] = useState<any>(false)
    const [showToast, dismiss] = useIonToast();
    const [searchLoader, setSearchLoader] = useState(false);
    const [patientProfileData, setPatientProfileData] = useState<any>([]);
    const [msgHistoryBlock, setMsgHistoryBlock] = useState<boolean>(false);
    const [msgHistory, setMsgHistory] = useState<any>([])
    const [selectedDate, setSelectedDate] = useState<any>('');
    const [startDate, setStartDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const getDischarges = async (value: any) => {
        if (value === "search") {
            setSearchLoader(true)
        }
        await instance({
            method: "GET",
            url: "discharge/getDischarge",
            validateStatus: () => true,
        })
            .then((resp) => {
                let obj: any = {};
                obj = resp;
                setShowLoading(false);
                if (obj.status === "SUCCESS!") {
                    let data = obj.result;
                    setDischargeData(data.discharges);
                    data.discharges.map(async (value: any) => {
                        if (value._id === getDischareData._id) {
                            setGetDischargeData(value)
                        }
                    })

                    setShowLoading(false);
                    setSearchLoader(false)
                    setSearchData(data.discharges)
                } else {
                    setShowLoading(false);
                    setSearchLoader(false)
                }
            })
            .catch(() => {
                setShowLoading(false);
            });
    };


    useEffect(() => {
        getDischarges("");
    }, []);

    const onChangeSearch = (query: any) => {
        const orginal = dischargeData
        if (query.detail.value) {
            const filteredData = orginal.filter(
                item => {
                    return (
                        item.name.toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
                        new Date(item.timestamp).toLocaleDateString(
                            "en-US",
                            { timeZone: "America/Los_Angeles" }
                        ).toLowerCase().includes(query.detail.value.toLowerCase().trim())
                    );
                }
            );
            setSearchData(filteredData)
        }
        else {
            setSearchData(orginal)
        }
    }
    
    const getSelectedData = async (item: any) => {
        setSelectedAction("sendMessage")
        setMsgHistoryBlock(false)
        setGetDischargeData(item)
        setShowCol2(true)
        if (selectedDischargeData.includes(item._id)) {
            setSelectedDischargeData(selectedDischargeData.filter((a) => a !== item._id));
            setShowCol2(false)
        } else {
            setSelectedDischargeData([item._id]);
        }
        await getMsgHistory(item._id)
    }

    const patientProfile = async () => {
        setShowLoading(true);
        await instance({
            method: "GET",
            url: `/discharge/${getDischareData._id}`,
            validateStatus: () => true,
        })
            .then((resp) => {
                let obj: any = {};
                obj = resp;
                setShowLoading(false);
                if (obj.status === "SUCCESS!") {
                    let data = obj.result;
                    setShowLoading(false);
                    setPatientProfileData(obj.result[0])
                } else {
                    setShowLoading(false);
                }
            })
            .catch((err) => {
                setShowLoading(false);
            });
    };
    const dischargeActions = async (value: any) => {
        setSelectedAction(value)
        if (getDischareData.isPain === true || getDischareData.isPostcare === true || getDischareData.isSurvey === true || getDischareData.isEndSurvey === true) {
            let data: any = {}
            if (value === "feedback") {
                data.isPostcare = false
            }
            else if (value === "pain") {
                data.isPain = false
            }
            else if (value === "postCareFollow") {
                data.isSurvey = false
                data.isEndSurvey = false
            }
            await instance({
                method: "PUT",
                url: `discharge/doccument/${getDischareData._id}`,
                validateStatus: () => true,
                data
            }).then((resp) => {
                let obj: any = {};
                obj = resp;
                if (obj.status === "SUCCESS!") {
                    getDischarges("")
                } else {
                    showToast(obj.error.message, 3000);
                }
            })
        }
        else if (value === "profile") {
            patientProfile()
        }
        else if (value === "sendMessage") {
            getMsgHistory(getDischareData._id)
        }
    }

    const getMsgHistory = async (id: any) => {
        await instance({
            url: `discharge/getMessages/${id}`,
            method: "GET",
            validateStatus: () => true,
        }).then((resp) => {
            let obj: any = {};
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setMsgHistory(obj.result)

            } else {
                showToast(obj.error, 3000);
            }
        });
    }
    const messageApiCall = async (value: any, actions) => {
        let data: any = {};
        data.dischargeId = getDischareData && getDischareData._id;
        data.message = value.message;
        setShowLoading(true);
        await instance({
            url: "discharge/sendMessage",
            method: "POST",
            data,
            validateStatus: () => true,
        }).then((resp) => {
            let obj: any = {};
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setShowLoading(false);
                actions.resetForm()
                setMsgHistoryBlock(true)
                getMsgHistory(getDischareData._id)
                showToast("Message sent successfully", 3000);

            } else {
                setShowLoading(false);
                showToast(obj.error, 3000);
            }
        });
    };
    const profileUpadte = async (values) => {
        setIsOpen(false)
        let dateNum = new Date(values.patientDOB.slice(0, 2) + '/' + values.patientDOB.slice(2, 4) + '/' + values.patientDOB.slice(4)).getTime();
        let data: any = {};
        data.name = values.patientName;
        data.phoneNumber = values.patientMobile;
        data.department = values.department;
        data.dob = dateNum;
        if (values.patientDOB) {
            data.dob = dateNum
        }
        else {
            data.dob = new Date(selectedDate).getTime();
        }
        if (values.patientEmail) {
            data.email = values.patientEmail;
        }
        setShowLoading(true);
        await instance({
            method: "PUT",
            url: `discharge/profile/${getDischareData._id}`,
            validateStatus: () => true,
            data,
        })
            .then((resp) => {
                let obj: any = {};
                obj = resp;
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    getDischarges("");
                    patientProfile()
                    showToast("Updated Successfully", 3000);
                } else {
                    setShowLoading(false);
                }
            })
            .catch((err) => {
                setShowLoading(false);
                showToast(err, 3000);
            });
    }
    const validationSchema = yup.object({
        patientName: yup.string().required("This field is required"),
        patientEmail: yup
            .string()
            .email("Invalid email"),
        patientMobile: yup
            .string()
            .required("This field is required")
            .min(10, "Phone Number must be 10 digits."),
        department: yup.string().required("This field is required"),
        patientDOB: yup.date()
            .required('This field is required').typeError('Please enter valid date')
            .test('is-current-year', 'Please enter valid date', function (value: any) {
                const currentDate = new Date();
                return value <= currentDate;
            }),
    });

    const refreshData = async (value: any) => {
        getDischarges(value);
    }

    return (
        <>
            <IonGrid style={{ padding: "0px", background: "white" }}>
                <IonRow className="ion-justify-content-between">
                    <IonCol sizeMd="3" className="col1-block">
                        <IonRow style={{ padding: "16px 1.5rem" }}>
                            <IonText className="content-title-txt">Recent Patient Discharges</IonText>
                        </IonRow>
                        <IonRow style={{ width: "100%", background: "#EEEEEE" }} className="ion-align-items-center">
                            <IonSearchbar style={{ width: "90%" }} onIonChange={(ev) => onChangeSearch(ev)} className="pad-all-0 patient-search" mode="ios" placeholder="Search by patient name or discharge date" ></IonSearchbar>
                            {searchLoader ?
                                <IonSpinner name="crescent" color="grey"></IonSpinner> :
                                <RefreshOutline size={30} color="#1EB9CD" className="pointer" onClick={() => refreshData("search")} />}
                        </IonRow>
                        <IonRow>
                            <IonCol className="pad-all-0">
                                {searchData.length > 0 ?
                                    searchData.map((value: any, index: any) => (
                                        <div key={index} className="list-item-br pointer" onClick={() => getSelectedData(value)} style={{
                                            backgroundColor: selectedDischargeData.includes(value._id) ? "#DFFCFF" : "#fff"

                                        }}>
                                            
                                            <div   style={{ padding: "10px 0px 10px 15px" }}>
                                                <IonRow className="ion-justify-content-between"><div className="list-name-txt">{value.name}</div>
                                                <div>
                                                    {
                                                        ((value.isPain === true) || (value.isSurvey === true) || (value.isPostcare === true) || (value.isEndSurvey === true)) &&
                                                        <>
                                                            <DotSingle size="40" color="#C297DD" data-tip={((value.isPain === true) || (value.isSurvey === true) || (value.isPostcare === true)) ? "Select patient to see notification received" : ""} data-for="purple-dot" />
                                                            <ReactTooltip backgroundColor="white" className="tooltip-bg-clr tooltip" place="bottom" textColor="black" id='purple-dot' />
                                                        </>

                                                    }

                                                    <CheckAll data-tip={value.isLogin === true ? "Discharge is seen by patient" : "Discharge is not seen by patient"} data-for="seen"
                                                        color={
                                                            value.isLogin === true ? "#00B9CF" : "#7C7878"
                                                        }
                                                        size="20"
                                                    />
                                                    <ReactTooltip backgroundColor="white" className="tooltip-bg-clr tooltip" place="bottom" textColor="black" id='seen' />
                                                    <ChevronRight color="#00B9CF" size={30} />
                                                </div></IonRow>
                                                <IonRow className="ion-justify-content-between ion-align-items-center">
                                                <div className="list-title-txt">Date: {value.timestamp
                                                        ? new Date(value.timestamp).toLocaleDateString(
                                                            "en-US",
                                                            { timeZone: "America/Los_Angeles" }
                                                        )
                                                        : "NA"}</div>
                                                        <div style={{fontSize:"14px"}}>{value.dischargeType ? value.dischargeType + " " + "Patient" : "PostOp Patient"}</div>
                                                </IonRow>
                                            </div>
                                        </div>
                                    )) : <IonRow className="not-found-txt">
                                        <div>No results found</div>
                                    </IonRow>
                                }
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol sizeMd="9" className="col2-block" style={{ padding: "8px 0px" }}>
                        {showCol2 &&
                            <div>
                                <IonRow className="list-title-txt" style={{ padding: "6px 10px", borderBottom: "1px solid #B0B0B0" }}>
                                    <IonRow className="ion-align-items-center"><span>Discharge Document</span>:&nbsp;
                                        {getDischareData.dischageDocLink ? <a
                                            href={getDischareData.dischageDocLink}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FilePdf
                                                title="click"
                                                size="25"
                                                color="#00B9CF"
                                            />
                                        </a> : <span className="NA-txt"> N/A</span>} </IonRow>
                                    <IonRow className="ion-align-items-center ion-padding-horizontal"><span>Consent Document</span>:&nbsp;
                                        {getDischareData.consentDocLink ?
                                            <a
                                                href={getDischareData.consentDocLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <FilePdf
                                                    title="click"
                                                    size="25"
                                                    color="#00B9CF"
                                                />
                                            </a> : <span className="NA-txt"> N/A</span>}</IonRow>
                                </IonRow>
                                <IonRow className="ion-justify-content-between pointer" style={{ padding: "10px" }}>
                                    <div className="actions-txt" style={{ borderBottom: selectedAction === "sendMessage" ? "3px solid #00B9CF" : "white", color: selectedAction === "sendMessage" ? "#77787C" : "#919095" }} onClick={() => dischargeActions("sendMessage")}>
                                        <div className="list-flex">
                                            <MessageDetail size="25" color={selectedAction === "sendMessage" ? "#00B9CF" : "rgb(145, 144, 149)"} style={{ paddingRight: "5px" }} />
                                            <div>Send Message</div></div></div>
                                    <div className="actions-txt" style={{ borderBottom: selectedAction === "pain" ? "3px solid #00B9CF" : "white", color: selectedAction === "pain" ? "#77787C" : "#919095" }} onClick={() => dischargeActions("pain")}>
                                        <div className="list-flex">
                                            <AlertCircleOutline size="25" color={selectedAction === "pain" ? "#00B9CF" : "rgb(145, 144, 149)"} style={{ paddingRight: "5px" }} />
                                            <div>Pain Reported {getDischareData.isPain === true && <DotSingle size="40" color="#C297DD" />}</div></div></div>

                                    <div className="actions-txt" style={{ borderBottom: selectedAction === "postCareFollow" ? "3px solid #00B9CF" : "white", color: selectedAction === "postCareFollow" ? "#77787C" : "#919095", width: "auto" }} onClick={() => dischargeActions("postCareFollow")}>
                                        <div className="list-flex">
                                            <Wpforms size="25" color={selectedAction === "postCareFollow" ? "#00B9CF" : "rgb(145, 144, 149)"} style={{ paddingRight: "5px" }} />
                                            <div>Post Care Follow Up {((getDischareData.isSurvey === true) || (getDischareData.isEndSurvey === true)) && <DotSingle size="40" color="#C297DD" />}</div></div></div>

                                    <div className="actions-txt" style={{ borderBottom: selectedAction === "specialInst" ? "3px solid #00B9CF" : "white", color: selectedAction === "specialInst" ? "#77787C" : "#919095" }} onClick={() => dischargeActions("specialInst")}>
                                        <div className="list-flex">
                                            <CardChecklist size="25" color={selectedAction === "specialInst" ? "#00B9CF" : "rgb(145, 144, 149)"} style={{ paddingRight: "5px" }} />
                                            <div> Special Instructions</div></div></div>


                                    <div className="actions-txt" style={{ borderBottom: selectedAction === "postCare" ? "3px solid #00B9CF" : "white", color: selectedAction === "postCare" ? "#77787C" : "#919095" }} onClick={() => dischargeActions("postCare")}>
                                        <div className="list-flex">
                                            <FileEarmarkImage size="25" color={selectedAction === "postCare" ? "#00B9CF" : "rgb(145, 144, 149)"} style={{ paddingRight: "5px" }} />
                                            <div>Post Care</div></div></div>

                                    <div className="actions-txt" style={{ borderBottom: selectedAction === "feedback" ? "3px solid #00B9CF" : "white", color: selectedAction === "feedback" ? "#77787C" : "#919095", width: getDischareData.isPostcare && "auto" }} onClick={() => dischargeActions("feedback")}>
                                        <div className="list-flex">
                                            <Star size="25" color={selectedAction === "feedback" ? "#00B9CF" : "rgb(145, 144, 149)"} style={{ paddingRight: "5px" }} />
                                            <div> Patient Feedback {getDischareData.isPostcare && <DotSingle size="40" color="#C297DD" />}</div></div></div>

                                    <div className="actions-txt" style={{ borderBottom: selectedAction === "profile" ? "3px solid #00B9CF" : "white", color: selectedAction === "profile" ? "#77787C" : "#919095" }} onClick={() => dischargeActions("profile")}>
                                        <div className="list-flex">
                                            <User size="25" color={selectedAction === "feedback" ? "#00B9CF" : "rgb(145, 144, 149)"} style={{ paddingRight: "5px" }} />
                                            <div>Patient Profile</div></div></div>


                                </IonRow>
                                {selectedAction === "sendMessage" ?
                                    <div style={{ padding: "0px 10px" }}>
                                        <IonRow className="ion-justify-content-end actions-txt" style={{ width: "100%", margin: "0px", padding: "0px" }}
                                            onClick={() => setMsgHistoryBlock(!msgHistoryBlock)}>
                                            <IonButton className="button button-txt" style={{ margin: "0px" }}>History</IonButton></IonRow>
                                        <div style={{ display: "flex", padding: "20px 0px" }}>
                                            <Formik
                                                initialValues={{ message: "" }}
                                                onSubmit={messageApiCall}
                                                validationSchema={yup.object({
                                                    message: yup.string().required("This field is required"),
                                                })}
                                            >
                                                {(formikProps) => (
                                                    <form
                                                        onSubmit={(e) => { e.preventDefault(); formikProps.handleSubmit(e) }}
                                                        id="form"
                                                        style={{ height: "100%", width: msgHistoryBlock ? "50%" : "100%" }}
                                                    >
                                                        <IonRow>
                                                            <IonCol className="pad-all-0">
                                                                <IonRow>
                                                                    <IonCol className="pad-0">
                                                                        <IonTextarea
                                                                            name="message"
                                                                            placeholder="Type Your Message"
                                                                            className="msg-txt-area"
                                                                            onBlur={formikProps.handleBlur}
                                                                            onIonChange={formikProps.handleChange}
                                                                            value={formikProps.values.message}
                                                                        ></IonTextarea>
                                                                        <p
                                                                            className="fielderrormsg"
                                                                            style={{ marginBottom: "0px" }}
                                                                        >
                                                                            {formikProps.touched.message &&
                                                                                formikProps.errors.message}
                                                                        </p>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow>
                                                                    <IonButton style={{ margin: "10px 0px" }}
                                                                        className="button button-txt" type="submit">
                                                                        Send Message
                                                                    </IonButton>
                                                                </IonRow>
                                                            </IonCol>
                                                        </IonRow>
                                                    </form>
                                                )}
                                            </Formik>
                                            {msgHistoryBlock ?
                                                <IonCol style={{ padding: "0px 0px 0px 15px" }}>
                                                    {msgHistory && msgHistory.length > 0 ?
                                                        msgHistory.map((value, index) => (
                                                            <div key={index}>
                                                                <IonRow className="ion-justify-content-between" style={{ borderBottom: "1px solid #999" }}>
                                                                    <div>
                                                                        <div style={{ padding: "5px 0px", fontWeight: "400", fontSize: "16px" }} className="list-title-txt">{value.message}</div>
                                                                        <div style={{ paddingBottom: "5px", fontSize: "12px", fontWeight: "400" }} className="list-title-txt"> {value.timestamp
                                                                            ? new Date(value.timestamp).toLocaleDateString(
                                                                                "en-US",
                                                                                { timeZone: "America/Los_Angeles" }
                                                                            )
                                                                            : "NA"}{" "}{value.timestamp
                                                                                ? new Date(value.timestamp).toLocaleTimeString(
                                                                                    "en-US",
                                                                                    { timeZone: "America/Los_Angeles" }
                                                                                )
                                                                                : "NA"}</div></div>
                                                                    <div>
                                                                        <CheckAll
                                                                            color={
                                                                                value.isRead
                                                                                    === true ? "#00B9CF" : "#7C7878"
                                                                            }
                                                                            size="20"
                                                                        />
                                                                    </div>
                                                                </IonRow>
                                                            </div>
                                                        )) : <IonRow className="not-found-txt" style={{ width: "100%" }}>
                                                            <div>No Data Found</div>
                                                        </IonRow>}
                                                </IonCol> : null}
                                        </div>
                                    </div> : selectedAction === "pain" ?
                                        <PainManagement painManagement={getDischareData && getDischareData.pain} />
                                        : selectedAction === "feedback" ?
                                            <FeedBack feedback={getDischareData} /> :
                                            selectedAction === "profile" ?
                                                <div>
                                                    <Formik enableReinitialize={true}
                                                        initialValues={{
                                                            patientName:
                                                                patientProfileData && patientProfileData.name ? patientProfileData.name :
                                                                    "",
                                                            patientEmail:
                                                                patientProfileData && patientProfileData.email
                                                                    ? patientProfileData.email
                                                                    :
                                                                    "",
                                                            patientMobile:
                                                                patientProfileData && patientProfileData.phoneNumber
                                                                    ? patientProfileData.phoneNumber
                                                                    :
                                                                    "",
                                                            patientDOB:
                                                                patientProfileData && patientProfileData.dob
                                                                    ? moment(patientProfileData.dob
                                                                    ).format("MM-DD-YYYY")
                                                                    :
                                                                    "",

                                                            department:
                                                                patientProfileData && patientProfileData.department
                                                                    ? patientProfileData.department
                                                                    :
                                                                    "",

                                                            surgeryType:
                                                                patientProfileData && patientProfileData.type ? patientProfileData.type :
                                                                    "",

                                                        }}
                                                        onSubmit={profileUpadte} validationSchema={validationSchema}>
                                                        {(formikProps) => (
                                                            <form onSubmit={formikProps.handleSubmit} id="my-form">
                                                                <div
                                                                    className="fieldiv"
                                                                    style={{ maxWidth: "100%", padding: "6px" }}
                                                                >
                                                                    <IonRow>
                                                                        <IonCol sizeLg="6" sizeSm="12" sizeXs="12" onClick={(e) => { setIsOpen(false) }}>
                                                                            <IonLabel className="label">
                                                                                Patient Name <span className="star-clr">*</span>
                                                                            </IonLabel>
                                                                            <IonInput
                                                                                type="text"
                                                                                value={formikProps.values.patientName}
                                                                                className="ion-padding input-field mg-top placeholder-txt"
                                                                                placeholder="eg. Mary Jackson"
                                                                                name="patientName"
                                                                                onIonChange={formikProps.handleChange}
                                                                                onBlur={formikProps.handleBlur}
                                                                            >
                                                                                {" "}
                                                                            </IonInput>
                                                                            <p className="fielderrormsg">
                                                                                {formikProps.touched.patientName &&
                                                                                    formikProps.errors.patientName}
                                                                            </p>
                                                                        </IonCol>
                                                                        <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                                                                            <IonLabel className="label">
                                                                                Patient DOB <span className="star-clr">*</span>
                                                                            </IonLabel>
                                                                            <IonRow className="mg-top input-field placeholder-txt num-format-wd-100 ion-justify-content-between" style={{ height: "auto", paddingRight: "12px" }}>
                                                                                <NumberFormat placeholder="MM/DD/YYYY" format="##/##/####"
                                                                                    name="patientDOB" className="number-format"
                                                                                    value={formikProps.values.patientDOB ? formikProps.values.patientDOB : moment(selectedDate).format('MM/DD/YYYY')}
                                                                                    style={{ padding: "12px", height: "40.16px", border: "none", width: "80%", background: "white" }}
                                                                                    onValueChange={(val) => {
                                                                                        formikProps.setFieldValue("patientDOB", val.value)
                                                                                        setSelectedDate("")
                                                                                        if (selectedDate) {
                                                                                            setIsOpen(false)
                                                                                        }
                                                                                    }
                                                                                    }
                                                                                    onBlur={formikProps.handleBlur}
                                                                                /><span onClick={() => setIsOpen(!isOpen)} className="pointer"><Calendar size={15} color="black" /></span>
                                                                            </IonRow>
                                                                            {isOpen &&
                                                                                <DatePicker
                                                                                    placeholderText="MM/DD/YYYY"
                                                                                    dateFormat="MM/dd/yyyy"
                                                                                    name="patientDOB"
                                                                                    inline
                                                                                    className="ion-padding input-field mg-top placeholder-txt width-100  date-z-index"
                                                                                    selected={selectedDate}
                                                                                    onChange={(e) => { setSelectedDate(e); formikProps.values.patientDOB = "" }}
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    style={{ position: "absolute", zIndex: "111111" }}
                                                                                    onBlur={formikProps.handleBlur}
                                                                                    maxDate={startDate}
                                                                                ></DatePicker>
                                                                            }

                                                                            {/* <DatePicker
                                                                                placeholderText="MM/DD/YYYY"
                                                                                dateFormat="MM/dd/yyyy"
                                                                                name="patientDOB"
                                                                                className="ion-padding input-field mg-top placeholder-txt width-100  date-z-index"
                                                                                selected={formikProps.values.patientDOB}
                                                                                onChange={(val: any) => {
                                                                                    formikProps.setFieldValue("patientDOB", val);
                                                                                }}
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                onBlur={formikProps.handleBlur}
                                                                            ></DatePicker> */}
                                                                            <p className="fielderrormsg">
                                                                                {formikProps.touched.patientDOB &&
                                                                                    formikProps.errors.patientDOB}
                                                                            </p>
                                                                        </IonCol>

                                                                    </IonRow>
                                                                    <IonRow onClick={(e) => { setIsOpen(false) }}>
                                                                        <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                                                                            <IonLabel className="label" style={{ whiteSpace: "nowrap" }}>
                                                                                Patient Phone number <span className="star-clr">*</span>
                                                                            </IonLabel>
                                                                            <NumberFormat
                                                                                placeholder="xxx-xxx-xxxx"
                                                                                format="(###) ###-####"
                                                                                name="patientMobile"
                                                                                className="mg-top input-field placeholder-txt num-format-wd-100"
                                                                                value={formikProps.values.patientMobile}
                                                                                style={{ width: "100%", padding: "12px" }}
                                                                                onValueChange={(val) =>
                                                                                    formikProps.setFieldValue("patientMobile", val.value)
                                                                                }
                                                                                onBlur={formikProps.handleBlur}
                                                                            />
                                                                            <p className="fielderrormsg err-ft-14">
                                                                                {formikProps.touched.patientMobile &&
                                                                                    formikProps.errors.patientMobile}
                                                                            </p>
                                                                        </IonCol>
                                                                        <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                                                                            <IonLabel className="label">
                                                                                Patient Email
                                                                            </IonLabel>
                                                                            <IonInput
                                                                                clearInput={true}
                                                                                type="text"
                                                                                name="patientEmail"
                                                                                onBlur={formikProps.handleBlur}
                                                                                className="input-field mg-top placeholder-txt"
                                                                                placeholder="eg. mary.jackson@gmail.com"
                                                                                value={formikProps.values.patientEmail}
                                                                                onIonChange={formikProps.handleChange}
                                                                            >
                                                                                {" "}
                                                                            </IonInput>
                                                                            <p className="fielderrormsg err-ft-14">
                                                                                {formikProps.touched.patientEmail &&
                                                                                    formikProps.errors.patientEmail}
                                                                            </p>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow onClick={(e) => { setIsOpen(false) }}>
                                                                        <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                                                                            <IonLabel className="label">
                                                                                Surgery Department <span className="star-clr">*</span>
                                                                            </IonLabel>
                                                                            <select
                                                                                name="department"
                                                                                value={formikProps.values.department}
                                                                                onChange={formikProps.handleChange}
                                                                                onBlur={formikProps.handleBlur}
                                                                                className="input-field mg-top placeholder-txt pointer"
                                                                                style={{
                                                                                    width: "100%",
                                                                                    padding: "9px",
                                                                                    outline: "none",
                                                                                    background: "white",
                                                                                    color: "#808080"
                                                                                }}
                                                                            >
                                                                                <option value="">Select Your option</option>
                                                                                <option value="Urology">Urology</option>
                                                                                <option value="Endodontics">Endodontics</option>
                                                                                <option value="Periodontitis">Periodontitis</option>
                                                                                <option value="ENT">ENT</option>
                                                                                <option value="Cardiology" disabled>
                                                                                    Cardiology
                                                                                </option>
                                                                                <option value="Neurology" disabled>
                                                                                    Neurology
                                                                                </option>
                                                                                <option value="Orthopedics" disabled>
                                                                                    Orthopedics
                                                                                </option>
                                                                                <option value="Podiatry" disabled>
                                                                                    Podiatry
                                                                                </option>
                                                                                <option value="Spine Surgery" disabled>
                                                                                    Spine Surger
                                                                                </option>
                                                                            </select>
                                                                            <p className="fielderrormsg err-ft-14">
                                                                                {formikProps.touched.department &&
                                                                                    formikProps.errors.department}
                                                                            </p>

                                                                        </IonCol>

                                                                    </IonRow>
                                                                    <IonRow>
                                                                        <IonButton
                                                                            expand="block"
                                                                            type="submit" style={{ margin: "0px 5px" }}
                                                                            className="button  button-txt"
                                                                        >
                                                                            Update Profile
                                                                        </IonButton>
                                                                    </IonRow>
                                                                </div>
                                                            </form>
                                                        )}

                                                    </Formik>
                                                </div>
                                                : selectedAction === "specialInst" ?
                                                    <SpecialInstructions userId={getDischareData && getDischareData._id}
                                                        name={getDischareData && getDischareData.name} /> :
                                                    selectedAction === "postCareFollow" ?
                                                        <PostCareFollowUp postCareFollowUp={getDischareData.survey}  endSurvey={getDischareData.endSurvey}/> :
                                                        selectedAction === "postCare" && (
                                                            <PostCareUpload userId={getDischareData && getDischareData.user}
                                                                providerId={getDischareData && getDischareData._id}
                                                                name={getDischareData && getDischareData.name} />
                                                        )}
                            </div>}
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonLoading isOpen={showLoading} message="Please wait..."></IonLoading>
        </>
    )
}
export default RecentDischarges