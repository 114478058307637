import { IonButton, useIonToast, IonLoading, IonContent, IonGrid, IonPage, IonRow, IonText } from "@ionic/react"
import { useState, useEffect, useRef, useContext } from "react";
import ProvidersHeader from "../../../components/providersInvite/providersHeader"
import './index.css';
import CommonContext from "../../../utils/contexts/commonProps";
import instance from "../../../utils/api";
import { ArrowDownCircleFill } from "@styled-icons/bootstrap/ArrowDownCircleFill";
import { ArrowUpCircleFill } from "@styled-icons/bootstrap/ArrowUpCircleFill"
const WelcomeToTNPL: React.FC = () => {
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showToast, dismiss] = useIonToast();
    const [providerId, setProviderId] = useState<any>("");
    const { common, setCommon } = useContext(CommonContext);
    const [providerName, setProviderName] = useState<any>("");
    const [headerTitle, setHeaderTitle] = useState<any>("Your provider")
    const [scrollBottom, setScrollBottom] = useState<any>(false)
    const [scrollTop, setScrollTop] = useState<any>(false)
    const bottomRef = useRef<any>(0);
    const myRef = useRef<any>(0)
    const el = myRef.current;
    useEffect(() => {
        if (el.scrollHeight > el.offsetHeight) {
            setScrollBottom(true)
        }
        
    }, [el.scrollHeight])

    useEffect(() => {
        let path = window.location.href;
        let urlProviderId = path.split("=");
        var proId: any
        var secondId: any
        if (urlProviderId.length === 3) {
            proId = urlProviderId[1].slice(0, -3)
            secondId = urlProviderId[2]
            localStorage.setItem("inviteProId", proId);
            localStorage.setItem("secondId", secondId);
            setProviderId(proId);
            if (providerId) {
                getUserDetails()
            }
        }
        else if (urlProviderId.length === 2) {
            proId = urlProviderId[1]
            localStorage.setItem("inviteProId", proId);
            setProviderId(proId);
            if (providerId) {
                getUserDetails()
            }
        }

    }, [providerId]);

    useEffect(() => {
        localStorage.clear()
    }, [])

    const getUserDetails = async () => {
        setShowLoading(true);
        await instance({
            method: 'GET',
            url: `mybenefitsprovider/provider/${providerId}`,
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp
            if (obj.status === "SUCCESS!") {
                setShowLoading(false);
                setProviderName(obj.result.list[0].providerFirstName);
                common.providerInviteData.providerInviteObj = obj.result.list[0];
            }
            else {
                setShowLoading(false);
                // showToast(obj.error.message, 3000)
            }
        })
    }
    // const onScroll = () => {
    //     const scrollTop = myRef.current.scrollTop
    //     if(scrollTop  > 0){
    //         setScrollBottom(true)
    //     }
    //     else if(scrollTop  === myRef.current.scrollHeight){
    //         setScrollBottom(false)
    //     }

    //     console.log(`onScroll, myRef.scrollTop: ${scrollTop}`, myRef)
    //   }
    return (
        <IonPage className="bg-cnt">
            <ProvidersHeader title={ (headerTitle) + " " + providerName + " " + ("has invited you to myBenefits.ai")}></ProvidersHeader>
            <div ref={myRef} className="bigDiv wel-bg-colr">
                {scrollBottom == true ?
                    <div className="scrol-y-btn">
                        <ArrowDownCircleFill size={30} color="#656363"
                            onClick={() => {
                                myRef.current.scrollIntoView({ behavior: "smooth" });
                                myRef.current.scrollTo({
                                    top: myRef.current.scrollHeight,
                                    behavior: 'smooth',
                                })
                                setScrollBottom(false)
                                setScrollTop(true)
                            }}
                        >
                        </ArrowDownCircleFill></div> : null}
                {scrollTop == true ?
                    <div className="scrol-y-btn">
                        <ArrowUpCircleFill size={30} color="#656363"
                            onClick={() => {
                                myRef.current.scrollIntoView({ behavior: "smooth" });
                                myRef.current.scrollTo({
                                    top: 0,
                                    behavior: 'smooth',
                                })
                                setScrollBottom(true)
                                setScrollTop(false)
                            }}
                        >
                        </ArrowUpCircleFill></div> : null}
                <IonGrid className="hght-100">
                    <div className="hght-100" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <IonRow className="ion-justify-content-center ion-padding tnpltxt">
                            <IonText>Receive care and wellness with easy and flexible Treat Now, Pay Later® plans with myBenefits.ai</IonText>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-padding">
                            <img src={require(`../../../images/icon1.png`).default} className="icon1" alt="" />
                        </IonRow>
                        <IonRow></IonRow>
                        <IonRow className="ion-justify-content-center ion-padding-vertical">
                            <IonButton expand="block" className='provoiders-btn pro-btn-wd provoiders-btn-txt' onClick={() => window.location.href = "/patient_verify"}>Start Application</IonButton>
                        </IonRow>
                    </div>
                </IonGrid>
            </div>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
            />
        </IonPage>
    )
}
export default WelcomeToTNPL