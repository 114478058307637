import {
  IonLoading,
  IonButton,
  useIonToast,
  IonCol,
  IonContent,
  IonToast,
  IonGrid,
  IonSearchbar,
  IonProgressBar,
  IonPage,
  IonRow,
  IonText,
  IonLabel,
  IonModal,
  IonInput,
  IonTextarea,
} from "@ionic/react";
import "./index.css";
import { Formik } from "formik";
import * as yup from "yup";
import ReactTooltip from 'react-tooltip';
import { useState, useEffect, useContext, } from "react";
import { useHistory } from "react-router-dom";
import { KeyboardArrowDown } from "@styled-icons/material/KeyboardArrowDown";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CommonContext from "../../utils/contexts/commonProps";
import moment from "moment";
import instance from "../../utils/api";
import { Dot } from "@styled-icons/bootstrap/Dot";
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline";
import Header from "../../components/Header";
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import EmailInviteSignUp from "../../components/EmailInviteSignUp";
import { usePlaidLink, PlaidLinkOnSuccess } from "react-plaid-link";
import { AlertCircle } from "@styled-icons/ionicons-outline/AlertCircle";
import { Printer } from "@styled-icons/bootstrap/Printer";
import { ChevronRight } from "@styled-icons/boxicons-regular/ChevronRight";
import { UserPlus } from "@styled-icons/boxicons-regular/UserPlus";
import { CloseCircle } from "@styled-icons/ionicons-sharp/CloseCircle";
import NumberFormat from "react-number-format";
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import Print from "../../components/Print";
import { Download } from '@styled-icons/boxicons-solid/Download';
import Loader from "../../components/Loader";

const TreatNowPayLater: React.FC = (props) => {
  const history = useHistory();
  const [linkToken, setLinkToken] = useState(null);
  const [tnplDetailsTable, setTnplDetailsTable] = useState<any>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [TreatmentDetails, setTreatmentDetails] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [tnplList, setTnplList] = useState<any>([]);
  const [addCodes, setCodes] = useState(false);
  const { common, setCommon } = useContext(CommonContext);
  const [tnplDetails, setTnplDetails] = useState<any>();
  const [tnplUserName, setTnplUserName] = useState<any>();
  const isBankAdded = localStorage.getItem("isBankAdded");
  const addBankLater = localStorage.getItem("addBankLater");
  const [showToast, dismiss] = useIonToast();
  const [errorMessage, setErrorMessage] = useState();
  const [showToast1, setShowToast1] = useState(false);
  const [cardsData, setCardsData] = useState<any>([]);
  const [tnplSource, setTnplSource] = useState<any>([]);
  const [providerDetails, setProviderDetails] = useState<any>([]);
  const [filterEmployer, setFilterEmployer] = useState<any>();
  const [activateAccountModal, setActivateAccountModal] = useState(false);
  const providerStatus = localStorage.getItem("providerStatus") || "";
  const [termsCondView, setTermsCondView] = useState(true);
  const [qrModelOpen, setQrModelOpen] = useState(false);
  const [inviteNow, setInviteNow] = useState(false);
  const [inviteChoice, setInviteChoice] = useState(false);
  const [inviteNames, setInviteNames] = useState<any>("");
  const [popoverOpen, setPopoverOpen] = useState<any>();
  const [coachMarkModal, setCoachMarkModal] = useState<any>(false);
  const [modalValue, setModalValue] = useState<any>("")
  var coachMarker = localStorage.getItem("coachMarker") || null


  useEffect(() => {
    let path = window.location.href;
    let urlToken = path.split("?");
    let finalUrlToken = urlToken[1];
    if (finalUrlToken && providerStatus != "dashboard") {
      setActivateAccountModal(true);
      let urlValues = finalUrlToken.split("/");
      instance.defaults.headers.common["Authorization"] = urlValues[0];
      localStorage.setItem("auth_token", urlValues[0]);
      if (urlValues[1] === "self") {
        localStorage.setItem("selfSigned", urlValues[1]);
      } else if (urlValues[1] != "self") {
        localStorage.setItem("inviteId", urlValues[1]);
      }
    } else if (providerStatus != "dashboard") {
      setActivateAccountModal(true);
    } else if (providerStatus === "dashboard") {
      setActivateAccountModal(false);
    }
  }, [providerStatus]);

  useEffect(() => {
    setTnplDetailsTable(false);
    if (isBankAdded === "true" || isBankAdded === "false") {
      localStorage.removeItem("walkInProviderSignUp");
    } else if (addBankLater === "true") {
      localStorage.removeItem("addBankLater");
    }
    TnpList();
  }, [TreatmentDetails.length && tnplSource.length]);

  useEffect(() => {
    getUserDetails();

  }, [providerDetails.length]);

  useEffect(() => {
    if (providerStatus === "invite" && !common.termsCondView) {
      common.progressBar = 0.92;
    } else if (providerStatus === "address") {
      common.progressBar = 0.94;
      common.termsCondView = false;
    } else if (providerStatus === "bank-details") {
      common.progressBar = 0.96;
      common.termsCondView = false;
    }
    else if (providerStatus === "kyp") {
      common.progressBar = 0.98
      common.termsCondView = false
      setCommon(common)
    }
  }, [common.progressBar && common.termsCondView]);

  const getUserDetails = async () => {
    localStorage.removeItem("coachMarker")
    await instance({
      method: "GET",
      url: "user/getUserDetails",
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        setShowLoading(false);
        let data = obj.result[0];
        common.providerData.providerObj = data;
        setCommon(common);
        setProviderDetails(data);
        setPopoverOpen(obj.result[0].coachMarker);
        if (obj.result[0].coachMarker === false) {
          setCoachMarkModal(true)
        }
        if (obj.result[0] && obj.result[0].mybenefitsprovider && obj.result[0].mybenefitsprovider.displayName) {
          localStorage.setItem("providerName", obj.result[0].mybenefitsprovider.displayName);
        }
        else if (obj.result[0] && obj.result[0].mybenefitsprovider && obj.result[0].mybenefitsprovider.providerFirstName) {
          localStorage.setItem("providerName", obj.result[0].mybenefitsprovider.providerFirstName)
        }
        else {
          localStorage.clear()
        }
        localStorage.setItem("providerStatus", obj.result[0].status);
        //localStorage.setItem("providerStatus", 'kyp');
        localStorage.setItem(
          "isBankAdded",
          obj.result[0].mybenefitsprovider.isBankAdded
        );
      } else {
        setShowLoading(false);
      }
    });
  };

  const TnpList = async () => {
    setShowLoading(true);
    await instance({
      method: "GET",
      url: `/PayLater/tnplList`,
      validateStatus: () => true,
    }).then((res) => {
      let obj: any = {};
      obj = res;
      if (obj.status === "SUCCESS!") {
        setCardsData(obj.cards);
        setTnplSource(obj.cards[4]);
        if (obj.result.length > 0) {
          obj.result.map((item: any) => {
            if (item.dateOfService || item.users[0].dob) {
              item.dateOfService = moment(
                parseInt(item.dateOfService)
              ).format("MM/DD/YYYY");
              item.users[0].dob = moment(
                parseInt(item.users[0].dob)
              ).format("MM/DD/YYYY");
            }
            setTnplList(obj.result);
            setTreatmentDetails(obj.result);
          })
          setShowLoading(false);
        }
      } else {
        setShowLoading(false);
        // showToast(obj.error.message, 3000)
      }
    });
  };

  const dissmissQrModel = () => {
    setQrModelOpen(false)
  }
  // const generateToken = async () => {
  //   await instance({
  //     method: "GET",
  //     url: "mybenefitsprovider/linkToken",
  //     validateStatus: () => true
  //   }).then((resp) => {
  //     let obj: any = {}
  //     obj = resp
  //     console.log(obj)
  //     setLinkToken(obj.result.link_token);
  //   })
  // };

  // useEffect(() => {
  //   if (isBankAdded === "false") {
  //     generateToken();
  //   }
  // }, [isBankAdded]);

  // const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
  //   let metaKeys: any;
  //   metaKeys = metadata
  //   let data = {
  //     publicToken: publicToken,
  //     accountId: metaKeys.account_id,
  //     bankName: metaKeys.institution.name
  //   }
  //   instance({
  //     method: "PUT",
  //     url: "mybenefitsprovider/getAccessToken",
  //     data,
  //     validateStatus: () => true
  //   }).then((resp) => {
  //     let obj: any = {};
  //     obj = resp;
  //     if (obj.status === "SUCCESS!") {
  //       window.location.href = "/dashboard"
  //     }
  //   })
  // }, []);

  // const { open, ready } = usePlaidLink({
  //   token: linkToken,
  //   onSuccess,
  // })

  const searchlist = (e) => {

    setSearchText(e.detail.value);
    if (e.detail.value.length >= 6) {
      setCodes(true);
    } else {
      setCodes(false);
    }
    // const orginal = TreatmentDetails
    // if (e.detail.value.length > 0) {
    //   let filterdata = orginal.filter((item) => {
    //     let query = (e.detail.value).toLowerCase()
    //     return (
    //       String(item.digitCode).toLowerCase().indexOf(query) >= 0 ||
    //       item.status.toLowerCase().indexOf(query) >= 0 ||
    //       item.users[0].firstName.toLowerCase().indexOf(query) >= 0 ||
    //       item.users[0].lastName.toLowerCase().indexOf(query) >= 0
    //     )
    //   })
    //   setTnplList(filterdata);
    //   if (filterdata.length === 0) {
    //     setCodes(true);
    //   }
    // } else {
    //   setCodes(false);
    //   setTnplList(orginal)
    // }
  };

  const navToCodes = async (code: any) => {
    setShowLoading(true);
    if (searchText.length === 6) {
      await instance({
        method: "GET",
        url: `PayLater/searchloanDetails/${code}`,
        validateStatus: () => true,
      }).then((response) => {
        let obj: any = {};
        obj = response;
        if (obj.status === "SUCCESS!") {
          let data = obj.result;
          common.patientData.patientObj = data;
          setCommon(common);
          setShowLoading(false);
          history.push(
            `/patient_procedure_code/${common.patientData.patientObj.digitCode}`
          );
        } else if (obj.status === "ERROR!") {
          setShowLoading(false);
          setErrorMessage(obj.error.message);
          setShowToast1(true);
        }
      });
    }
    else {
      setShowLoading(false);
      showToast("Please Enter 6 Digit Token", 3000)
    }
  };
  const navToBank = (e: any, value) => {
    e.stopPropagation();
    setTnplDetailsTable(false);
    common.patientData.patientObj = value;
    setCommon(common);
    window.location.href = "/provider_bank_details";
  };

  const tnplGetDetails = async (e, id: any, value: any) => {
    e.stopPropagation();
    setModalValue(value)
    setTnplDetails(id);
    setTnplDetailsTable(true);
    // setShowLoading(true);
    // await instance({
    //   method: "GET",
    //   url: `PayLater/tnplRequestcodes/${id}`,
    //   validateStatus: () => true,
    // }).then((response) => {
    //   let obj: any = {};
    //   obj = response;
    //   if (obj.status === "SUCCESS!") {
    //     setTnplDetails(obj.result.list);
    //     setTnplDetailsTable(true);
    //     setTnplUserName(obj.result.name);
    //     setShowLoading(false);
    //   } else if (obj.status === "ERROR!") {
    //     setShowLoading(false);
    //     showToast(obj.error.message, 3000);
    //   }
    //});
  };
  // const handleChange = async (employer: any) => {
  //   setShowLoading(true);
  //   if (employer.value === "ALL") {
  //     TnpList();
  //   } else {
  //     await instance({
  //       method: "GET",
  //       url: `PayLater/tnpllist?employer=${employer.value}`,
  //       validateStatus: () => true,
  //     }).then((res) => {
  //       let obj: any = {};
  //       obj = res;
  //       if (obj.status === "SUCCESS!") {
  //         if (obj.result.length > 0) {
  //           setTnplList(obj.result);
  //           setShowLoading(false);
  //         }
  //       } else {
  //         setShowLoading(false);
  //         showToast(obj.error.message, 3000);
  //       }
  //     });
  //   }
  // };
  // let options =
  //   tnplSource &&
  //   tnplSource.employers &&
  //   tnplSource.employers.length > 0 &&
  //   tnplSource.employers.map(function (item) {
  //     return {
  //       value: item && item._id,
  //       label: `${item && item.employer[0].name} ($${item.totalAmount})`,
  //     };
  //   });

  // const data = [{ label: "ALL", value: "ALL" }, { options }];
  const dismissModal = () => {
    setActivateAccountModal(false);
  };
  const searchKeyEvent = (event: any) => {
    if (event.keyCode == 13) {
      event.preventDefault();
      if (searchText.length >= 6) {
        navToCodes(searchText);
      } else {
        showToast("Please Enter 6 Digit Code", 3000)
        setShowLoading(false);
      }
    }
    var ASCIICode = (event.which) ? event.which : event.keyCode;
    if ((ASCIICode >= 7 && ASCIICode <= 57) || (ASCIICode >= 96 && ASCIICode <= 105)) {
      return true;
    }
    else {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  };
  const chooseInvite = async (value: any) => {
    setInviteChoice(true)
    if (value == "patient") {
      setInviteNames("Invite Patient");
    }
    else if (value == "provider") {
      setInviteNames("Invite Provider")
    }
  }

  const dissmissInviteModal = () => {
    setInviteNow(false);
    setInviteChoice(false);
  }
  const viewImage = (data: any) => {
    setTnplDetailsTable(false)
    var image = new Image();
    image.src = data;
    var w = window.open(data);
    w?.document.write(`<!DOCTYPE html><head><title>preview</title></head>
   <body style="margin:0px;width:100%;height:100vh;display:flex;flex-direction:column;align-items:center;background-color: #171717;">
   <img src=${data} style="padding-top:8px;object-fit:contain;
   object-fit: contain;
    height: 97%;
    width: 768px;
    max-width: calc(100% - 16px - 16px);
    z-index: 1;"
    ></body></html>`);
  }
  const inviteApiCall = async (values: any) => {
    if (values.phoneNumber || values.email) {
      setShowLoading(true)
      let data: any = {}
      if (inviteNames === "Invite Patient") {
        data.type = "Patient"
      }
      else {
        data.type = "Provider"
      }
      if (values.phoneNumber && !values.email) {
        data.phoneNumber = values.phoneNumber
      }
      else if (values.email && !values.phoneNumber) {
        data.email = values.email
      }
      else if (values.phoneNumber && values.email) {
        data.phoneNumber = values.phoneNumber
        data.email = values.email
      }
      await instance({
        method: "POST",
        url: 'user/invite',
        data,
        validateStatus: () => true,
      }).then((res) => {
        let obj: any = {};
        obj = res;
        if (obj.status === "SUCCESS!") {
          setShowLoading(false);
          setInviteNow(false)
          showToast(obj.message, 3000);
        } else {
          setShowLoading(false);
          showToast(obj.error.message, 3000);
        }
      });
    }
    else {
      showToast("Please Enter Phone Number or Email", 3000)
    }
  }
  const toDataURL = (url: any) => {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  }
  const download = async (e: any, value: any) => {
    const a = document.createElement("a");
    a.href = await toDataURL(value);
    a.download = "image.jpg";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const validationSchema = yup.object({
    phoneNumber: yup.string()
      .min(10, 'Phone Number must be 10 digits.'),
    email: yup.string().email('Invalid email')
  });
  const coachApiCall = async () => {
    let data: any = {}
    data.coachMarker = true
    setShowLoading(true)
    await instance({
      method: 'PUT',
      url: `mybenefitsprovider/updateprovider`,
      data,
      validateStatus: () => true,
    }).then((res) => {
      let obj: any = {};
      obj = res;
      if (obj.status === "SUCCESS!") {
        setShowLoading(false)
        getUserDetails()
      }
      else {
        setShowLoading(false);
        showToast(obj.error.message, 3000);
      }
    })
  }

  const searchAlllist = (e: any) => {
    setFilterEmployer(e.detail.value)
    const orginal = TreatmentDetails
    if (e.detail.value.length > 0) {
      let filterdata = TreatmentDetails.filter((item: any) => {
        let query = (e.detail.value).toLowerCase()
        return (
          String(item.digitCode).toLowerCase().includes((query)) ||
          item.status.toLowerCase().includes(query) ||
          item.users[0].firstName.toLowerCase().includes(query) ||
          item.users[0].lastName.toLowerCase().includes(query) ||
          String(item.payoutAmount).toLowerCase().includes(query) ||
          item.paymentType.toLowerCase().includes(query) ||
          item.dateOfService.toLowerCase().includes(query) ||
          item.users[0].dob.toLowerCase().includes(query) ||
          item.loanId.toLowerCase().includes(query)
        )
      })
      setTnplList(filterdata);
    } else {
      setTnplList(orginal)
    }
  }

  const messages = async (value: any) => {
    let data: any = {}
    data.id = tnplDetails._id
    data.message = value.message
    setShowLoading(true)
    await instance({
      url: "PayLater/message",
      method: "PUT",
      data,
      validateStatus: () => true
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        setShowLoading(false);
        showToast(obj.message, 3000);
        setTnplDetailsTable(false)
      } else {
        setShowLoading(false);
        showToast(obj.error, 3000);
      }
    })
  }
  return (
    <IonPage className="box-shaw">

      {/* <Header popover={popoverOpen}></Header> */}
      {popoverOpen && popoverOpen === true ?
        <Header popover={popoverOpen} unreadCount={0}></Header> : ""}

      <IonContent
        fullscreen
        className="bgg"
        scrollY={true}
      >
        {popoverOpen === undefined || popoverOpen === false ?
          <Header popover={popoverOpen} unreadCount={0}></Header> : ""}

        <IonGrid style={{ padding: "0px" }}>
          <div className="row1">
            <IonGrid className="grid">
              <IonRow>
                <IonCol className="pad-0">
                  <IonRow className="ion-justify-content-between ion-align-items-center">
                    <div className="ion-padding-vertical">
                      <IonText className="mydash-txt">My Dashboard</IonText>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      {providerStatus != "dashboard" ? (
                        <div className="profileincom pointer" onClick={() => {
                          setActivateAccountModal(true);
                        }}>
                          {providerStatus === "dashboard" ? "" :
                            <IonCol className="ion-no-padding">
                              <div className="mydash-txt flex-row" style={{ fontWeight: "400", fontSize: "20px", lineHeight: "normal" }}><IonText>
                                {providerStatus === "invite" && common.termsCondView
                                  ? "90%"
                                  : ""}
                                {providerStatus === "invite" &&
                                  common.progressBar == 0.92 &&
                                  !common.termsCondView
                                  ? "92%"
                                  : ""}
                                {providerStatus === "address" || common.progressBar == 0.94
                                  ? "94%"
                                  : ""}
                                {providerStatus === "bank-details" ||
                                  common.progressBar == 0.96
                                  ? "96%"
                                  : ""}
                                {providerStatus === "kyp" ||
                                  common.progressBar == 0.98
                                  ? "98%"
                                  : ""}
                                {" "}{" "}
                                of Profile Completed
                              </IonText>
                                <ChevronRight size="30" color="#FFFFF" />
                              </div>
                              <div className="mydash-txt" style={{ fontWeight: "400", fontSize: "15px", lineHeight: "normal" }}>To receive payments, complete your profile.</div>
                              <div className="ion-padding-top progressbar-clr">
                                <IonProgressBar
                                  className="progressbar-clr"
                                  value={common.progressBar}
                                  style={{ height: "10px" }}
                                ></IonProgressBar>
                              </div>
                            </IonCol>}
                        </div>
                      ) : null}
                      <div className="ion-text-center pointer ion-padding-vertical" style={{ margin: "0px 15px" }} onClick={() => {
                        setQrModelOpen(true);
                      }}>
                        <div>
                          <Printer title="printer" size="30" color="white" />
                        </div>
                        <div>
                          <IonText className="card-status-txt clr-white" style={{ color: "white" }}>Print Invite</IonText>
                        </div>
                      </div>
                    </div>
                  </IonRow>

                  <IonRow className="ion-justify-content-between ion-wrap">
                    <div className="pro-card">
                      <div className="flex-col">
                        <div className="flex-row ion-justify-content-start">
                          <IonText className="card1-title-txt fnt-fm-robot">Treat Now, Pay Later</IonText>
                          <AlertCircle className="alerticon" size="30" color="#00B9CF" data-tip="Providers can provide treatment without any hassle of collection and worry. Payment will be made to the provider's bank account within 24 hours. Patients can get easy financing through Treat Now, Pay Later for any treatment with any provider" data-for='paylater'></AlertCircle>
                          {(popoverOpen === false) && (coachMarker === "true") ?
                            <div className="coach-mark">
                              <div className="arrow">
                                <div className="point"></div>
                                <div className="curve"></div>
                              </div>
                              <div className="modal-content">
                                <div className="container">
                                  <p>Your Financial Metrics</p>
                                </div>
                              </div>
                            </div> : ""
                          }
                        </div>

                        <ReactTooltip backgroundColor="white" className="tooltip" place="bottom" textColor="black" id='paylater' />
                        <IonRow className="card-sub-txt fnt-fm-robot"><IonText>A Pre-approved 0% interest loans for patients</IonText></IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-between">
                          <div>
                            <div className="card-amount-txt fnt-fm-robot">
                              <IonText> $
                                {cardsData && cardsData[0] && cardsData[0].totalAmount
                                  ? (cardsData[0].totalAmount).toFixed(2)
                                  : "0.00"}</IonText>
                            </div>
                            <div>
                              <IonText className="card-status-txt fnt-fm-robot">Treatment Approved</IonText>
                            </div>
                          </div>
                          <div>
                            <div>
                              <IonText className="card-amount-txt fnt-fm-robot"> $
                                {cardsData && cardsData[0] && cardsData[0].paid
                                  ? (cardsData[0].paid).toFixed(2)
                                  : "0.00"}</IonText>
                            </div>
                            <div>
                              <IonText className="card-status-txt fnt-fm-robot">Payment Received</IonText>
                            </div>
                          </div>
                        </IonRow>
                      </div>
                    </div>
                    <div className="pro-card">
                      <div className="flex-col">
                        <div className="flex-row ion-justify-content-start">
                          <IonText className="card1-title-txt fnt-fm-robot">Patient’s Requests</IonText>
                          <AlertCircle className="alerticon" size="30" color="#00B9CF" data-tip="Patients / Employees can invite providers for new treatments for any wellness care.  To measure the success of treatment plans, providers will see the number of requests and approved treatment plans." data-for='requests'></AlertCircle>
                          {(popoverOpen === false) && (coachMarker === "true") &&
                            <div className="coach-mark bg-trans">
                              <div className="arrow">
                                <div className="point"></div>
                                <div className="curve"></div>
                              </div>
                              <div className="modal-content">
                                <div className="container">
                                  <p>View total patient loan request received and serviced</p>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                        <div>
                          <ReactTooltip backgroundColor="white" className="tooltip" place="bottom" textColor="black" id='requests' />
                        </div>
                        <IonRow className="card-sub-txt fnt-fm-robot"><IonText>Treat Now, Pay Later Requests by patients</IonText></IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-between">
                          <div>
                            <div className="card-amount-txt fnt-fm-robot">
                              <IonText>{cardsData && cardsData[3] && cardsData[3].requests
                                ? cardsData[3].requests
                                : "0"}</IonText>
                            </div>
                            <div>
                              <IonText className="card-status-txt fnt-fm-robot">New Request</IonText>
                            </div>
                          </div>
                          <div>
                            <div>
                              <IonText className="card-amount-txt">{cardsData && cardsData[3] && cardsData[3].approved
                                ? cardsData[3].approved
                                : "0"}</IonText>
                            </div>
                            <div>
                              <IonText className="card-status-txt fnt-fm-robot">Treatment Completed</IonText>
                            </div>
                          </div>
                        </IonRow>
                      </div>
                    </div>
                    <div className="pro-card">
                      <div className="flex-col">
                        <div className="flex-row ion-justify-content-start">
                          <IonText className="card1-title-txt fnt-fm-robot">Invite</IonText>
                          <AlertCircle className="alerticon" size="30" color="#00B9CF" data-tip="Providers can invite other Providers friends and earn treat coins. Providers can invite patients and earn treat coins. Providers can redeem every treat coin they earn monthly into their accounts." data-for='invite'></AlertCircle>
                          {(popoverOpen === false) && (coachMarker === "true") &&
                            <div className="coach-mark bg-trans">
                              <div className="arrow">
                                <div className="point"></div>
                                <div className="curve"></div>
                              </div>
                              <div className="modal-content">
                                <div className="container">
                                  <p>Invite your patient and provider friends to join Treat Now, Pay Later and earn treat coins</p>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                        <ReactTooltip backgroundColor="white" className="tooltip" place="bottom" textColor="black" id='invite' />
                        <IonRow className="card-sub-txt"><IonText>Send Invites to join Treat Now Pay Later</IonText></IonRow>
                        <IonRow className="ion-padding-top ion-justify-content-between">
                          <IonRow>
                            <div className="brder-rt">
                              <div className="card-amount-txt">
                                <IonText>{cardsData && cardsData[5] && cardsData[5].patientInvites
                                  ? cardsData[5].patientInvites
                                  : "0"}</IonText>
                              </div>
                              <div>
                                <IonText className="card-status-txt">Patient <br></br>Invites</IonText>
                              </div>
                            </div>
                            <div style={{ paddingLeft: "15px" }}>
                              <div className="card-amount-txt">
                                <IonText>{cardsData && cardsData[5] && cardsData[5].providerInvites
                                  ? cardsData[5].providerInvites
                                  : "0"}</IonText>
                              </div>
                              <div>
                                <IonText className="card-status-txt">Provider <br></br> Invites</IonText>
                              </div>
                            </div>
                          </IonRow>
                          <div className="pointer" onClick={() => setInviteNow(true)}>
                            <div className="userplus">
                              <UserPlus title="userPlus" size="40" />
                            </div>
                            <div style={{ marginTop: "8px" }}>
                              <IonText className="card-status-txt">Invite Now</IonText>
                            </div>
                          </div>
                        </IonRow>
                      </div>
                    </div>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          {providerStatus != "invite" ? (
            <IonGrid style={{ padding: "0px" }} className="grid">
              <IonRow className="center-row">
                <IonRow className="ion-align-items-center ion-padding-bottom">
                  <IonCol sizeXs="12" size="7" sizeLg="7">
                    {/* {tnplDetailsTable ? null : ( */}
                    <div>
                      <div className="flex-col">
                        <IonLabel className="search-label">
                          Loan Lookup
                        </IonLabel>
                        {(popoverOpen === false) && (coachMarker === "true") &&
                          <div className="coach-mark bg-trans">
                            <span className="arrow arrow-top-15">
                              <div className="point"></div>
                              <div className="curve"></div>
                            </span>
                            <div className="modal-content">
                              <div className="container pad-0">
                                <p>Accept loans from patients by entering a 6 digit code unique for each loan</p>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      <div
                        className="mg-top"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <div className="searchbar">
                          <IonSearchbar
                            onKeyDown={(event) => searchKeyEvent(event)}
                            mode="md"
                            searchIcon="none"
                            className="search-placeholder-txt  searchbar-pad0"
                            placeholder="Enter the 6 digit Token"
                            value={searchText}
                            type="text"
                            onIonChange={(e) => searchlist(e)}
                          ></IonSearchbar>
                        </div>
                        {addCodes === true && (
                          <div>
                            <div
                              className="arrowfor pointer"
                              onClick={() => navToCodes(searchText)}
                            >
                              <ArrowIosForwardOutline
                                color="white"
                                type="ArrowIosForwardOutline"
                                size="30"
                              ></ArrowIosForwardOutline>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* )} */}
                  </IonCol>
                  <IonCol sizeXs="12" size="5" sizeLg="5">
                    <div className="flex-col">
                      <IonLabel className="search-label">
                        Search Transaction
                      </IonLabel>
                    </div>

                    {/* <Select
                      className="mg-top"
                      defaultValue={{ label: "ALL", value: "ALL" }}
                      value={filterEmployer}
                      placeholder="Select"
                      onChange={(value) => handleChange(value)}
                      options={data}
                    /> */}
                    <div className="searchbar mg-top">
                      <IonSearchbar
                        mode="md"
                        searchIcon="none"
                        className="search-placeholder-txt  searchbar-pad0"
                        placeholder="Enter Token, Patient or Payment Information"
                        value={filterEmployer}
                        type="text"
                        onIonChange={(e) => searchAlllist(e)}
                      ></IonSearchbar>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow style={{ width: "100%" }} className="tnplrow">
                  <div className="tnplheader">
                    <IonText className="search-label">Transactions</IonText>
                  </div>
                  <div
                    className="ovrflow"
                    style={{ width: "100%" }}
                  >
                    <table className="table-container">
                      <thead className="patient-thread">
                        <tr>
                          <th className="patient-th pd-lr-24 whitespace">
                            <div>
                              <span>Loan ID</span>
                              <span className="icon-pd-lt">
                                <KeyboardArrowDown
                                  title="KeyboardArrowDown"
                                  size="25"
                                  color="#c7c4c3"
                                />
                              </span>
                            </div>
                          </th>
                          <th className="patient-th pd-lr-24 whitespace">
                            <div>
                              <span>Name</span>
                              <span className="icon-pd-lt">
                                <KeyboardArrowDown
                                  title="KeyboardArrowDown"
                                  size="25"
                                  color="#c7c4c3"
                                />
                              </span>
                            </div>
                          </th>
                          <th className="patient-th pd-lr-24 whitespace">
                            <div>
                              <span>Date of birth</span>
                              <span className="icon-pd-lt">
                                <KeyboardArrowDown
                                  title="KeyboardArrowDown"
                                  size="25"
                                  color="#c7c4c3"
                                />
                              </span>
                            </div>
                          </th>
                          <th className="patient-th pd-lr-24 whitespace">
                            {" "}
                            <div>
                              <span>Date of service</span>
                              <span className="icon-pd-lt">
                                <KeyboardArrowDown
                                  title="KeyboardArrowDown"
                                  size="25"
                                  color="#c7c4c3"
                                />
                              </span>
                            </div>
                          </th>
                          <th className="patient-th pd-lr-24 whitespace">
                            {" "}
                            <div>
                              <span>Paid</span>
                              <span className="icon-pd-lt" >
                                <KeyboardArrowDown
                                  title="KeyboardArrowDown"
                                  size="25"
                                  color="#c7c4c3"
                                />
                              </span>
                            </div>
                          </th>
                          <th className="patient-th pd-lr-24 whitespace">
                            {" "}
                            <div>
                              <span>Payment Status</span>
                              <span className="icon-pd-lt">
                                <KeyboardArrowDown
                                  title="KeyboardArrowDown"
                                  size="25"
                                  color="#c7c4c3"
                                />
                              </span>
                            </div>
                          </th>
                          <th className="patient-th pd-lr-24 whitespace" style={{ textAlign: "center" }}>
                            {" "}
                            <div>
                              <span>Type</span>
                            </div>
                          </th>
                          <th className="patient-th pd-lr-24 whitespace" style={{ textAlign: "center" }}>
                            {" "}
                            <div>
                              <span>Send Message</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="patient-tbody">
                        {tnplList &&
                          tnplList.map((item: any, key: any) => {
                            return (
                              <tr
                                key={key}
                                className="patient-tr"
                                onClick={(e) => tnplGetDetails(e, item, "transactionDetails")}
                              >
                                <td className="td-txt pd-lr-24">
                                  {item && item.loanId}
                                </td>
                                <td className="username-color">
                                  {item && item.users[0].firstName}{" "}
                                  {item && item.users[0].lastName}
                                </td>

                                <td className="td-txt pd-lr-24">
                                  {item && item.users[0] && item.users[0].dob}

                                </td>
                                <td className="td-txt pd-lr-24">
                                  {item && item.dateOfService
                                    ? item.dateOfService
                                    : "NA"}
                                </td>
                                <td className="td-txt pd-lr-24">
                                  ${item && item.payoutAmount.toFixed(2)}
                                </td>
                                <td className="td-txt pd-lr-24">
                                  {isBankAdded == "false" ? (
                                    <IonButton
                                      className="addbank-btn"
                                      onClick={(e) => navToBank(e, item)}
                                    >
                                      Add Bank Account
                                    </IonButton>
                                  ) : (
                                    <div className="dot-flex">
                                      <div>
                                        <Dot
                                          title="Dot"
                                          size="30"
                                          color={
                                            (item.status == "In progress" || item.status == "Payment Initiated" || item.status == "Payment Processed")
                                              ? "#1ABCFE"
                                              : item.status ==
                                                "Completed"
                                                ? "#0ACF83"
                                                : item.status == "Pending"
                                                  ? "#F55B5D"
                                                  : ""
                                          }
                                        />
                                      </div>
                                      <div>{item.status}</div>
                                    </div>
                                  )}
                                </td>
                                <td className="td-txt pd-lr-24" style={{ textAlign: "center" }}>
                                  {item && item.paymentType}
                                </td>
                                <td className="td-txt pd-lr-24" style={{ textAlign: "center" }}><img src={require('../../images/message.png').default} className="msg-img" onClick={(e) => tnplGetDetails(e, item, "messages")} /></td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {/* )} */}
                  </div>

                </IonRow>
              </IonRow>
            </IonGrid>
          ) : null}

        </IonGrid>
        <IonModal isOpen={tnplDetailsTable} onDidDismiss={() => setTnplDetailsTable(false)} className="modal-ht">
          <IonContent>
            <div style={{ display: "flex", flexDirection: "column" }} className="popup-header">
              <IonRow className="close pointer" onClick={() => setTnplDetailsTable(false)}>
                <CloseOutline title="Close" size={30} color="#FFFFFF"></CloseOutline>
              </IonRow>
              <IonRow className="ion-justify-content-center popup-title ion-padding" style={{ fontSize: "30px" }}><IonText>
                {modalValue === "messages" ? `Send Message to ${tnplDetails.users[0].firstName} ${tnplDetails.users[0].lastName}` :
                  "Transaction Details"}</IonText></IonRow>
            </div>
            {modalValue === "messages" ?
              <>
                <Formik initialValues={{ message: "" }} onSubmit={messages} validationSchema={
                  yup.object({
                    message: yup.string()
                      .required("This field is required")
                  })
                }>{formikProps => (
                  <form onSubmit={formikProps.handleSubmit}>
                    <IonRow style={{ maxWidth: '430px', margin: '0 auto' }}>
                      <IonCol className="fields-row-pd">
                        <IonRow className='ion-padding-top'>
                          <IonCol className="pad-0">
                            <IonLabel className='label'>Enter Message</IonLabel>
                            <IonTextarea name="message" placeholder="Message" className="msg-txt-area"
                              onBlur={formikProps.handleBlur}
                              onIonChange={formikProps.handleChange}
                              value={formikProps.values.message}
                              maxlength={250}
                            >
                            </IonTextarea>
                            <p className="fielderrormsg" style={{ marginBottom: "0px" }}>
                              {formikProps.touched.message && formikProps.errors.message}
                            </p>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-vertical ion-justify-content-center'>
                          <IonButton className='btn-wd-100 nextbtn' type="submit">Send</IonButton>
                        </IonRow>

                      </IonCol>
                    </IonRow>
                  </form>
                )}
                </Formik>
              </>
              : <>
                <IonRow className="ion-margin-vertical ion-padding-vertical ion-justify-content-center">
                  <table className="tnplDetails-tb">
                    <thead>
                      <tr className="tr-ht-30">
                        <td className="patient-th">Loan ID</td>
                        <td className="td-txt tb-td-txt">{tnplDetails && tnplDetails.loanId ? tnplDetails.loanId : "NA"}</td>
                      </tr>
                      <tr className="tr-ht-30">
                        <td className="patient-th">Patient Name</td>
                        <td className="td-txt tb-td-txt">{tnplDetails && tnplDetails.users[0] ? `${tnplDetails.users[0].firstName} ${tnplDetails.users[0].lastName}` : "NA"}</td>
                      </tr>
                      <tr className="tr-ht-30">
                        <td className="patient-th">DOB</td>
                        <td className="td-txt tb-td-txt">{tnplDetails && tnplDetails.users[0] ? tnplDetails.users[0].dob : "NA"}</td>
                      </tr>
                      <tr className="tr-ht-30">
                        <td className="patient-th">Date of Service</td>
                        <td className="td-txt tb-td-txt">{tnplDetails && tnplDetails.dateOfService ? tnplDetails.dateOfService : "NA"}</td>
                      </tr>
                      <tr className="tr-ht-30">
                        <td className="patient-th">Payment Status</td>
                        <td className="td-txt tb-td-txt">{tnplDetails && tnplDetails.status ? tnplDetails.status : "NA"}</td>
                      </tr>
                      {tnplDetails && tnplDetails.paymentType != "Walk-in" ? "" :
                        <tr className="tr-ht-30">
                          <td className="patient-th">Token</td>
                          <td className="td-txt tb-td-txt">{tnplDetails && tnplDetails.digitCode ? tnplDetails.digitCode : "NA"}</td>
                        </tr>}
                      <tr className="tr-ht-30">
                        <td className="patient-th">Payment Date</td>
                        <td className="td-txt tb-td-txt">{tnplDetails && tnplDetails.dateOfAccept ? moment(parseInt(tnplDetails.dateOfAccept)).format("MM/DD/YYYY") : "NA"}</td>
                      </tr>
                      {tnplDetails && tnplDetails.paymentType == "Scan and Pay" &&
                        <>
                          <tr className="tr-ht-30">
                            <td className="patient-th">Invoice Number</td>
                            <td className="td-txt tb-td-txt">{tnplDetails && tnplDetails.invoiceNumber ? tnplDetails.invoiceNumber : "NA"}</td>
                          </tr>
                          <tr className="tr-ht-30">
                            <td className="patient-th">Invoice</td>
                            <td className="td-txt tb-td-txt">
                              <div style={{ display: "flex", alignItems: "center" }}>

                                {tnplDetails && tnplDetails.invoiceImage ?
                                  <>
                                    <span style={{ color: "#00B9CF", textDecoration: "underline" }} className="td-txt pointer" onClick={() => viewImage(tnplDetails && tnplDetails.invoiceImage)}>View</span>
                                    <a style={{ paddingLeft: "7px" }} href={tnplDetails && tnplDetails.invoiceImage}>Download</a>
                                  </> : "None"
                                }

                                {/* <span style={{ paddingLeft: "7px", color: "#00B9CF", textDecoration: "underline" }} className="td-txt pointer" onClick={(e) => download(e, tnplDetails && tnplDetails.invoiceImage)}> {tnplDetails && tnplDetails.invoiceImage ? "Download" : ""}</span> */}
                              </div>
                            </td>
                          </tr>
                        </>
                      }
                    </thead>
                  </table>
                </IonRow>
                <IonRow className='ion-padding-vertical ion-justify-content-center'>
                  <IonButton className='btn-wd-100 nextbtn' onClick={() => setTnplDetailsTable(false)}>Close</IonButton>
                </IonRow>
              </>}
          </IonContent>
        </IonModal>
        <IonModal
          id="qr-modal"
          isOpen={qrModelOpen}
          onDidDismiss={() => dissmissQrModel()}

        >
          <Print qrCode={providerDetails.mybenefitsprovider} dissmissQrModal={dissmissQrModel}></Print>
        </IonModal>
        {providerStatus != "dashboard" && (
          <IonModal
            backdropDismiss={
              providerStatus === "invite" || providerStatus === "address"
                ? false
                : true
            }
            className="signup-modal"
            isOpen={activateAccountModal}
            onDidDismiss={() => dismissModal()}
          >
            <IonContent>
              <EmailInviteSignUp
                dismissModal={dismissModal}
              ></EmailInviteSignUp>
            </IonContent>
          </IonModal>
        )}

        <IonModal isOpen={inviteNow} onDidDismiss={() => dissmissInviteModal()} className="invitenow-modal">
          <IonRow className="close pointer" onClick={() => dissmissInviteModal()}>
            <CloseCircle title="closeCircle" color="white" size="40" />
          </IonRow>

          <div style={{ height: "100%", background: "white", maxWidth: "470px", borderRadius: "18px", overflowY: "auto" }}>
            {inviteChoice === true ?
              <IonGrid>
                <IonRow className="ion-justify-content-center ion-padding invitetitle">
                  <IonText>{inviteNames}</IonText>
                </IonRow>

                <Formik
                  initialValues={{
                    phoneNumber: "",
                    email: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={inviteApiCall}>
                  {formikProps => (
                    <div>
                      <form onSubmit={formikProps.handleSubmit} id="form1">
                        <IonRow className="ion-padding pd-lrt-2r">
                          <IonCol className="pad-0">
                            <IonLabel className='label'>Enter Phone Number</IonLabel>
                            <div className="input-field number-field placeholder-txt num-format-wd-100">
                              <NumberFormat
                                placeholder="Enter Number"
                                name="phoneNumber"
                                className="num-for-br-no"
                                format="(###) ###-####"
                                value={formikProps.values.phoneNumber}
                                // onChange={formikProps.handleChange}
                                onValueChange={val => formikProps.setFieldValue("phoneNumber", val.value)}
                                onBlur={formikProps.handleBlur}
                              />
                              {formikProps.values.phoneNumber && formikProps.touched.phoneNumber && !formikProps.errors.phoneNumber ?
                                <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                            </div>

                            <p className="fielderrormsg ion-no-margin error-ft">
                              {formikProps.touched.phoneNumber && formikProps.errors.phoneNumber}
                            </p>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center"><IonText className='label'>or</IonText></IonRow>
                        <IonRow className="ion-padding pd-lrt-2r">
                          <IonCol className="pad-0">
                            <IonLabel className='label'>Enter Email</IonLabel>
                            <IonInput type="text" name="email" className="input-field mg-top placeholder-txt"
                              placeholder="Enter Email" value={formikProps.values.email.trim()}
                              onBlur={formikProps.handleBlur}
                              onIonChange={formikProps.handleChange}
                            > {formikProps.values.email && formikProps.touched.email && !formikProps.errors.email ?
                              <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}</IonInput>
                            {formikProps.touched.email && formikProps.errors.email ? (
                              <p className="fielderrormsg ion-no-margin error-ft">{formikProps.errors.email}</p>
                            ) : null}
                          </IonCol>
                        </IonRow>
                        <IonButton expand="block" type="submit" className='searchbtn invite-btn searchbtn-txt'>Invite</IonButton>
                      </form>
                    </div>
                  )}
                </Formik>
              </IonGrid>
              :
              <IonGrid>
                <IonRow className="ion-justify-content-center ion-padding invitetitle">
                  <IonText>Who you want to invite</IonText>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-padding">
                  <div className="invboxs pointer" onClick={() => { chooseInvite("patient") }}>
                    <div><img src={require(`../../images/patient.png`).default} className="invimg" /></div>
                    <div className="invnme">Patient</div>
                  </div>
                  <div className="invboxs pointer" onClick={() => { chooseInvite("provider") }}>
                    <div><img src={require(`../../images/provider.png`).default} className="invimg" /></div>
                    <div className="invnme">Provider</div>
                  </div>
                </IonRow>
              </IonGrid>}
          </div>
        </IonModal>
        <IonModal isOpen={coachMarkModal && popoverOpen !== true && coachMarker != null} id="coach-modal-btn" showBackdrop={false} backdropDismiss={false}>
            <IonContent>
              <IonButton className="get-btn" onClick={() => coachApiCall()}>Get Started</IonButton>
            </IonContent>
          </IonModal>
        {showLoading && <Loader />}
        <IonToast
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message={errorMessage}
          duration={3000}
        />
      </IonContent>
    </IonPage >
  );
};

export default TreatNowPayLater;
