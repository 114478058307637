import { IonCol, IonGrid, IonRow, IonLabel, IonContent, IonHeader, IonItem, IonList, IonPage, IonText, IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack';
const NotFoundPage: React.FC = () => {

    return (
        <IonPage>
            <IonHeader style={{ zIndex: '99999' }} className="header-box">
                <IonGrid className='pad-0'>
                    <IonRow className='header'>
                        <IonRow>
                            <IonButtons onClick={() => { window.location.href = "/provider_login" }} className="pointer">
                                <ArrowBack title="Close" size={30} color="#3DC2FF"></ArrowBack>
                            </IonButtons>
                            <IonTitle style={{color:'black'}}>Not Found</IonTitle>
                        </IonRow>
                    </IonRow>
                </IonGrid>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow className='ion-padding'>
                        <IonCol>
                        <div style={{ display: 'flex', flexDirection: "column" }}>
                            <p style={{ color: '#1A1A1A' }}>Sorry</p>
                            <p style={{ marginTop: '0px', color: '#1A1A1A' }}>Requested content not found.</p>
                        </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}
export default NotFoundPage;