import {
    IonContent, IonModal, IonLoading, useIonToast, IonPage, IonGrid, IonRow, IonText, IonCol, IonInput,
    IonLabel, IonButton, IonItem,
} from "@ionic/react"
import { useState, useEffect } from "react";
import './index.css'
import ProvidersHeader from "../../../components/providersInvite/providersHeader";
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import NumberFormat from "react-number-format";
import { Formik, } from 'formik';
import * as yup from 'yup';
import instance from "../../../utils/api";
const PatientVerification: React.FC = () => {
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [headerTitle, setHeaderTitle] = useState<any>("Enter Details");
    const [otpBlock, setOtpBlock] = useState<any>(false);
    const [showToast, dismiss] = useIonToast();
    const [phoneNumber, setPhoneNumber] = useState<any>("")
    const [email, setEmail] = useState<any>("")
    const [existingUserBlock, setexistingUserBlock] = useState<any>(false);
    const validationSchema = yup.object({
        phoneNumber: yup.string()
            .required('This field is required')
            .min(10, 'Phone Number must be 10 digits.'),
        email: yup.string().email('Invalid email')
            .required("This field is required"),
    });
    useEffect(() => {
        if (otpBlock === true) {
            setHeaderTitle("OTP Verification")
        }
        else if (otpBlock === false) {
            setHeaderTitle("Enter Details")
        }
    }, [otpBlock])

    const signUp = async (values: any) => {
        setShowLoading(true);
        let data: any = {}
        data.phoneNumber = values.phoneNumber
        data.email = values.email
        if (values) {
            await instance({
                method: 'POST',
                url: 'user/employeeCheck',
                data,
                validateStatus: () => true
            }).then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    setOtpBlock(true)
                    setPhoneNumber(values.phoneNumber)
                    setEmail(values.email)
                    showToast(`OTP Sent to ${values.phoneNumber}`, 3000)
                }
                else {
                    setShowLoading(false);
                }
            })
        }
    }
    const resendOtp = async (number: any, emai: any) => {
        setShowLoading(true);
        let data: any = {}
        data.phoneNumber = phoneNumber
        data.email = email
        if (data) {
            await instance({
                method: 'POST',
                url: 'user/employeeCheck',
                data,
                validateStatus: () => true
            }).then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    setOtpBlock(true)
                    showToast(`OTP Sent to ${phoneNumber}`, 3000)
                }
                else {
                    setShowLoading(false);
                }
            })
        }
    }
    const verifyOtp = async (values: any) => {
        setShowLoading(true);
        let data: any = {}
        data.otp = values.otp
        if (data) {
            await instance({
                method: 'PUT',
                url: 'token/verifyEmployeeOTP',
                data,
                validateStatus: () => true
            }).then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    if (obj.token) {
                        instance.defaults.headers.common['Authorization'] = obj.result.token;
                        localStorage.setItem("auth_token", obj.token);
                        localStorage.setItem("isloanBankAdded", obj.result.isloanBankAdded);
                        setexistingUserBlock(true)
                        // window.location.href = "/request_amount";
                    }
                    else {
                        localStorage.setItem('email', email);
                        localStorage.setItem('phoneNumber', phoneNumber);
                        localStorage.removeItem("isloanBankAdded");
                        window.location.href = "/patient_info"
                    }
                }
                else {
                    setShowLoading(false);
                    showToast(obj.error.message, 3000)
                }
            })
        }
    }
    const otpValidation = yup.object({
        otp: yup.string()
            .required('This field is required')
            .min(4, 'Please Enter 4 digits OTP.'),
    });
    const navToApp = () => {
        const url = "https://play.google.com/store/apps/details?id=app.mybenefits.ai";
        window.location.replace(url);
    }
    return (
        <IonPage className="bg-cnt">
            <ProvidersHeader title={headerTitle}></ProvidersHeader>
            <IonContent fullscreen>
                <IonGrid className="ht-100">
                    {otpBlock === false ?
                        <div className="ht-100 pro-flex-col ion-justify-content-start ion-padding-top">
                            {/* <IonRow className="tnk-txt ion-padding"><IonText>Thank you for verifying your identity</IonText></IonRow>
                            <IonRow className="ion-padding"><CheckCircleFill size="40" color="#00B9CF" /></IonRow>
                            <IonRow className="name-txt"><IonText>John Doe</IonText></IonRow>
                            <IonRow className="name-txt ft-wt-400" ><IonText>4/23/1955</IonText></IonRow> */}
                            <Formik
                                initialValues={{
                                    email: "",
                                    phoneNumber: ""
                                }}
                                validationSchema={validationSchema}
                                onSubmit={signUp}>
                                {formikProps => (
                                    <div className='fieldiv ion-padding wd-100'>
                                        <IonCol className="fields-row-pd">
                                            <form onSubmit={formikProps.handleSubmit}>
                                                <IonRow className='ion-padding-top'>
                                                    <IonCol className='pad-lr-0'>
                                                        <IonLabel className='pro-label-txt'>Phone number</IonLabel>

                                                        <div className="pro-inpt-field mg-field-top num-format-wd-100  number-flex">
                                                            <NumberFormat
                                                                placeholder="Enter Number"
                                                                format="(###) ###-####"
                                                                name="phoneNumber"
                                                                className="num-for-br-no"
                                                                value={formikProps.values.phoneNumber}
                                                                // onChange={formikProps.handleChange}
                                                                onValueChange={val => formikProps.setFieldValue("phoneNumber", val.floatValue)}
                                                                onBlur={formikProps.handleBlur}
                                                            />
                                                            {formikProps.values.phoneNumber && formikProps.touched.phoneNumber && !formikProps.errors.phoneNumber ?
                                                                <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                        </div>

                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.phoneNumber && formikProps.errors.phoneNumber}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-padding-vertical'>
                                                    <IonCol className='pad-lr-0'>
                                                        <IonLabel className='pro-label-txt'>Email</IonLabel>
                                                        <IonInput type="text" name="email" className="pro-inpt-field mg-field-top"
                                                            placeholder="Enter Email" value={formikProps.values.email.trim()}
                                                            onBlur={formikProps.handleBlur}
                                                            onIonChange={formikProps.handleChange}
                                                        > {formikProps.values.email && formikProps.touched.email && !formikProps.errors.email ?
                                                            <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}</IonInput>
                                                        {formikProps.touched.email && formikProps.errors.email ? (
                                                            <p className="fielderrormsg">{formikProps.errors.email}</p>
                                                        ) : null}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-justify-content-center ion-padding-vertical">
                                                    <IonButton expand="block" type='submit' className='provoiders-btn provoiders-btn-txt ion-no-margin'>Next</IonButton>
                                                </IonRow>
                                            </form>
                                        </IonCol>
                                    </div>
                                )}
                            </Formik>
                        </div> :
                        <div className="ht-100 pro-flex-col ion-justify-content-start ion-padding-top">
                            <IonRow className="tnpltxt ion-padding-vertical"><IonText>One Time Password</IonText></IonRow>
                            <Formik
                                initialValues={{
                                    otp: "",
                                }}
                                validationSchema={otpValidation}
                                onSubmit={verifyOtp}>
                                {formikProps => (
                                    <div className='fieldiv ion-padding wd-100 fields-pd'>
                                        <IonCol className="fields-row-pd">
                                            <form onSubmit={formikProps.handleSubmit}>
                                                <IonRow className='ion-padding-top'>
                                                    <IonCol className='pad-lr-0'>
                                                        <IonLabel className='pro-label-txt'>Enter OTP</IonLabel>

                                                        <div className="pro-inpt-field mg-field-top num-format-wd-100  number-flex">
                                                            <NumberFormat
                                                                placeholder="Enter Number"
                                                                format="####"
                                                                name="otp"
                                                                className="num-for-br-no"
                                                                value={formikProps.values.otp}
                                                                // onChange={formikProps.handleChange}
                                                                onValueChange={val => formikProps.setFieldValue("otp", val.floatValue)}
                                                                onBlur={formikProps.handleBlur}
                                                            />
                                                            {formikProps.values.otp && formikProps.touched.otp && !formikProps.errors.otp ?
                                                                <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                        </div>

                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.otp && formikProps.errors.otp}
                                                        </p>
                                                        <IonRow className="ion-justify-content-center ion-padding-vertical">
                                                            <IonButton expand="block" type='submit' className='provoiders-btn provoiders-btn-txt ion-no-margin'>Verify</IonButton>
                                                        </IonRow>
                                                        <IonRow className="ion-justify-content-center ion-padding" onClick={() => resendOtp(phoneNumber, email)}>
                                                            <IonText className='label pointer ion-text-center'>Didn't receive OTP? <a className="later-bnk-txt">Resend OTP</a></IonText>
                                                        </IonRow>
                                                    </IonCol>
                                                </IonRow>
                                            </form>
                                        </IonCol>
                                    </div>
                                )}
                            </Formik>
                        </div>}
                </IonGrid>
                <IonModal isOpen={existingUserBlock} className="pro-modal bg-cnt" onDidDismiss={() => setexistingUserBlock(false)}>
                    {/* <ProvidersHeader title={""}></ProvidersHeader> */}
                    <IonContent fullscreen>
                        <div className='fieldiv ion-padding wd-100' style={{ height: "100%", display: "flex" }}>
                            <IonCol>
                                <IonRow className="tnpltxt ion-justify-content-center ion-margin-bottom ion-padding-bottom">
                                    <IonText> You already have an existing myBenefits.ai account.</IonText>
                                </IonRow>
                                <div className="ion-margin-vertical ion-justify-content-center">
                                    <IonButton expand="block" onClick={() => navToApp()} className='provoiders-btn provoiders-btn-txt ion-no-margin'>Open in App</IonButton>
                                </div>
                                <IonRow className="tnpltxt ion-justify-content-center">
                                    <IonText>OR</IonText>
                                </IonRow>
                                <div className="ion-margin-vertical ion-justify-content-center">
                                    <IonButton expand="block" onClick={() => window.location.href = "/request_amount"} className='provoiders-btn provoiders-btn-txt ion-no-margin'>Continue</IonButton>
                                </div>
                            </IonCol>
                        </div>
                    </IonContent>
                </IonModal>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
        </IonPage >
    )
}
export default PatientVerification;