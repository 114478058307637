import { IonAvatar, useIonToast, IonCard, IonButton, IonCardContent, IonLoading, IonCardHeader, IonToast, IonCheckbox, IonItemDivider, IonToggle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar } from '@ionic/react';
import "./index.css";
import { useState, useEffect, useContext } from 'react';
import NumberFormat from 'react-number-format';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Done } from '@styled-icons/material/Done';
import CommonContext from "../../utils/contexts/commonProps";
import instance from '../../utils/api';

const ProviderAddress: React.FC = () => {
    const [billingAddress, setBillingAddress] = useState('')
    const [businessCity, setBusinessCity,] = useState('');
    const [zipCode, setZipCode] = useState('')
    const [showToast, dismiss] = useIonToast();
    const [showLoading, setShowLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState<any>();
    console.log(phoneNumber)
    useEffect(() => {
        let path = window.location.href;
        let urlToken = path.split("?");
        let finalUrlToken = urlToken[1]
        if (finalUrlToken) {
            instance.defaults.headers.common['Authorization'] = finalUrlToken;
            localStorage.setItem("auth_token", finalUrlToken);
        }
    }, [])

    const addAddress = async () => {
        let data = {
            firstLineAddress: billingAddress,
            businessCity,
            zipCode,
            phoneNumber
        }
        const pattern = /^[a-zA-Z]*$/g;
        const result = pattern.test(businessCity);
        if (!phoneNumber) {
            showToast('Please Enter Phone Number.', 3000)
        }
        else if (phoneNumber.length < 10) {
            showToast('Phone Number must be 10 digits.', 3000)
        }
        else if (!billingAddress) {
            showToast('Please Enter Billing Address.', 3000)
        }
        else if (!businessCity) {
            showToast('Please Enter Bussiness City', 3000)
        }
        // else if (result == false) {
        //     showToast('Please Enter Bussiness City In Alphabets Only', 3000)
        // }
        else if (!zipCode) {
            showToast('Please Enter ZipCode.', 3000)
        }
        else if(zipCode.length < 5 || zipCode.length > 5){
            showToast('zipCode.length should contain max 5 digits', 3000)
        }
        else {
            setShowLoading(true)
            await instance({
                method: 'PUT',
                url: 'mybenefitsprovider/updateprovider',
                data,
                validateStatus: () => true
            }).then((response) => {
                let obj: any = {}
                obj = response
                setShowLoading(false);
                if (obj.status === "SUCCESS!") {
                    window.location.href = "/provider-password";
                }
                else if (obj.error) {
                    setShowLoading(false);
                    showToast(obj.error.message, 3000)
                }
            })
        }
    }
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow className="ion-justify-content-center  align-items-center display-flex">
                        <IonRow className="ion-justify-content-center myben-title-pd-tp">
                            <div style={{ display: 'flex' }}>
                                <img src={require(`../../images/final_logo.png`).default} className="" height="38px" width="37px" alt="" />
                            </div>
                            <IonRow className='ion-padding-bottom'>
                                <IonCol>
                                    <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                                    <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='subtitle'>Treat Now, Pay Later</IonText></IonRow>
                                </IonCol>
                            </IonRow>
                        </IonRow>
                        <IonRow className='ion-justify-content-center ion-padding-bottom ion-padding-top'>
                            <IonText className='pass-title-fnt'>Provider Address</IonText>
                        </IonRow>
                        <IonRow style={{ maxWidth: '430px', margin: '0 auto' }}>
                            <IonCol className="fields-row-pd">
                                <IonRow className='ion-padding-bottom'>
                                    <IonCol className="pad-0">
                                        <IonLabel className='label'>Enter Phone Number</IonLabel>
                                        <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="(XXX) XXX-XXXX" format="(###) ###-####" value={phoneNumber} onValueChange={(e: any) => setPhoneNumber(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                                    </IonCol>
                                </IonRow>
                                <IonRow className='ion-padding-bottom'>
                                    <IonCol className="pad-0">
                                        <IonLabel className='label'>Street Address</IonLabel>
                                        <IonInput type="text" autocomplete='on' placeholder="123 Street" className="input-field mg-top placeholder-txt" value={billingAddress}
                                            onIonInput={(e: any) => setBillingAddress(e.target.value)}
                                        > </IonInput>
                                    </IonCol>
                                </IonRow>
                                <div className='city-flex'>
                                    <IonRow className="pd-rt-20 ion-padding-bottom">
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Enter City</IonLabel>
                                            <IonInput type="text" autocomplete='on' className="input-field mg-top placeholder-txt" value={businessCity} pattern="/^[a-zA-Z]*$/" onIonInput={(e: any) => setBusinessCity(e.target.value)}
                                            > </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Enter Zip Code</IonLabel>
                                            <NumberFormat className="input-field number-field placeholder-txt" value={zipCode} style={{ border: 'none', outline: '1px solid #CDC5C5' }} format="#####" onValueChange={(e: any) => setZipCode(e.value)}
                                            />
                                        </IonCol>
                                    </IonRow>
                                </div>
                                <IonButton expand="block" className='nextbtn' onClick={() => addAddress()}>Next</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center ion-padding myben-ft ht-100'>@myBenefits 2021</IonRow>
                    </IonRow>
                </IonGrid>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
        </IonPage>
    )
}
export default ProviderAddress