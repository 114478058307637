import { useIonAlert, useIonToast, IonButton, IonLoading, IonToggle, IonCol, IonContent, IonGrid, IonLabel, IonPage, IonRow, IonText, } from '@ionic/react';
import "../../pages/AddBankAccount/index.css";
import { useState, useEffect, useContext } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from "react-router-dom";
import instance from '../../utils/api';
var CryptoJS = require("crypto-js");

const ProviderBankDetails: React.FC = () => {
    const [route, setRoute] = useState<string>();
    const [number, setNumber] = useState<any>("");
    const [checked, setChecked] = useState(false);
    const [key, setKey] = useState<any>("AC1f7b6b8f1325f67ad887e297c69dd9de")
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [alert] = useIonAlert();
    const [showToast, dismiss] = useIonToast();
    const token = localStorage.getItem("auth_token");

    const addBankDetailes = async () => {
        let bank = {
            number,
            route
        }
        if (!number) {
            showToast('Please Enter Account Number.', 3000)
        }
        else if (number.length < 8 || number.length > 17) {
            showToast('Account Number should contain min 8 digits or max 17 digits.', 3000)
        }
        else if (!route) {
            showToast('Please Enter Routing Number.', 3000)
        }
        else {
            setShowLoading(true);
            let encrypt = CryptoJS.AES.encrypt(JSON.stringify(bank), key).toString();
            let res: any = ''
            res = encrypt
            let data: any = {}
            data.bank = res
            data.status = "dashboard"
            await instance({
                method: 'PUT',
                url: 'mybenefitsprovider/updateprovider',
                data,
                validateStatus: () => true
            }).then((response) => {
                let obj: any = {}
                obj = response;
                if (obj.status === "SUCCESS!") {
                    localStorage.setItem("isBankAdded", obj.result.list[0].isBankAdded);
                    localStorage.setItem("providerStatus", obj.result.list[0].status);
                    window.location.href = "/dashboard"
                    setShowLoading(false);
                }
                else {
                    setShowLoading(false);
                    showToast(obj.error.message, 3000)
                }
            })
        }
    }
    const skipBank = () => {
        if (token != undefined) {
            history.push('/dashboard');
        }
    }
    return (
        <IonPage>
            <IonContent>
                <IonRow className="ion-justify-content-center myben-title-pd-tp">
                    <div style={{ display: 'flex' }}>
                        <img src={require(`../../images/final_logo.png`).default} className="" height="38px" width="37px" alt="" />
                    </div>
                    <IonRow className='ion-padding-bottom'>
                        <IonCol>
                            <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                            <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='subtitle'>Treat Now, Pay Later</IonText></IonRow>
                        </IonCol>
                    </IonRow>
                </IonRow>
                <IonRow className='ion-justify-content-center ion-padding-bottom ion-padding-top'>
                    <IonText className='pass-title-fnt'>Add Bank Account</IonText>
                </IonRow>

                <IonRow style={{ maxWidth: '430px', margin: '0 auto' }}>
                    <IonCol className="fields-row-pd">
                        <IonRow className='ion-padding-bottom'>
                            <IonCol className="pad-0">
                                <IonLabel className='label'>Enter Routing Number</IonLabel>
                                <div>
                                    <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="###-##-####" format="#########" value={route} onValueChange={(e: any) => setRoute(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="pad-0">
                                <IonLabel className='label'>Enter Account Number</IonLabel>
                                <div>
                                    <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="###-##-####" format="#################" value={number} onValueChange={(e: any) => setNumber(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-top ion-padding-bottom'>
                            <IonText className='drctdbttxt1'>Where is my routing & account number?</IonText>
                        </IonRow>
                        <IonButton expand="block" className='nextbtn' onClick={() => addBankDetailes()}>Add</IonButton>
                        <IonRow onClick={() => skipBank()} className="ion-justify-content-center ion-padding">
                            <IonText className='label pointer later-bnk-txt'><a>Add Bank Later</a></IonText>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow className='ion-align-items-center ion-justify-content-center ion-padding myben-ft ht-100'>@myBenefits 2021</IonRow>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
        </IonPage>
    )
}
export default ProviderBankDetails