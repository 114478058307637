// import React, { useState } from 'react'
// import { Redirect, Route, RouteProps } from 'react-router'

// export interface IPrivateRouteProps extends RouteProps {
// }
// const PublicRoute: React.FC<IPrivateRouteProps> = (props) => {
//      const [isLoggin, setIsLoggin] = useState(localStorage.getItem("patientLogin"))
//      return isLoggin !== null ? (
//        <Route {...props} component={props.component} />
//      ) : (
//        <Redirect to="/patient" />
//      )
//     }
// export default PublicRoute;
//public route 
import React, { useState } from 'react';
import { Route, Redirect, RouteProps, } from 'react-router-dom';
interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any 
  component: any;
}
const PublicRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const [isLoggin, setIsLoggin] = useState(localStorage.getItem("patientLogin"))
  return (<Route {...rest} render={(routeProps) => isLoggin ? (
    <Redirect to={{ pathname: '/patient_dashboard', state: { from: routeProps.location } }} />) :
    (<Component {...routeProps} />)} />);
};
export default PublicRoute;