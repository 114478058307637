import {
    IonRow,
    IonButton,
    IonCol,
    IonCard,
    IonCardContent,
    IonText,
    IonLabel,
    IonInput,
    IonTextarea,
    IonLoading,
    useIonToast,
    IonModal,
    IonPage,
    IonContent,
    IonFooter,
} from "@ionic/react";
import { useState, useEffect } from "react";
import { Upload2 } from "@styled-icons/remix-fill/Upload2"
import { PlayCircleOutline } from "@styled-icons/evaicons-outline/PlayCircleOutline";
import { DocumentText } from "@styled-icons/ionicons-outline/DocumentText";
import { Image } from "@styled-icons/bootstrap/Image";
import Modal from "../../components/Modal";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import * as yup from "yup";
import { Formik } from "formik";
import './index.css'
import instance from "../../utils/api";
import { async } from "q";
import { CheckSquareFill } from "@styled-icons/bootstrap/CheckSquareFill";
import { CheckboxUnchecked } from "@styled-icons/fluentui-system-regular/CheckboxUnchecked"
interface Props {
    userId: string
    // hideModal: () => void,
    name: string;
    providerId:string;
}
const PostCareUpload: React.FC<Props> = (props) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<any>();
    const [uploadFile, setUploadFile] = useState<any>();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showToast, dismiss] = useIonToast();
    const [getResourcesData, setResourcesData] = useState<any>([])
    const [checkedValues, setCheckedValues] = useState<any>([])
    const [ViewPostCareData, setViewPostCareData] = useState<any>()
    const [postCareImagesBlock, setPostCareImagesBlock] = useState<boolean>(false)
    const [postCareValue, setPostCareValue] = useState<any>();
    const [playingVideo, setPlayingVideo] = useState<any>(false);

    const getResources = async () => {
        setShowLoading(true)
        await instance({
            method: "GET",
            url: "/resource",
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp;
            if (obj.status === "SUCCESS!") {
                if (obj.result.length > 0) {
                    var data: any = []
                    obj.result.map((async (item: any) => {
                        if (item.discharge && item.discharge.length > 0) {
                            item.discharge.map((async (value: any) => {
                                if (value === (props && props.providerId)) {
                                    await data.push(item._id)
                                }
                            }))
                        }
                    }))
                    if (data.length > 0) {
                        setCheckedValues(data)
                    }
                    setResourcesData(obj.result)
                }
                setShowLoading(false)
            }
            else {
                setShowLoading(false)
            }
        })
    }

    const save = async (values: any) => {
        if (!uploadFile) {
            setErrorMessage("Please choose a file ");
        }
        else {
            if (values) {
                var formData = new FormData();
                setShowLoading(false)
                formData.append("file", uploadFile);
                formData.append("title", values.title);
                formData.append("description", values.Description);
                formData.append("user", props && props.userId);
                formData.append("discharge",props && props.providerId)
                setShowLoading(true)
                await instance({
                    method: "POST",
                    url: "/resource",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                    validateStatus: () => true
                }).then((resp) => {
                    let obj: any = {};
                    obj = resp;
                    setShowLoading(false);
                    if (obj.status === "SUCCESS!") {
                        showToast("Uploaded Successfully", 3000)
                        setShowLoading(false);
                        setOpenModal(false);
                        getResources()
                    }
                    else {
                        setShowLoading(false);
                    }
                }).catch((err) => {
                    setShowLoading(false);
                })
            }
        }
    }


    const handleFileEvent = (e: any) => {
        if (e.target.files[0]) {
            setErrorMessage("");
        }
        setUploadFile(e.target.files[0]);
    };

    const validationSchema = yup.object({
        Description: yup.string()
            .required("This field is required"),
        title: yup.string()
            .required("This field is required")
    })

    const submitPostCare = async () => {
        setShowLoading(true);
        let data: any = {}
        data.user = props && props.userId
        data.discharge = props && props.providerId
        data.resource = checkedValues
        await instance({
            method: "PUT",
            url: "/resource/assignResource",
            data,
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp
            if (obj.status === "SUCCESS!") {
                showToast("Successfully Submitted", 3000)
                setShowLoading(false);
                getResources()
                // props.hideModal();

            }
            else {
                setShowLoading(false);
            }
        })
    }

    useEffect(() => {
        getResources()
    }, [])

    const handleCheckChange = async (e, value) => {
        if (checkedValues.includes(value)) {
            setCheckedValues(checkedValues.filter((a) => a !== value));
        } else {
            setCheckedValues([...checkedValues, value]);
        }
    }

    const viewPostCare = (value: any, data: any) => {
        setViewPostCareData(data)
        setPostCareValue(value)
        setPostCareImagesBlock(true)
    }
    return (
        <>
                <IonRow className="ion-justify-content-start" style={{ width: "100%" }}>
                    <div style={{ width: postCareImagesBlock === true ? "66%" : "100%" }}>
                        <IonCol className="pad-0">
                            <IonRow className="ion-justify-content-start ion-padding-horizontal" style={{ width: "100%",padding:"6px"}}>
                                <IonCard className="post-care-cards" style={{ margin: "0px 5px",boxShadow:"none" }}>
                                    <IonCardContent style={{ padding: "0px", height: "100%" }}>
                                        <IonCol className="postscore-card-data">
                                            <div style={{ margin: "auto" }} className="pointer" onClick={() => { setOpenModal(true); setPostCareImagesBlock(false) }}>
                                                <Upload2 size="70" color="#00B9CF" />
                                            </div>
                                            <div className="txt-robot postscore-txt-border" style={{ textAlign: "left" }}>Upload File</div>
                                        </IonCol>
                                    </IonCardContent>
                                </IonCard>
                                {getResourcesData && getResourcesData.length > 0 ?
                                    getResourcesData.map((item: any, index: any) => (
                                        <IonCard className="post-care-cards" style={{ margin: "0px 5px",boxShadow:"none"}} key={index}>
                                            <IonCardContent style={{ padding: "0px", height: "100%" }}>
                                                <IonCol className="postscore-card-data">
                                                    {item.mimetype && item.mimetype.split("/")[0] === "video" ?
                                                        <div style={{ margin: "auto" }} className="pointer" onClick={() => viewPostCare("video", item.s3link)}>
                                                            <PlayCircleOutline size="70" color="#00B9CF" />
                                                        </div> : item.mimetype && item.mimetype.split("/")[0] === "application" ?
                                                            <div style={{ margin: "auto" }} className="pointer">
                                                                <a href={item.s3link} target="_blank" className="report-btn searchbtn searchbtn-txt" rel="noreferrer">
                                                                    <DocumentText size="70" color="#00B9CF" />
                                                                </a>
                                                            </div> : item.mimetype && item.mimetype.split("/")[0] === "image" ? (
                                                                <div style={{ margin: "auto" }} className="pointer" onClick={() => viewPostCare("image", item.s3link)}>
                                                                    <Image size="70" color="#00B9CF" />
                                                                </div>
                                                            ) : null
                                                    }
                                                    <div className="txt-robot postscore-txt-border ion-align-items-center ion-justify-content-start" style={{ display: "flex", flexDirection: "row" }}>
                                                        <div onClick={(e) => handleCheckChange(e, item._id)} className="pointer">
                                                            {checkedValues.includes(item._id) ?
                                                                <CheckSquareFill size={17} color="#00B9CF" /> : <CheckboxUnchecked size={23} color="#606060" />}
                                                        </div>
                                                        <div className="postcare-title">{item.title}</div>
                                                    </div>
                                                </IonCol>
                                            </IonCardContent>
                                        </IonCard>
                                    ))
                                    : null}
                            </IonRow>
                        </IonCol>
                    </div>
                    {postCareImagesBlock ?
                        <div style={{ width: "34%", margin: "0px" }}>
                            <IonRow className="ion-justify-content-end pointer pad-0 ion-align-items-center"
                                onClick={() => setPostCareImagesBlock(false)}>
                                <CloseOutline
                                    title="Close"
                                    size={30}
                                    color="#1EB9CD"
                                ></CloseOutline>
                            </IonRow>
                            {postCareValue === "image" ?
                                <div>
                                    <img src={ViewPostCareData} height="100%" width="100%" alt="" style={{ objectFit: "contain" }} />
                                </div> :
                                <div>
                                    <video width="100%" height="100%" controls={true} autoPlay onPlaying={playingVideo ? playingVideo : undefined}>
                                        <source src={ViewPostCareData} type="video/mp4" />
                                    </video>
                                </div>
                            }
                        </div>
                        : null}
                </IonRow>
          
                {checkedValues.length > 0 &&
                    <IonRow style={{padding:"10px"}}>
                        <IonButton className='actions-btn' disabled={checkedValues.length === 0} onClick={() => submitPostCare()}>Submit</IonButton>
                    </IonRow>}
           
            <IonModal isOpen={openModal} onDidDismiss={() => setOpenModal(false)} id="upload-care-modal">
                <div style={{ display: "flex", flexDirection: "column" }} className="popup-header">
                    <IonRow className="close pointer" onClick={() => setOpenModal(false)}>
                        <CloseOutline title="Close" size={30} color="#FFFFFF"></CloseOutline>
                    </IonRow>
                    <IonRow className="ion-justify-content-center popup-title ion-padding" style={{ fontSize: "30px" }}><IonText>
                        Upload Post Care Resource
                    </IonText></IonRow>

                </div>
                <IonContent>
                    <Formik initialValues={{ Description: "", title: "", }} onSubmit={save} validationSchema={
                        validationSchema
                    }>{formikProps => (
                        <form onSubmit={formikProps.handleSubmit} id="form-id">
                            <IonRow style={{ maxWidth: '430px', margin: '0 auto' }}>
                                <IonCol className="fields-row-pd">
                                    <IonRow className='ion-padding-top'>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Enter Title</IonLabel>
                                            <IonInput name="title" placeholder="title" className="ion-padding input-field mg-top placeholder-txt"
                                                type="text"
                                                onBlur={formikProps.handleBlur}
                                                onIonChange={formikProps.handleChange}
                                                value={formikProps.values.title}

                                            >
                                            </IonInput>
                                            <p className="fielderrormsg" style={{ marginBottom: "0px" }}>
                                                {formikProps.touched.title && formikProps.errors.title}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-padding-top'>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Enter Description</IonLabel>
                                            <IonTextarea name="Description" placeholder="Description" className="dis-txt-area review-txt-area"
                                                onBlur={formikProps.handleBlur}
                                                onIonChange={formikProps.handleChange}
                                                value={formikProps.values.Description}
                                            >
                                            </IonTextarea>
                                            <p className="fielderrormsg" style={{ marginBottom: "0px" }}>
                                                {formikProps.touched.Description && formikProps.errors.Description}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol className="pad-0">
                                            <IonLabel className="label">
                                                Upload file
                                            </IonLabel>
                                        </IonCol>
                                    </IonRow>
                                    <div className="App">
                                        <input
                                            id="fileUpload"
                                            type="file"
                                            multiple
                                            onChange={(e) => handleFileEvent(e)} style={{ color: "black" }}
                                        />
                                        <p className="fielderrormsg err-ft-14">
                                            {errorMessage ? errorMessage : null}
                                        </p>
                                    </div>

                                </IonCol>
                            </IonRow>
                        </form>
                    )}
                    </Formik>
                </IonContent>
                <IonFooter>
                    <IonRow className='ion-padding-vertical ion-justify-content-center' style={{background:"white"}}>
                        <IonButton form="form-id" className='button button-txt' style={{margin:"0px"}} type="submit">Submit</IonButton>
                    </IonRow>
                </IonFooter>
            </IonModal>
            <IonLoading isOpen={showLoading} message={'Please wait...'} />
        </>
    )
}
export default PostCareUpload