import { IonPage, IonRow, IonCol, IonText, IonLabel, IonTextarea, IonLoading, IonButton, IonRadioGroup, IonRadio, IonItem, useIonToast, IonFooter, IonContent } from "@ionic/react"
import { Formik, Field, Form } from 'formik';
import { useState, } from "react";
import * as yup from 'yup';
import instance from "../../../utils/api";
import { useHistory } from "react-router";
import { ArrowBackOutline } from '@styled-icons/evaicons-outline/ArrowBackOutline';
import './index.css';
import { TagFaces } from "@styled-icons/material-twotone/TagFaces";
import { FaceMeh } from "@styled-icons/fa-regular/FaceMeh";
import { FaceSadTear } from "@styled-icons/fa-regular/FaceSadTear";
import { FaceSmile } from "@styled-icons/fa-regular/FaceSmile";
import { FaceFrown } from "@styled-icons/fa-regular/FaceFrown";

interface Props {
  id: String,
  dischargeData: [],
  showLoader: () => void,
  hideLoader: () => void,
  goBackToSurvey: () => void,
  logactivityType: String;
}
const SurveyQuestions: React.FC<Props> = (props) => {
  const [painRating, setPainRating] = useState<any>(0);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToast, dismiss] = useIonToast();
  const [feebBack, setFeedBack] = useState<any>("");
  const [feebBackPain, setFeedBackPain] = useState<any>("")
  const history = useHistory();
  const [feebBackerrorMsg, setfeebBackerrorMsg] = useState<any>("");
  const [painerorMessage, setPainerorMessage] = useState<any>("");
  const [question1, setQuestion1] = useState<any>("");
  const [question2, setQuestion2] = useState<any>("");
  const [question3, setQuestion3] = useState<any>("");
  const [erorMessage, setErorMessage] = useState<any>("");

  const postcareFollowup2 = async () => {
    if (!question1 || !question2 || !question3) {
      showToast("All fields are required")
    }
    else {
      props.showLoader()
      console.log("data")
      let data: any = {}
      data.endSurvey = [{
        "question": "How was your experience with the hospital staff??",
        "answer": question1
      },
      {
        "question": "How was your experience with your Provider?",
        "answer": question2
      },
      {
        "question": "How was your overall post-op Care?",
        "answer": question3
      },
      ]
      console.log(data)
      await instance({
        method: "PUT",
        url: `discharge/doccument/${props.id}`,
        validateStatus: () => true,
        data
      }).then((resp) => {
        let obj: any = {}
        obj = resp
        if (obj.status === "SUCCESS!") {
          showToast("Your Feedback is submitted", 3000);
          props.hideLoader()
          history.push("/patient_dashboard", { props: props.dischargeData })
        }
        else {
          showToast(obj.error, 3000)
          props.hideLoader()
        }
      })
    }
  }
  const submitForm = async (values: any) => {
    if (props.logactivityType === "postcare_followup") {
      if (!feebBack) {
        setfeebBackerrorMsg("This field is required")
      }
      else if (((feebBack === "notgood") || (feebBack === "bad")) && !feebBackPain) {
        setPainerorMessage("This field is required")
      }
      else {
        props.showLoader()
        console.log("data")
        let data: any = {}
        data.survey = [{
          "question": "How are you doing today?",
          "answer": feebBack
        },
        {
          "question": "Is there any pain?",
          "answer": feebBackPain
        },
        ]
        if(feebBackPain === ""){
          data.survey.splice(1,1)
        }
        await instance({
          method: "PUT",
          url: `discharge/doccument/${props.id}`,
          validateStatus: () => true,
          data
        }).then((resp) => {
          let obj: any = {}
          obj = resp
          if (obj.status === "SUCCESS!") {
            showToast("Your Feedback is submitted", 3000);
            props.hideLoader()
            history.push("/patient_dashboard", { props: props.dischargeData })
          }
          else {
            showToast(obj.error, 3000)
            props.hideLoader()
          }
        })
       }
    }
    else {
      postcareFollowup2()
    }
  }
  const validationSchema = yup.object({
    question1: yup.string()
      .required('This field is required'),
    question2: yup.string()
      .required('This field is required'),
    question3: yup.string()
      .required('This field is required'),
    question4: yup.string()
      .required('This field is required')
  });

  const chooseRating = (value: any) => {
    setFeedBack(value);
    setfeebBackerrorMsg("")
  }

  const choosePain = (value: any) => {
    setFeedBackPain(value);
    setPainerorMessage("")
  }

  return (
   <IonPage>
      <IonContent>
        <IonRow className="ion-align-items-center" style={{ padding: "30px 10px" }}>
          <div><ArrowBackOutline size="30" color="black" onClick={() => props.goBackToSurvey()}></ArrowBackOutline></div>
          <IonText className="patient-header-txt"> Post care follow up</IonText>
        </IonRow>
        {props.logactivityType === "postcare_followup" ?
          <div className='fieldiv ion-padding wd-100' style={{ padding: "0px 12px" }}>
            <IonCol className="fields-row-pd">
              <div className="radiabtn" style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
                <IonLabel className='pro-label-txt'>How are you doing today?</IonLabel>
                <IonRow className="ion-padding-vertical">
                  <IonCol className="feedback-col">
                    <FaceSmile onClick={() => chooseRating("good")} size={40} color={feebBack === "good" ? "#00B42C" : "grey"} />
                    <div className="txt-center">Good</div>
                  </IonCol>
                  <IonCol className="feedback-col">
                    <FaceSmile onClick={() => chooseRating("better")} size={40} color={feebBack === "better" ? "#60C51F" : "grey"} />
                    <div className="txt-center">Better</div>
                  </IonCol>
                  <IonCol className="feedback-col">
                    <FaceMeh onClick={() => chooseRating("ok")} size={40} color={feebBack === "ok" ? "#F5D100" : "grey"} />
                    <div className="txt-center">Ok</div>
                  </IonCol>
                  <IonCol className="feedback-col">
                    <FaceFrown onClick={() => chooseRating("notgood")} size={40} color={feebBack === "notgood" ? "#F0671F" : "grey"} />
                    <div className="txt-center">NotGood</div>
                  </IonCol>
                  <IonCol className="feedback-col">
                    <FaceSadTear onClick={() => chooseRating("bad")} size={40} color={feebBack === "bad" ? "#EA3426" : "grey"} />
                    <div className="txt-center">Bad</div>
                  </IonCol>
                </IonRow>
                <p className="fielderrormsg">
                  {feebBackerrorMsg}
                </p>
              </div>
              {((feebBack === "notgood") || (feebBack === "bad")) &&
                <div className="radiabtn" style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
                  <IonLabel className='pro-label-txt'>Is there any pain?</IonLabel>
                  <IonRow className="ion-padding-vertical">
                    <IonCol className="feedback-col">
                      <FaceSmile onClick={() => { choosePain("noPain") }} size={40} color={feebBackPain === "noPain" ? "#00B42C" : "grey"} />
                      <div className="txt-center">No Pain</div>
                    </IonCol>
                    <IonCol className="feedback-col">
                      <FaceSmile onClick={() => choosePain("midPain")} size={40} color={feebBackPain === "midPain" ? "#60C51F" : "grey"} />
                      <div className="txt-center">Mid Pain</div>
                    </IonCol>
                    <IonCol className="feedback-col">
                      <FaceMeh onClick={() => choosePain("moderatePain")} size={40} color={feebBackPain === "moderatePain" ? "#F5D100" : "grey"} />
                      <div className="txt-center">Moderate Pain</div>
                    </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol className="feedback-col">
                      <FaceMeh onClick={() => choosePain("severePain")} size={40} color={feebBackPain === "severePain" ? "#F29900" : "grey"} />
                      <div className="txt-center">Severe Pain</div>
                    </IonCol>
                    <IonCol className="feedback-col">
                      <FaceFrown onClick={() => choosePain("veryseverePain")} size={40} color={feebBackPain === "veryseverePain" ? "#F0671F" : "grey"} />
                      <div className="txt-center">Very Severe Pain</div>
                    </IonCol>
                    <IonCol className="feedback-col">
                      <FaceSadTear onClick={() => choosePain("worstPain")} size={40} color={feebBackPain === "worstPain" ? "#EA3426" : "grey"} />
                      <div className="txt-center">Worst Pain</div>
                    </IonCol>
                  </IonRow>
                  <p className="fielderrormsg">
                    {painerorMessage}
                  </p>
                </div>}
              {/* <div className="radiabtn" style={{ display: "flex", flexDirection: "column" }}>
                    <IonLabel className='pro-label-txt'>Is there any pain?</IonLabel>
                    <IonRow className="ion-justify-content-start" style={{ paddingTop: "10px" }}>
                      {[...Array(10)].map((item, index) => {
                        const givenRating = index + 1;
                        return (
                          <IonRow key={index} id="question2"
                            className={(givenRating < painRating || givenRating === painRating ? "sltrat pain-rate-circle " : "pain-rate-circle")} onClick={() => {
                              setPainRating(givenRating);
                              formikProps.setFieldValue("question2", givenRating)
                            }}>
                            {index + 1}
                          </IonRow>
                        );
                      })}</IonRow>
                    <p className="fielderrormsg">
                      {formikProps.touched.question2 && formikProps.errors.question2}
                    </p>
                  </div>
                  <div className="radiabtn" style={{ display: "flex", flexDirection: "column" }}>
                    <IonLabel className='pro-label-txt'>Have you taken your medications?</IonLabel>
                    <IonRadioGroup name="question3"
                      onIonChange={(e) => {
                        formikProps.setFieldValue("question3", e.detail.value)
                      }
                      } onBlur={() => formikProps.handleBlur}
                    >
                      <IonItem>
                        <IonLabel>Yes</IonLabel>
                        <IonRadio slot="start" value="Yes" mode="md" />
                      </IonItem>
                      <IonItem>
                        <IonLabel>No</IonLabel>
                        <IonRadio value="No" slot="start" mode="md" />
                      </IonItem>
                    </IonRadioGroup>
                    <p className="fielderrormsg">
                      {formikProps.touched.question3 && formikProps.errors.question3}
                    </p>
                  </div>
                  <div className="radiabtn" style={{ display: "flex", flexDirection: "column" }}>
                    <IonLabel className='pro-label-txt'>Rate your experience with your care provider?</IonLabel>
                    <IonRadioGroup name="question4"
                      onIonChange={(e) => {
                        formikProps.setFieldValue("question4", e.detail.value)
                      }
                      } onBlur={() => formikProps.handleBlur}
                    >
                      <IonItem>
                        <IonLabel>Good</IonLabel>
                        <IonRadio slot="start" value="Good" mode="md" />
                      </IonItem>
                      <IonItem>
                        <IonLabel>Better</IonLabel>
                        <IonRadio value="Better" slot="start" mode="md" />
                      </IonItem>
                      <IonItem>
                        <IonLabel>Okay</IonLabel>
                        <IonRadio slot="start" value="Okay" mode="md" />
                      </IonItem>
                      <IonItem>
                        <IonLabel>Not Good</IonLabel>
                        <IonRadio slot="start" value="Not Good" mode="md" />
                      </IonItem>
                      <IonItem>
                        <IonLabel>Bad</IonLabel>
                        <IonRadio slot="start" value="Bad" mode="md" />
                      </IonItem>
                    </IonRadioGroup>
                    <p className="fielderrormsg">
                      {formikProps.touched.question4 && formikProps.errors.question4}
                    </p>
                  </div>
                  <IonRow>
                    <IonCol className="pad-lr-0">
                      <IonLabel className='pro-label-txt'>
                        Message to your care provider
                      </IonLabel>
                      <IonTextarea
                        name="question5"
                        placeholder="Type your message here."
                        className="pain-msg-txt-box msg-txt-area" style={{ height: "116px", marginTop: "10px" }}
                        onBlur={formikProps.handleBlur}
                        onIonChange={formikProps.handleChange}
                        value={formikProps.values.question5}
                      ></IonTextarea>

                    </IonCol>
                  </IonRow> */}
              {/* <IonRow className="ion-justify-content-center ion-padding-vertical">
                  <IonButton expand="block" type='submit' className='patient-btn provoiders-btn provoiders-btn-txt ion-no-margin'>Next</IonButton>
                </IonRow> */}


            </IonCol>
          </div> :
          <div>
            <div className="radiabtn" style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
              <IonLabel className='pro-label-txt'>How was your experience with the hospital staff?</IonLabel>
              <IonRow className="ion-padding-vertical">
                <IonCol className="feedback-col">
                  <FaceSmile onClick={() => { setQuestion1("happy") }} size={40} color={question1 === "happy" ? "#00B42C" : "grey"} />
                  <div className="txt-center">Happy</div>
                </IonCol>
                <IonCol className="feedback-col">
                  <FaceFrown onClick={() => setQuestion1("unHappy")} size={40} color={question1 === "unHappy" ? "#F5D100" : "grey"} />
                  <div className="txt-center">UnHappy</div>
                </IonCol>
              </IonRow>
              <p className="fielderrormsg">
                {painerorMessage}
              </p>
            </div>
            <div className="radiabtn" style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
              <IonLabel className='pro-label-txt'>How was your experience with your Provider?</IonLabel>
              <IonRow className="ion-padding-vertical">
                <IonCol className="feedback-col">
                  <FaceSmile onClick={() => { setQuestion2("happy") }} size={40} color={question2 === "happy" ? "#00B42C" : "grey"} />
                  <div className="txt-center">Happy</div>
                </IonCol>
                <IonCol className="feedback-col">
                  <FaceFrown onClick={() => setQuestion2("unHappy")} size={40} color={question2 === "unHappy" ? "#F5D100" : "grey"} />
                  <div className="txt-center">UnHappy</div>
                </IonCol>
              </IonRow>
              <p className="fielderrormsg">
                {painerorMessage}
              </p>
            </div>
            <div className="radiabtn" style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
              <IonLabel className='pro-label-txt'>How was your overall post-op Care?</IonLabel>
              <IonRow className="ion-padding-vertical">
                <IonCol className="feedback-col">
                  <FaceSmile onClick={() => { setQuestion3("happy") }} size={40} color={question3 === "happy" ? "#00B42C" : "grey"} />
                  <div className="txt-center">Happy</div>
                </IonCol>
                <IonCol className="feedback-col">
                  <FaceFrown onClick={() => setQuestion3("unHappy")} size={40} color={question3 === "unHappy" ? "#F5D100" : "grey"} />
                  <div className="txt-center">UnHappy</div>
                </IonCol>
              </IonRow>
              <p className="fielderrormsg">
                {painerorMessage}
              </p>
            </div>
          </div>}
      </IonContent>
       <IonFooter style={{ background: "white" }}>
        <div className="ion-justify-content-center" style={{ margin: "12px" }}>
         <IonButton expand="block" onClick={submitForm} className='provoiders-btn provoiders-btn-txt ion-no-margin patient-btn'>Submit</IonButton>
         </div>
       </IonFooter>
       <IonLoading isOpen={showLoading} message={'Please wait...'} />
      </IonPage>
   
  )
}
export default SurveyQuestions