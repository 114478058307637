import { IonRow, IonCol } from "@ionic/react"
import moment from "moment"
interface Props {
   painManagement: []
}
const PainManagement: React.FC<Props> = (props) => {
   const painManagementData = props && props.painManagement
   return (
      <>
        <IonRow className="ion-justify-content-start" style={{ width: "100%" }}>
         <div className="ovrflow" style={{ height: "100%", width: "100%",padding:"10px" }}>
              <table>
                <thead className="patient-thread">
                  <tr>
                     <th className="list-title-txt pd-lr-24 whitespace txt-al-ctr">Date & Time</th>
                     <th className="list-title-txt pd-lr-24 whitespace txt-al-ctr">Pain Level</th>
                     <th className="list-title-txt pd-lr-24 whitespace txt-al-ctr">Comment by Patient</th>
                  </tr>
               </thead>
               <tbody className="patient-tbody">
                  {painManagementData &&
                     painManagementData.length > 0 ? (
                     painManagementData.sort((a: any, b: any) => moment(a.timestamp).format("YYYYMMDD") > moment(b.timestamp).format("YYYYMMDD") ? 1 : -1).map((item: any, index: any) => (
                        <tr  key={index}
                        className="patient-tr"
                        style={{ cursor: "auto" }}>
                           <td className="list-title-txt pd-lr-24 txt-al-ctr" style={{fontWeight:"400"}}> {item.timestamp
                              ? new Date(item.timestamp).toLocaleDateString(
                                 "en-US",
                                 { timeZone: "America/Los_Angeles" }
                              )
                              : "NA"}{" "}{item.timestamp
                                 ? new Date(item.timestamp).toLocaleTimeString(
                                    "en-US",
                                    { timeZone: "America/Los_Angeles" }
                                 )
                                 : "NA"}</td>
                           <td className="list-title-txt pd-lr-24 txt-al-ctr" style={{fontWeight:"400"}}>{item.rating ? item.rating + "/10" : "NA"}</td>
                           <td className="list-title-txt pd-lr-24 txt-al-ctr" style={{fontWeight:"400"}}> {item.message ? item.message : "NA"}</td>
                        </tr>))
                  ) : (
                     <IonRow className="not-found-txt" style={{width:"100%"}}>
                      <div>No Data Found</div>
                    </IonRow>
                  )}
               </tbody>
            </table>
            </div>
         </IonRow>
      </>
   )
}
export default PainManagement