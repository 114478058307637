import {
    IonCol, IonContent, IonLoading, useIonToast,
    IonLabel, IonButton, IonPage, IonRow, IonText, IonGrid
} from '@ionic/react';
import Header from '../../components/Header';
import OuterHeader from '../../components/outerHeader';

const Help: React.FC = () => {
    return (
        <IonPage className='bg-cnt'>
            <Header popover={false} unreadCount={0}></Header>
            <IonContent fullscreen>
                <IonRow className="ion-justify-content-center display-flex">
                    <IonCol className='pad-0'>
                        <IonRow className="head-prov-title-bg ion-justify-content-center ion-align-items-center ion-margin-bottom">
                            <IonText>
                                We Are Here To Help!
                            </IonText>
                        </IonRow>
                        <IonCol style={{ maxWidth: '430px', margin: '0 auto' }}>
                            <IonRow className='ion-text-center ion-justify-content-center wid-700 ion-padding'>
                                <IonCol sizeXs='12' sizeMd='4' sizeLg='4'>
                                    <div>
                                        <img src={require(`../../images/Group-96.png`).default} />
                                    </div>
                                    <IonText className='helptxt'>Enter 6 digit Pin, Enter Amount & Date of Service
                                    </IonText></IonCol>
                                <IonCol sizeXs='12' sizeMd='4' sizeLg='4'>
                                    <div>
                                        <img src={require(`../../images/Group-97.png`).default} />
                                    </div>
                                    <IonText className='helptxt'> Provide Service</IonText>
                                </IonCol>
                                <IonCol sizeXs='12' sizeMd='4' sizeLg='4'>
                                    <div>
                                        <img src={require(`../../images/Group-98.png`).default} />
                                    </div>
                                    <IonText className='helptxt'>Receive Payments</IonText>
                                </IonCol>
                            </IonRow>
                            <div className="faqpos ion-padding pointer header-txt">
                                <IonText>Frequently Asked Questions<a href="/faq" target="_blank" className='link-pad'>Click Here</a></IonText>
                            </div>
                        </IonCol>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    )
}
export default Help;