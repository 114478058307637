import './index.css'
const Loader:React.FC=()=>{
    return (
        <div className='black-background'>
            <div className='loader-flex'>
            <div className="lds-hourglass"></div>
            <div style={{color:"black",marginLeft:"16px",fontSize:"14px"}}>Please wait...</div>
            </div>
     
        </div>
       
    )
}
export default Loader