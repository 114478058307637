
import {
    IonCol, useIonToast,
    IonLoading, IonButton, IonContent, IonToast, IonInput, IonLabel, IonPage, IonRow, IonText
} from '@ionic/react';
import { useState, useContext, useEffect } from 'react';

import CommonContext from "../../../utils/contexts/commonProps";
import { useHistory } from "react-router-dom";
import instance from '../../../utils/api';
import OuterHeader from '../../../components/outerHeader';
import { Formik } from 'formik';
import * as yup from 'yup';
import Loader from '../../../components/Loader';

const PlusAdminLogin: React.FC = () => {
    const [showToast, dismiss] = useIonToast();
    const { common, setCommon } = useContext(CommonContext);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const history = useHistory();

    const next = async (values: any) => {
        let data: any = {}
        data.password = values.password
        setShowLoading(true)
        await instance({
            method: 'POST',
            url: `discharge/adminLogin`,
            data,
            validateStatus: () => true
        }).then(res => {
            let obj: any = {};
            obj = res
            if (obj.status === "SUCCESS!") {
                history.push('/plus-all_discharges');
                setShowLoading(false)
            }
            else {
                setShowLoading(false);
               showToast(obj.error.message,3000)
            }
        })
    }
    const validationSchema = yup.object({
        // email: yup.string().email("Invalid email")
        //     .required("This field is required"),
        password: yup.string().required("This field is required")
    })

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonRow className="ion-justify-content-center display-flex">
                    <IonCol className='pad-0'>
                        <OuterHeader></OuterHeader>
                        <IonRow className="head-prov-title-bg ion-justify-content-center ion-align-items-center ion-margin-bottom">
                            <IonText>
                               Admin
                            </IonText>
                        </IonRow>
                        <Formik initialValues={{
                            email: "",
                            password: ""
                        }} onSubmit={next} validationSchema={validationSchema}>
                            {formikProps => (
                                <IonRow style={{ maxWidth: '430px', margin: '0 auto',padding:"0px 30px" }}>
                                    <form onSubmit={formikProps.handleSubmit} style={{ width: "100%" }}>
                                        <IonCol className="fields-row-pd">
                                            {/* <IonRow className='ion-padding-top'>
                                                <IonCol className="pad-0">
                                                    <IonLabel className='label'>Enter Email</IonLabel>
                                                    <IonInput autocomplete='on' type="text" name="email" className="input-field mg-top placeholder-txt" placeholder="Email" value={formikProps.values.email}
                                                        onIonChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                    > </IonInput>
                                                    {formikProps.touched.email && formikProps.errors.email &&
                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.email && formikProps.errors.email} </p>}
                                                </IonCol>
                                            </IonRow> */}
                                            <IonRow className='ion-padding-top'>
                                                <IonCol className="pad-0">
                                                    <IonLabel className='label'>Enter Password</IonLabel>
                                                    <IonInput type="password" name="password" className="ion-padding input-field mg-top placeholder-txt" placeholder="****" value={formikProps.values.password}
                                                        onIonChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                    > </IonInput>
                                                    {formikProps.touched.password && formikProps.errors.password &&
                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.password && formikProps.errors.password}
                                                        </p>
                                                    }
                                                </IonCol>
                                            </IonRow>
                                            <IonButton expand="block" className='searchbtn searchbtn-txt' type="submit">Login</IonButton>
                                        </IonCol>
                                    </form>
                                </IonRow>
                            )}
                        </Formik>
                    </IonCol>
                </IonRow>
                {showLoading && <Loader />}
            </IonContent>
        </IonPage>
    )
}
export default PlusAdminLogin;