import { IonButton, IonCol, IonHeader, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonSearchbar, IonText, IonTextarea, useIonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { ArrowBackOutline } from "@styled-icons/evaicons-outline/ArrowBackOutline";
import { useHistory } from 'react-router';
import instance from '../../../utils/api';
import SearchList from './searchList';
import FeedBack from './FeedBack';
import { PlayCircleOutline } from "@styled-icons/evaicons-outline/PlayCircleOutline";
import { DocumentText } from "@styled-icons/ionicons-outline/DocumentText";
import { Image } from "@styled-icons/bootstrap/Image";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import { CaretDown } from "@styled-icons/boxicons-regular/CaretDown";

const SpecialInstructions: React.FC = () => {
    const history = useHistory<any>();
    const listValue = history.location && history.location.state && history.location.state.props;
    const dischargeId = history.location && history.location.state && history.location.state.dischargeId;
    console.log(dischargeId, listValue);
    const [showToast] = useIonToast();
    const [errorMessage, setErrorMessage] = useState<any>("");
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [patientDetails, setPatientDetails] = useState<any>();
    const [msgToDoctor, setMsgToDoctor] = useState<any>("");
    const [painRating, setPainRating] = useState<any>(0);
    const [postCareData, setPostCareData] = useState<any>([]);
    const [viewPostCareImage, setViewPostCareImage] = useState<any>();
    const [imagePopUp, setImagePopUp] = useState<boolean>(false);
    const [postCareValue, setPostCareValue] = useState<any>();
    const [playingVideo] = useState<any>(false);
    const [searchData, setSearchData] = useState<any>([]);
    const [downloadReport, setDownloadReport] = useState<any>("");
    const [showDropDown, setShowDropDown] = useState<boolean>(false)
    const [selectedOptionData, setSelectedOptionData] = useState<any>([])
    const [selectedOptionBlock, setSelectedOptionBlock] = useState<boolean>(false);
    const [searchShow, setSearchShow] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filterData, setFilterData] = useState<any>([]);

    const getPatientData = async () => {
        if (dischargeId) {
            setShowLoading(true);
            instance({
                method: "GET",
                url: `discharge/${dischargeId}`,
                validateStatus: () => true,
            }).then((resp) => {
                let obj: any = {};
                obj = resp;
                if (obj.status === "SUCCESS!") {
                    setPatientDetails(obj.result[0]);
                    if (obj.result.length > 0) {
                        if (listValue === "postopInstructions") {
                            setSearchData(obj.result[0].docData);
                            setFilterData(obj.result[0].docData);
                        }
                        else if (listValue === "preopInstructions") {
                            setSearchData(obj.result[0].preopDocData);
                            setFilterData(obj.result[0].preopDocData);
                        }
                    }
                    setShowLoading(false);
                } else {
                    setShowLoading(false);
                }
            });
        }
    };

    const getPostCare = async () => {
        setShowLoading(true);
        await instance({
            method: "GET",
            url: `/resource/patientResources/${patientDetails && patientDetails._id
                }`,
            validateStatus: () => true,
        }).then((resp) => {
            let obj: any = {};
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setShowLoading(false);
                setPostCareData(obj.result);
            } else {
                setShowLoading(false);
            }
        });
    }
    useEffect(() => {
        getPatientData()
    }, [])

    useEffect(() => {
        if (listValue === "postCare" && patientDetails && patientDetails._id) {
            getPostCare()
        }
    }, [patientDetails && patientDetails._id]);

    const searchList = () => {
        return (
            <div>
                <SearchList filteredData={searchData} />
            </div>
        );
    };

    const downloadFile = (url: any) => {
        const link = document.createElement("a");
        link.href = url;
        link.click();
    };

    const fullreportActivity = async () => {
        let data: any = {};
        data.eventName = "full_report_section_click"
        data.user = patientDetails && patientDetails.user;
        data.discharge = dischargeId && dischargeId;
        await instance({
            method: "POST",
            url: "/discharge/patientActivity",
            data,
            validateStatus: () => true,
        }).then((resp) => {
            let obj: any = {};
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setShowLoading(false);
            } else {
                setShowLoading(false);
            }
        });
    }
    const downloadReportApi = async () => {
        setShowLoading(true);
        fullreportActivity()
        await instance({
            method: "GET",
            url: `discharge/getDocSignedLinkEvent/${dischargeId}`,
            validateStatus: () => true,
        }).then((resp) => {
            let obj: any = {};
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setShowLoading(false);
                console.log(obj.result)
                setDownloadReport(obj.result);
                if (listValue === "preop") {
                    downloadFile(obj.result.preopUrl);
                }
                else if (listValue === "postop") {
                    downloadFile(obj.result.postopUrl);
                }
            } else {
                setShowLoading(false);
            }
        });
    };


    const reportActivityLog = async () => {
        downloadReportApi();
    };

    const messageToDoctor = async () => {
        let data: any = {};
        data.rating = painRating;
        if (msgToDoctor) {
            data.message = msgToDoctor;
        }
        if (!painRating) {
            setErrorMessage("This field is required");
        } else {
            setShowLoading(true);
            await instance({
                method: "PUT",
                url: `discharge/pain/${dischargeId}`,
                data,
                validateStatus: () => true,
            }).then((resp) => {
                let obj: any = {};
                obj = resp;
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    showToast("Your pain level has been reported to your doctor.", 3000);
                    getPatientData();
                } else {
                    setShowLoading(false);
                }
            });
        }
    };

    const viewPostCare = (value: any, data: any) => {
        setViewPostCareImage(data);
        setPostCareValue(value);
        setImagePopUp(true);
    };
    const onChangeSearch = (query) => {
        const orginal = filterData
        setSearchQuery(query.detail.value);
        if (query.detail.value) {
            setSearchShow(true)
            const filteredData = orginal.filter(
                person => {
                    return (
                        person.categoryName.toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
                        person.categoryInfo.toLowerCase().includes(query.detail.value.toLowerCase().trim())
                    );
                }
            );
            setSearchData(filteredData)
        }
        else {
            setSearchData(orginal)
        }
    }
    const selectedOption = (value: any) => {
        setSelectedOptionData(value)
        setShowDropDown(false)
        setSelectedOptionBlock(true)
    }
    return (
        <IonPage className="patient-bg">
            <IonHeader>
                <div className="bg-img">
                    <IonRow
                        className="ion-padding ion-justify-content-between ion-align-items-center">
                        <ArrowBackOutline onClick={() => history.push("/patient_dashboard")} size={30} color='black' />
                        <div className="patient-header-txt" style={{ fontSize: "20px" }}>{listValue === "preopInstructions" ? "PreOp Instructions" : listValue === "postopInstructions" ? "PostOp Instructions" : listValue === "postCare" ? "Post Care Resources" : listValue === "preop" ? "Preop Report" : "PostOp Report"}</div>
                        <div></div>
                    </IonRow>
                </div>
            </IonHeader>
            <div className="ion-padding-horizontal"
                style={{ textAlign: "justify", background: "white", overflow: "auto", height: "100%" }}>
                {((listValue === "postopInstructions") || (listValue === "preopInstructions")) ?
                    <IonRow style={{ width: "100%" }}>
                        <div className="special-ins-dropdown">
                            <div className="special-bg-clr" onClick={() => setShowDropDown(!showDropDown)}>
                                <IonRow className="ion-justify-content-between"> {selectedOptionData.categoryName ?
                                    <div>
                                        {selectedOptionData.categoryName}
                                    </div> : <div>Select Instruction </div>}
                                    <CaretDown size="20" color="white" /></IonRow>
                            </div>
                            {showDropDown &&
                                <div style={{ padding: "0px 16px", }}>
                                    <IonSearchbar onIonChange={(ev) => onChangeSearch(ev)} value={searchQuery} className="pad-0" mode="ios"></IonSearchbar>
                                    <div style={{ width: "100%", height: "300px", overflow: "auto" }}>
                                        {searchData && searchData.length > 0 ? searchData.map((value, index) => (
                                            <div key={index}><IonRow>
                                                <IonCol onClick={() => selectedOption(value)} style={{ background: selectedOptionData.categoryName === value.categoryName ? "#E6F8FC" : "", padding: "0px" }}>
                                                    <div className="special-options-txt">{value.categoryName}</div>
                                                </IonCol>
                                            </IonRow></div>
                                        )) : <div>No search found</div>}

                                    </div>
                                </div>}
                        </div>
                        {showDropDown === false && selectedOptionBlock === false &&
                            <div style={{ width: "100%" }}>
                                {searchList()}
                            </div>
                        }
                        {selectedOptionBlock === true && showDropDown === false &&
                            <div className="dataCard">
                                <div className="cat-txt">{selectedOptionData.categoryName}</div>
                                <div style={{ color: "#4C4F78" }} className="txt-robot">{selectedOptionData.categoryInfo}</div>
                            </div>
                        }

                    </IonRow> : listValue === "postop" ?
                        <IonRow className="pain-card">
                            <IonCol>
                                <IonText style={{ color: "black" }}>You can view your postop report submitted by hospital</IonText>
                                <div className="ion-padding-vertical">
                                    {patientDetails && patientDetails.dischageDocLink ?
                                        <IonButton style={{ margin: "0px" }} onClick={() => reportActivityLog()}>
                                            View PostOp Report
                                        </IonButton> : <p style={{ margin: "0px", fontSize: "16px", color: "black" }}>No Report Found</p>}
                                </div>
                            </IonCol>
                        </IonRow>
                        : listValue === "preop" ?
                            <IonRow className="pain-card">
                                <IonCol>
                                    <IonText style={{ color: "black" }}>You can view your preop report submitted by hospital</IonText>
                                    <div className="ion-padding-vertical">
                                        {patientDetails && patientDetails.preopDischageDocLink ?
                                            <IonButton style={{ margin: "0px" }} onClick={() => reportActivityLog()}>
                                                View PreOp Report
                                            </IonButton> : <p style={{ margin: "0px", fontSize: "16px", color: "black" }}>No Report Found</p>}
                                    </div>
                                </IonCol>
                            </IonRow>
                            : listValue === "pain" ?
                                (
                                    <IonRow className="pain-card">
                                        <IonCol>
                                            <IonText className="label">Rate Your Pain</IonText>
                                            <IonRow className="ion-justify-content-start" style={{ paddingTop: "10px" }}>
                                                {[...Array(10)].map((item, index) => {
                                                    const givenRating = index + 1;
                                                    return (
                                                        <IonRow key={index}
                                                            className={(givenRating < painRating || givenRating === painRating ? "sltrat pain-rate-circle " : "pain-rate-circle")} onClick={() => {
                                                                setPainRating(givenRating);
                                                                setErrorMessage("")
                                                            }}>
                                                            {index + 1}
                                                        </IonRow>
                                                    );
                                                })}</IonRow>
                                            {errorMessage ?
                                                <p className="fielderrormsg err-ft-14" style={{ paddingTop: "5px" }}>
                                                    {errorMessage}
                                                </p> : null
                                            }
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="pad-lr-0">
                                                    <IonLabel className="label">Message to Doctor</IonLabel>
                                                    <IonTextarea
                                                        placeholder="Write Your Message for Doctor?"
                                                        className="pain-msg-txt-box msg-txt-area" style={{ height: "116px", marginTop: "12px" }}
                                                        name="query"
                                                        value={msgToDoctor}
                                                        onIonChange={(e) => {
                                                            setMsgToDoctor(e.detail.value);
                                                        }}
                                                    ></IonTextarea>
                                                </IonCol>
                                            </IonRow>
                                            <IonButton
                                                expand="block"
                                                onClick={messageToDoctor}
                                                className="searchbtn mg-bt searchbtn-txt"
                                            >
                                                Submit
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                ) : listValue === "postCare" ?
                                    <IonRow className="ion-justify-content-start" style={{ width: "100%" }}>
                                        {postCareData && postCareData.length > 0 ?
                                            postCareData.map((item: any, index: any) => (
                                                <div className="postScore-cards dataCard" style={{ margin: "20px 5px" }} key={index}>
                                                    <div style={{ padding: "0px", height: "100%" }}>
                                                        <IonCol className="postscore-card-data">
                                                            {item.mimetype && item.mimetype.split("/")[0] === "video" ?
                                                                <div style={{ margin: "auto" }} className="pointer" onClick={() => viewPostCare("video", item.s3link)}>
                                                                    <PlayCircleOutline size="70" color="#00B9CF" />
                                                                </div> : item.mimetype && item.mimetype.split("/")[0] === "application" ?
                                                                    <div style={{ margin: "auto" }} className="pointer">
                                                                        <a href={item.s3link} className="report-btn searchbtn searchbtn-txt" rel="noreferrer">
                                                                            <DocumentText size="70" color="#00B9CF" />
                                                                        </a>
                                                                    </div> : item.mimetype && item.mimetype.split("/")[0] === "image" ? (
                                                                        <div style={{ margin: "auto" }} className="pointer" onClick={() => viewPostCare("image", item.s3link)}>
                                                                            <Image size="70" color="#00B9CF" />
                                                                        </div>
                                                                    ) : null
                                                            }
                                                            <div className="txt-robot postscore-txt-border ion-align-items-center ion-justify-content-center" style={{ display: "flex", flexDirection: "row" }}>
                                                                <div className="postcare-title">{item.title}</div>
                                                            </div>
                                                        </IonCol>
                                                    </div>
                                                </div>
                                            ))
                                            : <IonRow style={{ width: "100%" }} className='ion-justify-content-center ion-padding not-found-txt'><div>No Data Found</div></IonRow>}
                                    </IonRow>
                                    : listValue === "feedback" && patientDetails ?
                                        <FeedBack dischargeId={dischargeId} patientDetails={patientDetails}></FeedBack>
                                        : null
                }
            </div>
            <IonModal isOpen={imagePopUp} onDidDismiss={() => setImagePopUp(false)}>
                <IonRow
                    style={{ height: "6%" }}
                    className="ion-justify-content-end pointer pad-0 ion-align-items-center"
                    onClick={() => setImagePopUp(false)}
                >
                    <CloseOutline
                        title="Close"
                        size={30}
                        color="#1EB9CD"
                    ></CloseOutline>
                </IonRow>
                {postCareValue === "image" ? (
                    <div style={{ height: "94%", overflow: "hidden" }}>
                        <img
                            src={viewPostCareImage}
                            height="100%"
                            width="100%"
                            alt=""
                            style={{ objectFit: "contain" }}
                        />
                    </div>
                ) : (
                    <div style={{ height: "94%", overflow: "hidden" }}>
                        <video
                            width="100%"
                            height="100%"
                            controls={true}
                            autoPlay
                            onPlaying={playingVideo ? playingVideo : undefined}
                        >
                            <source src={viewPostCareImage} type="video/mp4" />
                        </video>
                    </div>
                )}
            </IonModal>
            <IonLoading isOpen={showLoading} message="Please await.." />
        </IonPage>
    )
}
export default SpecialInstructions;