import { IonRow } from "@ionic/react"
import { Star } from "@styled-icons/bootstrap/Star";
import { StarFill } from "@styled-icons/bootstrap/StarFill";
interface Props {
    feedback: any
 }
 const FeedBack: React.FC<Props> = (props) => {
    const feedBackData = props && props.feedback
  
    return(
        <>
         <IonRow style={{padding:"10px"}}>
                  {feedBackData &&
                    feedBackData.rating &&
                    feedBackData.comment ? (
                    <table>
                      <thead>
                        <tr>
                          <td className="list-title-txt">Rating</td>
                          <td className="list-title-txt" style={{fontWeight:"400"}}>
                            <span>
                              {feedBackData && feedBackData.rating
                                ? [...Array(5)].map((x, index) => (
                                  <span key={index}>
                                    {feedBackData &&
                                      feedBackData.rating &&
                                      feedBackData.rating &&
                                      feedBackData.rating > index ? (
                                      <span key={x}>
                                        <StarFill color="#00B9CF" size="20" />
                                      </span>
                                    ) : (
                                      <span key={x}>
                                        <Star size="20" color="#7C7878" />
                                      </span>
                                    )}
                                  </span>
                                ))
                                : "NA"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="list-title-txt ion-padding-top">Review</td>
                          <td className="list-title-txt  ion-padding-top" style={{fontWeight:"400",textTransform:"capitalize"}}>
                            {feedBackData && feedBackData.comment
                              ? feedBackData.comment
                              : "NA"}
                             
                          </td>
                        </tr>
                      </thead>
                    </table>
                  ) : (
                    <IonRow className="not-found-txt" style={{width:"100%"}}>
                      <div>Feedback not Provided</div>
                    </IonRow>
                  )}
                </IonRow>
        </>
    )
}
export default FeedBack