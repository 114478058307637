import { IonContent, IonGrid, IonPage, IonButton, IonRow, IonText, IonCol } from "@ionic/react"
const PaymentCollected: React.FC = () => {
    const digitCode = localStorage.getItem("digitCode") || ""
    return (
        <IonPage className="bg-cnt">
            <IonContent fullscreen>
                <div className="ht-100 flex-col-amnt pad-0">
                    <div className="flex-d-col ht-80 bg-clr" style={{overflowY:"hidden"}}>
                        <IonRow className="ion-justify-content-center cong-txt ion-padding-bottom"><IonText>Thank you. Your loan is created
                        </IonText></IonRow>
                        <IonRow className="ion-justify-content-center ion-padding-vertical cong-sub-txt"><IonText>Please provide this Token to the Provider
                        </IonText></IonRow>
                        <IonRow className="ion-justify-content-center cong-sub-txt"><IonText>{digitCode.substring(0, 3) + "-" + digitCode.substring(3, digitCode.length)}
                        </IonText></IonRow>
                        <IonRow className="ion-justify-content-center ion-padding-vertical">
                            <img src={require(`../../../images/dog.png`).default} alt="" />
                        </IonRow>
                    </div>
                    <div className="btn-bg">
                        <IonCol>
                            {/* <IonRow className="ion-justify-content-center clear-txt" style={{padding:"1rem"}}><IonText>Skip</IonText></IonRow> */}
                            <IonRow className="ion-justify-content-center">
                                <div className="download-app-btn" onClick={()=> localStorage.clear()}>
                                    <a className="down-app-clr provoiders-btn-txt " target="_blank" href="https://play.google.com/store/apps/details?id=app.mybenefits.ai">
                                    Download App
                                    </a>
                                </div>
                            </IonRow>
                        </IonCol>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
export default PaymentCollected