import {
  useIonToast,
  IonRow,
  IonText,
  IonLabel,
  IonCol,
  IonButton,
  IonCheckbox,
  IonInput,
  IonCard,
  IonTextarea,
  IonContent,
  IonCardContent,
  IonFooter,
  IonLoading,
  IonModal,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import instance from "../../utils/api";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import Modal from "../../components/Modal";
import * as yup from "yup";
import { Formik } from "formik";
interface Props {
  userId: string;
  // hideModal: () => void;
  name: string;
}
const SpecialInstructions: React.FC<Props> = (props) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToast, dismiss] = useIonToast();
  const [editCategoryData, setEditCategoryData] = useState<any>([]);
  const [addCategoryModal, setAddCategoryModal] = useState<boolean>(false);
  const [showsubmitBtn, setshowSubmitBtn] = useState<boolean>(false);
  const [addCategoryData, setAddCategoryData] = useState<any>([]);
  const [dischargeType, setdischargeType] = useState<any>("PreOp")
  const editCategory = async () => {
    setShowLoading(true);
    await instance({
      method: "GET",
      url: `/discharge/${props.userId}`,
      validateStatus: () => true,
    })
      .then((resp) => {
        let obj: any = {};
        obj = resp;
        setShowLoading(false);
        if (obj.status === "SUCCESS!") {
          let data = obj.result;
          console.log(data)
          setShowLoading(false);
          if (dischargeType === "PreOp") {
            setEditCategoryData(data[0].preopDocData);
            console.log("PreOp")
          } else if (dischargeType === "PostOp") {
            console.log("PostOp")
            setEditCategoryData(data[0].docData);
          }

        } else {
          setShowLoading(false);
        }
      })
      .catch((err) => {
        setShowLoading(false);
      });
  };



  const validationSchema = yup.object({
    info: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
  });

  const addCategory = (values: any) => {
    setAddCategoryModal(false);
    var data = [
      {
        categoryName: values.name,
        categoryInfo: values.info,
        display: true,
      },
    ];
    if (addCategoryData === undefined) {
      setAddCategoryData([...data])
    }
    else {
      setAddCategoryData([...data, ...addCategoryData]);
    }

    // if(editCategoryData === undefined){
    //   setEditCategoryData([...data]);
    // }
    // else {
    //   //setEditCategoryData([...data, ...editCategoryData]);
    //   console.log(editCategoryData)
    //   setEditCategoryData([...data, ...editCategoryData]);
    //   //setEditCategoryData([...data.map((data) => ({ ...data })), ...editCategoryData,]);
    // }
  };

  const handleChange = (e: any, id: any, value: any) => {
    var result = [...editCategoryData];
    result = result.map((x) => {
      if (x._id === id) {
        if (value === "check") {
          x.display = e.target.checked;
        }
        if (value === "input") {
          x.categoryInfo = e.detail.value;
        }
        if (value === "name") {
          x.categoryName = e.detail.value;
        }
      }
      return x;
    });
    setEditCategoryData(result);
  };

  const submitCategory = async () => {
    setShowLoading(true);
    const myArrayCopy = [...addCategoryData, ...editCategoryData];
    let data: any = {};
    if(dischargeType === "PreOp"){
      data.preopDocData = myArrayCopy
    }
    else if(dischargeType === "PostOp"){
      data.docData = myArrayCopy
    }
   
    data.notification = true
    await instance({
      method: "PUT",
      url: `/discharge/doccument/${props.userId}`,
      data,
      validateStatus: () => true,
    })
      .then((resp) => {
        let obj: any = {};
        obj = resp;
        setShowLoading(false);
        if (obj.status === "SUCCESS!") {
          setShowLoading(false);
          //props.hideModal();
          editCategory();
          showToast("Submited Successfully", 3000);
        } else {
          setShowLoading(false);
        }
      })
      .catch((err) => {
        setShowLoading(false);
      });
  };

  useEffect(()=>{
    editCategory()
  },[dischargeType])
  return (
    <>
      <IonSegment>
        <IonSegment mode="ios" value={dischargeType} style={{ margin: "10px" }}>
          <IonSegmentButton
            value="PreOp"
            onClick={() => {
              setdischargeType("PreOp");
              editCategory();
            }}

          >
            <IonLabel>
              Preop
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="PostOp"
            onClick={() => {
              setdischargeType("PostOp");
              editCategory();
            }}

          >
            <IonLabel>
              Postop
            </IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonSegment>
      <IonRow style={{ padding: "10px" }} className="ion-align-items-center ion-justify-content-between"> <IonButton
        className="button button-txt" style={{ margin: "0px" }}
        onClick={() => setAddCategoryModal(true)}
      >
        Add category
      </IonButton>
        {showsubmitBtn ||
          (addCategoryData && addCategoryData.length > 0) ? (
          <IonRow className="ion-padding-vertical ion-justify-content-center">
            <IonButton
              className="btn-wd-100 nextbtn"
              onClick={() => submitCategory()}
            >
              Save
            </IonButton>
          </IonRow>
        ) : (<IonRow className="ion-padding-vertical ion-justify-content-center">

        </IonRow>
        )}
      </IonRow>
      <IonRow className="ion-justify-content-start" style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <IonCol className="pad-0">
            <IonRow
              className="ion-justify-content-start"
              style={{ width: "100%", padding: "0px" }}
            >
              {addCategoryData && addCategoryData.length > 0
                ? addCategoryData.map((value, index) => (
                  <IonCard className="specialins-card-wd category-box" key={index}>
                    <IonCardContent>
                      <div className="txt-robot">
                        <IonRow className="ion-align-items-center ion-justify-content-between">
                          <IonInput
                            type="text"
                            value={value.categoryName}
                            style={{ margin: "0px 15px 0px 0px" }}
                            className="input-field mg-top placeholder-txt"
                            readonly
                          ></IonInput>
                          <IonCheckbox
                            mode="ios"
                            disabled
                            //onFocus={() => setshowSubmitBtn(true)}
                            checked={value.display}

                          ></IonCheckbox>
                        </IonRow>
                        <IonTextarea
                          name="message"
                          placeholder="Type here."
                          className="dis-txt-area msg-txt-area"
                          style={{ height: "80px", borderRadius: "5px" }}
                          value={value.categoryInfo}
                          readonly
                        ></IonTextarea>
                      </div>
                    </IonCardContent>
                  </IonCard>
                ))
                : null}
              {(editCategoryData && editCategoryData.length > 0) ? (
                editCategoryData.map((value: any, index: any) => (
                  <IonCard className="specialins-card-wd category-box" key={index}>

                    <IonCardContent>
                      <div className="txt-robot">
                        <IonRow className="ion-align-items-center ion-justify-content-between">
                          <IonInput
                            type="text"
                            value={value.categoryName}
                            onFocus={() => setshowSubmitBtn(true)}
                            style={{ margin: "0px 15px 0px 0px" }}
                            onIonChange={(e) =>
                              handleChange(e, value._id, "name")
                            }
                            className="input-field mg-top placeholder-txt"
                          ></IonInput>
                          {/* <div style={{padding:"0px 15px 0px 0px"}}>{value.categoryName}</div> */}
                          {/* <input type="checkbox" value={value.display} checked={value.display} onChange={(e) => handleroom(e, value._id)}/> */}
                          <IonCheckbox
                            mode="ios"
                            onFocus={() => setshowSubmitBtn(true)}
                            value={value.display}
                            checked={value.display}
                            onIonChange={(e) =>
                              handleChange(e, value._id, "check")
                            }
                          ></IonCheckbox>
                        </IonRow>
                        <IonTextarea
                          onFocus={() => setshowSubmitBtn(true)}
                          name="message"
                          placeholder="If you want to send a message to patient, type your message here."
                          className="dis-txt-area msg-txt-area"
                          style={{ height: "80px", borderRadius: "5px" }}
                          value={value.categoryInfo}
                          onIonChange={(e) =>
                            handleChange(e, value._id, "input")
                          }
                        ></IonTextarea>
                      </div>
                    </IonCardContent>

                  </IonCard>
                ))
              ) : (
                <IonRow className="not-found-txt" style={{ width: "100%" }}>
                  <div>No Data Found</div>
                </IonRow>
              )}
            </IonRow>
          </IonCol>
        </div>
      </IonRow>
      <IonModal isOpen={addCategoryModal} id="add-category-modal" onDidDismiss={() => setAddCategoryModal(false)}>
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="popup-header"
        >
          <IonRow
            className="close pointer"
            onClick={() => setAddCategoryModal(false)}
          >
            <CloseOutline
              title="Close"
              size={30}
              color="#FFFFFF"
            ></CloseOutline>
          </IonRow>
          <IonRow
            className="ion-justify-content-center popup-title ion-padding"
            style={{ fontSize: "30px" }}
          >
            <IonText>Add Category</IonText>
          </IonRow>
        </div>
        <IonContent>
          <Formik
            initialValues={{ name: "", info: "" }}
            onSubmit={addCategory}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <form onSubmit={formikProps.handleSubmit} id="form">
                <IonRow style={{ maxWidth: "430px", margin: "0 auto" }}>
                  <IonCol className="fields-row-pd">
                    <IonRow className="ion-padding-top">
                      <IonCol className="pad-0">
                        <IonLabel className="label">Enter Title</IonLabel>
                        <IonInput
                          name="name"
                          placeholder="Category Name"
                          className="ion-padding input-field mg-top placeholder-txt"
                          type="text"
                          onBlur={formikProps.handleBlur}
                          onIonChange={formikProps.handleChange}
                          value={formikProps.values.name}
                        ></IonInput>
                        <p
                          className="fielderrormsg"
                          style={{ marginBottom: "0px" }}
                        >
                          {formikProps.touched.name &&
                            formikProps.errors.name}
                        </p>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                      <IonCol className="pad-0">
                        <IonLabel className="label">
                          Enter Description
                        </IonLabel>
                        <IonTextarea
                          name="info"
                          placeholder="Category Info"
                          className="dis-txt-area review-txt-area"
                          onBlur={formikProps.handleBlur}
                          onIonChange={formikProps.handleChange}
                          value={formikProps.values.info}
                        ></IonTextarea>
                        <p
                          className="fielderrormsg"
                          style={{ marginBottom: "0px" }}
                        >
                          {formikProps.touched.info &&
                            formikProps.errors.info}
                        </p>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </form>
            )}
          </Formik>
        </IonContent>
        <IonFooter>
          <IonRow className="ion-padding-vertical ion-justify-content-center">
            <IonButton form="form" className="button button-txt" style={{ margin: "0px" }} type="submit">
              Add
            </IonButton>
          </IonRow>
        </IonFooter>
      </IonModal>
      <IonLoading isOpen={showLoading} message={'Please wait...'} />
    </>
  );
};
export default SpecialInstructions;
