import { IonContent, IonGrid, IonDatetime, IonSelect, IonSelectOption, IonLoading, useIonToast, IonPage, IonRow, IonText, IonButton, IonCol, IonInput, IonLabel, IonModal } from "@ionic/react"
import { useState, useEffect, } from "react"
import ProvidersHeader from "../../../components/providersInvite/providersHeader";
import './index.css';
import { Formik, } from 'formik';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import instance from "../../../utils/api";
import moment from "moment";
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
const PatientInfo: React.FC = () => {
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [headerTitle, setHeaderTitle] = useState<any>("Verify Your Identity");
    const [showToast, dismiss] = useIonToast();
    const phoneNumber = localStorage.getItem('phoneNumber');
    const email = localStorage.getItem('email')
    const [manualDetailsBlock, setManualDetailsBlock] = useState<any>(false);
    const [states, setGetStates] = useState<any>([]);
    const secondId = localStorage.getItem("secondId") || ""
    const providerId = localStorage.getItem("inviteProId") || ""
    // useEffect(() => {
    //     if (manualDetailsBlock === false) {
    //         setHeaderTitle("Scan driver license or state-issued ID")
    //     }
    //     else if (manualDetailsBlock === true) {
    //         setHeaderTitle("Verify your identity")
    //     }
    // }, [manualDetailsBlock])

    // useEffect(() => {
    //     // Object.keys(Formik.errors)[0])[0].scrollIntoView({ block: 'center'})
    //     // Object.keys(Formik.errors)[0])[0].focus({ preventScroll: true})
    //     console.log(Formik.errors)
    // }, [])

    const validationSchema = yup.object({
        firstName: yup
            .string()
            .required("This field is required"),
        lastName: yup
            .string()
            .required("This field is required"),
        gender: yup
            .string()
            .required("This field is required"),
        address: yup
            .string()
            .required("This field is required"),
        dateOfBirth: yup
            .string()
            .required("This field is required")
            .test(
                "DOB",
                "Date of Birth should be Greater than 16 years",
                (date) => moment().diff(moment(date), "years") >= 16
            ),
        driverlicense: yup
            .string()
            .required("This field is required"),
        securityNumber: yup
            .string()
            .required("This field is required")
            .min(4, 'SecurityNumber must be 4 digits.'),
        state: yup
            .string()
            .required("This field is required")
    });
    const getStates = async () => {
        await instance({
            method: 'GET',
            url: 'user/getStates',
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp
            if (obj.status === "SUCCESS!") {
                setShowLoading(false);
                obj.result.sort((a: any, b: any) => a._id.localeCompare(b._id))
                setGetStates(obj.result)
            }
            else {
                setShowLoading(false);
                showToast(obj.error.message, 3000)
            }
        })
    }
    useEffect(() => {
        getStates()
    }, [states.length > 0])

    const submit = async (values: any) => {
        setShowLoading(true);
        let data: any = {}
        data.firstName = values.firstName
        data.lastName = values.lastName
        data.address = values.address
        data.dob = values.dateOfBirth
        data.gender = values.gender
        data.ssn = values.securityNumber
        data.state = values.state
        data.drivingLicense = values.driverlicense
        if (secondId) {
            data.id = secondId
        }
        data.provider = providerId
        data.email = email
        data.phoneNumber = phoneNumber
        if (data) {
            await instance({
                method: 'POST',
                url: 'user/employee',
                data,
                validateStatus: () => true
            }).then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    instance.defaults.headers.common['Authorization'] = obj.result.token;
                    localStorage.setItem("patientName", values.firstName + " " + values.lastName)
                    localStorage.setItem("auth_token", obj.result.token);
                    window.location.href = "/amount_approved";
                }
                else {
                    setShowLoading(false);
                    showToast(obj.error.message, 3000)
                }
            })
        }
    }

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            gender: "",
            address: "",
            dateOfBirth: "",
            securityNumber: "",
            driverlicense: "",
            state: ""
        },
        validationSchema: yup.object({
            firstName: yup
                .string()
                .required("This field is required"),
            lastName: yup
                .string()
                .required("This field is required"),
            gender: yup
                .string()
                .required("This field is required"),
            address: yup
                .string()
                .required("This field is required"),
            dateOfBirth: yup
                .string()
                .required("This field is required")
                .test(
                    "DOB",
                    "Date of Birth should be Greater than 16 years",
                    (date) => moment().diff(moment(date), "years") >= 16
                ),
            driverlicense: yup
                .string()
                .required("This field is required"),
            securityNumber: yup
                .string()
                .required("This field is required")
                .min(4, 'SecurityNumber must be 4 digits.'),
            state: yup
                .string()
                .required("This field is required")
        }),
        onSubmit: values => {
            setShowLoading(true);
            console.log(values)
            let data: any = {}
            data.firstName = values.firstName
            data.lastName = values.lastName
            data.address = values.address
            data.dob = values.dateOfBirth
            data.gender = values.gender
            data.ssn = values.securityNumber
            data.state = values.state
            data.drivingLicense = values.driverlicense
            if (secondId) {
                data.id = secondId
            }
            data.provider = providerId
            data.email = email
            data.phoneNumber = phoneNumber
            if (data) {
                instance({
                    method: 'POST',
                    url: 'user/employee',
                    data,
                    validateStatus: () => true
                }).then((resp) => {
                    let obj: any = {}
                    obj = resp
                    if (obj.status === "SUCCESS!") {
                        setShowLoading(false);
                        instance.defaults.headers.common['Authorization'] = obj.result.token;
                        localStorage.setItem("patientName", values.firstName + " " + values.lastName)
                        localStorage.setItem("auth_token", obj.result.token);
                        window.location.href = "/amount_approved";
                    }
                    else {
                        setShowLoading(false);
                        showToast(obj.error.message, 3000)
                    }
                })
            }
        },
    });
    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) {
            const input = document.querySelector(`input[name=${Object.keys(formik.errors)[0]}]`);
            input!.scrollIntoView({ behavior: "smooth", block: "center" })
        }
    }, [formik]);

    return (
        <IonPage className="bg-cnt">
            {/* <ProvidersHeader title={headerTitle}></ProvidersHeader> */}
            {/* {manualDetailsBlock === false ?
                <IonContent fullscreen>
                    <IonGrid className="ht-100 ion-padding">
                        <IonRow className="ht-100 ion-justify-content-center">
                            <div className="ht-100 pro-flex-col">
                                <IonRow className="tnpltxt ion-padding">
                                    <IonText> Postion the barcode on the back of the your card in the frame.</IonText>
                                </IonRow>
                                <IonRow className="ion-justify-content-center ion-padding">
                                    <IonButton expand="block" className='pro-btn provoiders-btn-txt' onClick={() => setManualDetailsBlock(true)}>Enter details manually instead</IonButton>
                                </IonRow>
                            </div>
                        </IonRow>
                    </IonGrid>
                </IonContent> : */}
            {/* <IonModal isOpen={manualDetailsBlock} cssClass="pro-modal bg-cnt" onDidDismiss={() => setManualDetailsBlock(false)}> */}
            <ProvidersHeader title={headerTitle}></ProvidersHeader>
            <IonContent fullscreen>
                <IonGrid className="ht-100">
                    <div className="ht-100 pro-flex-col">
                        <IonRow className="tnpltxt ion-padding">
                            <IonText> Have a better way receive care and wellness with easy and flexible Treat Now, Pay Later plans with myBenefits.ai</IonText>
                        </IonRow>
                        <IonRow className="wd-100 ion-justify-content-center">
                            {/* <Formik
                                        initialValues={{
                                            firstName: "",
                                            lastName: "",
                                            gender: "",
                                            address: "",
                                            dateOfBirth: "",
                                            securityNumber: "",
                                            driverlicense: "",
                                            state: ""
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={submit}>
                                        {formik => (
                                            */}
                            <div className='fieldiv ion-padding wd-100 fields-pd'>
                                <IonCol className="fields-row-pd">
                                    <form onSubmit={formik.handleSubmit}>
                                        <IonRow className='ion-padding-top'>
                                            <IonCol className='pad-lr-0'>
                                                <IonLabel className='pro-label-txt'>First Name</IonLabel>
                                                <IonInput type="text" name="firstName" className="pro-inpt-field mg-field-top"
                                                    placeholder="Enter" value={formik.values.firstName}
                                                    onBlur={formik.handleBlur}
                                                    onIonChange={formik.handleChange}
                                                > {formik.values.firstName && formik.touched.firstName && !formik.errors.firstName ?
                                                    <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}</IonInput>
                                                <p className="fielderrormsg">
                                                    {formik.touched.firstName && formik.errors.firstName}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-padding-top'>
                                            <IonCol className='pad-lr-0'>
                                                <IonLabel className='pro-label-txt'>Last Name</IonLabel>
                                                <IonInput type="text" name="lastName" className="pro-inpt-field mg-field-top"
                                                    placeholder="Enter" value={formik.values.lastName}
                                                    onBlur={formik.handleBlur}
                                                    onIonChange={formik.handleChange}
                                                > {formik.values.lastName && formik.touched.lastName && !formik.errors.lastName ?
                                                    <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}</IonInput>
                                                <p className="fielderrormsg">
                                                    {formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ""}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-padding-top'>
                                            <IonCol className='pad-lr-0'>
                                                <IonLabel className='pro-label-txt'>Gender</IonLabel>
                                                <IonRow className="ion-justify-content-between">
                                                    <div className="pro-inpt-field mg-field-top select-box">
                                                        <IonSelect style={{ width: "100%" }} placeholder="Select" name="gender" onIonChange={(e) => {
                                                            formik.setFieldValue("gender", e.detail.value)

                                                        }

                                                        } onBlur={() => formik.handleBlur}>
                                                            <IonSelectOption value="male">Male</IonSelectOption>
                                                            <IonSelectOption value="female">Female</IonSelectOption>
                                                            <IonSelectOption value="other">Others</IonSelectOption>

                                                        </IonSelect>
                                                        {formik.values.gender && !formik.errors.gender ?
                                                            <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                    </div>

                                                </IonRow>
                                                <p className="fielderrormsg">
                                                    {formik.touched.gender && formik.errors.gender}
                                                </p>

                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-padding-top'>
                                            <IonCol className='pad-lr-0'>
                                                <IonLabel className='pro-label-txt'>Address</IonLabel>
                                                <IonInput type="text" name="address" className="pro-inpt-field mg-field-top"
                                                    placeholder="Enter" value={formik.values.address}
                                                    onBlur={formik.handleBlur}
                                                    onIonChange={formik.handleChange}
                                                > {formik.values.address && formik.touched.address && !formik.errors.address ?
                                                    <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}</IonInput>
                                                <p className="fielderrormsg">
                                                    {formik.touched.address && formik.errors.address}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-padding-top'>
                                            <IonCol className='pad-lr-0'>
                                                <IonLabel className='pro-label-txt'>Date of Birth</IonLabel>
                                                {/* <IonInput clearInput={true} type="text" name="dateOfBirth" className="pro-inpt-field mg-field-top"
                                                                        placeholder="Enter" value={formik.values.dateOfBirth}
                                                                        onIonChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    > </IonInput> */}
                                                <DatePicker placeholderText="MM/DD/YYYY" dateFormat="MM/dd/yyyy" name="dateOfBirth"
                                                    className='date-pd-10 pro-inpt-field mg-field-top width-100 date-z-index' selected={formik.values.dateOfBirth}
                                                    onChange={(val: any) => {
                                                        formik.setFieldValue("dateOfBirth", val);
                                                    }}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onBlur={formik.handleBlur}>
                                                        {formik.values.dateOfBirth && formik.touched.dateOfBirth && !formik.errors.dateOfBirth ?
                                                        <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                        </DatePicker>

                                                <p className="fielderrormsg">
                                                    {formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-padding-top'>
                                            <IonCol className='pad-lr-0'>
                                                <IonLabel className='pro-label-txt'>Last Four Social Security Number</IonLabel>
                                                <div className="pro-inpt-field mg-field-top num-format-wd-100  number-flex">
                                                    <NumberFormat
                                                        placeholder="Enter"
                                                        name="securityNumber"
                                                        className="num-for-br-no"
                                                        type="password"
                                                        format="####"
                                                        mask=""
                                                        value={formik.values.securityNumber}
                                                        onValueChange={val => formik.setFieldValue("securityNumber", val.value)}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.values.securityNumber && formik.touched.securityNumber && !formik.errors.securityNumber ?
                                                        <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                </div>

                                                <p className="fielderrormsg">
                                                    {formik.touched.securityNumber && formik.errors.securityNumber}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-padding-top'>
                                            <IonCol className='pad-lr-0'>
                                                <IonLabel className='pro-label-txt'>Driver’s license #</IonLabel>
                                                <IonInput type="text" name="driverlicense" className="pro-inpt-field mg-field-top"
                                                    placeholder="Enter" value={formik.values.driverlicense}
                                                    onBlur={formik.handleBlur}
                                                    onIonChange={formik.handleChange}
                                                > {formik.values.driverlicense && formik.touched.driverlicense && !formik.errors.driverlicense ?
                                                    <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}</IonInput>
                                                <p className="fielderrormsg">
                                                    {formik.touched.driverlicense && formik.errors.driverlicense}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-padding-top'>
                                            <IonCol className='pad-lr-0'>
                                                <IonLabel className='pro-label-txt'>State</IonLabel>
                                                <IonRow className="ion-justify-content-between">
                                                    <div className="pro-inpt-field mg-field-top select-box">
                                                        <IonSelect style={{ width: "100%" }} placeholder="Select" name="state" onIonChange={(e: any) =>
                                                            formik.setFieldValue("state", e.target.value)
                                                        } onBlur={() => formik.handleBlur}>
                                                            {states.length > 0 &&
                                                                states.map((value: any, key: any) => {
                                                                    return (
                                                                        <div key={key}>
                                                                            <IonSelectOption value={value._id}>{value._id}</IonSelectOption>
                                                                        </div>
                                                                    )
                                                                })}
                                                        </IonSelect>
                                                        {formik.values.state && !formik.errors.state ?
                                                            <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                    </div>
                                                </IonRow>
                                                <p className="fielderrormsg">
                                                    {formik.touched.state && formik.errors.state}
                                                </p>

                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center ion-padding-vertical">
                                            <IonButton expand="block" type='submit' className='provoiders-btn provoiders-btn-txt ion-no-margin'>Let’s verify my identity</IonButton>
                                        </IonRow>
                                    </form>
                                </IonCol>
                            </div>
                            {/* )}
                                    </Formik> */}
                        </IonRow>
                    </div>
                </IonGrid>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
            {/* </IonModal> */}
            {/* } */}
        </IonPage>
    )
}
export default PatientInfo