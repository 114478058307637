import React, { Component } from 'react'

const CommonContext = React.createContext()

class CommonProvider extends Component {
    // Context state
    
    constructor(props) {
        super(props);
        this.state = {
            common: {
                providerInviteData:{
                    providerInviteObj:{}
                },
                signUpData : {
                    ProviderEmailId: '',
                },
                providerData:{
                    providerObj:{}
                },
                requestAmount:{},
                signUpDetails: {
                    ageGroupMin: 25,
                    ageGroupMax: 75,
                    noOfEmployees: 0,
                    annualMaxBenefit: 0,
                    stopLoss: 0,
                    employerSharePercent: 50,
                    employeeSharePercent: 50
                },
                createPassword: {
                    newPassword: '',
                    confirmPassword: ''
                },
                orgData: {
                    name: '',
                    taxId: '',
                    address : {
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zip: ''
                    }
                },
                bank: {
                    number: '',
                    route: '',
                    name: '',
                },
                billingBank: {
                    number: '',
                    route: '',
                    name: '',
                },

                auth: {
                    isAuthenticated: false,
                    authObj: {}
                },
                patientData:{
                    patientObj:{}
                },
               digitCode:'',
               procodeView:false,
               progressBar:0.9,
               termsCondView:true
            }
        }
        this.setCommon = this.setCommon.bind(this);
    }

    // Method to update state
    setCommon = (common) => {
        this.setState((prevState) => ({ common }))
    }

    render() {
        const { children } = this.props
        const { common } = this.state
        const { setCommon } = this;
        return (
            <CommonContext.Provider
                value={{
                    common,
                    setCommon,
                }}
            >
                {children}
            </CommonContext.Provider>
        )
    }
}
export { CommonProvider };
export default CommonContext
