import { IonContent, IonGrid, IonLoading, useIonToast, IonPage, IonRow, IonCard, IonCardContent, IonText, IonButton, IonCol, IonCheckbox, IonLabel, IonToggle, IonModal, } from "@ionic/react"
import { useState, useEffect } from "react";
import ProvidersHeader from "../../../components/providersInvite/providersHeader";
import './index.css';
import { Formik, } from 'formik';
import * as yup from 'yup';
import NumberFormat from "react-number-format";
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import axios from "axios";
import instance from "../../../utils/api";
import { handleInputChange } from "react-select/dist/declarations/src/utils";
var CryptoJS = require("crypto-js");
const PaymentMethod: React.FC = () => {
    const [headerTitle, setHeaderTitle] = useState<any>("");
    const [wrAccRouteNo, setWrAccRouteNo] = useState(false)
    const [DDA, setDDA] = useState<any>(false);
    const [checkError, setCheckError] = useState<any>("")
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showToast, dismiss] = useIonToast();
    const [key, setKey] = useState<any>("AC1f7b6b8f1325f67ad887e297c69dd9de");
    const [bankConfirm, setBankConfirm] = useState<any>(false);
    const [bankName, setBankName] = useState('')
    const [route, setRoute] = useState<any>();
    
    useEffect(() => {
        axios
            .get(`https://www.routingnumbers.info/api/data.json?rn=${route}`)
            .then(data => {
                setBankName(data.data.customer_name);
            })
    }, [route])

    useEffect(() => {
        if (bankConfirm === false) {
            setHeaderTitle("Payment By Bank")
        }
        else if (bankConfirm === true) {
            setHeaderTitle("Bank Confirmation")
        }
    }, [bankConfirm])
    const validationSchema = yup.object({
        accountNo: yup
            .string()
            .min(8, "Account Number should contain min 8 digits or max 17 digits.")
            .max(17, "Account Number should contain min 8 digits or max 17 digits.")
            .required("This field is required"),
        routingNo: yup
            .string()
            .min(9, "Routing Number should be Exact 9 Digits.")
            .required("This field is required"),
    });
    const confirmBank = async (values:any) => {
        let bank: any = {}
        bank.number = values.accountNo
        bank.route = values.routingNo
        bank.name = bankName
        console.log(route,bankName,bank)
        if(DDA == false){
            setCheckError("Please Accept direct debit agreement");
        }
        else {
            setShowLoading(true);
            let encrypt = CryptoJS.AES.encrypt(JSON.stringify(bank), key).toString();
                let res: any = '';
                res = encrypt;
                let data: any = {};
                data.loanBank = res;
                data.isloanBankAdded = true;
                await instance({
                    method: 'PUT',
                    url: 'user/updateEmployee',
                    data,
                    validateStatus: () => true
                }).then((response) => {
                    let obj: any = {}
                    obj = response;
                    if (obj.status === "SUCCESS!") {
                         window.location.href = "/agreement"
                        setShowLoading(false);
                    }
                    else {
                        setShowLoading(false);
                         showToast(obj.error.message, 3000)
                    }
                })
        }
    }

    const submit=()=>{
        if (!bankName) {
            showToast("Enter valid Routing Number", 2000)
        }
        else if(DDA == false){
            setCheckError("Please Accept Direct Debit Agreement");
        }
        else {
            setBankConfirm(true)
    }
}
    return (
        <IonPage className="bg-cnt">
            <ProvidersHeader title={headerTitle} />
            <IonContent fullscreen>
                <IonGrid className="ht-100">
                    <div className="ht-100 pro-flex-col ion-justify-content-start">
                        <div className="pay-txt sub-txt-pd ion-padding">
                            {/* <div>
                                <IonText>Lorem ipsum dolor sit amet, consectetur a <br></br> Lorem ipsum dolor sit amet, consectetur a</IonText>
                            </div>
                            <div className="ion-padding-vertical">
                                <IonText className="pay-txt">0Lorem ipsum dolor sit amet, consectetur a</IonText>
                            </div> */}
                        </div>
                        <IonRow className="rout-txt ion-justify-content-center ion-padding-vertical" onClick={() => { setWrAccRouteNo(!wrAccRouteNo) }}>
                            <IonText>Where is my routing & account number?</IonText>
                        </IonRow>
                        {wrAccRouteNo ?
                            <IonGrid>
                                <IonRow className='ion-justify-content-center ion-text-center vh-15'>
                                </IonRow>
                                <IonCard style={{ height: "140px", backgroundColor: 'white' }}>
                                    <IonCardContent style={{ padding: "5px 5px 5px 5px" }}>
                                        <IonRow>
                                            <IonCol size="2">
                                                <div className='circleash'>
                                                </div>
                                            </IonCol>
                                            <IonCol size="8" className='pt-14 pl-20' style={{ paddingLeft: '20px' }}>
                                                <div className='ashlines'></div>
                                                <div className='ashlines-2' style={{ marginTop: "10px" }}></div>
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-end'>
                                                <div className='color-ash'>001</div>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size="2">
                                                <div className='ashlines-3'></div>
                                                <div className='ashlines-3 mt-5'></div>
                                            </IonCol>
                                            <IonCol size="6">
                                                <div className='ashlines-4 mt-15'></div>
                                                {/* <div className='ashlines-2' style={{marginTop:"10px"}}></div> */}
                                            </IonCol>
                                            <IonCol size="4" className='mrt-50'>
                                                $<div className='ashlines-1'></div>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size="12" className='color-ash'>
                                                :| 123456789  :| 000987654321 || 001
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                                <IonRow className='ion-justify-content-center'>
                                    <IonCol size="1">
                                    </IonCol>
                                    <IonCol size="5">
                                        <div className="vl"></div><br />
                                        <IonText>Routing Number</IonText>
                                    </IonCol>
                                    <IonCol size="5">
                                        <div className="vl"></div><br />
                                        <IonText>Account Number</IonText>
                                    </IonCol>
                                    <IonCol size="1">
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                            : null}
                        <IonRow className="wd-100 ion-justify-content-center">
                            <Formik
                                initialValues={{
                                    accountNo: "",
                                    routingNo: "",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={submit}>
                                {formikProps => (
                                    <div className='fieldiv ion-padding wd-100'>
                                        <IonCol>
                                            <form onSubmit={formikProps.handleSubmit}>
                                                <IonRow className='ion-padding-top'>
                                                    <IonCol className='pad-lr-0'>
                                                        <IonLabel className='pro-label-txt'>Account Number</IonLabel>
                                                        <div className="pro-inpt-field mg-field-top num-format-wd-100  number-flex">
                                                            <NumberFormat
                                                                name="accountNo"
                                                                placeholder="###-##-####"
                                                                format="#################"
                                                                className="num-for-br-no"
                                                                value={formikProps.values.accountNo}
                                                                onValueChange={val => {formikProps.setFieldValue("accountNo", val.value)}}
                                                                onBlur={formikProps.handleBlur}
                                                            />
                                                            {formikProps.values.accountNo != null && formikProps.touched.accountNo && !formikProps.errors.accountNo ?
                                                                <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                        </div>

                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.accountNo && formikProps.errors.accountNo}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-padding-top'>
                                                    <IonCol className='pad-lr-0'>
                                                        <IonLabel className='pro-label-txt'>Routing Number</IonLabel>
                                                        <div className="pro-inpt-field mg-field-top num-format-wd-100  number-flex">
                                                            <NumberFormat
                                                                name="routingNo"
                                                                placeholder="###-##-####"
                                                                format="#########"
                                                                className="num-for-br-no"
                                                                value={formikProps.values.routingNo}
                                                                onValueChange={val => {formikProps.setFieldValue("routingNo", val.value);setRoute(val.value) }}
                                                                onBlur={formikProps.handleBlur}
                                                            />
                                                            {formikProps.values.routingNo != null && formikProps.touched.routingNo && !formikProps.errors.routingNo ?
                                                                <CheckCircleFill size="25" color="#00B9CF" className="icon-mg-5" /> : null}
                                                        </div>

                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.routingNo && formikProps.errors.routingNo}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                                {bankName &&
                                                    <IonRow className='ion-padding-vertical'>
                                                        <IonCol className="pad-0">
                                                            <IonLabel className='pro-label-txt'>Bank Name</IonLabel>
                                                            <div className="mg-field-top bnk-name">
                                                                <IonText>{bankName}</IonText>
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                }
                                                <IonRow className="rout-txt ion-justify-content-center ion-padding-vertical">
                                                    <IonText>Accept Direct Debit Agreement (DDA)?</IonText>
                                                </IonRow>
                                                <IonRow>
                                                    <IonToggle name="isChecked" className="DDA-toggle" value={DDA} onIonChange={e => {setDDA(e.detail.checked);
                                                        setCheckError(false)}}></IonToggle>
                                                </IonRow>
                                                <p className="fielderrormsg">
                                                    {checkError ? checkError : ""}
                                                </p>
                                                <IonRow className="ion-justify-content-center ion-padding-vertical">
                                                    <IonButton expand="block" type="submit" className='provoiders-btn provoiders-btn-txt'>Next</IonButton>
                                                </IonRow>
                                                {bankConfirm == true ?
                                                    <IonModal isOpen={bankConfirm} className="pro-modal bg-cnt" onDidDismiss={() => setBankConfirm(false)}>
                                                        <ProvidersHeader title={headerTitle}></ProvidersHeader>
                                                        <IonContent fullscreen>
                                                            <div className="ht-100 pro-flex-col ion-justify-content-start">
                                                                <div className="pay-txt sub-txt-pd ion-padding">
                                                                    {/* <div>
                                                                        <IonText>Lorem ipsum dolor sit amet, consectetur a <br></br> Lorem ipsum dolor sit amet, consectetur a</IonText>
                                                                    </div>
                                                                    <div className="ion-padding-vertical">
                                                                        <IonText className="pay-txt">0Lorem ipsum dolor sit amet, consectetur a</IonText>
                                                                    </div> */}
                                                                </div>
                                                                <div className='fieldiv ion-padding wd-100 fields-pd'>
                                                                    <IonCol>
                                                                        <IonRow className='ion-padding-top'>
                                                                            <IonCol className='pad-lr-0'>
                                                                                <IonLabel className='pro-label-txt'>Account Number</IonLabel>
                                                                                <div className="bank-details-txt mg-field-top">
                                                                                <IonText>{(formikProps.values.accountNo).slice(-4).padStart(formikProps.values.accountNo.length, 'X')}</IonText>
                                                                                </div>
                                                                                
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        <IonRow className='ion-padding-top'>
                                                                            <IonCol className='pad-lr-0'>
                                                                                <IonLabel className='pro-label-txt'>Routing Number</IonLabel>
                                                                                <div className="bank-details-txt mg-field-top">
                                                                                <IonText>{(formikProps.values.routingNo).slice(-4).padStart(formikProps.values.routingNo.length, 'X')}</IonText>
                                                                                </div>
                                                                               
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        <IonRow className='ion-padding-top'>
                                                                            <IonCol className='pad-lr-0'>
                                                                                <IonLabel className='pro-label-txt'>Bank Name</IonLabel>
                                                                                <div className="bank-details-txt mg-field-top">
                                                                                <IonText>{bankName && bankName ?  bankName : "None"}</IonText>
                                                                                </div>
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        <IonRow className="ion-justify-content-center ion-padding-vertical confirm-btn">
                                                                            <IonButton expand="block" type='submit' onClick={()=>confirmBank(formikProps.values)} className='provoiders-btn provoiders-btn-txt'>Confirm</IonButton>
                                                                        </IonRow>
                                                                    </IonCol>
                                                                </div>
                                                            </div>
                                                        </IonContent>
                                                    </IonModal> : null}


                                            </form>
                                        </IonCol>
                                    </div>
                                )}
                            </Formik>
                        </IonRow>
                    </div>
                </IonGrid>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
        </IonPage>
    )
}
export default PaymentMethod