import {
  IonContent,
  IonPage,
  IonRow,
  IonButton,
  IonText,
  useIonToast,
  IonCol,
  IonFooter,
  IonToolbar,
  IonGrid,
  IonTextarea,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonModal,
  IonInput,
} from "@ionic/react";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import "./index.css";
import instance from "../../utils/api";
import Loader from "../../components/Loader";
import { CheckAll } from "@styled-icons/bootstrap/CheckAll";
import ReactTooltip from "react-tooltip";
import { Star } from "@styled-icons/bootstrap/Star";
import { StarFill } from "@styled-icons/bootstrap/StarFill";
import { useHistory } from "react-router-dom";
import Ratings from "../DischargeDocuments/Ratings";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";
import NumberFormat from "react-number-format";

const QnAReports: React.FC = () => {
  const history = useHistory<any>();
  const [dischargeList, setDischargeList] = useState<any>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [FloraAnswerBlock, setFloraAnswerBlock] = useState<any>(false);
  const [QnAMonitorlist, setQnAMonitorlist] = useState<any>([]);
  const [QnAMonitorItem, setQnAMonitorItem] = useState<any>([]);
  const [editAnswer, seteditAnswer] = useState<any>("");
  const [askedQuestion, setAskedQuestion] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [selectedFloraItem, setSelectedFloraItem] = useState<any>([]);
  const [isRead, setIsRead] = useState<boolean>(false);
  const [showToast, dismiss] = useIonToast();
  const [tabDisplayValue, setTabDisplayValue] = useState("all");
  const [tooltipValue, setTooltipValue] = useState("");
  const [allRatingModal, setAllRatingModal] = useState<any>(false);
  const [questionInfoTabValue, setQuestionInfoTabValue] = useState<any>("questionInfo");
  const [patientProfileData, setPatientProfileData] = useState<any>([]);

  const props =
    history.location && history.location.state && history.location.state.props;

  const getQuestionAnswer = async () => {
    await instance({
      method: "GET",
      url: "discharge/getQuestionAnswer",
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        setQnAMonitorlist(obj.result);
        setShowLoading(false);
        setIsRead(false);
      } else {
        setShowLoading(false);
      }
    });
  };

  const getDischarges = async () => {
    await instance({
      method: "GET",
      url: "discharge/getDischarge",
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      setShowLoading(false);
      if (obj.status === "SUCCESS!") {
        let data = obj.result;
        setDischargeList(data);
      }
    });
  };

  useEffect(() => {
    getDischarges();
    getQuestionAnswer();
    if (props === "fromHeader") {
      setTabDisplayValue("unanswered");
    }
  }, []);

  const dismissCreateDischarge = () => {
    setFloraAnswerBlock(false);
    setErrorMessage("");
  };

  const getBgColor = (quantity: any) => {
    const score = Number(quantity);
    if (score >= 0 && score <= 3) return "red";
    if (score >= 4 && score <= 7) return "yellow";
    if (score >= 8 && score <= 10) return "green";
    return "";
  };

  const getColor = (quantity: any) => {
    const score = Number(quantity);
    if (score >= 4 && score <= 7) return "black";
    return "white";
  };

  const viewFloraAnswer = async (e: any, item: any) => {
    setQuestionInfoTabValue("questionInfo")
    setFloraAnswerBlock(true);
    e.stopPropagation();
    setQnAMonitorItem(item);
    setAskedQuestion(item && item.question ? item.question : "");
    ReactTooltip.hide();
    setIsRead(true);
    seteditAnswer(item && item.providerResponse ? item.providerResponse : "");
    if (selectedFloraItem.includes(item._id)) {
      setSelectedFloraItem(selectedFloraItem.filter((a) => a !== item._id));
      setFloraAnswerBlock(false);
    } else {
      setSelectedFloraItem([item._id]);
    }
    await instance({
      method: "PUT",
      url: `discharge/readQna/${item._id}`,
      validateStatus: () => true,
    }).then((resp) => {
      let obj: any = {};
      obj = resp;
      if (obj.status === "SUCCESS!") {
        getQuestionAnswer();
      } else {
        showToast(obj.error.message, 3000);
      }
    });
  };
  const hideratingModal = () => {
    setAllRatingModal(false)
  }
  const submitAnswer = async () => {
    if (!editAnswer) {
      setErrorMessage("This field is required");
    } else {
      let data: any = {};
      setShowLoading(true);
      data.id = QnAMonitorItem._id;
      data.providerResponse = editAnswer;
      await instance({
        method: "PUT",
        url: "discharge/providerResponse",
        data,
        validateStatus: () => true,
      }).then((resp) => {
        let obj: any = {};
        obj = resp;
        if (obj.status === "SUCCESS!") {
          setShowLoading(false);
          getQuestionAnswer();
          showToast("Response sent", 3000);
          setFloraAnswerBlock(false);
          setSelectedFloraItem([])
        } else {
          setShowLoading(false);
          showToast(obj.error.message, 3000);
        }
      });
    }
  };

  const patientProfile = async () => {
    setShowLoading(true);
    await instance({
      method: "GET",
      url: `/discharge/${QnAMonitorItem.discharge}`,
      validateStatus: () => true,
    })
      .then((resp) => {
        let obj: any = {};
        obj = resp;
        setShowLoading(false);
        if (obj.status === "SUCCESS!") {
          let data = obj.result;
          setShowLoading(false);
          setPatientProfileData(obj.result[0])
        } else {
          setShowLoading(false);
        }
      })
      .catch((err) => {
        setShowLoading(false);
      });
  };
  return (
    <IonPage>
      <Header
        popover={true}
        unreadCount={
          QnAMonitorlist.filter((item: any) => item.isAnswerFound === false).length
        }
      ></Header>
      <IonContent style={{ height: "100%" }}>
        <IonRow className="cards-head-title-bg ht-100 ion-justify-content-center ion-align-items-center">
          <div style={{ width: "85%" }}>
            <IonRow className="ion-justify-content-start ion-align-items-center">
              <div className="ion-padding-vertical title-txt">
                <IonText className="trt-hd">Patient Engagement</IonText>
              </div>
              <div></div>
            </IonRow>
          </div>
          <div style={{ width: "85%" }}>
            <IonRow className="ion-justify-content-between ion-wrap">
              <div className="pro-card">
                <div className="flex-col">
                  <div
                    className="flex-row ion-justify-content-center"
                    style={{ flexDirection: "column" }}
                  >
                    <IonText className="card1-title-txt fnt-fm-robot">
                      Patient Engagement %
                    </IonText>
                    <div style={{ paddingTop: "8px" }}>
                      <IonText className="card-amount-txt">
                        {dischargeList &&
                          dischargeList.patientEngagement &&
                          dischargeList.patientEngagement.avarage
                          ? dischargeList.patientEngagement.avarage + "%"
                          : "0%"}
                      </IonText>
                    </div>
                  </div>
                  <div></div>
                  <IonRow className="ion-padding-top ion-justify-content-between ion-align-items-center">
                    <div style={{ width: "9rem" }}>
                      <div
                        className="card-status-txt"
                        style={{ textTransform: "capitalize" }}
                      >
                        <IonText>Total Discharges</IonText>
                      </div>
                      <div>
                        <div
                          style={{ paddingTop: "8px" }}
                          className="card-amount-txt"
                        >
                          {dischargeList &&
                            dischargeList.patientEngagement &&
                            dischargeList.patientEngagement.total
                            ? dischargeList.patientEngagement.total
                            : "0"}
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "9rem" }}>
                      <div>
                        <IonText
                          className="card-status-txt"
                          style={{ textTransform: "capitalize" }}
                        >
                          Discharges Viewed
                        </IonText>
                      </div>
                      <div>
                        <div
                          style={{ paddingTop: "8px" }}
                          className="card-amount-txt"
                        >
                          {dischargeList &&
                            dischargeList.patientEngagement &&
                            dischargeList.patientEngagement.viewd
                            ? dischargeList.patientEngagement.viewd
                            : 0}
                        </div>
                      </div>
                    </div>
                  </IonRow>
                </div>
              </div>
              <div className="pro-card">
                <div className="flex-col">
                  <div
                    className="flex-row ion-justify-content-center"
                    style={{ flexDirection: "column" }}
                  >
                    <IonText className="card1-title-txt fnt-fm-robot">
                      Post Care Provided %
                    </IonText>
                    <div style={{ paddingTop: "8px" }}>
                      <IonText className="card-amount-txt">
                        {dischargeList &&
                          dischargeList.postCare &&
                          dischargeList.postCare.avarage
                          ? dischargeList.postCare.avarage + "%"
                          : "0 %"}
                      </IonText>
                    </div>
                  </div>
                  <div></div>
                  <IonRow className="ion-padding-top ion-justify-content-between ion-align-items-center">
                    <div style={{ width: "9rem" }}>
                      <div
                        className="card-status-txt"
                        style={{ textTransform: "capitalize" }}
                      >
                        <IonText>Question Asked</IonText>
                      </div>
                      <div>
                        <div
                          style={{ paddingTop: "8px" }}
                          className="card-amount-txt"
                        >
                          {dischargeList &&
                            dischargeList.postCare &&
                            dischargeList.postCare.questionAsked
                            ? dischargeList.postCare.questionAsked
                            : 0}
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "9rem" }}>
                      <div>
                        <IonText
                          className="card-status-txt"
                          style={{ textTransform: "capitalize" }}
                        >
                          Ai Answered
                        </IonText>
                      </div>
                      <div>
                        <div
                          style={{ paddingTop: "8px" }}
                          className="card-amount-txt"
                        >
                          {dischargeList &&
                            dischargeList.postCare &&
                            dischargeList.postCare.aiAnswered
                            ? dischargeList.postCare.aiAnswered
                            : 0}
                        </div>
                      </div>
                    </div>
                  </IonRow>
                </div>
              </div>
              <div className="pro-card">
                <div className="flex-col">
                  <div
                    className="flex-row ion-justify-content-center"
                    style={{ flexDirection: "column" }}
                  >
                    <IonText className="card1-title-txt fnt-fm-robot ion-padding-bottom">
                      Impact on Hospital
                    </IonText>
                  </div>
                  <div></div>
                  <IonRow className="ion-padding-top ion-justify-content-between ion-align-items-center">
                    <div style={{ width: "9rem" }} onClick={() => setAllRatingModal(true)} className="pointer">
                      <div
                        className="card-status-txt"
                        style={{ textTransform: "capitalize" }}
                      >
                        <IonText>Rating <InfoCircle color="#00B9CF" size={15} data-tip="Click here to see reviews" data-for="review" />
                        </IonText>
                        <ReactTooltip backgroundColor="white" className="tooltip" place="bottom" textColor="black" id='review' />
                      </div>
                      <div>
                        <div style={{ paddingTop: "8px" }}>
                          {" "}
                          {5 > 0
                            ? [...Array(5)].map((x, index) => (
                              <span key={index}>
                                {dischargeList &&
                                  dischargeList.impact &&
                                  dischargeList.impact.rating &&
                                  dischargeList.impact.rating > index ? (
                                  <span key={x} style={{ padding: "2px" }}>
                                    <StarFill size="12" color="#00B9CF" />
                                  </span>
                                ) : (
                                  <span key={x} style={{ padding: "2px" }}>
                                    <Star size="12" color="#00B9CF" />
                                  </span>
                                )}
                              </span>
                            ))
                            : null}
                        </div>
                        <div className="card-amount-txt">
                          <IonText>
                            {dischargeList &&
                              dischargeList.impact &&
                              dischargeList.impact.count
                              ? dischargeList.impact.count
                              : 0}
                          </IonText>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "9rem" }}>
                      <div>
                        <IonText
                          className="card-status-txt"
                          style={{ textTransform: "capitalize" }}
                        >
                          Time Savings
                        </IonText>
                      </div>
                      <div>
                        <div
                          style={{ paddingTop: "8px" }}
                          className="card-amount-txt"
                        >
                          {" "}
                          <IonText>
                            {dischargeList &&
                              dischargeList.impact &&
                              dischargeList.impact.timeSaving
                              ? Math.floor(
                                dischargeList.impact.timeSaving / 60
                              ) +
                              "hr" +
                              " " +
                              (dischargeList.impact.timeSaving % 60) +
                              "min"
                              : "0hr"}
                          </IonText>
                        </div>
                      </div>
                    </div>
                  </IonRow>
                </div>
              </div>
            </IonRow>
          </div>
        </IonRow>

        <IonGrid style={{ width: "85%" }}>
          <IonRow style={{ width: "100%", height: "100%" }}>
            <div className="ovrflow" style={{ height: "100%", width: "100%" }}>
              <IonRow style={{ width: "100%", height: 650 }}>
                <div style={{ width: "50%", height: 600 }}>
                  <div data-tip data-for="All">
                    <ReactTooltip
                      backgroundColor="white"
                      className="tooltip"
                      place="bottom"
                      textColor="black"
                      id="All"
                    >
                      {tooltipValue === "All messages"
                        ? "All messages"
                        : tooltipValue === "Unread messages"
                          ? "Unread messages"
                          : "Unanswered questions by Flora AI"}
                    </ReactTooltip>
                    <IonRow style={{ width: "100%" }}>
                      <IonSegment value={tabDisplayValue}>
                        <IonSegmentButton
                          value="all"
                          onClick={() => {
                            setTabDisplayValue("all");
                            setQuestionInfoTabValue("questionInfo");
                            setFloraAnswerBlock(false);
                          }}
                          onMouseEnter={() => setTooltipValue("All messages")}
                          onMouseLeave={() => setTooltipValue("")}
                        >
                          <IonLabel>
                            All{" "}
                            {QnAMonitorlist && QnAMonitorlist.length > 0 ? (
                              <IonText>({QnAMonitorlist.length})</IonText>
                            ) : (
                              ""
                            )}
                          </IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton
                          value="unread"
                          onClick={() => {
                            setTabDisplayValue("unread");
                            setQuestionInfoTabValue("questionInfo");
                            setFloraAnswerBlock(false);
                          }}
                          onMouseEnter={() =>
                            setTooltipValue("Unread messages")
                          }
                          onMouseLeave={() => setTooltipValue("")}
                        >
                          <IonLabel>
                            Unread (
                            {
                              QnAMonitorlist.filter(
                                (item: any) => item.isRead === false
                              ).length
                            }
                            )
                          </IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton
                          value="unanswered"
                          onClick={() => {
                            setTabDisplayValue("unanswered");
                            setQuestionInfoTabValue("questionInfo");
                            setFloraAnswerBlock(false);
                          }}
                        >
                          <IonLabel>
                            Unanswered (
                            {
                              QnAMonitorlist.filter(
                                (item: any) => item.isAnswerFound === false
                              ).length
                            }
                            )
                          </IonLabel>
                        </IonSegmentButton>
                      </IonSegment>
                    </IonRow>
                  </div>

                  <div
                    style={{ width: "100%", height: 600 }}
                    className={QnAMonitorlist.length > 0 ? "flora-qns-box" : ""}
                  >
                    {QnAMonitorlist && QnAMonitorlist.length > 0 ? (
                      tabDisplayValue === "all" ? (
                        QnAMonitorlist.map((item: any, index: any) => {
                          return (
                            <div
                              onClick={(e) => viewFloraAnswer(e, item)}
                              key={index}
                              className={
                                item.isAnswerFound
                                  ? "flora-qns-list"
                                  : "bg-ans-found flora-qns-list"
                              }
                              style={{
                                backgroundColor: selectedFloraItem.includes(
                                  item._id
                                )
                                  ? "#E6F8FC"
                                  : "",
                                color: selectedFloraItem.includes(item._id)
                                  ? "black"
                                  : "",
                              }}
                            >
                              <div className="patient-th">
                                {item && item.name ? item.name : "NA"}
                              </div>
                              <IonRow className="ion-justify-content-between ion-align-items-center">
                                <div
                                  className="td-txt"
                                  style={{ padding: "3px 0px" }}
                                >
                                  {item && item.question ? item.question : "NA"}
                                </div>
                                {item.isRead ? (
                                  <IonRow>
                                    <CheckAll color="#00B9CF" size="30" />
                                  </IonRow>
                                ) : selectedFloraItem.includes(item._id) &&
                                  isRead === true ? (
                                  <IonRow>
                                    <CheckAll color="#00B9CF" size="30" />
                                  </IonRow>
                                ) : null}
                              </IonRow>
                              <IonRow>
                                <div
                                  style={{ paddingRight: "10px" }}
                                  className="td-txt"
                                >
                                  {" "}
                                  {item.timestamp
                                    ? new Date(
                                      item.timestamp
                                    ).toLocaleDateString("en-US", {
                                      timeZone: "America/Los_Angeles",
                                    })
                                    : "NA"}
                                </div>
                                <div className="td-txt">
                                  {" "}
                                  {item.timestamp
                                    ? new Date(
                                      item.timestamp
                                    ).toLocaleTimeString("en-US", {
                                      timeZone: "America/Los_Angeles",
                                    })
                                    : "NA"}
                                </div>
                              </IonRow>
                            </div>
                          );
                        })
                      ) : tabDisplayValue === "unread" ? (
                        QnAMonitorlist.filter(
                          (item: any) => item.isRead === false
                        ).map((item: any, index: any) => {
                          return (
                            <div
                              onClick={(e) => viewFloraAnswer(e, item)}
                              key={index}
                              className={
                                item.isAnswerFound
                                  ? "flora-qns-list"
                                  : "bg-ans-found flora-qns-list"
                              }
                              style={{
                                backgroundColor: selectedFloraItem.includes(
                                  item._id
                                )
                                  ? "#E6F8FC"
                                  : "",
                                color: selectedFloraItem.includes(item._id)
                                  ? "black"
                                  : "",
                              }}
                            >
                              <div className="patient-th">
                                {item && item.name ? item.name : "NA"}
                              </div>
                              <IonRow className="ion-justify-content-between ion-align-items-center">
                                <div
                                  className="td-txt"
                                  style={{ padding: "3px 0px" }}
                                >
                                  {item && item.question ? item.question : "NA"}
                                </div>
                                {item.isRead ? (
                                  <IonRow>
                                    <CheckAll color="#00B9CF" size="30" />
                                  </IonRow>
                                ) : selectedFloraItem.includes(item._id) &&
                                  isRead === true ? (
                                  <IonRow>
                                    <CheckAll color="#00B9CF" size="30" />
                                  </IonRow>
                                ) : null}
                              </IonRow>
                              <IonRow>
                                <div
                                  style={{ paddingRight: "10px" }}
                                  className="td-txt"
                                >
                                  {" "}
                                  {item.timestamp
                                    ? new Date(
                                      item.timestamp
                                    ).toLocaleDateString("en-US", {
                                      timeZone: "America/Los_Angeles",
                                    })
                                    : "NA"}
                                </div>
                                <div className="td-txt">
                                  {" "}
                                  {item.timestamp
                                    ? new Date(
                                      item.timestamp
                                    ).toLocaleTimeString("en-US", {
                                      timeZone: "America/Los_Angeles",
                                    })
                                    : "NA"}
                                </div>
                              </IonRow>
                            </div>
                          );
                        })
                      ) : tabDisplayValue === "unanswered" ? (
                        QnAMonitorlist.filter(
                          (item: any) => item.isAnswerFound === false
                        ).map((item: any, index: any) => {
                          return (
                            <div
                              onClick={(e) => viewFloraAnswer(e, item)}
                              key={index}
                              className={
                                item.isAnswerFound
                                  ? "flora-qns-list"
                                  : "bg-ans-found flora-qns-list"
                              }
                              style={{
                                backgroundColor: selectedFloraItem.includes(
                                  item._id
                                )
                                  ? "#E6F8FC"
                                  : "",
                                color: selectedFloraItem.includes(item._id)
                                  ? "black"
                                  : "",
                              }}
                            >
                              <div className="patient-th">
                                {item && item.name ? item.name : "NA"}
                              </div>
                              <IonRow className="ion-justify-content-between ion-align-items-center">
                                <div
                                  className="td-txt"
                                  style={{ padding: "3px 0px" }}
                                >
                                  {item && item.question ? item.question : "NA"}
                                </div>
                                {item.isRead ? (
                                  <IonRow>
                                    <CheckAll color="#00B9CF" size="30" />
                                  </IonRow>
                                ) : selectedFloraItem.includes(item._id) &&
                                  isRead === true ? (
                                  <IonRow>
                                    <CheckAll color="#00B9CF" size="30" />
                                  </IonRow>
                                ) : null}
                              </IonRow>
                              <IonRow>
                                <div
                                  style={{ paddingRight: "10px" }}
                                  className="td-txt"
                                >
                                  {" "}
                                  {item.timestamp
                                    ? new Date(
                                      item.timestamp
                                    ).toLocaleDateString("en-US", {
                                      timeZone: "America/Los_Angeles",
                                    })
                                    : "NA"}
                                </div>
                                <div className="td-txt">
                                  {" "}
                                  {item.timestamp
                                    ? new Date(
                                      item.timestamp
                                    ).toLocaleTimeString("en-US", {
                                      timeZone: "America/Los_Angeles",
                                    })
                                    : "NA"}
                                </div>
                              </IonRow>
                            </div>
                          );
                        })
                      ) : (
                        ""
                      )
                    ) : (
                      <p className="txt-robot">No Data Found</p>
                    )}
                  </div>
                </div>
                {FloraAnswerBlock && (
                  <div className="flora-ans-box">
                    <IonRow style={{ width: "100%" }}>
                      <IonSegment value={questionInfoTabValue}>
                        <IonSegmentButton
                          value="questionInfo"
                          onClick={() => {
                            setQuestionInfoTabValue("questionInfo");
                          }}
                        >
                          <IonLabel>
                            Question Info
                          </IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton
                          value="patientProfile"
                          onClick={() => {
                            setQuestionInfoTabValue("patientProfile");
                            patientProfile()
                          }}
                        >
                          <IonLabel>
                            Patient Profile
                          </IonLabel>
                        </IonSegmentButton>

                      </IonSegment>
                    </IonRow>
                    {questionInfoTabValue === "questionInfo" ? (
                      <IonCol>
                        <IonRow>
                          <IonText
                            className="txt-robot"
                            style={{ fontWeight: "600" }}
                          >
                            Question Asked
                          </IonText>
                        </IonRow>
                        <IonRow>
                          <IonTextarea
                            value={QnAMonitorItem && QnAMonitorItem.question}
                            className="dis-txt-area msg-txt-area"
                            style={{ height: "100px" }}
                            placeholder="Question"
                            name="patientName"
                            readonly={true}
                          ></IonTextarea>
                        </IonRow>
                        <IonRow>
                          <IonText
                            className="txt-robot ion-padding-top"
                            style={{ fontWeight: "600" }}
                          >
                            Answer by Flora AI
                          </IonText>
                        </IonRow>
                        <IonRow>
                          <IonTextarea
                            value={QnAMonitorItem && QnAMonitorItem.answer}
                            className="dis-txt-area msg-txt-area"
                            style={{ height: "100px" }}
                            placeholder="Bot Answer"
                            name="patientName"
                            readonly={true}
                          ></IonTextarea>
                        </IonRow>
                        <IonRow
                          className="ion-padding-top txt-robot"
                          style={{ fontWeight: "600" }}
                        >
                          <IonText>Provider Response</IonText>
                        </IonRow>
                        <IonTextarea
                          value={editAnswer}
                          className="dis-txt-area msg-txt-area"
                          style={{ height: "100px" }}
                          placeholder="Send a message to patient regarding the question asked."
                          name="patientName"
                          onIonChange={(e) => {
                            seteditAnswer(e.detail.value);
                            setErrorMessage("");
                          }}
                        ></IonTextarea>
                        <p className="fielderrormsg">
                          {errorMessage ? errorMessage : ""}
                        </p>
                        <IonRow className="ion-padding-vertical ion-justify-content-center">
                          <IonButton
                            className="nextbtn"
                            onClick={() => submitAnswer()}
                          >
                            Send Response
                          </IonButton>
                        </IonRow>
                      </IonCol>) :
                      <div
                        className="fieldiv"
                        style={{ maxWidth: "100%", padding: "0px" }}
                      >

                        <IonRow>
                          <IonCol>
                            <IonLabel className="label">
                              Patient Name <span className="star-clr">*</span>
                            </IonLabel>
                            <IonInput
                              type="text"
                              value={patientProfileData && patientProfileData.name ? patientProfileData.name : ""}
                              readonly
                              className="ion-padding input-field mg-top placeholder-txt"
                              placeholder="eg. Mary Jackson"
                            >
                            </IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-margin-vertical">
                          <IonCol>
                            <IonLabel className="label" style={{ whiteSpace: "nowrap" }}>
                              Patient Phone number
                            </IonLabel>
                            <NumberFormat
                              placeholder="xxx-xxx-xxxx"
                              format="(###) ###-####"
                              readOnly
                              className="mg-top input-field placeholder-txt num-format-wd-100"
                              value={patientProfileData && patientProfileData.phoneNumber
                                ? patientProfileData.phoneNumber : ""}
                              style={{ width: "100%", padding: "12px" }}
                            />
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonLabel className="label">
                              Patient Email
                            </IonLabel>
                            <IonInput
                              clearInput={true}
                              type="text"
                              className="input-field mg-top placeholder-txt"
                              placeholder="eg. mary.jackson@gmail.com"
                              value={patientProfileData && patientProfileData.email
                                ? patientProfileData.email : ""}
                              readonly
                            >
                            </IonInput>
                          </IonCol>
                        </IonRow>
                      </div>
                    }
                  </div>
                )}
              </IonRow>
            </div>
          </IonRow>
        </IonGrid>
        <IonFooter>
          <IonToolbar style={{ padding: "20px 0px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src={require(`../../images/final_logo.png`).default}
                  style={{ maxWidth: "fit-content" }}
                  height="38px"
                  width="37px"
                  alt=""
                />
              </div>
              <IonRow>
                <IonCol className="pad-0">
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonText className="logo-txts">myBenefits.ai</IonText>
                  </IonRow>
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonText className="subtitle">Treat Now, Pay Later</IonText>
                  </IonRow>
                </IonCol>
              </IonRow>
            </div>
            <div>
              <IonRow
                className="ion-align-items-center ion-justify-content-center txt-robot"
                style={{ paddingTop: "10px" }}
              >
                <div
                  style={{ paddingRight: "12px" }}
                  className="terms-hover pointer"
                >
                  Privacy Policy | Terms of Use
                </div>
                <div>© 2023 mybenefits.ai Inc | All rights reserved</div>
              </IonRow>
            </div>
          </IonToolbar>
        </IonFooter>
      </IonContent>
      <IonModal isOpen={allRatingModal} onDidDismiss={() => hideratingModal()} id="rating-modal">
        <Ratings dissmissModal={hideratingModal} ratingsData={dischargeList && dischargeList.discharges}></Ratings>
      </IonModal>
      {showLoading && <Loader />}
    </IonPage>
  );
};
export default QnAReports;
