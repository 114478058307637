import {
    IonCol, useIonToast,
    IonLoading, IonButton, IonContent, IonToast, IonInput, IonLabel, IonPage, IonRow, IonText, IonModal,
} from '@ionic/react';
import { useState, } from 'react';
import './index.css';
import instance from '../../utils/api';
import { CloseO } from '@styled-icons/evil/CloseO';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle'
import OuterHeader from '../../components/outerHeader';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { Formik, } from 'formik';
import * as yup from 'yup';
const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showToast, dismiss] = useIonToast();
    const [Modal, setModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [showToast1, setShowToast1] = useState(false);
    const next = async (values: any) => {
        let data: any = {}
        data.email = values.email
        setShowLoading(true)
        await instance({
            method: 'POST',
            url: `user/forgotPassword`,
            data,
            validateStatus: () => true
        }).then(resp => {
            let obj: any = {}
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setShowLoading(false)
                setModal(true)
            }
            else {
                setShowLoading(false);
                setErrorMessage(obj.error.message);
                setShowToast1(true);
            }
        })
    }
    const dismissModal = () => {
        setModal(false)
    }
    const validateYupSchema = yup.object({
        email: yup.string().email('Invalid email')
            .required("This field is required"),
    })
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonRow className="ion-justify-content-center display-flex">
                    <IonCol className='pad-0'>
                        <OuterHeader></OuterHeader>
                        <IonRow className="head-prov-title-bg ion-justify-content-center ion-align-items-center ion-margin-bottom">
                            <IonText>
                                Forgot Password
                            </IonText>
                        </IonRow>

                        <Formik initialValues={{
                            email: ""
                        }} validationSchema={validateYupSchema} onSubmit={next}>
                            {formikProps => (
                                <form onSubmit={formikProps.handleSubmit}>
                                    <IonRow style={{ maxWidth: '430px', margin: '0 auto' }}>
                                        <IonCol className="fields-row-pd">
                                            <IonRow className='ion-padding-top'>
                                                <IonCol className="pad-0">
                                                    <IonLabel className='label'>Enter Email</IonLabel>
                                                    <IonInput type="text" name='email' className="input-field mg-top placeholder-txt" placeholder="Email"
                                                        onBlur={formikProps.handleBlur}
                                                        onIonChange={formikProps.handleChange}
                                                        value={formikProps.values.email.trim()}
                                                    > </IonInput>
                                                    <p className="fielderrormsg">
                                                        {formikProps.touched.email && formikProps.errors.email}
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                            <IonButton expand="block" className='searchbtn searchbtn-txt' type='submit'>Reset</IonButton>
                                            <IonRow className='signuprow ion-justify-content-center'>
                                                <span>Have an account</span>?<span className='signup' onClick={() => { window.location.href = '/provider_login' }}>Sign in now</span>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                </form>
                            )}

                        </Formik>
                    </IonCol>
                </IonRow>
                <IonToast
                    isOpen={showToast1}
                    onDidDismiss={() => setShowToast1(false)}
                    message={errorMessage}
                    duration={3000}
                />
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent >
            <IonModal backdropDismiss={false} className="rest-modal" isOpen={Modal} onDidDismiss={() => dismissModal()}>
                <IonContent>
                    <IonCol className='pad-0'>
                        <IonRow className='ion-justify-content-end pointer' onClick={() => window.location.href = "/provider_login"}>
                            <CloseOutline title="Close" size={30} color="#3DC2FF"></CloseOutline>
                        </IonRow>
                    </IonCol>

                    <div className='modaldiv'>
                        <CheckCircle title="Close" size={50} color="#00B9CF"></CheckCircle>
                        <IonRow><IonText className='pass-title-fnt ion-padding'>Success!</IonText></IonRow>
                        <IonText className='rest-txt'>Password reset request was sent Successfully. Please check your email to reset your password.</IonText>
                    </div>
                </IonContent>
            </IonModal>
        </IonPage >
    )
}
export default ForgotPassword