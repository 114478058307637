import { IonContent, IonModal, useIonToast, IonText, IonPage, IonRow, IonFooter, IonCol, IonGrid, IonButton, IonCard, IonCardContent, IonTextarea, IonInput, IonLoading, IonSegment, IonSegmentButton, IonLabel, IonSearchbar } from "@ionic/react"
import { useEffect, useState } from "react";
import instance from "../../../utils/api";
import PlusHeader from "../Header/inex";
import '../index.css';
import { ChevronLeft } from "@styled-icons/boxicons-regular/ChevronLeft";
import { CheckCircle } from "@styled-icons/boxicons-solid/CheckCircle";
import { CloseCircle } from "@styled-icons/remix-fill/CloseCircle";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import * as yup from "yup";
import { Formik } from "formik";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
const AllDischarges: React.FC = () => {
  const [allDischarges, setAllDischarges] = useState<any>([])
  const [dischargeItem, setDischargeItem] = useState<any>([])
  const [dischargeModal, setDischargeModal] = useState<boolean>(false);
  const [editCategoryData, setEditCategoryData] = useState<any>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToast, dismiss] = useIonToast();
  const [disableSavebtn, setDisableBtn] = useState<boolean>(true);
  const [segmentValue, setSegmentValue] = useState<any>("editCategories");
  const [searchData, setSearchData] = useState<any>([]);
  const [textData, setTextData] = useState<any>("")
  const [addCategoryModal, setAddCategoryModal] = useState<boolean>(false);
  const [addCategoryData, setAddCategoryData] = useState<any>([])

  const getAllDischarges = async () => {
    setShowLoading(true)
    await instance({
      method: "GET",
      url: "discharge/getAllDischarges",
      validateStatus: () => true
    }).then((resp) => {
      let obj: any = {}
      obj = resp;
      if (obj.status === "SUCCESS!") {
        setAllDischarges(obj.result)
        setSearchData(obj.result)
        setShowLoading(false)
      }
      else {
        setShowLoading(false)
      }
    })
  }

  useEffect(() => {
    getAllDischarges()
  }, [allDischarges.length])

  const getDischargeItem = async (item: any) => {
    setAddCategoryData([])
    setShowLoading(true)
    await instance({
      method: "GET",
      url: `discharge/${item}`,
      validateStatus: () => true
    })
      .then((resp) => {
        let obj: any = {}
        obj = resp;
        if (obj.status === "SUCCESS!") {
          setDischargeItem(obj.result[0])
          setDischargeModal(true);
          setShowLoading(false)
          setTextData(obj.result[0].textData)
          setEditCategoryData(obj.result[0].docData && obj.result[0].docData)
        }
        else {
          setShowLoading(false)
        }
      })
  }

  const handleChange = (e: any, id: any, value: any, editValue: any, index: any) => {
    if (editValue === "editCategory") {
      var result = [...editCategoryData];
      result = result.map((x) => {
        if (x._id === id) {
          if (value === "info") {
            x.categoryInfo = e.detail.value;
            setDisableBtn(false)
          }
          if (value === "name") {
            x.categoryName = e.detail.value;
            setDisableBtn(false)
          }
        }
        return x;
      });
      setEditCategoryData(result)
    }

    else if (editValue === "addEditCategory") {
      var addResult = [...addCategoryData];
      addResult = addResult.map((x, key) => {
        if (key === index) {
          if (value === "info") {
            x.categoryInfo = e.detail.value;
            setDisableBtn(false)
          }
          if (value === "name") {
            x.categoryName = e.detail.value;
            setDisableBtn(false)
          }
        }
        return x;
      });
      setAddCategoryData(addResult)
    }
  };

  const submitCategory = async () => {
    setShowLoading(true);
    let data: any = {};
    //data.isAdminReview = true
    const myArrayCopy = [...addCategoryData,...editCategoryData];
    data.docData = myArrayCopy;
    if (textData) {
      data.textData = textData
    }
    if (dischargeItem && dischargeItem.isAdminReview === false) {
      data.dischargeStatus = "Saved"
    }
    await instance({
      method: "PUT",
      url: `/discharge/doccument/${dischargeItem._id}`,
      data,
      validateStatus: () => true,
    })
      .then((resp) => {
        let obj: any = {};
        obj = resp;
        setShowLoading(false);
        if (obj.status === "SUCCESS!") {
          setShowLoading(false);
          showToast("Submited Successfully", 3000);
          setDisableBtn(true)
          getAllDischarges()
        } else {
          setShowLoading(false);
        }
      })
      .catch((err) => {
        setShowLoading(false);
      });
  };

  const review = async () => {
    setShowLoading(true);
    let data: any = {};
    data.isAdminReview = true
    data.dischargeStatus = "Reviewed"
    await instance({
      method: "PUT",
      url: `/discharge/doccument/${dischargeItem._id}`,
      data,
      validateStatus: () => true,
    })
      .then((resp) => {
        let obj: any = {};
        obj = resp;
        setShowLoading(false);
        if (obj.status === "SUCCESS!") {
          setShowLoading(false);
          showToast("Submited Successfully", 3000);
          getDischargeItem(dischargeItem._id)
          getAllDischarges()
        } else {
          setShowLoading(false);
        }
      })
      .catch((err) => {
        setShowLoading(false);
      });
  }

  const segment = (e) => {
    setSegmentValue(e.target.value)
  }


  const onChangeSearch = (query: any) => {
    const orginal = allDischarges
    if (query.detail.value) {
      const filteredData = orginal.filter(
        item => {
          return (
            (item && item.provider[0] && item.provider[0].displayName && item.provider[0].displayName)?.toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
            item.name.toLowerCase().includes(query.detail.value.toLowerCase().trim()) ||
            new Date(item.timestamp).toLocaleDateString(
              "en-US",
              { timeZone: "America/Los_Angeles" }
            ).toLowerCase().includes(query.detail.value.toLowerCase().trim())

          );
        }
      );
      setSearchData(filteredData)
    }
    else {
      setSearchData(orginal)
    }
  }
  const deleteCategory = (value: any,index:any,delet:any) => {
    setDisableBtn(false)
    if(delet === "deleteAddCategory"){
      const updatedItems = addCategoryData.filter((item: any,key:any) => key !== index);
      setAddCategoryData(updatedItems)
    }
    else if(delet === "deleteEditCategory"){
    const updatedItems = editCategoryData.filter((item: any) => item._id !== value._id);
    setEditCategoryData(updatedItems)
    }
  }
  const addCategory = (values: any) => {
    setDisableBtn(false)
    setAddCategoryModal(false);
    var data = [
      {
        categoryName: values.name,
        categoryInfo: values.info,
        display: true,
      },
    ];
    if (addCategoryData === undefined) {
      setAddCategoryData([...data])
    }
    else {
      setAddCategoryData([...data, ...addCategoryData]);
    }
    // if(editCategoryData === undefined){
    //   setEditCategoryData([...data])
    // }
    // else {
    //   setEditCategoryData(prevData => [...data, ...prevData]);
    // setDisableBtn(false)
    // console.log(editCategoryData)
    // }
  };

  const validationSchema = yup.object({
    info: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
  });
  return (
    <IonPage>
      <PlusHeader />
      <IonContent>
        <IonSearchbar onIonChange={(ev) => onChangeSearch(ev)} className="pad-0" mode="ios"></IonSearchbar>
        <div
          className="ovrflow"
          style={{ width: "100%", overflow: "auto" }}
        >
          <table className="table-container">
            <thead className="patient-thread">
              <tr>
                <th className="patient-th pd-lr-24 whitespace td-txt-center">
                  Created At
                </th>
                <th className="patient-th pd-lr-24 whitespace td-txt-center">
                  Updated At
                </th>
                <th className="patient-th pd-lr-24 whitespace td-txt-center">
                  Provider Name
                </th>
                <th className="patient-th pd-lr-24 whitespace td-txt-center">
                  Patient Name
                </th>
                <th className="patient-th pd-lr-24 whitespace td-txt-center">
                  Admin Reviewed
                </th>
                <th className="patient-th pd-lr-24 whitespace td-txt-center">
                  Status of discharges
                </th>
                <th className="patient-th pd-lr-24 whitespace td-txt-center">
                  Provider Id
                </th>
                <th className="patient-th pd-lr-24 whitespace td-txt-center">
                  Discharge Id
                </th>
                <th className="patient-th pd-lr-24 whitespace td-txt-center">
                  Patient Id
                </th>
              </tr>
            </thead>
            <tbody className="patient-tbody">
              {searchData && searchData.length > 0 &&
                searchData.map((item: any, key: any) => {
                  return (
                    <tr
                      key={key}
                      className="patient-tr" onClick={() => getDischargeItem(item._id)}
                    >
                       <td className="td-txt pd-lr-24 td-txt-center">
                      {item.createdAt
                          ? new Date(Date.parse(item.createdAt)).toLocaleDateString(
                            "en-US",
                            { timeZone: "America/Los_Angeles" }
                          )
                          : "NA"} {" "}
                        {item.createdAt
                          ? new Date(Date.parse(item.createdAt)).toLocaleTimeString(
                            "en-US",
                            { hour: "2-digit", minute: "2-digit", timeZone: "America/Los_Angeles" }
                          )
                          : "NA"}
                      </td>
                      <td className="td-txt pd-lr-24 td-txt-center">
                        {item.updatedAt
                          ? new Date(Date.parse(item.updatedAt)).toLocaleDateString(
                            "en-US",
                            { timeZone: "America/Los_Angeles" }
                          )
                          : "NA"} {" "}
                        {item.updatedAt
                          ? new Date(Date.parse(item.updatedAt)).toLocaleTimeString(
                            "en-US",
                            { hour: "2-digit", minute: "2-digit", timeZone: "America/Los_Angeles" }
                          )
                          : "NA"}
                      </td>
                      <td className="td-txt pd-lr-24 td-txt-center">
                        {item && item.provider && item.provider[0].displayName}
                      </td>
                      <td className="td-txt pd-lr-24 td-txt-center">
                        {item && item.name}
                      </td>
                      <td className="td-txt pd-lr-24 td-txt-center">
                        {item.isAdminReview === true ?
                          <CheckCircle size={30} color="green" /> : <CloseCircle size={30} color="red" />
                        }
                      </td>
                      <td className="td-txt pd-lr-24 td-txt-center">
                        {item && item.dischargeStatus ? item.dischargeStatus : "Pending"}
                      </td>
                      <td className="td-txt pd-lr-24 td-txt-center ">
                        {item && item.mybenefitsprovider}
                      </td>
                      <td className="td-txt pd-lr-24 td-txt-center">
                        {item && item._id}
                      </td>
                      <td className="td-txt pd-lr-24 td-txt-center">
                        {item && item.user}
                      </td>
                    </tr>
                  );
                })}
            </tbody>

          </table>
          {searchData.length === 0 &&
            <IonRow className="ion-align-items-center ion-justify-content-center txt-robot">No Data Found</IonRow>}
        </div>
      </IonContent>
      <IonModal isOpen={dischargeModal} backdropDismiss={false} onDidDismiss={() => { setDischargeModal(false) }} id="plus-discharge-modal">
        <IonRow className="popup-header ion-padding-vertical">
          <IonCol className="pad-0">
            <IonRow style={{ position: "relative" }}
              className="ion-justify-content-center pointer pad-0 ion-align-items-center"
              onClick={() => {
                setDischargeModal(false)
                setDisableBtn(true)
                setSegmentValue("editCategories")
              }}
            >
              <div style={{ position: "absolute", left: "0" }}>
                <ChevronLeft
                  title="Close"
                  size={40}
                  color="#FFFFFF"
                ></ChevronLeft>
              </div>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="txt-robot" style={{ fontWeight: "600" }}>Discharge Id</div>
                <div>{dischargeItem._id}</div>
              </IonCol>
              <IonRow>
                <IonButton disabled={disableSavebtn} expand="block"
                  type="submit" onClick={() => submitCategory()}
                  className="searchbtn searchbtn-txt mg-0 ion-padding-horizontal">Save</IonButton>
                {dischargeItem && dischargeItem.isAdminReview === false && dischargeItem.dischargeType === "PostOp" &&
                  <IonButton expand="block" onClick={() => review()}
                    type="submit"
                    className="searchbtn searchbtn-txt mg-0">Mark as Reviewed</IonButton>}
              </IonRow>
            </IonRow>
            <IonCol>
              <IonRow className="ion-align-items-center ion-justify-content-between">
                <div style={{ width: "300px" }}>
                  <IonSegment mode="ios" value={segmentValue}
                    onIonChange={(e) => segment(e)}>
                    <IonSegmentButton value="editCategories"><IonLabel style={{ color: "black" }}>Edit Categories</IonLabel></IonSegmentButton>
                    <IonSegmentButton value="editTextFile"><IonLabel style={{ color: "black" }}>Edit TextFile</IonLabel></IonSegmentButton>
                    {/* <IonSegmentButton>Edit Categories</IonSegmentButton> */}
                  </IonSegment>
                </div>
                <IonRow>
                  <IonButton expand="block" onClick={() => setAddCategoryModal(true)}
                    type="submit" style={{ margin: "0px 15px" }}
                    className="searchbtn searchbtn-txt">
                    <IonRow className="ion-align-items-center"><span>Add Category</span></IonRow>
                  </IonButton>
                  <a href={dischargeItem.dischageDocLink} target="_blank" className="report-btn searchbtn searchbtn-txt pointer" rel="noreferrer">
                    <IonButton expand="block"
                      type="submit"
                      className="searchbtn searchbtn-txt mg-0">
                      <IonRow className="ion-align-items-center"><span>View Report</span></IonRow>
                    </IonButton>

                  </a>
                </IonRow>

              </IonRow>
            </IonCol>
            <IonCol className="pad-0">
              {segmentValue === "editCategories" ?
                <IonRow
                  className="ion-justify-content-start ion-padding-horizontal"
                  style={{ width: "100%" }}
                >
                  {addCategoryData && addCategoryData.length > 0
                    ? addCategoryData.map((value, index) => (
                      <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="4" sizeXl="4" key={index}>
                        <IonCard className="category-box" key={index} style={{ margin: "0px" }}>
                          <IonCardContent>
                            <div className="txt-robot">
                              <IonRow className="ion-align-items-center ion-justify-content-between">
                                <IonInput
                                  type="text"
                                  value={value.categoryName}
                                  style={{ margin: "0px 15px 0px 0px" }}
                                  className="input-field mg-top placeholder-txt"
                                  onIonChange={(e) =>
                                    handleChange(e, value._id, "name", "addEditCategory", index)
                                  }
                                ></IonInput>
                                <div onClick={() => deleteCategory(value,index,"deleteAddCategory")} className="pointer">
                                  <Delete size={25} color="red" />
                                </div>
                              </IonRow>
                              <IonTextarea
                                name="message"
                                placeholder="Type here."
                                className="dis-txt-area msg-txt-area"
                                style={{ height: "80px", borderRadius: "5px" }}
                                value={value.categoryInfo}
                                onIonChange={(e) =>
                                  handleChange(e, value._id, "info", "addEditCategory", index)
                                }
                              ></IonTextarea>
                            </div>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    ))
                    : null}
                  {editCategoryData && editCategoryData.length > 0 ? (
                    editCategoryData.map((value: any, index: any) => (
                      <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="4" sizeXl="4" key={index}>
                        <IonCard className="category-box" key={index} style={{ margin: "0px" }}>
                          <IonCardContent>
                            <div className="txt-robot">
                              <IonRow className="ion-align-items-center ion-justify-content-between">
                                <IonInput
                                  type="text"
                                  value={value.categoryName}
                                  className="input-field mg-top placeholder-txt"
                                  onIonChange={(e) =>
                                    handleChange(e, value._id, "name", "editCategory", index)
                                  }
                                ></IonInput>
                                <div onClick={() => deleteCategory(value,index,"deleteEditCategory")} className="pointer">
                                  <Delete size={25} color="red" />
                                </div>

                              </IonRow>
                              <IonTextarea
                                name="message"
                                placeholder="Type here."
                                className="dis-txt-area msg-txt-area"
                                style={{ height: "80px", borderRadius: "5px" }}
                                value={value.categoryInfo}
                                onIonChange={(e) =>
                                  handleChange(e, value._id, "info", "editCategory", index)
                                }
                              ></IonTextarea>
                            </div>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    )))
                    : <div className="no-data-msg ion-padding txt-robot">No Data Found</div>}

                </IonRow> : segmentValue === "editTextFile" ?
                  <IonRow>
                    <IonTextarea value={textData} onIonChange={(e) => { setTextData(e.detail.value); setDisableBtn(false) }} style={{ height: "470px" }} className="texteditor dis-txt-area msg-txt-area">
                    </IonTextarea>
                  </IonRow> : null}
            </IonCol>
          </IonGrid>
        </IonContent>
      </IonModal>
      <IonModal isOpen={addCategoryModal} id="add-category-modal" onDidDismiss={() => setAddCategoryModal(false)}>
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="popup-header"
        >
          <IonRow
            className="close pointer"
            onClick={() => setAddCategoryModal(false)}
          >
            <CloseOutline
              title="Close"
              size={30}
              color="#FFFFFF"
            ></CloseOutline>
          </IonRow>
          <IonRow
            className="ion-justify-content-center popup-title ion-padding"
            style={{ fontSize: "30px" }}
          >
            <IonText>Add Category</IonText>
          </IonRow>
        </div>
        <IonContent>
          <Formik
            initialValues={{ name: "", info: "" }}
            onSubmit={addCategory}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <form onSubmit={formikProps.handleSubmit} id="form">
                <IonRow style={{ maxWidth: "430px", margin: "0 auto" }}>
                  <IonCol className="fields-row-pd">
                    <IonRow className="ion-padding-top">
                      <IonCol className="pad-0">
                        <IonLabel className="label">Enter Title</IonLabel>
                        <IonInput
                          name="name"
                          placeholder="Category Name"
                          className="ion-padding input-field mg-top placeholder-txt"
                          type="text"
                          onBlur={formikProps.handleBlur}
                          onIonChange={formikProps.handleChange}
                          value={formikProps.values.name}
                        ></IonInput>
                        <p
                          className="fielderrormsg"
                          style={{ marginBottom: "0px" }}
                        >
                          {formikProps.touched.name &&
                            formikProps.errors.name}
                        </p>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-top">
                      <IonCol className="pad-0">
                        <IonLabel className="label">
                          Enter Description
                        </IonLabel>
                        <IonTextarea
                          name="info"
                          placeholder="Category Info"
                          className="dis-txt-area review-txt-area"
                          onBlur={formikProps.handleBlur}
                          onIonChange={formikProps.handleChange}
                          value={formikProps.values.info}
                        ></IonTextarea>
                        <p
                          className="fielderrormsg"
                          style={{ marginBottom: "0px" }}
                        >
                          {formikProps.touched.info &&
                            formikProps.errors.info}
                        </p>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </form>
            )}
          </Formik>
        </IonContent>
        <IonFooter>
          <IonRow className="ion-padding-vertical ion-justify-content-center">
            <IonButton form="form" className="btn-wd-100 nextbtn" type="submit">
              Add
            </IonButton>
          </IonRow>

        </IonFooter>

      </IonModal>
      <IonLoading isOpen={showLoading} message="Please wait..." />
    </IonPage>
  )
}
export default AllDischarges;