import {
    IonCol, useIonToast, IonGrid, IonCard, IonCardContent,
    IonLoading, IonButton, IonProgressBar, IonContent, IonSpinner, useIonAlert, IonInput, IonLabel, IonPage, IonRow, IonText,
} from '@ionic/react';
import { useState, useContext, useEffect, useCallback } from 'react';
import CommonContext from "../../utils/contexts/commonProps";
import instance from '../../utils/api';
import NumberFormat from 'react-number-format';
import '../../pages/TermsAndConditions/terms.css'
import moment from 'moment';
import axios from "axios";
import { Formik, } from 'formik';
import * as yup from 'yup';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { usePlaidLink, PlaidLinkOnSuccess } from 'react-plaid-link';
import { Check2 } from '@styled-icons/bootstrap/Check2';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';
import { CloseCircleOutline } from '@styled-icons/evaicons-outline/CloseCircleOutline';
import { useHistory } from 'react-router';

var CryptoJS = require("crypto-js");

interface Props {
    dismissModal: () => void,
}
const EmailInviteSignUp: React.FC<Props> = (props) => {
    const [alert] = useIonAlert();
    const [linkToken, setLinkToken] = useState(null);
    const [otp, setOtp] = useState<any>();
    const [verifyemailbtn, setVerifyEmailBtn] = useState(false)
    const [verifyOtpScreen, setVerifyOtpScreen] = useState(false)
    const [showToast, dismiss] = useIonToast();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [billingAddress, setBillingAddress] = useState('')
    const [businessCity, setBusinessCity,] = useState('');
    const [zipCode, setZipCode] = useState('')
    const [ein, setEin] = useState<string>();
    const [route, setRoute] = useState<string>();
    const [number, setNumber] = useState<any>("");
    const [phoneNumber, setPhoneNumber] = useState<any>("");
    const [key, setKey] = useState<any>("AC1f7b6b8f1325f67ad887e297c69dd9de")
    const [wrAccRouteNo, setWrAccRouteNo] = useState(false)
    const { common, setCommon } = useContext(CommonContext);
    const [email, setEmail] = useState('')
    const [bankName, setBankName] = useState('')
    const [compareEmail, setCompareEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState();
    const [inviteDetails, setInviteDetails] = useState<any>([]);
    const providerStatus = localStorage.getItem("providerStatus") || ""
    const inviteId = localStorage.getItem("inviteId") || undefined
    const [bankDetails, setBankDetails] = useState<any>({});
    const [spinner, setSpinner] = useState<any>(false);
    const [kypVerified, setKypVerified] = useState<any>(false);
    const [kypResponse, setKypResponse] = useState<any>("");
    const history = useHistory()
    useEffect(() => {
        if (providerStatus === "invite" && !common.termsCondView) {
            common.progressBar = 0.92
        }
        else if (providerStatus === "address") {
            common.progressBar = 0.94
            common.termsCondView = false
            setCommon(common)
        }
        else if (providerStatus === "bank-details") {
            common.progressBar = 0.96
            common.termsCondView = false
            setCommon(common)
        }
        else if (providerStatus === "kyp") {
            common.progressBar = 0.98
            common.termsCondView = false
            setCommon(common)
        }
    }, [providerStatus])

    useEffect(() => {
        axios
            .get(`https://www.routingnumbers.info/api/data.json?rn=${route}`)
            .then(data => {
                setBankName(data.data.customer_name)
            })
    }, [route])

    const getUserDetails = async () => {
        setShowLoading(true);
        await instance({
            method: 'GET',
            url: 'user/getUserDetails',
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp
            if (obj.status === "SUCCESS!") {
                setShowLoading(false);
                localStorage.setItem("providerStatus", obj.result[0].status);
                //localStorage.setItem("providerStatus", "kyp");
                let data = obj.result[0].mybenefitsprovider
                common.providerData.providerObj = obj.result;
                common.bank = obj.result[0].mybenefitsprovider.bank
                setCommon(common)
                setEmail(data && data.email);
                setCompareEmail(data && data.email);
            }
            else {
                setShowLoading(false);
                showToast(obj.error.message, 3000)
            }
        })
    }
    useEffect(() => {
        getUserDetails()
    }, []);

    useEffect(() => {
        if (inviteId != undefined) {
            inviteGetDetails()
        }
    }, [inviteDetails.length])

    const inviteGetDetails = async () => {
        await instance({
            method: 'GET',
            url: `user/inviteDetails/${inviteId}`,
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setInviteDetails([obj.result[0]])
            }
        })
    }
    // const submit = async () => {
    //     const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g;
    //     const result = pattern.test(newPassword)
    //     if (!newPassword) {
    //         showToast('Please Enter Password', 3000)
    //     }
    //     else if (!confirmPassword) {
    //         showToast('Please Enter Confirm Password', 3000)
    //     }
    //     else if (result == false) {
    //         showToast('Password Should be at least one uppercase letter, one lowercase letter, one number and one special character', 5000)
    //     }
    //     else if (confirmPassword != newPassword) {
    //         showToast('Password & Confirm Password should be same', 3000)
    //     }
    //     else {
    //         let data = {
    //             newPassword,
    //             confirmPassword
    //         }
    //         setShowLoading(true);
    //         await instance({
    //             method: 'POST',
    //             url: `user/changePassword`,
    //             data,
    //             validateStatus: () => true
    //         }).then(res => {
    //             let obj: any = {};
    //             obj = res
    //             if (!obj.error) {
    //                 if (obj.status === "SUCCESS!") {
    //                     localStorage.setItem("providerStatus", obj.result.status);
    //                     setShowLoading(false);
    //                     common.progressBar = 0.94
    //                     setCommon(common)
    //                 }
    //             }
    //             else {
    //                 setShowLoading(false);
    //                 showToast(obj.error.message, 3000)
    //             }
    //         })
    //     }
    // }


    const validationSchema = yup.object({
        newPassword: yup
            .string()
            .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g, "Password should be at least one uppercase letter, one lowercase letter, one number and one special character")
            .required("This field is required"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('newPassword'), null], 'Password & Confirm Password should be same')
            .required("This field is required")
    });

    const submit = async (values: any) => {
        let newPassword = values.newPassword
        let confirmPassword = values.confirmPassword
        let data = {
            newPassword,
            confirmPassword
        }
        setShowLoading(true);
        await instance({
            method: 'POST',
            url: `user/changePassword`,
            data,
            validateStatus: () => true
        }).then(res => {
            let obj: any = {};
            obj = res
            if (obj.status === "SUCCESS!") {
                localStorage.setItem("providerStatus", obj.result.status);
                setShowLoading(false);
                common.progressBar = 0.94
                setCommon(common);
            }
            else {
                setShowLoading(false);
                showToast(obj.error.message, 3000)
            }
        })
    }
    const addAddress = async () => {
        let data = {
            firstLineAddress: billingAddress,
            businessCity,
            zipCode,
            phoneNumber,
            status: 'bank-details'
        }
        const pattern = /^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/;
        const result = pattern.test(businessCity);
        if (!phoneNumber) {
            showToast('Please Enter Phone Number.', 3000)
        }
        else if (phoneNumber.length < 10) {
            showToast('Phone Number must be 10 digits.', 3000)
        }
        else if (!billingAddress) {
            showToast('Please Enter Street Address.', 3000)
        }
        else if (!businessCity) {
            showToast('Please Enter Valid Zip Code', 3000)
        }
        else if (result == false) {
            showToast('City name should contain alphabets only', 3000)
        }
        else if (!zipCode) {
            showToast('Please Enter Zip Code.', 3000)
        }
        else if (zipCode.length < 5 || zipCode.length > 5) {
            showToast('Zip Code should contain max 5 digits', 3000)
        }
        else {
            setShowLoading(true)
            await instance({
                method: 'PUT',
                url: 'mybenefitsprovider/updateprovider',
                data,
                validateStatus: () => true
            }).then((response) => {
                let obj: any = {}
                obj = response
                setShowLoading(false);
                if (obj.status === "SUCCESS!") {
                    localStorage.setItem("providerStatus", obj.result.list[0].status);
                    common.progressBar = 0.96
                    setCommon(common)
                }
                else if (obj.error) {
                    setShowLoading(false);
                    showToast(obj.error.message, 3000)
                }
            })
        }
    }
    const addBankDetailes = async () => {
        let bank = {
            number,
            route,
            ein
        }
        if (!ein) {
            showToast('Please Enter EIN Number or Tax Id.', 3000)
        }
        else if (ein.length < 9) {
            showToast('EIN Number or Tax Id should be Exact 9 Digits.', 3000)
        }
        else if (!route) {
            showToast('Please Enter Routing Number.', 3000)
        }
        else if (route.length < 9) {
            showToast('Routing Number should be Exact 9 Digits.', 3000)
        }
        else if (!number) {
            showToast('Please Enter Account Number.', 3000)
        }
        else if (number.length < 8 || number.length > 17) {
            showToast('Account Number should contain min 8 digits or max 17 digits.', 3000)
        }
        else {
            setShowLoading(true);
            let encrypt = CryptoJS.AES.encrypt(JSON.stringify(bank), key).toString();
            let res: any = '';
            res = encrypt;
            let data: any = {};
            data.bank = res;
            data.status = "kyp";
            await instance({
                method: 'PUT',
                url: 'mybenefitsprovider/updateprovider',
                data,
                validateStatus: () => true
            }).then((response) => {
                let obj: any = {}
                obj = response;
                if (obj.status === "SUCCESS!") {
                    localStorage.setItem("isBankAdded", obj.result.list[0].isBankAdded);
                    localStorage.setItem("providerStatus", obj.result.list[0].status);
                    common.progressBar = 10
                    setCommon(common)
                    // props.dismissModal();
                    // window.location.href = "/dashboard"
                    setShowLoading(false);
                }
                else {
                    setShowLoading(false);
                    showToast(obj.error.message, 3000)
                }
            })
        }
    }
    const agree = () => {
        common.progressBar = 0.92
        common.termsCondView = false;
        setCommon(common)
    }

    const sendOtp = async () => {
        let data = {
            email: email
        }
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z]{3,20}[\.][a-zA-Z]{2,5}/g;
        const result = pattern.test(email);
        if (!email) {
            showToast('Please Enter Email', 3000)
        }
        else if (result === false) {
            showToast("Enter valid Email Id", 3000);
        }
        else {
            setShowLoading(true);
            await instance({
                method: 'PUT',
                url: 'mybenefitsprovider/updateEmail',
                data,
                validateStatus: () => true
            }).then((response) => {
                let obj: any = {}
                obj = response;
                if (obj.status === "SUCCESS!") {
                    localStorage.setItem("providerStatus", 'otpScreen');
                    showToast(`OTP Sent to ${email}`, 3000)
                    setShowLoading(false);
                }
                else {
                    setShowLoading(false);
                    setErrorMessage(obj.error.message);
                }
            })
        }
    }
    const verifyOtp = async () => {
        let data = {
            email: email,
            otp: otp
        }
        if (!otp || otp.length < 4) {
            showToast("Please Enter 4 digits OTP", 3000)
        }
        else {
            setShowLoading(true);
            await instance({
                method: 'PUT',
                url: 'token/verifyEmailOTP',
                data,
                validateStatus: () => true
            }).then((response) => {
                let obj: any = {}
                obj = response;
                if (obj.status === "SUCCESS!") {
                    localStorage.setItem('providerStatus', 'invite');
                    common.signUpData.ProviderEmailId = email;
                    setCommon(common)
                    showToast('OTP Verified, Email Updated Successfully', 3000)
                    setShowLoading(false);
                    setVerifyOtpScreen(false)
                    setVerifyEmailBtn(false)
                }
                else {
                    setShowLoading(false);
                    setErrorMessage(obj.error.message);
                    showToast(obj.error.message, 3000)
                }
            })
        }
    }
    const handleEmailChange = (e) => {
        if (compareEmail != e.detail.value!) {
            setEmail(e.detail.value!);
            setVerifyEmailBtn(true)
        }
        else {
            setEmail(e.detail.value!);
            setVerifyEmailBtn(false)
        }
    }
    const dismissModal = () => {
        props.dismissModal()
        //history.push('/discharge');
        // localStorage.setItem("coachMarker",'true');
        
    }
    // const generateToken = async () => {
    //     await instance({
    //         method: "GET",
    //         url: "mybenefitsprovider/linkToken",
    //         validateStatus: () => true
    //     }).then((resp) => {
    //         let obj: any = {}
    //         obj = resp
    //         setLinkToken(obj.result.link_token);
    //     })
    // };

    // useEffect(() => {
    //     generateToken();
    // }, []);

    // const getBankDetails = async () => {
    //     setShowLoading(true);
    //     await instance({
    //         method: 'GET',
    //         url: 'mybenefitsprovider/plaidBankDetails',
    //         validateStatus: () => true
    //     }).then((resp) => {
    //         let obj: any = {}
    //         obj = resp
    //         if (obj.status === "SUCCESS!") {
    //             setShowLoading(false);
    //             setBankDetails(obj.result)
    //             if (obj.result.plaidStatus === "verification" || Object.keys(obj.result).length === 0) {

    //             }
    //             else if (Object.keys(obj.result).length > 0 && obj.result.plaidStatus != "verification") {
    //                 props.dismissModal();
    //                 getUserDetails();
    //             }
    //         }
    //         else {
    //             setShowLoading(false);
    //         }
    //     })
    // }
    // useEffect(() => {
    //     getBankDetails()
    // }, []);

    // const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    //     setShowLoading(true)
    //     let metaKeys: any;
    //     metaKeys = metadata
    //     let data = {
    //         publicToken: publicToken,
    //         accountId: metaKeys.account_id,
    //         bankName: metaKeys.institution.name,
    //         linkToken: linkToken,
    //         plaidStatus: metaKeys.account.verification_status === null ? "verified" : metaKeys.account.verification_status,
    //     }
    //     instance({
    //         method: "PUT",
    //         url: "mybenefitsprovider/getAccessToken",
    //         data,
    //         validateStatus: () => true
    //     }).then((resp) => {
    //         let obj: any = {};
    //         obj = resp;
    //         if (obj.status === "SUCCESS!") {
    //             getBankDetails()
    //             setShowLoading(false);
    //         }
    //     })
    // }, []);


    // useEffect(() => {
    //     if ((bankDetails && bankDetails.plaidStatus === "verification")) {
    //         verfiy()
    //     }
    //     else {
    //         generateToken()
    //     }
    // }, [bankDetails && bankDetails.plaidStatus])



    // const verfiy = async () => {
    //     await instance({
    //         method: "GET",
    //         url: 'mybenefitsprovider/linkToken?type=Verify',
    //         validateStatus: () => true,
    //     }).then((resp) => {
    //         let obj: any = {}
    //         obj = resp
    //         if (obj.status === "SUCCESS!") {
    //             setLinkToken(obj.result.link_token);
    //         }
    //     })
    // }
    // const { open, ready } = usePlaidLink({
    //     token: linkToken,
    //     onSuccess,
    // })

    const zipCodeHandleChnage = async (e: any) => {
        setZipCode(e.value);
        setBusinessCity("")
        await instance({
            method: 'GET',
            url: `mybenefitsprovider/zipcity?zip=${e.value}`,
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {};
            obj = resp
            if (obj.status === 'SUCCESS!') {
                if (obj.result.length > 0) {
                    setBusinessCity(obj.result[0].city);
                }
            }
        })
    }
    const back = () => {
        localStorage.setItem("providerStatus", 'invite');
        getUserDetails();
    }
    const kypApiCall = async (e: any) => {
        setSpinner(true)
        await instance({
            method: 'PUT',
            url: 'mybenefitsprovider/kyp',
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {};
            obj = resp
            if (obj.status === 'SUCCESS!') {
                setKypVerified(true)
                setSpinner(false);
                localStorage.setItem('providerStatus', 'dashboard');
                setKypResponse(obj.message)
            }
            else {
                setSpinner(false)
                setKypVerified(true)
                localStorage.setItem('providerStatus', 'kyp');
                setKypResponse(obj.message)
            }
        })
    }

    const kypCompleted = () => {
        //history.push('/discharge');
        //localStorage.setItem("coachMarker",'true')
        props.dismissModal();
        setSpinner(false)
        setKypVerified(false)
        getUserDetails();

    }
    return (
        <IonPage>
            <IonContent>
                <div className="ion-justify-content-center display-flex hgt-100">
                    <IonCol className='pad-0'>
                        <IonRow className='popup-header'>
                            <IonCol className='pad-0'>
                                {providerStatus === "bank-details" || providerStatus === "kyp" || providerStatus == 'dashboard' ?
                                    <IonRow className='ion-justify-content-end pointer pad-0' onClick={()=>dismissModal()}>
                                        <CloseOutline title="Close" size={30} color="#FFFFFF"></CloseOutline>
                                    </IonRow> : <IonRow className='ion-justify-content-end pointer ion-padding-top'>
                                    </IonRow>}
                                <IonRow className='ion-justify-content-center popup-title'>
                                    <IonText>
                                        {common.termsCondView ? "Terms & Conditions" : providerStatus === "invite" && !common.termsCondView ? "Create Account" : providerStatus == "bank-details" ? "Add Bank Account" : providerStatus == "address" ? "Add Provider Information" : providerStatus == "otpScreen" ? "Verify OTP" : providerStatus == "kyp" && !kypResponse ? "Provider Verification" : ((providerStatus == "kyp" || providerStatus == "dashboard") && kypResponse) ? 
                                        <IonRow>{kypResponse === "KYP Process Failed" ?  "Provider Verification" : "Provider Verification Completed"}</IonRow> : ""}
                                    </IonText>
                                </IonRow>
                                <IonRow className='pad-bt-0 ion-align-items-center  ion-justify-content-center' style={{ paddingTop: "8px" }}>
                                    {(providerStatus === "dashboard" ? "" :
                                        <IonText className='popup-title percent-txt'>{(providerStatus === "invite" && common.termsCondView) ? "90%" : ""}
                                            {(providerStatus === "invite" && common.progressBar == 0.92 && !common.termsCondView) ? "92%" : ""}
                                            {(providerStatus === "otpScreen" && common.progressBar == 0.92 && !common.termsCondView) ? "92%" : ""}
                                            {(providerStatus === "address" || common.progressBar == 0.94) ? "94%" : ""}
                                            {(providerStatus === "bank-details" || common.progressBar == 0.96) ? "96%" : ""}
                                            {(providerStatus === "kyp" || common.progressBar == 0.98) ? "98%" : ""}
                                            {" "}
                                            of Profile Completed
                                        </IonText>)}
                                </IonRow>
                                {(providerStatus === "dashboard" ? <IonRow className='ion-padding-bottom'></IonRow> :
                                    <IonRow className='progressbar-clr' style={{ width: '80%', maxWidth: '180px', margin: '0 auto', }}>
                                        <IonProgressBar className='progressbar-clr' value={common.progressBar} style={{ height: '8px' }} type="determinate"></IonProgressBar><br />
                                    </IonRow>)}

                            </IonCol>
                        </IonRow>
                        {providerStatus === "otpScreen" &&
                            <IonRow onClick={() => back()} className="pointer ion-padding-horizontal ion-padding-top label" style={{ color: "#1EB9CD" }}><IonText>Back</IonText></IonRow>}
                        {common.termsCondView && providerStatus != "address" && providerStatus != "bank-details" && providerStatus != "kyp" ?
                            <>
                                {inviteId != undefined ?
                                    <div className='detaildiv'>
                                        <table className='table-pad'>
                                            <thead>
                                                <tr>
                                                    <th className='th-pad userdetails-header'>Patient Name</th>
                                                    <th className='th-pad userdetails-header'>Date of Birth</th>
                                                    <th className='th-pad userdetails-header'>Gender</th>
                                                    <th className='th-pad userdetails-header'>Employer</th>
                                                    <th className='th-pad userdetails-header'>Approved Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inviteDetails.map((value, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td className='td-pad userdetails-values'>{value.firstName} {value.lastName}</td>
                                                            <td className='td-pad userdetails-values'>{moment(parseInt(value.dob)).format("DD/MM/YYYY")}</td>
                                                            <td className='td-pad userdetails-values'>{value.gender}</td>
                                                            <td className='td-pad userdetails-values'>{value && value.employer && value.employer[0].name}</td>
                                                            <td className='td-pad userdetails-values'>${value.loanAmount}</td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    : null}
                                <IonRow className='ion-justify-content-center ion-padding-top' style={{ height: "74%" }}>
                                    <IonText className="terms-txt terms-txt-pd" style={{ maxWidth: '490px', margin: '0 auto', backgroundColor: 'white', }}>1. We ("myBenefits.ai") have no liability to you for any damages you may suffer as a result of any such third party's (other service providers engaged by myBenefits.ai) actions or inactions.
                                        <br></br><br></br>
                                        2. Indemnification. You agree to indemnify, defend and hold MyBenfits.ai harmless from any liability, including reasonable attorneys' fees, related to your use of the Services or any violation of these Terms of Service.
                                        <br></br><br></br>
                                        3. Limitation of Liability. myBenefits.ai HAVE NO LIABILITY WHATSOEVER FOR YOUR USE OF ANY PRODUCT AVAILABLE FROM OR THOUGH THE WEBSITE OR THE USE OF THE SERVICES. IN PARTICULAR, BUT NOT AS A LIMITATION THEREOF, myBenefits.ai ARE NOT LIABLE FOR ANY COMPENSATORY, DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF HEALTH, WELLNESS, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, PRODUCT OR SERVICE LIABILITY OR OTHERWISE
                                        <br></br><br></br>
                                        4. Accuracy, Reliability and Timeliness of Information: YOUR USE OF THE TOOLS AND SERVICES PROVIDED BY myBenefits.ai IS AT YOUR SOLE RISK. INFORMATION PROVIDED THROUGH THE TOOLS IS ON AN "AS IS" AND "AS AVAILABLE" BASIS FOR INFORMATIONAL PURPOSES ONLY. WE MAKE NO WARRANTY THAT THE TOOLS WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR BE UNINTERRUPTED, TIMELY, SECURE, ACCURATE OR RELIABLE.
                                        <br></br><br></br>
                                        5. Bank Transfers: When we initiate a bank transfer (ACH) to pay you (“Provider”), it takes 1-3 business days provided we have all the necessary information from you is accurate and compliant
                                        <br></br><br></br>
                                        Further, you acknowledge and agree that you will not hold myBenefits.ai responsible for, any liability arising from the actions or inactions of this third party in connection with the permissions you grant.</IonText>
                                </IonRow>
                            </> : null}
                        <IonRow style={{ maxWidth: '430px', margin: '0 auto', height: "74%" }}>
                            {providerStatus === "invite" && !common.termsCondView &&
                                <IonCol className="fields-row-pd">
                                    <IonRow>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Email</IonLabel>
                                            <IonInput type="text" className="ion-padding input-field mg-top placeholder-txt" placeholder="Enter" value={email}
                                                onIonChange={handleEmailChange}
                                            ></IonInput>
                                        </IonCol>
                                    </IonRow>{
                                        verifyemailbtn ? <IonButton expand="block" className='searchbtn mg-bt searchbtn-txt' onClick={() => sendOtp()}>Verify Email</IonButton> : ''
                                    }
                                    <Formik
                                        initialValues={{
                                            newPassword: "",
                                            confirmPassword: "",
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={submit}>

                                        {formikProps => (
                                            <form onSubmit={formikProps.handleSubmit}>
                                                <IonRow className='ion-padding-top'>
                                                    <IonCol className='pad-lr-0'>
                                                        <IonLabel className='label'>Password</IonLabel>
                                                        <IonInput type="password" disabled={verifyemailbtn} value={formikProps.values.newPassword}
                                                            className="ion-padding input-field mg-top placeholder-txt"
                                                            placeholder="Enter" name='newPassword'
                                                            onIonChange={formikProps.handleChange}
                                                            onFocus={formikProps.handleBlur}
                                                        > </IonInput>
                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.newPassword && formikProps.errors.newPassword}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className='pad-lr-0'>
                                                        <IonLabel className='label'>Confirm Password</IonLabel>
                                                        <IonInput disabled={verifyemailbtn} type="password" name='confirmPassword'
                                                            className="ion-padding input-field mg-top placeholder-txt" placeholder="Enter"
                                                            value={formikProps.values.confirmPassword}
                                                            onIonChange={formikProps.handleChange}
                                                            onFocus={formikProps.handleBlur}
                                                        > </IonInput>
                                                        <p className="fielderrormsg">
                                                            {formikProps.touched.confirmPassword && formikProps.errors.confirmPassword}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonButton expand="block" disabled={verifyemailbtn} type='submit' className='searchbtn mg-bt searchbtn-txt'>Next</IonButton>
                                            </form>
                                        )}
                                    </Formik>
                                    {/* <IonRow className="ion-padding-top">
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Password</IonLabel>
                                            <IonInput type="password" disabled={verifyemailbtn} className="ion-padding input-field mg-top placeholder-txt" placeholder="Enter" value={newPassword}
                                                onIonChange={e => setNewPassword(e.detail.value!)}
                                            > </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Confirm Password</IonLabel>
                                            <IonInput type="password" disabled={verifyemailbtn} className="ion-padding input-field mg-top placeholder-txt" placeholder="Enter" value={confirmPassword}
                                                onIonChange={e => setConfirmPassword(e.detail.value!)}
                                            > </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <IonButton expand="block" disabled={verifyemailbtn} className='searchbtn mg-bt searchbtn-txt' onClick={() => submit()}>Next</IonButton> */}
                                </IonCol>
                            }
                            {providerStatus === "otpScreen" &&
                                <IonCol className="fields-row-pd">
                                    <IonRow className='ion-padding-bottom'>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Enter OTP</IonLabel>
                                            <div>
                                                <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="####" format="####" value={otp} onValueChange={(e: any) => setOtp(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonButton expand="block" className='searchbtn mg-bt searchbtn-txt' onClick={() => verifyOtp()}>Verify OTP</IonButton>
                                    <IonRow className="ion-justify-content-center ion-padding" onClick={() => sendOtp()}>
                                        <IonText className='label pointer later-bnk-txt'><a>Resend OTP</a></IonText>
                                    </IonRow>
                                </IonCol>
                            }
                            {providerStatus === "address" &&
                                <IonCol className="fields-row-pd ion-padding-top">
                                    <IonRow className='ion-padding-bottom'>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Enter Phone Number</IonLabel>
                                            <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="(XXX) XXX-XXXX" format="(###) ###-####" value={phoneNumber} onValueChange={(e: any) => setPhoneNumber(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-padding-bottom'>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Enter Street Address</IonLabel>
                                            <IonInput autocomplete='on' type="text" placeholder="123 Street" className="input-field mg-top placeholder-txt" value={billingAddress}
                                                onIonInput={(e: any) => setBillingAddress(e.target.value)}
                                            > </IonInput>
                                        </IonCol>
                                    </IonRow>
                                    <div className='city-flex'>
                                        <IonRow className="ion-padding-bottom" style={{ width: "48%" }}>
                                            <IonCol className="pad-0">
                                                <IonLabel className='label'>Enter Zip Code</IonLabel>
                                                <NumberFormat className="input-field number-field placeholder-txt"
                                                    value={zipCode} style={{ border: 'none', outline: '1px solid #CDC5C5', width: "100%" }}
                                                    format="#####" onValueChange={(e: any) => zipCodeHandleChnage(e)}
                                                />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow style={{ width: "48%" }}>
                                            <IonCol className="pad-0">
                                                <IonLabel className='label'>Enter City</IonLabel>
                                                <IonInput readonly type="text" className="input-field mg-top placeholder-txt" value={businessCity} pattern="/^[a-zA-Z]*$/"
                                                > </IonInput>
                                            </IonCol>
                                        </IonRow>
                                    </div>
                                    <IonButton expand="block" className='nextbtn' onClick={() => addAddress()}>Next</IonButton>
                                </IonCol>
                            }
                            {providerStatus === "bank-details" &&
                                // <IonCol className="fields-row-pd">
                                //     {bankDetails && bankDetails.plaidStatus === "verification" ?
                                //         <IonCol>
                                //             <IonRow className='ion-padding ion-justify-content-center banktxt'>
                                //                 <IonText style={{ color: 'black' }}>Your bank account needs to be verified. Click on button below.</IonText>
                                //             </IonRow>
                                //             <IonButton expand="block" className='nextbtn width-100' onClick={() => open()}>Verify My Bank Account</IonButton>
                                //         </IonCol>
                                //         :
                                //         <IonCol>
                                //             <IonRow className='ion-padding ion-justify-content-center banktxt'>
                                //                 <IonText style={{ color: 'black' }}>Kindly link your bank account to start receiving payments</IonText>
                                //             </IonRow>
                                //             <IonButton expand="block" className='nextbtn width-100' onClick={() => { open() }} disabled={!ready}>
                                //                 Connect Bank Account
                                //             </IonButton>
                                //         </IonCol>

                                //     }
                                // </IonCol>

                                <IonCol className="fields-row-pd">
                                    <IonRow className='ion-padding-bottom'>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Enter EIN Number or Tax Id</IonLabel>
                                            <div>
                                                <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="###-##-####" format="#########" value={ein} onValueChange={(e: any) => setEin(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-padding-bottom'>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Enter Routing Number</IonLabel>
                                            <div>
                                                <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="###-##-####" format="#########" value={route} onValueChange={(e: any) => setRoute(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="pad-0">
                                            <IonLabel className='label'>Enter Account Number</IonLabel>
                                            <div>
                                                <NumberFormat className="ion-padding mg-top input-field placeholder-txt num-format-wd-100" placeholder="###-##-####" format="#################" value={number} onValueChange={(e: any) => setNumber(e.value.slice(0, e.maxLength))} style={{ width: "100%" }} />
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                    {bankName &&
                                        <IonRow className="ion-padding-top ion-text-center">
                                            <IonCol className="pad-0">
                                                <IonText>{bankName}</IonText>
                                            </IonCol>
                                        </IonRow>
                                    }
                                    <IonRow className='ion-padding-top pointer'>
                                        <IonText className='drctdbttxt1' onClick={() => { setWrAccRouteNo(!wrAccRouteNo) }}>Where is my routing & account number?</IonText>
                                    </IonRow>
                                    {wrAccRouteNo ?
                                        <IonGrid>
                                            <IonRow className='ion-justify-content-center ion-text-center vh-15'>
                                            </IonRow>
                                            <IonCard style={{ height: "140px", width: "90%", backgroundColor: 'white' }}>
                                                <IonCardContent style={{ padding: "5px 5px 5px 5px" }}>
                                                    <IonRow>
                                                        <IonCol size="2">
                                                            <div className='circleash'>
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size="8" className='pt-14 pl-20' style={{ paddingLeft: '20px' }}>
                                                            <div className='ashlines'></div>
                                                            <div className='ashlines-2' style={{ marginTop: "10px" }}></div>
                                                        </IonCol>
                                                        <IonCol size="2" className='ion-text-end'>
                                                            <div className='color-ash'>001</div>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="2">
                                                            <div className='ashlines-3'></div>
                                                            <div className='ashlines-3 mt-5'></div>
                                                        </IonCol>
                                                        <IonCol size="6">
                                                            <div className='ashlines-4 mt-15'></div>
                                                            {/* <div className='ashlines-2' style={{marginTop:"10px"}}></div> */}
                                                        </IonCol>
                                                        <IonCol size="4" className='mrt-50'>
                                                            $<div className='ashlines-1'></div>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="12" className='color-ash'>
                                                            :| 123456789  :| 000987654321 || 001
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                            <IonRow className='ion-justify-content-center'>
                                                <IonCol size="1">
                                                </IonCol>
                                                <IonCol size="5">
                                                    <div className="vl"></div><br />
                                                    <IonText>Routing Number</IonText>
                                                </IonCol>
                                                <IonCol size="5">
                                                    <div className="vl"></div><br />
                                                    <IonText>Account Number</IonText>
                                                </IonCol>
                                                <IonCol size="1">
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                        : null}
                                    <IonRow className='ion-padding-bottom'>
                                        <IonCol>
                                            <div>
                                                <IonText className='banktext'> myBenefits.ai is a safe and secure application. All banking information is secure and encrypted with AES 256 Bit encryption. If you have any questions please contact us at 408-393-5236 or support@mybenefits.ai</IonText>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonButton expand="block" className='nextbtn' onClick={() => addBankDetailes()}>Done</IonButton>
                                    <IonRow onClick={()=>dismissModal()} className="ion-justify-content-center ion-padding">
                                        <IonText className='label pointer later-bnk-txt'><a>Add Bank Later</a></IonText>
                                    </IonRow>
                                </IonCol>

                            }
                            {providerStatus === "kyp" &&
                                <IonCol className="fields-row-pd kyp-flex">
                                    {spinner === false && kypVerified === false &&
                                        <div style={{width:"100%"}}>
                                            <IonRow className='ion-padding-vertical ion-justify-content-center'>
                                                <IonText className='kyp-txt'>In order to receive payments, we need to verify your account. In most cases we can verify based on information already provided.Click on continue to initiate this process.</IonText>
                                                {/* <IonCol>
                                          <IonSpinner name="crescent" style={{height:"50px",width:"50px",color:"skyblue"}}></IonSpinner>
                                        </IonCol> */}

                                            </IonRow>
                                            <div className='ion-justify-content-between ion-padding-vertical kyp-flex'>
                                                <IonButton style={{width:"100px"}} className='nextbtn ion-margin-vertical' onClick={() => dismissModal()}>Skip</IonButton>
                                                <IonButton style={{width:"100px"}} className='nextbtn ion-margin-bottom' onClick={(e) => kypApiCall(e)}>Continue</IonButton>
                                            </div>
                                        </div>}
                                    {spinner === true && kypVerified === false &&
                                        <>
                                            <IonRow className='ion-padding-bottom'>
                                                <IonSpinner name="crescent" style={{ height: "100px", width: "100px", color: "#00B9CF" }}></IonSpinner>

                                            </IonRow>

                                            <IonText className='kyp-txt'>Provider Verification</IonText>

                                        </>
                                    }

                                    {spinner === false && kypVerified === true && <>
                                        {kypResponse === "KYP Process Failed" &&
                                            <>
                                                <IonRow className='ion-padding-bottom'>
                                                    {/* <CloseCircleOutline size={100} color="red"></CloseCircleOutline> */}
                                                    <img src={require(`../../images/say-no.png`).default} className="" height="100px" width="100px" alt="" />
                                                </IonRow>
                                                <IonText className='kyp-txt ion-padding-vertical'>Thank you for initiating the verification process. We require additional information to complete this process, someone from our team will get in touch with you shortly.</IonText>
                                                <IonRow className='ion-padding-vertical'>
                                                    <IonButton className='btn-wd-100 nextbtn' onClick={() => kypCompleted()}>Done</IonButton>
                                                </IonRow>
                                            </>
                                        }
                                    </>
                                    }
                                </IonCol>
                            }
                            {providerStatus === "dashboard" &&
                                <IonCol className="fields-row-pd kyp-flex">
                                    {spinner === false && kypVerified === true && <>
                                        {kypResponse === "KYP Process Completed" &&
                                            <>
                                                <IonRow className='ion-padding-bottom'>
                                                    <CheckCircle size={100} color="green" />
                                                </IonRow>
                                                <IonText className='kyp-txt ion-padding-vertical'>Great News! Your account has been verified. You will now be able to receive payments. No further action is required on your part.</IonText>
                                                <IonRow className='ion-padding-vertical'>
                                                    <IonButton className='btn-wd-100 nextbtn' onClick={() => kypCompleted()}>Done</IonButton>
                                                </IonRow>
                                            </>
                                        }
                                    </>
                                    }

                                </IonCol>


                            }
                        </IonRow>
                    </IonCol>
                </div>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
            {common.termsCondView && providerStatus != "address" && providerStatus != "bank-details" && providerStatus != "kyp" ?
                <div className="bottom-div">
                    <IonRow className='ion-justify-content-center pd-bt-4'>
                        <IonButton expand="block" className='agreebtn agreebtn-txt' onClick={() => agree()} > I Agree</IonButton></IonRow>
                </div> : null}
        </IonPage>
    )
}
export default EmailInviteSignUp