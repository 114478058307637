import React, { useState } from 'react'; import { Route, Redirect, RouteProps, } from 'react-router-dom';
interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any 
  component: any;
}
const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const [isLoggin, setIsLoggin] = useState(localStorage.getItem("patientLogin"))
  const getDataFromLocalStorage = (): any => {
    const storedData = localStorage.getItem("patientLogin");
    if (storedData) {
      const { data, timestamp } = JSON.parse(storedData);
      const now = new Date();
      const timeDifference = now.getTime() - timestamp;
      // const minutesDifference = timeDifference / (1000 * 60);
      // if (minutesDifference <= 5) { 
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      if (daysDifference <= 30) {
        return data;
      } else {
        localStorage.clear();
      }
    } return null;
  };
  return (<Route {...rest} render={(routeProps) => getDataFromLocalStorage() ? (
    <Component {...routeProps} />) : (
    <Redirect to={{ pathname: '/patient', state: { from: routeProps.location } }} />)} />);
};
export default PrivateRoute;