
import { Document } from "@styled-icons/fluentui-system-filled/Document"
import moment from "moment";
import { useHistory } from "react-router";
interface Props {
    eventsData: any,
    value: string,
    dischargeId: string
}
const InstructionsUpdate: React.FC<Props> = (props) => {
    const history = useHistory<any>()
    const duration = moment(new Date(props.eventsData.timestamp)).fromNow();
    return (
        <div>
            <div style={{ display: "flex", alignItems: "center" }} onClick={() => history.push("/specialInstructions", { props: props.value, dischargeId: props.dischargeId })}>
                <div className="icon-bg" style={{ background: "#D9FAFF" }}><Document size={30} color="#170558" /></div>
                <div>
                    <div className="txt-robot" style={{ lineHeight: "20px",textAlign:"left",fontSize:"14px"}}>{`Your provider ${props && props.eventsData.providerName} has updated the discharge instructions`}</div>
                    <div style={{ paddingTop: "8px", fontSize: "12px" }}>
                        {duration && duration === 'a few seconds ago' ? 'Just now' : duration}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InstructionsUpdate;