import { IonContent, IonPage,IonRow,IonButton,IonText,IonFooter } from "@ionic/react"
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
interface Props{
    dissmissModal:()=>void,
    ratingsData:[]
}
const Ratings:React.FC<Props>=(props)=>{
    return(
        <IonPage>
           <div
          style={{ display: "flex", flexDirection: "column" }}
          className="popup-header"
        >
          <IonRow className="close pointer" onClick={() =>props.dissmissModal()}>
            <CloseOutline
              title="Close"
              size={30}
              color="#FFFFFF"
            ></CloseOutline>
          </IonRow>
          <IonRow
            className="ion-justify-content-center popup-title ion-padding"
            style={{ fontSize: "30px" }}
          >
            <IonText>
              Ratings
            </IonText>
          </IonRow>
        </div>
        <IonContent style={{ height: "auto", width: "100%",}}>
          <div style={{ width: "100%", height: "100%", backgroundColor: "white",overflow:"auto"  }}>
            <table className="table-container" style={{ background: "white" }}>
              <thead className="patient-thread">
                <tr>
                  <th className="patient-th pd-lr-24 whitespace txt-al-ctr">
                    Date & Time
                  </th>
                  <th className="patient-th pd-lr-24 whitespace txt-al-ctr">
                    Patient name
                  </th>
                  <th className="patient-th pd-lr-24 whitespace txt-al-ctr">
                    Comment
                  </th>
                  <th className="patient-th pd-lr-24 whitespace txt-al-ctr">
                    Rating
                  </th>
                </tr>
              </thead>
              <tbody className="patient-tbody">
                {props.ratingsData && props.ratingsData.length > 0 ? (
                 props.ratingsData.filter(
                    (item: any) => item.isReview === true
                  ).map((item: any, index: any) => {
                    return (
                      <tr key={index} className="patient-tr">
                        <td className="td-txt pd-lr-24 txt-al-ctr">{item.timestamp
                          ? new Date(item.timestamp).toLocaleDateString(
                            "en-US",
                            { timeZone: "America/Los_Angeles" }
                          )
                          : "NA"}{" "}{item.timestamp
                            ? new Date(item.timestamp).toLocaleTimeString(
                              "en-US",
                              { timeZone: "America/Los_Angeles" }
                            )
                            : "NA"}</td>
                        <td className="td-txt pd-lr-24 txt-al-ctr">{item.name}</td>
                        <td className="td-txt pd-lr-24 txt-al-ctr">{item.comment}</td>
                        <td className="td-txt pd-lr-24 txt-al-ctr">{item.rating}</td>
                      </tr>
                    )
                  })) : null}
              </tbody>
            </table>
            {props.ratingsData && props.ratingsData.length === 0 && (
              <IonRow className="ion-padding-vertical ion-justify-content-center">
                <div className="no-data-msg">No Data Found</div>
              </IonRow>
            )}
          </div>
        </IonContent>
        <IonFooter>
          <IonRow className="ion-padding-vertical ion-justify-content-center ion-align-items-flex-end">
            <IonButton
              className="btn-wd-100 nextbtn"
              onClick={() => props.dissmissModal()}
            >
              Close
            </IonButton>
          </IonRow>
        </IonFooter>
        </IonPage>
    )
}
export default Ratings