import {
  IonButton, IonToast, IonGrid, IonCol, IonLoading, IonContent, IonInput, useIonAlert,
  IonLabel, IonPage, IonRow, IonText, useIonToast,
} from '@ionic/react';
import './index.css';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import instance from '../../utils/api';
import OuterHeader from '../../components/outerHeader';
import { Formik } from 'formik';
import Loader from '../../components/Loader';
import * as yup from 'yup';

const Signup: React.FC = () => {
  const [showToast, dismiss] = useIonToast();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  var unlock_source = localStorage.getItem("unlock_source");
  const [providerId, setProviderId] = useState<any>("");
  const [InviteProviderId, setInviteProvider] = useState<any>("");

  useEffect(() => {
    let path = window.location.href;
    let urlProviderId = path.split("=");
    var secondId: any
    var proId: any
    if (urlProviderId.length > 1) {
      proId = urlProviderId && urlProviderId[1].slice(0, -3)
      secondId = urlProviderId[2]
      setProviderId(secondId);
      setInviteProvider(proId);
    }
  }, [providerId]);

  const createAccount = async (values: any) => {
    let data: any = {}
    data.email = values.email
    data.type = values.providerType
    if (providerId) {
      data.id = providerId
    }
    if (InviteProviderId) {
      data.provider = InviteProviderId
    }
    if (unlock_source != null) {
      data.signupSource = unlock_source
    }
    if (values.practiceName) {
      data.displayName = values.practiceName
    }
    if (values.providerFirstName && values.providerLastName) {
      data.providerFirstName = values.providerFirstName
      data.providerLastName = values.providerLastName
    }
    setShowLoading(true);
    await instance({
      method: 'POST',
      url: 'mybenefitsprovider/create',
      data,
      validateStatus: () => true
    }).then((response) => {
      let obj: any = {}
      obj = response;
      if (obj.status === "SUCCESS!") {
        localStorage.setItem("isBankAdded", obj.result.isBankAdded);
        localStorage.setItem("isSelfSignedUp", obj.result.selfSigned);
        window.location.href = "/emailsentsuccess"
        setShowLoading(false);
        localStorage.removeItem("unlock_source");
      }
      else {
        setShowLoading(false);
        showToast(obj.error.message, 3000)
      }
    })
  }

  const ProviderTypeData = [
    { label: "Dental", value: "Dental" },
    { label: "Vision", value: "Vision" },
    { label: "Accupuncture", value: "Accupuncture" },
    { label: "Chiropractic", value: "Chiropractic" },
    { label: "Mental Health", value: "Mental Health" },
    { label: "Vet", value: "Vet" },
    { label: "Physical Therapy", value: "Physical Therapy" },
    { label: "Spa", value: "Spa" },
    { label: "Hospital", value: "Hospital" },
    { label: "Yoga", value: "Yoga" },
    { label: "Doctor Specialist", value: "Doctor Specialist" },
    { label: "Others", value: "Others" }
  ];



  const validationSchema = yup.object({
    providerType: yup.string().required("This field is required"),
    email: yup.string().email('Invalid Email')
      .required("This field is required"),
    valiTrue: yup.boolean(),
    practiceName: yup.string().when('valiTrue', {
      is: true,
      then: yup.string().required('This field is required')
    }),
    providerFirstName: yup.string().when('valiTrue', {
      is: false,
      then: yup.string().required('This field is required')
    }),
    providerLastName: yup.string().when('valiTrue', {
      is: false,
      then: yup.string().required('This field is required')
    })
  })
  return (
    <IonPage>
      <IonContent>
        <IonGrid className='pad-0'>
          <IonRow>
            <IonCol className='pad-0'>
              <OuterHeader></OuterHeader>
              <IonRow className="head-prov-title-bg ion-justify-content-center ion-align-items-center ion-margin-bottom">
                <IonText>
                  Welcome Provider!
                </IonText>
              </IonRow>
              <Formik initialValues={{
                providerType: "",
                practiceName: "",
                providerFirstName: "",
                providerLastName: "",
                email: '',
                valiTrue: false
              }} onSubmit={createAccount} validationSchema={validationSchema}>
                {formikProps => (
                  <form onSubmit={formikProps.handleSubmit}>
                    <div className='fieldiv ion-padding-top'>
                      <IonCol className="fields-row-pd">
                        <IonRow className="ion-pd ion-padding-top">
                          <IonCol className="pad-0" style={{ zIndex: '99999' }}>
                            <IonLabel className="label" >Provider Type</IonLabel>
                            <Select className='mg-top pointer'
                              name="providerType"
                              placeholder="Select"
                              options={ProviderTypeData}
                              onChange={(e: any) => {
                                formikProps.setFieldValue("providerType", e.value);
                                if (e.value === "Spa" || e.value === "Yoga" || e.value === "Hospital") {
                                  formikProps.setFieldValue("valiTrue", "true")
                                }
                                else {
                                  formikProps.setFieldValue("valiTrue", "false")
                                }
                              }}
                              onBlur={formikProps.handleBlur}
                            />
                            {formikProps.touched.providerType && formikProps.errors.providerType &&
                              <p className="fielderrormsg">
                                {formikProps.touched.providerType && formikProps.errors.providerType}
                              </p>
                            }
                          </IonCol>
                        </IonRow>
                        {formikProps.values.providerType && (formikProps.values.providerType === "Spa" || formikProps.values.providerType === "Yoga" || formikProps.values.providerType === "Hospital") ?
                          <IonRow className="ion-padding-top">
                            <IonCol className="pad-0">
                              <IonLabel className='label'>Practice Name</IonLabel>
                              <IonInput type="text" name="practiceName" className="input-field mg-top placeholder-txt" placeholder="Enter" value={formikProps.values.practiceName}
                                onIonChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                              > </IonInput>
                              {formikProps.touched.practiceName && formikProps.errors.practiceName &&
                                <p className="fielderrormsg">
                                  {formikProps.touched.practiceName && formikProps.errors.practiceName}
                                </p>
                              }
                            </IonCol>
                          </IonRow> :
                          <>
                            <IonRow className="ion-padding-top">
                              <IonCol className="pad-0">
                                <IonLabel className='label'>First Name</IonLabel>
                                <IonInput type="text" name="providerFirstName" className="input-field mg-top placeholder-txt" placeholder="Enter" value={formikProps.values.providerFirstName}
                                  onIonChange={formikProps.handleChange}
                                  onBlur={formikProps.handleBlur}
                                > </IonInput>
                                {formikProps.touched.providerFirstName && formikProps.errors.providerFirstName &&
                                  <p className="fielderrormsg">
                                    {formikProps.touched.providerFirstName && formikProps.errors.providerFirstName}
                                  </p>
                                }
                              </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-top">
                              <IonCol className="pad-0">
                                <IonLabel className='label'>Last Name</IonLabel>
                                <IonInput type="text" name='providerLastName' className="input-field mg-top placeholder-txt" placeholder="Enter" value={formikProps.values.providerLastName}
                                  onIonChange={formikProps.handleChange}
                                  onBlur={formikProps.handleBlur}
                                > </IonInput>
                                {formikProps.touched.providerLastName && formikProps.errors.providerLastName &&
                                  <p className="fielderrormsg">
                                    {formikProps.touched.providerLastName && formikProps.errors.providerLastName}
                                  </p>
                                }
                              </IonCol>
                            </IonRow>
                          </>
                        }
                        <IonRow className="ion-padding-top">
                          <IonCol className="pad-0">
                            <IonLabel className='label'>Practice Email</IonLabel>
                            <IonInput type="text" name="email" className="input-field mg-top placeholder-txt" placeholder="Enter" value={formikProps.values.email.trim()}
                              onIonChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                            > </IonInput>
                            {formikProps.touched.email && formikProps.errors.email &&
                              <p className="fielderrormsg">
                                {formikProps.touched.email && formikProps.errors.email}
                              </p>
                            }
                          </IonCol>
                        </IonRow>
                        <IonButton expand="block" className='searchbtn  btn-mg-tp-0 searchbtn-txt' type="submit">Create Account</IonButton>
                        <IonRow className='signuprow ion-justify-content-center ion-padding'>
                          <span>Have an account</span>?<span className='signup' onClick={() => { window.location.href = '/provider_login' }}>Sign in now</span>
                        </IonRow>
                      </IonCol>
                    </div>
                  </form>
                )}
              </Formik>

            </IonCol>
            {/* <IonCol sizeXs='12' sizeMd='3' className="ion-padding-top ion-padding-bottom col-1">
              <TreatNowDescription />
            </IonCol> */}
          </IonRow>
        </IonGrid>
        {showLoading && <Loader />}
      </IonContent>
    </IonPage>
  );
};

export default Signup;
