import {
    IonPage, IonRow, IonCol, IonLabel, useIonToast, IonLoading, IonPopover,
    IonIcon, IonGrid, IonHeader, IonText, IonContent, IonButton, IonInput, IonTextarea
} from '@ionic/react';
import './index.css';
import { useState, useContext, useEffect } from 'react';
import { person, power } from 'ionicons/icons';
import instance from '../../../utils/api';
import Select from 'react-select';
const AdminDashboard: React.FC = () => {
    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
    const adminName = localStorage.getItem("admin_name");
    const [providerEmail, setProviderEmail] = useState<any>();
    const [cardsData, setCardsData] = useState<any>([]);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showToast, dismiss] = useIonToast();
    const [EmployersList, setEmployersList] = useState<any>([]);
    const [selectEmployer, setSelectEmployer] = useState<any>({ label: 'ALL', value: 'ALL' })
    const signOut = () => {
        localStorage.clear()
        window.location.href = "/admin";
    }
    const showPopUp = (e: any) => {
        e.persist();
        setShowPopover({ showPopover: true, event: e })
    }
    const AdminDetails = async () => {
        let url: any
        if (selectEmployer.value === "ALL") {
            url = 'user/adminDashboard'
        }
        else {
            url = `user/adminDashboard?id=${selectEmployer.value}`
        }
        setShowLoading(true);
        await instance({
            method: 'GET',
            url: url,
            validateStatus: () => true
        })
            .then((res) => {
                let obj: any = {}
                obj = res
                if (obj.status === "SUCCESS!") {
                    setCardsData(obj.cards);
                    setShowLoading(false);
                    getEmployersList()
                }
                else {
                    setShowLoading(false);
                    signOut();
                }
            })
    }



    const getEmployersList = async () => {
        await instance({
            method: 'GET',
            url: 'employer/getEmployers',
            validateStatus: () => true
        }).then((resp) => {
            let obj: any = {}
            obj = resp;
            if (obj.status === "SUCCESS!") {
                setEmployersList(obj.result)
            }
        })
    }
    useEffect(() => {
        getEmployersList()
    }, [])


    useEffect(() => {
        AdminDetails();
    }, [])

    let options =
        EmployersList && EmployersList.length > 0 && EmployersList.map(function (item: any) {
            return { value: item && item._id, label: item && item.name };
        })

    const data = [
        { label: "ALL", value: "ALL" },
        { options }
    ]
    const download = async () => {
        let url: any
        if (selectEmployer.value === "ALL") {
            url = 'user/downloadReport'
        }
        else {
            url = `user/downloadReport?id=${selectEmployer.value}`
        }
        await instance({
            method: 'GET',
            url: url,
            validateStatus: () => true,
        })
            .then((res) => {
                let obj: any = {}
                obj = res
                if (obj.status === "SUCCESS!") {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = obj.result[0];
                    downloadLink.download = `${obj.fileName}`;
                    downloadLink.click();
                    downloadLink.remove();
                }
                else {
                    setShowLoading(false);
                }
            })
    }
    const downloadReport = async () => {
        let url: any
        if (selectEmployer.value === "ALL") {
            url = 'user/detailedReport'
        }
        else {
            url = `user/detailedReport?id=${selectEmployer.value}`
        }
        await instance({
            method: 'GET',
            url: url,
            validateStatus: () => true,
        })
            .then((res) => {
                let obj: any = {}
                obj = res
                if (obj.status === "SUCCESS!") {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = obj.result[0];
                    downloadLink.download = `${obj.fileName}.xlsx`;
                    downloadLink.click();
                    downloadLink.remove();
                }
                else {
                    setShowLoading(false);
                }
            })
    }
    const deleteProvider = async () => {
        if (!providerEmail) {
            showToast('Please Enter Provider Emails', 3000)
        }
        else {
            let providerEmails = providerEmail.split(",");
            let data = {
                emails: providerEmails
            }
            setShowLoading(true);
            await instance({
                method: 'DELETE',
                url: 'user/provider/delete',
                data,
                validateStatus: () => true
            }).then((resp) => {
                let obj: any = {}
                obj = resp
                if (obj.status === "SUCCESS!") {
                    setShowLoading(false);
                    setProviderEmail("");
                    AdminDetails();
                    showToast("Deleted Provider successfully", 3000)
                }
                else {
                    setShowLoading(false);
                    showToast(obj.error.message, 3000)
                }
            })
        }
    }
    console.log(selectEmployer.value)

    const handleEmployerList=(value:any)=>{
        console.log(value)
        setSelectEmployer(value)
        AdminDetails()
    }
    return (
        <IonPage>
            <IonHeader style={{ zIndex: '0' }} className="header-box">
                <IonGrid className='pad-0'>
                    <IonRow className='header'>
                        <IonRow className='ion-align-items-center margin-auto'>
                            <IonRow className="ion-justify-content-center ion-align-items-center">
                                <div style={{ display: 'flex' }}>
                                    <img src={require(`../../../images/final_logo.png`).default} className="" height="38px" width="37px" alt="" />
                                </div>
                                <IonRow>
                                    <IonCol>
                                        <IonRow className="ion-align-items-center ion-justify-content-center"><IonText className='logo-txts'>myBenefits.ai</IonText></IonRow>
                                    </IonCol>
                                </IonRow>
                            </IonRow>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-evenly headers-list-txt '>
                            <IonRow>
                                <div className='align-center img-pd pointer' onClick={(e: any) => showPopUp(e)}>
                                    <span>
                                        <img src={require(`../../../images/home.png`).default} className='userimg' alt="" />
                                    </span>
                                    <span className='username-pd header-txt'>{adminName ? adminName : "Admin"}</span>
                                </div></IonRow>
                            <IonPopover
                                event={popoverState.event}
                                isOpen={popoverState.showPopover}
                                onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', padding: '5px' }}>
                                    <div className='align-center' style={{ padding: '8px' }}><IonIcon style={{ paddingRight: "10px", fontSize: '20px' }} icon={person} />Change Password</div>
                                    <div onClick={() => signOut()} className='align-center' style={{ padding: '8px' }}><IonIcon size='30' style={{ paddingRight: "10px", fontSize: '20px' }} icon={power}></IonIcon>Sign out</div>
                                </div>
                            </IonPopover>
                        </IonRow>
                    </IonRow>
                </IonGrid>
            </IonHeader>
            <IonContent>
                <IonGrid style={{ padding: '1rem 3rem' }}>
                    <IonRow className='downbtn'>
                        <IonCol>
                            <div><IonLabel className='searchlabel'>Employer</IonLabel></div>
                            <div style={{ width: '250px' }}>
                                <Select className='mg-top' defaultValue={{ label: 'ALL', value: 'ALL' }} value={selectEmployer} onChange={(value) => {handleEmployerList(value)}}
                                    options={data}
                                />
                            </div>
                        </IonCol>
                        <IonButton expand="block" className='searchbtn searchbtn-txt mg-btn-5 pad-lr-15' onClick={() => download()} >Download</IonButton>
                        <IonButton expand="block" className='searchbtn searchbtn-txt mg-btn-5 pad-lr-15' onClick={() => downloadReport()} >Download Detail Report</IonButton>
                    </IonRow>
                    <IonRow className="ion-wrap ion-padding-top">
                        <IonCol className="ion-align-self-start" size='4' sizeSm='6' sizeXs='12' sizeLg='4'>
                            <div className='card-divs'>
                                <div className="pro-card cards-width-auto">
                                    <div className='cards-title feedback-pdbt'>
                                        {cardsData && cardsData[0] && cardsData[0].header ? cardsData[0].header : 'Employees'}
                                    </div>
                                    <div>
                                        <div className='totalamount-txt'>{cardsData && cardsData[0] && cardsData[0].employees ? cardsData[0].employees : '0'}</div>
                                    </div>
                                    <div className="card1-descp-txt">
                                        {cardsData && cardsData[0] && cardsData[0].description ? cardsData[0].description : ''}
                                    </div>
                                </div>
                            </div>
                        </IonCol>
                        <IonCol className="ion-align-self-start" size='4' sizeSm='6' sizeXs='12' sizeLg='4' >
                            <div className='card-divs'>
                                <div className="pro-card cards-width-auto" >
                                    <div className='cards-title feedback-pdbt'>
                                        {cardsData && cardsData[1] && cardsData[1].header ? cardsData[1].header : 'Providers'}
                                    </div>
                                    <div>
                                        <div className='totalamount-txt'>{cardsData && cardsData[1] && cardsData[1].total ? cardsData[1].total : "0"}</div>
                                    </div>
                                    <div className='cards1-flex'>
                                        <div>
                                            <div className='pending-ftwt'>{cardsData && cardsData[1] && cardsData[1].invited ? cardsData[1].invited : '0'}</div>
                                            <div className='pending-txt'>Providers Invited</div>
                                        </div>
                                        <div>
                                            <div className="denied-ftwt">{cardsData && cardsData[1] && cardsData[1].signedup ? cardsData[1].signedup : '0'}</div>
                                            <div className='pending-txt'>Providers Signed Up</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-align-items-center pad-email-row'>
                        <IonLabel className='label'>Provider Email:</IonLabel>
                        <IonRow className='mg-rt-5'>
                            <IonInput type="text" className="input-field placeholder-txt"
                                placeholder="Email" onIonInput={(e: any) => setProviderEmail(e.target.value)} value={providerEmail}>
                            </IonInput>
                        </IonRow>
                        <IonButton expand='block' className='delete-btn searchbtn-txt' onClick={() => deleteProvider()}>Delete</IonButton>
                    </IonRow>
                </IonGrid>
                <IonLoading isOpen={showLoading} message={'Please wait...'} />
            </IonContent>
        </IonPage>
    )
}
export default AdminDashboard;