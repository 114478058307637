import { IonContent, IonLoading, useIonToast, IonGrid, IonPage, IonRow, IonText, IonButton, IonCol, IonLabel, IonInput, IonTextarea } from "@ionic/react"
import { useState, useEffect, useContext } from "react";
import { number } from "yup";
import instance from "../../../utils/api";
import ProvidersHeader from "../../../components/providersInvite/providersHeader"
import './index.css'
import moment from "moment";
import CommonContext from "../../../utils/contexts/commonProps";

interface Props {
    showBlock: Boolean,
    amount: number,
    range: number,
    loanBalance:Number
}
const Agreement: React.FC<Props> = (props) => {
    const [headerTitle, setHeaderTitle] = useState<any>("Financial Agreement ");
    const [signature, setSignature] = useState<any>("");
    const [showToast, dismiss] = useIonToast();
    const [showLoading, setShowLoading] = useState(false);
    const amount = localStorage.getItem("amount");
    var range = localStorage.getItem("range");
    const type = localStorage.getItem("type");
    const finalPaymentDate = localStorage.getItem("lastPaymentDate");
    var presentDate = new Date().toISOString();
    var ranges = props.range == 0 ? 1 : props.range

    useEffect(() => {
        if(props.range  || props.amount){
            localStorage.setItem("amount", String(props.amount));
            localStorage.setItem("range", JSON.stringify(props.range == 0 ? 1 : props.range))
        }
    }, [props.amount, props.range])

    useEffect(() => {
        if (ranges > 0) {
            // var d = new Date();
            // d.setMonth(d.getMonth() + props.range);
            var now = new Date();
            var current = new Date(now.getFullYear(), now.getMonth() + ranges);
            localStorage.setItem("lastPaymentDate", current.toISOString())
        }
    }, [ranges])
    

    const acceptApiCall = async () => {
        if(!signature){
            showToast("Please Enter Signature", 3000)
        }
        else {
        let data: any = {}
        data.totalAmount = amount
        data.description = type
        data.serviceType = type
        data.tenure = Number(range);
        if(props.loanBalance === 5000){
          data.source = "web";
        }
        setShowLoading(true);
        await instance({
            method: 'POST',
            url: 'PayLater',
            data,
            validateStatus: () => true
        }).then((response) => {
            let obj: any = {}
            obj = response;
            if (obj.status === "SUCCESS!") {
                localStorage.clear();
                localStorage.setItem("digitCode", obj.result.digitCode)
                window.location.href = "/payment_collected";
                setShowLoading(false);
            }
            else {
                setShowLoading(false);
                showToast(obj.error.message, 3000)
            }
        })
    }
    }
   
    return (
        <div className="bg-clr-white scroll-y">
            {props.showBlock === false &&
                <div style={{ position: "absolute", top: "0", width: "100%" }}>
                    <ProvidersHeader title={headerTitle} /></div>}
            <IonGrid className={"ht-100 ion-padding-top " + (props.showBlock === false ? "mg-top-80" : "")}>
                <div className={"ht-100 pro-flex-col ion-justify-content-start ion-align-items-stretch " + (props.showBlock === false ? "fields-pd" : "")}>
                    <table>
                        <tbody>
                            <tr>
                                <td className="amt-cal-txt">Amount Financed:</td>
                                <td className="amt-value txt-align-rt">${amount && Number(amount)}</td>
                            </tr>
                            <tr>
                                <td className="amt-cal-txt">Total Finance Charge:</td>
                                <td className="amt-value txt-align-rt">$0.00</td>
                            </tr>
                            <tr>
                                <td className="amt-cal-txt">APR:</td>
                                <td className="amt-value txt-align-rt">0%</td>
                            </tr>
                            <tr>
                                <td className="amt-cal-txt">Your Monthly Payments</td>
                                <td className="amt-value txt-align-rt">${amount && Number(range) ? (Number(amount) / Number(range)).toFixed(2):"x.xx"}</td>
                            </tr>
                            <tr>
                                <td className="amt-cal-txt">Final Payment Date</td>
                                <td className="amt-value txt-align-rt">{finalPaymentDate ? moment(finalPaymentDate).format("MM/DD/YYYY") : moment(presentDate).format("MM/DD/YYYY")}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Note: Payments will begin every 1st of the month once the token is used.</p>
                    {props.showBlock === false &&
                        <div>
                            <div>
                                <IonRow className='ion-padding-vertical'>
                                    <IonCol className='pad-lr-0'>
                                        <IonLabel className='pro-label-txt'>Signature</IonLabel>
                                        <IonTextarea className="text-area-ht mg-field-top" value={signature}
                                            onIonChange={e => setSignature(e.detail.value!)}>
                                        </IonTextarea>
                                    </IonCol>
                                </IonRow>
                            </div>
                            <IonRow className="ion-justify-content-end clear-txt ion-padding-bottom ion-margin-bottom">
                                <IonText onClick={() => setSignature("")}>Clear</IonText>
                            </IonRow>
                            <IonRow className="ion-justify-content-center ion-padding-vertical ion-margin-top">
                                <IonButton expand="block" type='submit' className='provoiders-btn provoiders-btn-txt' onClick={() => acceptApiCall()}>Accept</IonButton>
                            </IonRow>
                        </div>
                    }
                </div>
            </IonGrid>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
            />
        </div>

    )
}
export default Agreement