import { IonCol, IonProgressBar, IonText, IonModal, IonContent } from "@ionic/react";
import { useState, useEffect, useContext } from "react";
import instance from "../../utils/api";
import CommonContext from "../../utils/contexts/commonProps";
import { ChevronRight } from "@styled-icons/boxicons-regular/ChevronRight";
import EmailInviteSignUp from "../../components/EmailInviteSignUp";
const ProgressBar: React.FC = () => {
    const [activateAccountModal, setActivateAccountModal] = useState(false);
    const providerStatus = localStorage.getItem("providerStatus") || "";
    const { common, setCommon } = useContext(CommonContext);
    useEffect(() => {
        let path = window.location.href;
        let urlToken = path.split("?");
        let finalUrlToken = urlToken[1];
        if (finalUrlToken && providerStatus !== "dashboard") {
            setActivateAccountModal(true);
            let urlValues = finalUrlToken.split("/");
            instance.defaults.headers.common["Authorization"] = urlValues[0];
            localStorage.setItem("auth_token", urlValues[0]);
            if (urlValues[1] === "self") {
                localStorage.setItem("selfSigned", urlValues[1]);
            } else if (urlValues[1] !== "self") {
                localStorage.setItem("inviteId", urlValues[1]);
            }
        } else if (providerStatus !== "dashboard") {
            setActivateAccountModal(true);
        } else if (providerStatus === "dashboard") {
            setActivateAccountModal(false);
        }
    }, [providerStatus]);

    const dismissModal = () => {
        setActivateAccountModal(false);
    };
    useEffect(() => {
        getUserDetails();
      }, []);
    
      useEffect(() => {
        if (providerStatus === "invite" && !common.termsCondView) {
          common.progressBar = 0.92;
        } else if (providerStatus === "address") {
          common.progressBar = 0.94;
          common.termsCondView = false;
        } else if (providerStatus === "bank-details") {
          common.progressBar = 0.96;
          common.termsCondView = false;
        }
        else if (providerStatus === "kyp") {
          common.progressBar = 0.98
          common.termsCondView = false
          setCommon(common)
        }
      }, [common.progressBar && common.termsCondView]);
    
      const getUserDetails = async () => {
        localStorage.removeItem("coachMarker")
        await instance({
          method: "GET",
          url: "user/getUserDetails",
          validateStatus: () => true,
        }).then((resp) => {
          let obj: any = {};
          obj = resp;
          if (obj.status === "SUCCESS!") {
            let data = obj.result[0];
            common.providerData.providerObj = data;
            setCommon(common);
            if (obj.result[0] && obj.result[0].mybenefitsprovider && obj.result[0].mybenefitsprovider.displayName) {
              localStorage.setItem("providerName", obj.result[0].mybenefitsprovider.displayName);
            }
            else if (obj.result[0] && obj.result[0].mybenefitsprovider && obj.result[0].mybenefitsprovider.providerFirstName) {
              localStorage.setItem("providerName", obj.result[0].mybenefitsprovider.providerFirstName)
            }
            else {
              localStorage.clear()
            }
            localStorage.setItem("providerStatus", obj.result[0].status);
            //localStorage.setItem("providerStatus", 'kyp');
            localStorage.setItem(
              "isBankAdded",
              obj.result[0].mybenefitsprovider.isBankAdded
            );
          } else {
           
          }
        });
      };
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {providerStatus !== "dashboard" && (
                <IonModal
                    backdropDismiss={
                        providerStatus === "invite" || providerStatus === "address"
                            ? false
                            : true
                    }
                    className="signup-modal"
                    isOpen={activateAccountModal}
                    onDidDismiss={() => dismissModal()}
                >
                    <IonContent>
                        <EmailInviteSignUp
                            dismissModal={dismissModal}
                        ></EmailInviteSignUp>
                    </IonContent>
                </IonModal>
            )}
        </div>
    )
}
export default ProgressBar